import React, { Fragment, useEffect, useState, useRef } from "react";
import Nav from "./Nav";
import Footer from "./Footer";

const Banner = () => {
  return (
    <>
      <body class="page-template-default page page-id-12401 wp-custom-logo page-agency">
        <main class="site-content">
          <section class="text-light pb-0 content-text content-textarea next-has-stripes has-stripes">
            <div class="stripes">
              <div class="stripes-bg"></div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div class="background-image-container">
              <div style={{ backgroundColor: "#13111c" }}></div>
            </div>
            <video
              class="video-bg"
              loop=""
              muted=""
              autoplay=""
              playsinline=""
              style={{ opacity: "0.15" }}
            >
              <source
                src="https://kingkong.co/uk/wp-content/uploads/sites/4/2021/05/hero-bg-video-2021.mp4"
                type="video/mp4"
              />
            </video>
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-12 text-center fade-in">
                  <span class="eyebrow__flashing">
                    <span class="eyebrow__flashing-dot"></span>
                    Breaking News: 26th of May, 2024
                  </span>
                </div>
                <div class="col-md-11 col-lg-9 text-center fade-in">
                  <span class="h2">Like a ticket to your dream job</span>
                </div>

                <div class="col-12">
                  <form
                    class="infusion-form round-form js-hero-multistep"
                    id="hero-multistep"
                    accept-charset="UTF-8"
                    action="https://wg216.infusionsoft.com/app/form/process/3e47b59487f15f3b034988453fe7f485"
                    method="POST"
                    novalidate="novalidate"
                  >
                    <div class="sliding-element active">
                      <input
                        id="your-email"
                        class="infusion-field-input-container"
                        name="inf_field_Email"
                        type="email"
                        placeholder="Enter your email here and we'll send you some 'magic'..."
                        required=""
                      />
                    </div>
                    <div class="sliding-element last-field">
                      <input
                        id="your-name"
                        class="infusion-field-input-container"
                        name="inf_field_FirstName"
                        type="text"
                        placeholder="Please also enter your name..."
                        required=""
                      />
                    </div>
                    <input
                      name="inf_form_xid"
                      type="hidden"
                      value="3e47b59487f15f3b034988453fe7f485"
                    />
                    <input
                      name="inf_form_name"
                      type="hidden"
                      value="Sign up for eBook"
                    />
                    <input
                      name="infusionsoft_version"
                      type="hidden"
                      value="1.70.0.412678"
                    />
                    <input name="inf_custom_GaContent" type="hidden" value="" />
                    <input name="inf_custom_GaSource" type="hidden" value="" />
                    <input name="inf_custom_GaMedium" type="hidden" value="" />
                    <input name="inf_custom_GaTerm" type="hidden" value="" />
                    <input
                      name="inf_custom_IPAddress"
                      type="hidden"
                      value="102.89.47.204"
                    />
                    <input
                      name="inf_custom_GaCampaign"
                      type="hidden"
                      value=""
                    />
                    <button type="submit" style={{ color: "white !important" }}>
                      Do it
                    </button>
                    <input
                      type="hidden"
                      name="inf_field_LeadSourceId"
                      value="direct-none-link"
                    />
                  </form>
                </div>
                <div class="col-12 col-md-9 col-lg-8 col-xl-6 null">
                  <div class="hero-video">
                    <span class="wistia-close">+</span>
                    <div
                      class="wistia-embed"
                      data-inline=""
                      data-wistia-id="pjvyj3bhy2"
                    >
                      <div class="wistia-embed__inner">
                        <picture>
                          <source
                            type="image/webp"
                            data-lazy-srcset="https://cdn.enhancv.com/single_column_resume_template_2_120817dd43.png"
                            srcset="
                            https://cdn.enhancv.com/single_column_resume_template_2_120817dd43.png
                    "
                          />
                          <img
                            width="1920"
                            height="1080"
                            src="https://cdn.enhancv.com/single_column_resume_template_2_120817dd43.png"
                            alt=""
                            data-lazy-src="https://cdn.enhancv.com/single_column_resume_template_2_120817dd43.png"
                            data-ll-status="loaded"
                            class="entered lazyloaded"
                          />
                        </picture>
                        <noscript>
                          <picture>
                            <source
                              type="image/webp"
                              srcset="
                              https://cdn.enhancv.com/single_column_resume_template_2_120817dd43.png
                      "
                            />
                            <img
                              width="1920"
                              height="1080"
                              src="https://cdn.enhancv.com/single_column_resume_template_2_120817dd43.png"
                              alt=""
                            />
                          </picture>
                        </noscript>
                        <div class="wistia-embed__button wistia-embed__button--orange">
                          <div class="wistia-embed__play"></div>
                        </div>
                        <div class="wistia-embed__time">7:47</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class="text-dark content-global awards next-has-stripes has-stripes">
            <div class="stripes">
              <div class="stripes-bg"></div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div
              class="background-image-container"
              style={{ backgroundColor: "#ffffff" }}
            ></div>
            <div class="container">
              <div class="row justify-content-center">
                <div class="image-marquee image-marquee--double">
                  <div
                    class="image-marquee__gradient--left"
                    style={{
                      background:
                        "linear-gradient(90deg, #ffffff 14.9%, rgba(55, 55, 55, 0) 100%)",
                    }}
                  ></div>
                  <div
                    class="image-marquee__gradient--right"
                    style={{
                      background:
                        "linear-gradient(90deg, #ffffff 14.9%, rgba(55, 55, 55, 0) 100%)",
                    }}
                  ></div>
                  <div class="image-marquee__scroller">
                    <div class="image-marquee__scroller__item">
                      <img
                        width="2109"
                        height="121"
                        src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%202109%20121'%3E%3C/svg%3E"
                        class=""
                        alt=""
                        data-lazy-src="https://kingkong.co/wp-content/uploads/2020/12/awards-row.svg"
                      />
                      <noscript>
                        <img
                          width="2109"
                          height="121"
                          src="https://kingkong.co/wp-content/uploads/2020/12/awards-row.svg"
                          class=""
                          alt=""
                        />
                      </noscript>
                      <img
                        width="2109"
                        height="121"
                        src="https://kingkong.co/wp-content/uploads/2020/12/awards-row.svg"
                        class="entered lazyloaded"
                        alt=""
                        data-lazy-src="https://kingkong.co/wp-content/uploads/2020/12/awards-row.svg"
                        data-ll-status="loaded"
                      />
                      <noscript>
                        <img
                          width="2109"
                          height="121"
                          src="https://kingkong.co/wp-content/uploads/2020/12/awards-row.svg"
                          class=""
                          alt=""
                        />
                      </noscript>
                      <img
                        width="2109"
                        height="121"
                        src="https://kingkong.co/wp-content/uploads/2020/12/awards-row.svg"
                        class="entered lazyloaded"
                        alt=""
                        data-lazy-src="https://kingkong.co/wp-content/uploads/2020/12/awards-row.svg"
                        data-ll-status="loaded"
                      />
                      <noscript>
                        <img
                          width="2109"
                          height="121"
                          src="https://kingkong.co/wp-content/uploads/2020/12/awards-row.svg"
                          class=""
                          alt=""
                        />
                      </noscript>
                      <img
                        width="2109"
                        height="121"
                        src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%202109%20121'%3E%3C/svg%3E"
                        class=""
                        alt=""
                        data-lazy-src="https://kingkong.co/wp-content/uploads/2020/12/awards-row.svg"
                      />
                      <noscript>
                        <img
                          width="2109"
                          height="121"
                          src="https://kingkong.co/wp-content/uploads/2020/12/awards-row.svg"
                          class=""
                          alt=""
                        />
                      </noscript>
                      <img
                        width="2109"
                        height="121"
                        src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%202109%20121'%3E%3C/svg%3E"
                        class=""
                        alt=""
                        data-lazy-src="https://kingkong.co/wp-content/uploads/2020/12/awards-row.svg"
                      />
                      <noscript>
                        <img
                          width="2109"
                          height="121"
                          src="https://kingkong.co/wp-content/uploads/2020/12/awards-row.svg"
                          class=""
                          alt=""
                        />
                      </noscript>
                      <img
                        width="2109"
                        height="121"
                        src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%202109%20121'%3E%3C/svg%3E"
                        class=""
                        alt=""
                        data-lazy-src="https://kingkong.co/wp-content/uploads/2020/12/awards-row.svg"
                      />
                      <noscript>
                        <img
                          width="2109"
                          height="121"
                          src="https://kingkong.co/wp-content/uploads/2020/12/awards-row.svg"
                          class=""
                          alt=""
                        />
                      </noscript>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class="text-dark content-wysiwyg next-has-stripes has-stripes">
            <div class="stripes">
              <div class="stripes-bg"></div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div
              class="background-image-container"
              style={{ backgroundColor: "#f3f3f3" }}
            ></div>
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-xxl-7 col-xl-8 col-md-9">
                  <p>
                    <strong class="date-updated">
                      Updated: 24th of May, 2024
                    </strong>
                  </p>
                  <p>Dear Business Builder,</p>
                  <p>
                    If you would like to get as many clients, customers and
                    sales as your business could possibly handle…
                  </p>
                  <p>
                    Then this will be the most exciting message you’ll ever
                    read.
                  </p>
                  <p>Here’s why:</p>
                  <p>
                    Let’s face it…You would have to be a zombie from the Walking
                    Dead – for the above offer not to have your pulse racing,
                    greed glands pumping…
                  </p>
                  <p>
                    And no doubt your inner sceptic screaming
                    <strong>“what on earth is this all about?!!”</strong>
                  </p>
                  <p>
                    I mean what business owner, in their right mind, wouldn’t
                    question a company making such a bold claim?
                  </p>
                  <p>
                    <em>
                      But don’t worry your pretty little mittens, because we’ve
                      come with a mountain of credentials and a parade of proof
                      to back it all up…
                    </em>
                  </p>
                  <p>*grabs popcorn*</p>
                  <p>
                    For starters, we’ve developed a unique ‘selling system’
                    which is unlike anything you’ve ever seen or heard about
                    before…
                  </p>
                  <p>
                    (this is just one of the things that makes this all
                    possible) and we’ve used it in over…
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section class="text-dark content-text content-code content-wysiwyg content-textarea next-has-stripes has-stripes">
            <div class="stripes">
              <div class="stripes-bg"></div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div
              class="background-image-container"
              style={{ backgroundColor: "#ffffff" }}
            ></div>
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-md-10 col-lg-9 text-center fade-in">
                  <span class="h2">
                    1067 Different Industries And Niches To Generate $7.8
                    Billion In Sales
                  </span>
                </div>
                <div class="col-12">
                  <lottie-player
                    src="/wp-content/uploads/lottie/graph.json"
                    background="transparent"
                    speed="1"
                    style={{ width: "100%", height: "100%" }}
                    loop=""
                    autoplay=""
                  ></lottie-player>
                  <br />
                </div>
                <div class="col-xxl-7 col-xl-8 col-md-9 moneybag-list">
                  <p>
                    <em>And we’ve also used this selling system to…</em>
                  </p>
                  <ul class="styled-list">
                    <li>
                      <strong>
                        Take a home building start-up from $0 to $7 million in 8
                        months flat…
                      </strong>
                      (they hit their building insurance quota and couldn’t take
                      on any more clients!)
                    </li>
                    <li>
                      <strong>
                        Transform a Pilates instructor training business, from
                        barely breaking even at $200k in revenue…
                      </strong>
                      to a wildly successful business with $2 million in annual
                      sales in 14 months.
                    </li>
                    <li>
                      <strong>
                        Launch a mattress start-up from $0 to $13 million in
                        revenue in their first 12 months….
                      </strong>
                      (Literally selling out warehouses full of stock and the
                      manufacturer couldn’t keep up!)
                    </li>
                    <li>
                      <strong>
                        Take a small father and son building business from $3
                        million to $50 million
                      </strong>
                      in sales in just 18 MONTHS!
                    </li>
                    <li>
                      <strong>
                        Skyrocket the sales of an Aged Care provider from $4
                        million to $25 million in sales in 12 months flat!
                      </strong>
                      (This was all done with a basic 2-step funnel and one
                      traffic source!)
                    </li>
                    <li>
                      <strong>
                        Sell $51 million dollars’ worth of property in 8 WEEKS
                        through one funnel!
                      </strong>
                      (This was for a client in a super-niche property market
                      and took him from zero to hero within two months)
                    </li>
                  </ul>
                  <p>
                    And so on…..Listen, we could easily fill up this entire page
                    with hundreds of success stories like this…
                  </p>
                  <p>
                    Because we’ve used this ‘selling system’ in almost every
                    industry you can think of.
                  </p>
                  <p>
                    And we’ve worked with small businesses, all the way up to
                    multi-billion dollar brands to apply this “selling system”…
                  </p>
                  <p>
                    In other words, our “system” for selling is the hottest (and
                    highest converting) sales vehicle we’ve ever seen for…
                  </p>
                </div>
                <div class="col-lg-10 col-xxl-8 null">
                  <a
                    class="btn btn-doublelayer btn-green"
                    href="/30-minute-strategy-session/"
                  >
                    <span class="btn-doublelayer__maintext">
                      Claim Your Free 30-Minute Strategy Session
                    </span>
                    <span class="btn-doublelayer__subtext">
                      Be quick! FREE spots are almost gone for May
                    </span>
                  </a>
                  <a
                    href="https://kingkong.co/reviews"
                    itemscope=""
                    itemtype="http://schema.org/LocalBusiness"
                    class="kkra-shortstring term-agency"
                  >
                    <meta
                      itemprop="image"
                      content="https://kingkong.co/wp-content/themes/kingkong/assets/src/images/site-logo.png"
                    />
                    <meta itemprop="telephone" content="+ 1-888-303-8580" />
                    <meta itemprop="name" content="King Kong" />
                    <meta itemprop="priceRange" content="$$$" />
                    <span
                      itemprop="address"
                      itemscope=""
                      itemtype="http://schema.org/PostalAddress"
                    >
                      <meta
                        itemprop="streetAddress"
                        content="Oppenheimer Tower, 10880 Wilshire Boulevard"
                      />
                      <meta itemprop="addressLocality" content="Los Angeles" />
                      <meta itemprop="addressRegion" content="California" />
                      <meta itemprop="addressCountry" content="United States" />
                      <meta itemprop="postalCode" content="90024" />
                    </span>
                    <div
                      class="kkra-shortstring__inner"
                      itemprop="aggregateRating"
                      itemscope=""
                      itemtype="http://schema.org/AggregateRating"
                    >
                      <div class="kkra-stars">
                        <svg
                          class="unfilled"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 104 18"
                        >
                          <path
                            d="M17.2 6l-4.5-.3c-.4-.1-.7-.3-.9-.7L10.1.7C9.7-.2 8.5-.2 8.2.6L6.4 4.9c-.2.4-.5.7-.9.7L1 6C0 6.1-.3 7.3.4 8l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2 1 .8 1.7 1.6 1.2l3.9-2.4c.3-.2.8-.2 1.1 0l3.9 2.4c.8.5 1.8-.2 1.6-1.2l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3.1c.7-.6.3-1.8-.6-1.9z"
                            class="st0"
                          ></path>
                          <path
                            d="M9.1 1s.1 0 0 0l1.8 4.4c.3.6.8 1 1.6 1.2h.2l4.4.4v.1l-3.4 3.1c-.5.5-.7 1.3-.6 2l1 4.5v.1H14l-3.9-2.4c-.3-.2-.7-.3-1.1-.3-.4 0-.8.1-1.1.3L4 16.8h-.1v-.1l1-4.5c.2-.7 0-1.5-.6-2.1L1 7.1V7h.1l4.5-.4c.7 0 1.4-.5 1.7-1.3L9.1 1m0-1c-.4 0-.8.2-.9.6L6.4 4.9c-.2.4-.5.7-.9.7L1 6C0 6.1-.3 7.3.4 8l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2.8.4 1.4 1 1.4.2 0 .4-.1.6-.2l3.9-2.4c.1-.1.3-.2.5-.2s.4.1.5.2l3.9 2.4c.2.1.4.2.6.2.6 0 1.2-.6 1-1.4l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3.1c.7-.6.3-1.8-.6-1.9l-4.5-.3c-.3-.1-.6-.3-.8-.7L10.1.7c-.2-.5-.6-.7-1-.7z"
                            class="st1"
                          ></path>
                          <path
                            d="M38.6 6l-4.4-.3c-.4-.1-.7-.3-.9-.7L31.5.7c-.3-.9-1.6-.9-1.9 0L27.8 5c-.2.4-.5.7-.9.7l-4.5.4c-.9 0-1.3 1.3-.6 1.9l3.4 3c.3.3.4.7.3 1.1l-1 4.5c-.2 1 .8 1.7 1.6 1.2l3.9-2.4c.3-.2.8-.2 1.1 0l3.9 2.4c.8.5 1.8-.2 1.6-1.2l-1-4.5c-.1-.4 0-.8.3-1.1l3.3-3c.8-.7.4-1.9-.6-2z"
                            class="st0"
                          ></path>
                          <path
                            d="M30.6 1l1.8 4.4c.3.6.8 1 1.6 1.2h.2l4.3.4c.1 0 .1 0 .1.1s0 .1-.1.2l-3.3 3c-.5.5-.7 1.3-.6 2l1 4.5v.1h-.1l-3.9-2.4c-.3-.2-.7-.3-1.1-.3-.4 0-.8.1-1.1.3l-3.9 2.4h-.1v-.1l1-4.5c.2-.7 0-1.5-.6-2l-3.4-3v-.1l4.6-.5c.7 0 1.4-.5 1.7-1.3L30.6 1c-.1 0-.1 0 0 0m0-1c-.4 0-.8.2-1 .7L27.8 5c-.2.4-.5.7-.9.7l-4.5.4c-.9 0-1.3 1.3-.6 1.9l3.4 3c.3.3.4.7.3 1.1l-1 4.5c-.2.8.4 1.4 1 1.4.2 0 .4-.1.6-.2l3.9-2.4c.1-.1.3-.2.5-.2s.4.1.5.2l3.9 2.4c.2.1.4.2.6.2.6 0 1.2-.6 1-1.4l-1-4.5c-.1-.4 0-.8.3-1.1l3.3-3c.8-.7.4-1.9-.6-2l-4.4-.3c-.4-.1-.7-.3-.9-.7L31.4.7c0-.5-.4-.7-.8-.7z"
                            class="st1"
                          ></path>
                          <path
                            d="M60.1 6l-4.5-.3c-.4-.1-.7-.3-.9-.7L53 .7c-.4-.9-1.6-.9-2 0l-1.7 4.2c-.2.4-.5.7-.9.7l-4.5.4c-.9.1-1.3 1.3-.6 2l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2 1 .8 1.7 1.6 1.2l3.9-2.4c.3-.2.8-.2 1.1 0l3.9 2.4c.8.5 1.8-.2 1.6-1.2l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3c.7-.7.3-1.9-.6-2z"
                            class="st0"
                          ></path>
                          <path
                            d="M52 1s.1 0 0 0l1.8 4.3c.3.6.8 1 1.6 1.2h.2L60 7l.1.1v.2l-3.4 3c-.5.5-.7 1.3-.6 2l1 4.5v.1H57l-3.9-2.4c-.3-.2-.7-.3-1.1-.3-.4 0-.8.1-1.1.3L47 16.9h-.1v-.1l1-4.5c.2-.7 0-1.5-.6-2.1l-3.3-3c-.1-.1-.1-.2-.1-.2h.1l4.5-.4c.7 0 1.4-.5 1.7-1.3L52 1c-.1 0 0 0 0 0m0-1c-.4 0-.8.2-1 .7l-1.7 4.2c-.2.4-.5.7-.9.7l-4.5.4c-.9.1-1.3 1.3-.6 2l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2.8.4 1.4 1 1.4.2 0 .4-.1.6-.2l3.9-2.4c.1-.1.3-.2.5-.2s.4.1.5.2l3.9 2.4c.2.1.4.2.6.2.6 0 1.2-.6 1-1.4l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3c.7-.7.3-1.9-.6-2l-4.5-.3c-.4-.1-.7-.3-.9-.7L53 .7c-.2-.5-.6-.7-1-.7z"
                            class="st1"
                          ></path>
                          <g>
                            <path
                              d="M81.6 6l-4.5-.3c-.4-.1-.7-.3-.9-.7L74.4.7c-.3-.9-1.6-.9-1.8-.1l-1.8 4.3c-.2.4-.5.7-.9.7l-4.5.4c-1 .1-1.4 1.3-.6 2l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2 1 .8 1.7 1.6 1.2l3.9-2.4c.3-.2.8-.2 1.1 0l3.9 2.4c.8.5 1.8-.2 1.6-1.2l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3.1c.7-.6.3-1.8-.6-1.9z"
                              class="st0"
                            ></path>
                            <path
                              d="M73.5 1.1l1.8 4.3c.3.6.8 1 1.6 1.2h.2l4.4.4v.1l-3.4 3.1c-.5.5-.7 1.3-.6 2l1 4.5v.1h-.1l-3.9-2.4c-.3-.2-.7-.3-1.1-.3-.4 0-.8.1-1.1.3l-3.9 2.4h-.1v-.1l1-4.5c.2-.7 0-1.5-.6-2.1l-3.3-3c-.1-.1-.1-.1-.1-.2l.1-.1 4.6-.2c.7 0 1.4-.5 1.7-1.3l1.8-4.2M73.4 0c-.4 0-.7.2-.8.6l-1.8 4.3c-.2.4-.5.7-.9.7l-4.5.4c-1 .1-1.4 1.3-.6 2l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2.8.4 1.4 1 1.4.2 0 .4-.1.6-.2l3.9-2.4c.1-.1.3-.2.5-.2s.4.1.5.2l3.9 2.4c.2.1.4.2.6.2.6 0 1.2-.6 1-1.4l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3.1c.7-.6.3-1.8-.6-1.9L77 5.7c-.4-.1-.7-.3-.9-.7L74.3.7c-.1-.5-.5-.7-.9-.7z"
                              class="st1"
                            ></path>
                          </g>
                          <g>
                            <path
                              d="M103.6 7.9c.7-.6.3-1.8-.6-1.9l-4.4-.3c-.4-.1-.7-.3-.9-.7L95.9.7c-.4-.9-1.6-.9-1.9 0L92.2 5c-.2.4-.5.7-.9.7l-4.5.4c-.9 0-1.3 1.3-.6 1.9l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2 1 .8 1.7 1.6 1.2l3.9-2.4c.3-.2.8-.2 1.1 0l3.9 2.4c.8.5 1.8-.2 1.6-1.2l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3.1z"
                              class="st0"
                            ></path>
                            <path
                              d="M94.9 1c.1 0 .1.1 0 0l1.8 4.3c.3.6.8 1 1.6 1.2h.2l4.4.3v.1L99.5 10c-.5.5-.7 1.3-.6 2l1 4.5v.1h-.1l-3.8-2c-.3-.2-.7-.3-1.1-.3-.4 0-.8.1-1.1.3L89.9 17h-.1v-.1l1-4.5c.2-.7 0-1.5-.6-2.1l-3.3-3v-.1l4.5-.4c.7 0 1.4-.5 1.7-1.3L94.9 1m0-1c-.4 0-.8.2-.9.7L92.2 5c-.2.4-.5.7-.9.7l-4.5.4c-.9 0-1.3 1.3-.6 1.9l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2.8.4 1.4 1 1.4.2 0 .4-.1.6-.2l3.9-2.4c.2-.1.3-.2.6-.2s.4.1.6.2l3.9 2.4c.2.1.4.2.6.2.6 0 1.2-.6 1-1.4l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3.1c.7-.6.3-1.8-.6-1.9l-4.4-.3c-.4-.1-.7-.3-.9-.7L96 .7c-.3-.5-.7-.7-1.1-.7z"
                              class="st1"
                            ></path>
                          </g>
                        </svg>
                        <div
                          class="kkra-stars__filled"
                          style={{ width: "90%" }}
                        >
                          <svg
                            class="filled"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 104 18"
                          >
                            <g transform="translate(-160 -526)">
                              <g transform="translate(64 308)">
                                <g transform="translate(96 218)">
                                  <g>
                                    <path
                                      d="M10.1.7L11.8 5c.2.4.5.6.9.7l4.5.3c.9.1 1.3 1.3.6 1.9L14.4 11c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1L.4 8c-.7-.7-.4-1.9.6-2l4.5-.4c.4 0 .7-.3.9-.7L8.2.6c.3-.8 1.5-.8 1.9.1"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M31.5.7L33.3 5c.2.4.5.6.9.7l4.4.3c1 .1 1.4 1.3.6 2l-3.3 3c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.4-3c-.7-.6-.3-1.9.6-1.9l4.5-.4c.4 0 .7-.3.9-.7L29.6.7c.3-.9 1.6-.9 1.9 0"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M53 .7L54.7 5c.2.4.5.6.9.7l4.5.3c.9.1 1.3 1.3.6 2l-3.4 3c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.3-3c-.7-.7-.3-1.9.6-2l4.5-.4c.4 0 .7-.3.9-.7L51 .7c.4-.9 1.6-.9 2 0"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M74.4.7L76.2 5c.2.4.5.6.9.7l4.5.3c.9.1 1.3 1.3.6 1.9L78.8 11c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2L74 15.4c-.3-.2-.8-.2-1.1 0L69 17.8c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.3-3c-.8-.7-.4-1.9.6-2l4.5-.4c.4 0 .7-.3.9-.7L72.6.6c.2-.8 1.5-.8 1.8.1"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M95.9.7L97.7 5c.2.4.5.6.9.7l4.4.3c.9.1 1.3 1.3.6 1.9l-3.4 3.1c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.3-3c-.7-.6-.3-1.9.6-1.9l4.5-.4c.4 0 .7-.3.9-.7L94 .7c.3-.9 1.5-.9 1.9 0"
                                      class="st20"
                                    ></path>
                                  </g>
                                  <g>
                                    <path
                                      d="M10.1.7L11.8 5c.2.4.5.6.9.7l4.5.3c.9.1 1.3 1.3.6 1.9L14.4 11c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1L.4 8c-.7-.7-.4-1.9.6-2l4.5-.4c.4 0 .7-.3.9-.7L8.2.6c.3-.8 1.5-.8 1.9.1"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M31.5.7L33.3 5c.2.4.5.6.9.7l4.4.3c1 .1 1.4 1.3.6 2l-3.3 3c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.4-3c-.7-.6-.3-1.9.6-1.9l4.5-.4c.4 0 .7-.3.9-.7L29.6.7c.3-.9 1.6-.9 1.9 0"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M53 .7L54.7 5c.2.4.5.6.9.7l4.5.3c.9.1 1.3 1.3.6 2l-3.4 3c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.3-3c-.7-.7-.3-1.9.6-2l4.5-.4c.4 0 .7-.3.9-.7L51 .7c.4-.9 1.6-.9 2 0"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M74.4.7L76.2 5c.2.4.5.6.9.7l4.5.3c.9.1 1.3 1.3.6 1.9L78.8 11c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2L74 15.4c-.3-.2-.8-.2-1.1 0L69 17.8c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.3-3c-.8-.7-.4-1.9.6-2l4.5-.4c.4 0 .7-.3.9-.7L72.6.6c.2-.8 1.5-.8 1.8.1"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M95.9.7L97.7 5c.2.4.5.6.9.7l4.4.3c.9.1 1.3 1.3.6 1.9l-3.4 3.1c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.3-3c-.7-.6-.3-1.9.6-1.9l4.5-.4c.4 0 .7-.3.9-.7L94 .7c.3-.9 1.5-.9 1.9 0"
                                      class="st20"
                                    ></path>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </svg>
                        </div>
                      </div>
                      <span itemprop="ratingValue">4.5 </span>stars out of
                      <span itemprop="ratingCount">383 reviews</span>
                      <div
                        itemprop="itemReviewed"
                        itemscope=""
                        itemtype="http://schema.org/LocalBusiness"
                      >
                        <meta
                          itemprop="image"
                          content="https://kingkong.co/wp-content/themes/kingkong/assets/src/images/site-logo.png"
                        />
                        <meta itemprop="telephone" content="+ 1-888-303-8580" />
                        <meta itemprop="name" content="King Kong" />
                        <meta itemprop="priceRange" content="$$$" />
                        <span
                          itemprop="address"
                          itemscope=""
                          itemtype="http://schema.org/PostalAddress"
                        >
                          <meta
                            itemprop="streetAddress"
                            content="Oppenheimer Tower, 10880 Wilshire Boulevard"
                          />
                          <meta
                            itemprop="addressLocality"
                            content="Los Angeles"
                          />
                          <meta itemprop="addressRegion" content="California" />
                          <meta
                            itemprop="addressCountry"
                            content="United States"
                          />
                          <meta itemprop="postalCode" content="90024" />
                        </span>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section class="text-dark content-text content-global video-stripe-proof content-wysiwyg content-textarea next-has-stripes next-is-pull-up has-stripes">
            <div class="stripes">
              <div class="stripes-bg"></div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div
              class="background-image-container"
              style={{ backgroundColor: "#f3f3f3" }}
            ></div>
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-md-11 col-lg-10 col-xl-9 text-center fade-in">
                  <span class="h2">
                    Generating A Torrential Downpour Of Customers That Flood
                    Your Business!
                  </span>
                </div>
                <div class="col-12">
                  <video
                    width="100%"
                    height="auto"
                    muted=""
                    playsinline=""
                    autoplay=""
                    loop=""
                    class="video-drop-shadow"
                    style={{
                      maxWidth: "1000px",
                      marginLeft: "auto",
                      marginRight: "auto",
                      display: "block",
                      marginBottom: "30px",
                    }}
                  >
                    <source
                      src="https://kingkong.co/wp-content/uploads/2021/05/stripe-proof.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div class="col-xxl-7 col-xl-8 col-md-9 mt-4">
                  <p>
                    And now, we’re truly making an offer which would be
                    difficult for any sane business owner to refuse –
                    <strong>to try it out risk-free!</strong>
                  </p>
                  <p>
                    Firstly, if you have an ad campaign on Google or Facebook,
                    we’ll completely rewrite your ads.
                  </p>
                  <p>
                    Secondly, you won’t pay us
                    <em>
                      a single cent of management fees in testing our ads
                      against the ones you’re already running.
                    </em>
                  </p>
                  <p>
                    What this means is, if we fail (very unlikely) to beat your
                    current campaigns, you won’t owe us a single red cent.
                  </p>
                  <p>Think about it, if we don’t deliver the goods you pay…</p>
                  <ul class="styled-list">
                    <li>NO set-up fees!</li>
                    <li>NO management fees!</li>
                    <li>NO copywriting fees!</li>
                    <li>NO design or development fees!</li>
                    <li>
                      No unicorn catching fees! (had to check I still
                      <br />
                      have your attention)
                    </li>
                    <li>
                      <strong>In fact, you pay us nothing!</strong>
                    </li>
                  </ul>
                  <p>And guess what else?</p>
                  <p>
                    If we do beat your current ad campaigns (highly likely)
                    <em>you’ll still not be out of any money…</em>
                  </p>
                  <p>
                    Because every dollar you pay us will come from the EXTRA
                    sales you wouldn’t have made unless we created our new
                    campaign for you!
                  </p>
                  <p>Why are we making such an offer?</p>
                  <p>Well, It’s very simple…</p>
                  <p>
                    If you’re like most business owners you’ve likely tried
                    almost every conceivable way to grow your business.
                  </p>
                  <p>Some of it’s worked, but most of it hasn’t.</p>
                  <p>
                    And at this point, you’ve probably been lied to, cheated by,
                    stolen from, extorted and downright…
                  </p>
                </div>
                <div class="col-lg-10 col-xxl-8 null">
                  <a
                    class="btn btn-doublelayer btn-green"
                    href="/30-minute-strategy-session/"
                  >
                    <span class="btn-doublelayer__maintext">
                      Claim Your Free 30-Minute Strategy Session
                    </span>
                    <span class="btn-doublelayer__subtext">
                      Be quick! FREE spots are almost gone for May
                    </span>
                  </a>
                  <a
                    href="https://kingkong.co/reviews"
                    itemscope=""
                    itemtype="http://schema.org/LocalBusiness"
                    class="kkra-shortstring term-agency"
                  >
                    <meta
                      itemprop="image"
                      content="https://kingkong.co/wp-content/themes/kingkong/assets/src/images/site-logo.png"
                    />
                    <meta itemprop="telephone" content="+ 1-888-303-8580" />
                    <meta itemprop="name" content="King Kong" />
                    <meta itemprop="priceRange" content="$$$" />
                    <span
                      itemprop="address"
                      itemscope=""
                      itemtype="http://schema.org/PostalAddress"
                    >
                      <meta
                        itemprop="streetAddress"
                        content="Oppenheimer Tower, 10880 Wilshire Boulevard"
                      />
                      <meta itemprop="addressLocality" content="Los Angeles" />
                      <meta itemprop="addressRegion" content="California" />
                      <meta itemprop="addressCountry" content="United States" />
                      <meta itemprop="postalCode" content="90024" />
                    </span>
                    <div
                      class="kkra-shortstring__inner"
                      itemprop="aggregateRating"
                      itemscope=""
                      itemtype="http://schema.org/AggregateRating"
                    >
                      <div class="kkra-stars">
                        <svg
                          class="unfilled"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 104 18"
                        >
                          <path
                            d="M17.2 6l-4.5-.3c-.4-.1-.7-.3-.9-.7L10.1.7C9.7-.2 8.5-.2 8.2.6L6.4 4.9c-.2.4-.5.7-.9.7L1 6C0 6.1-.3 7.3.4 8l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2 1 .8 1.7 1.6 1.2l3.9-2.4c.3-.2.8-.2 1.1 0l3.9 2.4c.8.5 1.8-.2 1.6-1.2l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3.1c.7-.6.3-1.8-.6-1.9z"
                            class="st0"
                          ></path>
                          <path
                            d="M9.1 1s.1 0 0 0l1.8 4.4c.3.6.8 1 1.6 1.2h.2l4.4.4v.1l-3.4 3.1c-.5.5-.7 1.3-.6 2l1 4.5v.1H14l-3.9-2.4c-.3-.2-.7-.3-1.1-.3-.4 0-.8.1-1.1.3L4 16.8h-.1v-.1l1-4.5c.2-.7 0-1.5-.6-2.1L1 7.1V7h.1l4.5-.4c.7 0 1.4-.5 1.7-1.3L9.1 1m0-1c-.4 0-.8.2-.9.6L6.4 4.9c-.2.4-.5.7-.9.7L1 6C0 6.1-.3 7.3.4 8l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2.8.4 1.4 1 1.4.2 0 .4-.1.6-.2l3.9-2.4c.1-.1.3-.2.5-.2s.4.1.5.2l3.9 2.4c.2.1.4.2.6.2.6 0 1.2-.6 1-1.4l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3.1c.7-.6.3-1.8-.6-1.9l-4.5-.3c-.3-.1-.6-.3-.8-.7L10.1.7c-.2-.5-.6-.7-1-.7z"
                            class="st1"
                          ></path>
                          <path
                            d="M38.6 6l-4.4-.3c-.4-.1-.7-.3-.9-.7L31.5.7c-.3-.9-1.6-.9-1.9 0L27.8 5c-.2.4-.5.7-.9.7l-4.5.4c-.9 0-1.3 1.3-.6 1.9l3.4 3c.3.3.4.7.3 1.1l-1 4.5c-.2 1 .8 1.7 1.6 1.2l3.9-2.4c.3-.2.8-.2 1.1 0l3.9 2.4c.8.5 1.8-.2 1.6-1.2l-1-4.5c-.1-.4 0-.8.3-1.1l3.3-3c.8-.7.4-1.9-.6-2z"
                            class="st0"
                          ></path>
                          <path
                            d="M30.6 1l1.8 4.4c.3.6.8 1 1.6 1.2h.2l4.3.4c.1 0 .1 0 .1.1s0 .1-.1.2l-3.3 3c-.5.5-.7 1.3-.6 2l1 4.5v.1h-.1l-3.9-2.4c-.3-.2-.7-.3-1.1-.3-.4 0-.8.1-1.1.3l-3.9 2.4h-.1v-.1l1-4.5c.2-.7 0-1.5-.6-2l-3.4-3v-.1l4.6-.5c.7 0 1.4-.5 1.7-1.3L30.6 1c-.1 0-.1 0 0 0m0-1c-.4 0-.8.2-1 .7L27.8 5c-.2.4-.5.7-.9.7l-4.5.4c-.9 0-1.3 1.3-.6 1.9l3.4 3c.3.3.4.7.3 1.1l-1 4.5c-.2.8.4 1.4 1 1.4.2 0 .4-.1.6-.2l3.9-2.4c.1-.1.3-.2.5-.2s.4.1.5.2l3.9 2.4c.2.1.4.2.6.2.6 0 1.2-.6 1-1.4l-1-4.5c-.1-.4 0-.8.3-1.1l3.3-3c.8-.7.4-1.9-.6-2l-4.4-.3c-.4-.1-.7-.3-.9-.7L31.4.7c0-.5-.4-.7-.8-.7z"
                            class="st1"
                          ></path>
                          <path
                            d="M60.1 6l-4.5-.3c-.4-.1-.7-.3-.9-.7L53 .7c-.4-.9-1.6-.9-2 0l-1.7 4.2c-.2.4-.5.7-.9.7l-4.5.4c-.9.1-1.3 1.3-.6 2l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2 1 .8 1.7 1.6 1.2l3.9-2.4c.3-.2.8-.2 1.1 0l3.9 2.4c.8.5 1.8-.2 1.6-1.2l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3c.7-.7.3-1.9-.6-2z"
                            class="st0"
                          ></path>
                          <path
                            d="M52 1s.1 0 0 0l1.8 4.3c.3.6.8 1 1.6 1.2h.2L60 7l.1.1v.2l-3.4 3c-.5.5-.7 1.3-.6 2l1 4.5v.1H57l-3.9-2.4c-.3-.2-.7-.3-1.1-.3-.4 0-.8.1-1.1.3L47 16.9h-.1v-.1l1-4.5c.2-.7 0-1.5-.6-2.1l-3.3-3c-.1-.1-.1-.2-.1-.2h.1l4.5-.4c.7 0 1.4-.5 1.7-1.3L52 1c-.1 0 0 0 0 0m0-1c-.4 0-.8.2-1 .7l-1.7 4.2c-.2.4-.5.7-.9.7l-4.5.4c-.9.1-1.3 1.3-.6 2l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2.8.4 1.4 1 1.4.2 0 .4-.1.6-.2l3.9-2.4c.1-.1.3-.2.5-.2s.4.1.5.2l3.9 2.4c.2.1.4.2.6.2.6 0 1.2-.6 1-1.4l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3c.7-.7.3-1.9-.6-2l-4.5-.3c-.4-.1-.7-.3-.9-.7L53 .7c-.2-.5-.6-.7-1-.7z"
                            class="st1"
                          ></path>
                          <g>
                            <path
                              d="M81.6 6l-4.5-.3c-.4-.1-.7-.3-.9-.7L74.4.7c-.3-.9-1.6-.9-1.8-.1l-1.8 4.3c-.2.4-.5.7-.9.7l-4.5.4c-1 .1-1.4 1.3-.6 2l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2 1 .8 1.7 1.6 1.2l3.9-2.4c.3-.2.8-.2 1.1 0l3.9 2.4c.8.5 1.8-.2 1.6-1.2l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3.1c.7-.6.3-1.8-.6-1.9z"
                              class="st0"
                            ></path>
                            <path
                              d="M73.5 1.1l1.8 4.3c.3.6.8 1 1.6 1.2h.2l4.4.4v.1l-3.4 3.1c-.5.5-.7 1.3-.6 2l1 4.5v.1h-.1l-3.9-2.4c-.3-.2-.7-.3-1.1-.3-.4 0-.8.1-1.1.3l-3.9 2.4h-.1v-.1l1-4.5c.2-.7 0-1.5-.6-2.1l-3.3-3c-.1-.1-.1-.1-.1-.2l.1-.1 4.6-.2c.7 0 1.4-.5 1.7-1.3l1.8-4.2M73.4 0c-.4 0-.7.2-.8.6l-1.8 4.3c-.2.4-.5.7-.9.7l-4.5.4c-1 .1-1.4 1.3-.6 2l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2.8.4 1.4 1 1.4.2 0 .4-.1.6-.2l3.9-2.4c.1-.1.3-.2.5-.2s.4.1.5.2l3.9 2.4c.2.1.4.2.6.2.6 0 1.2-.6 1-1.4l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3.1c.7-.6.3-1.8-.6-1.9L77 5.7c-.4-.1-.7-.3-.9-.7L74.3.7c-.1-.5-.5-.7-.9-.7z"
                              class="st1"
                            ></path>
                          </g>
                          <g>
                            <path
                              d="M103.6 7.9c.7-.6.3-1.8-.6-1.9l-4.4-.3c-.4-.1-.7-.3-.9-.7L95.9.7c-.4-.9-1.6-.9-1.9 0L92.2 5c-.2.4-.5.7-.9.7l-4.5.4c-.9 0-1.3 1.3-.6 1.9l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2 1 .8 1.7 1.6 1.2l3.9-2.4c.3-.2.8-.2 1.1 0l3.9 2.4c.8.5 1.8-.2 1.6-1.2l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3.1z"
                              class="st0"
                            ></path>
                            <path
                              d="M94.9 1c.1 0 .1.1 0 0l1.8 4.3c.3.6.8 1 1.6 1.2h.2l4.4.3v.1L99.5 10c-.5.5-.7 1.3-.6 2l1 4.5v.1h-.1l-3.8-2c-.3-.2-.7-.3-1.1-.3-.4 0-.8.1-1.1.3L89.9 17h-.1v-.1l1-4.5c.2-.7 0-1.5-.6-2.1l-3.3-3v-.1l4.5-.4c.7 0 1.4-.5 1.7-1.3L94.9 1m0-1c-.4 0-.8.2-.9.7L92.2 5c-.2.4-.5.7-.9.7l-4.5.4c-.9 0-1.3 1.3-.6 1.9l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2.8.4 1.4 1 1.4.2 0 .4-.1.6-.2l3.9-2.4c.2-.1.3-.2.6-.2s.4.1.6.2l3.9 2.4c.2.1.4.2.6.2.6 0 1.2-.6 1-1.4l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3.1c.7-.6.3-1.8-.6-1.9l-4.4-.3c-.4-.1-.7-.3-.9-.7L96 .7c-.3-.5-.7-.7-1.1-.7z"
                              class="st1"
                            ></path>
                          </g>
                        </svg>
                        <div
                          class="kkra-stars__filled"
                          style={{ width: "90%" }}
                        >
                          <svg
                            class="filled"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 104 18"
                          >
                            <g transform="translate(-160 -526)">
                              <g transform="translate(64 308)">
                                <g transform="translate(96 218)">
                                  <g>
                                    <path
                                      d="M10.1.7L11.8 5c.2.4.5.6.9.7l4.5.3c.9.1 1.3 1.3.6 1.9L14.4 11c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1L.4 8c-.7-.7-.4-1.9.6-2l4.5-.4c.4 0 .7-.3.9-.7L8.2.6c.3-.8 1.5-.8 1.9.1"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M31.5.7L33.3 5c.2.4.5.6.9.7l4.4.3c1 .1 1.4 1.3.6 2l-3.3 3c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.4-3c-.7-.6-.3-1.9.6-1.9l4.5-.4c.4 0 .7-.3.9-.7L29.6.7c.3-.9 1.6-.9 1.9 0"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M53 .7L54.7 5c.2.4.5.6.9.7l4.5.3c.9.1 1.3 1.3.6 2l-3.4 3c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.3-3c-.7-.7-.3-1.9.6-2l4.5-.4c.4 0 .7-.3.9-.7L51 .7c.4-.9 1.6-.9 2 0"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M74.4.7L76.2 5c.2.4.5.6.9.7l4.5.3c.9.1 1.3 1.3.6 1.9L78.8 11c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2L74 15.4c-.3-.2-.8-.2-1.1 0L69 17.8c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.3-3c-.8-.7-.4-1.9.6-2l4.5-.4c.4 0 .7-.3.9-.7L72.6.6c.2-.8 1.5-.8 1.8.1"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M95.9.7L97.7 5c.2.4.5.6.9.7l4.4.3c.9.1 1.3 1.3.6 1.9l-3.4 3.1c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.3-3c-.7-.6-.3-1.9.6-1.9l4.5-.4c.4 0 .7-.3.9-.7L94 .7c.3-.9 1.5-.9 1.9 0"
                                      class="st20"
                                    ></path>
                                  </g>
                                  <g>
                                    <path
                                      d="M10.1.7L11.8 5c.2.4.5.6.9.7l4.5.3c.9.1 1.3 1.3.6 1.9L14.4 11c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1L.4 8c-.7-.7-.4-1.9.6-2l4.5-.4c.4 0 .7-.3.9-.7L8.2.6c.3-.8 1.5-.8 1.9.1"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M31.5.7L33.3 5c.2.4.5.6.9.7l4.4.3c1 .1 1.4 1.3.6 2l-3.3 3c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.4-3c-.7-.6-.3-1.9.6-1.9l4.5-.4c.4 0 .7-.3.9-.7L29.6.7c.3-.9 1.6-.9 1.9 0"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M53 .7L54.7 5c.2.4.5.6.9.7l4.5.3c.9.1 1.3 1.3.6 2l-3.4 3c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.3-3c-.7-.7-.3-1.9.6-2l4.5-.4c.4 0 .7-.3.9-.7L51 .7c.4-.9 1.6-.9 2 0"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M74.4.7L76.2 5c.2.4.5.6.9.7l4.5.3c.9.1 1.3 1.3.6 1.9L78.8 11c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2L74 15.4c-.3-.2-.8-.2-1.1 0L69 17.8c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.3-3c-.8-.7-.4-1.9.6-2l4.5-.4c.4 0 .7-.3.9-.7L72.6.6c.2-.8 1.5-.8 1.8.1"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M95.9.7L97.7 5c.2.4.5.6.9.7l4.4.3c.9.1 1.3 1.3.6 1.9l-3.4 3.1c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.3-3c-.7-.6-.3-1.9.6-1.9l4.5-.4c.4 0 .7-.3.9-.7L94 .7c.3-.9 1.5-.9 1.9 0"
                                      class="st20"
                                    ></path>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </svg>
                        </div>
                      </div>
                      <span itemprop="ratingValue">4.5 </span>stars out of
                      <span itemprop="ratingCount">383 reviews</span>
                      <div
                        itemprop="itemReviewed"
                        itemscope=""
                        itemtype="http://schema.org/LocalBusiness"
                      >
                        <meta
                          itemprop="image"
                          content="https://kingkong.co/wp-content/themes/kingkong/assets/src/images/site-logo.png"
                        />
                        <meta itemprop="telephone" content="+ 1-888-303-8580" />
                        <meta itemprop="name" content="King Kong" />
                        <meta itemprop="priceRange" content="$$$" />
                        <span
                          itemprop="address"
                          itemscope=""
                          itemtype="http://schema.org/PostalAddress"
                        >
                          <meta
                            itemprop="streetAddress"
                            content="Oppenheimer Tower, 10880 Wilshire Boulevard"
                          />
                          <meta
                            itemprop="addressLocality"
                            content="Los Angeles"
                          />
                          <meta itemprop="addressRegion" content="California" />
                          <meta
                            itemprop="addressCountry"
                            content="United States"
                          />
                          <meta itemprop="postalCode" content="90024" />
                        </span>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section class="text-dark pt-0 pull-section-up content-image content-text content-wysiwyg next-has-stripes has-stripes">
            <div class="stripes">
              <div class="stripes-bg"></div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div
              class="background-image-container"
              style={{ backgroundColor: "#ffffff" }}
            ></div>
            <div class="container">
              <div class="row justify-content-center">
                <div class="emoji-wide text-center fade-in">
                  <img
                    width="1054"
                    height="580"
                    class="attachment-container_width size-container_width entered exited"
                    alt=""
                    data-lazy-srcset="https://kingkong.co/wp-content/uploads/2021/05/agency-ripped-off.png 1054w, https://kingkong.co/wp-content/uploads/2021/05/agency-ripped-off-300x165.png 300w, https://kingkong.co/wp-content/uploads/2021/05/agency-ripped-off-1024x563.png 1024w, https://kingkong.co/wp-content/uploads/2021/05/agency-ripped-off-768x423.png 768w"
                    data-lazy-sizes="(max-width: 1054px) 100vw, 1054px"
                    data-lazy-src="https://kingkong.co/wp-content/uploads/2021/05/agency-ripped-off.png"
                    src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%201054%20580'%3E%3C/svg%3E"
                  />
                  <noscript>
                    <img
                      width="1054"
                      height="580"
                      src="https://kingkong.co/wp-content/uploads/2021/05/agency-ripped-off.png"
                      class="attachment-container_width size-container_width"
                      alt=""
                      srcset="
                https://kingkong.co/wp-content/uploads/2021/05/agency-ripped-off.png          1054w,
                https://kingkong.co/wp-content/uploads/2021/05/agency-ripped-off-300x165.png   300w,
                https://kingkong.co/wp-content/uploads/2021/05/agency-ripped-off-1024x563.png 1024w,
                https://kingkong.co/wp-content/uploads/2021/05/agency-ripped-off-768x423.png   768w
              "
                      sizes="(max-width: 1054px) 100vw, 1054px"
                    />
                  </noscript>
                </div>
                <div class="col-md-9 my-5 text-center fade-in">
                  <span class="h2">
                    Ripped Off By Filthy Marketing Scum Bags!
                  </span>
                </div>
                <div class="col-xxl-7 col-xl-8 col-md-9">
                  <p>
                    Who sold you the world, and at best, delivered the moon.
                  </p>
                  <p>I know you know the type…</p>
                  <p>
                    These hipsters sitting around in front of white boards
                    talkin’ about vegan cheese, thigh gaps and how many
                    “impressions” your ads got….
                  </p>
                  <p>(Uuurrgghh! Makes my blood boil just thinking about it)</p>
                  <p>
                    Because in a world of coaches coaching coaches…
                    <strong>
                      Most digital marketers are like shady rug merchants.
                    </strong>
                  </p>
                  <p>
                    So rather than spend our time and resources trying to
                    convince you we’re different…
                  </p>
                  <p>
                    For a limited time we’ve decided to risk our own money to
                    SHOW YOU we’re different.
                  </p>
                  <p>
                    Because, in our opinion, the profession of digital marketing
                    has more incompetence per capita than any other profession.
                    Period.
                  </p>
                  <p>(Except for maybe solar companies and chiropractors)</p>
                  <p>
                    Anyhoo, whether it was a digital marketing agency, an
                    internal employee, business consultant or some almond latte
                    drinking “marketing expert”….that has promised you results
                    in the past…
                  </p>
                  <p>
                    But, when it was all said and done, simply left you with
                    more excuses than they did results.
                  </p>
                  <p>
                    We have a saying that, “digital marketing is 80% confusion
                    and 20% commission”.{" "}
                    <strong>It’s sad, but mostly true.</strong>
                  </p>
                  <p>
                    And most business owners approach growing their business
                    with digital marketing like…
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section class="text-dark content-text content-code content-wysiwyg content-textarea next-has-stripes has-stripes">
            <div class="stripes">
              <div class="stripes-bg"></div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div
              class="background-image-container"
              style={{ backgroundColor: "#f3f3f3" }}
            ></div>
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-12 text-center fade-in">
                  <span class="pill"> Don't Do This </span>
                </div>
                <div class="col-md-9 col-xxl-8 mb-5 text-center fade-in">
                  <span class="h2">
                    Walking Across A Live Minefield Stark Naked And Blindfolded!
                  </span>
                </div>
                <div class="col-12">
                  <lottie-player
                    src="/wp-content/uploads/lottie/mindfield-diagram.json"
                    background="transparent"
                    speed="1"
                    style={{ width: "100%", height: "100%" }}
                    autoplay=""
                  ></lottie-player>
                  <br />
                </div>
                <div class="col-xxl-7 col-xl-8 col-md-9 mt-5">
                  <p>
                    <em>
                      So how are we different from these armchair “experts”
                    </em>
                    and theory peddlers who talk a good game but rarely deliver?
                  </p>
                  <p>
                    Well, firstly, we’re the only agency confident (or crazy)
                    enough to actually guarantee results or we don’t get paid.
                  </p>
                  <p>
                    <strong>Secondly, we’re not some hack job</strong> company
                    that was started yesterday…
                  </p>
                  <p>
                    Nope, we’ve been in the game since 2014, which is like a
                    five-thousand years online….
                  </p>
                  <p>
                    Not only that, we’ve been ranked as the
                    <strong>
                      #1 fastest growing digital agency in Australia for several
                      years in a row.
                    </strong>
                  </p>
                  <p>
                    And the 17th fastest growing company across all industries
                    by the Australian Financial Review.
                  </p>
                  <p>
                    But more importantly, a bunch of our clients have also been
                    ranked as the fastest-growing companies in their respective
                    industries.
                  </p>
                  <p>Look, our strategies are proven….</p>
                  <p>
                    <em>
                      Battle-hardened by over
                      <strong>$50+ million in ad spend</strong> in 1067
                      different industries and niches – and $7.8 billion in
                      reported client revenue.
                    </em>
                  </p>
                  <p>We’ve got this stuff down to a science.</p>
                  <p>A mathematical equation.</p>
                  <p>But we don’t expect you to take our word for it.</p>
                  <p>*Wink*</p>
                  <p>
                    Let’s get down to the juicy proof pie we’ve been baking for
                    the better half of a decade!…
                  </p>
                </div>
                <div class="col-lg-10 col-xxl-8 null">
                  <a
                    class="btn btn-doublelayer btn-green"
                    href="/30-minute-strategy-session/"
                  >
                    <span class="btn-doublelayer__maintext">
                      Claim Your Free 30-Minute Strategy Session
                    </span>
                    <span class="btn-doublelayer__subtext">
                      Be quick! FREE spots are almost gone for May
                    </span>
                  </a>
                  <a
                    href="https://kingkong.co/reviews"
                    itemscope=""
                    itemtype="http://schema.org/LocalBusiness"
                    class="kkra-shortstring term-agency"
                  >
                    <meta
                      itemprop="image"
                      content="https://kingkong.co/wp-content/themes/kingkong/assets/src/images/site-logo.png"
                    />
                    <meta itemprop="telephone" content="+ 1-888-303-8580" />
                    <meta itemprop="name" content="King Kong" />
                    <meta itemprop="priceRange" content="$$$" />
                    <span
                      itemprop="address"
                      itemscope=""
                      itemtype="http://schema.org/PostalAddress"
                    >
                      <meta
                        itemprop="streetAddress"
                        content="Oppenheimer Tower, 10880 Wilshire Boulevard"
                      />
                      <meta itemprop="addressLocality" content="Los Angeles" />
                      <meta itemprop="addressRegion" content="California" />
                      <meta itemprop="addressCountry" content="United States" />
                      <meta itemprop="postalCode" content="90024" />
                    </span>
                    <div
                      class="kkra-shortstring__inner"
                      itemprop="aggregateRating"
                      itemscope=""
                      itemtype="http://schema.org/AggregateRating"
                    >
                      <div class="kkra-stars">
                        <svg
                          class="unfilled"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 104 18"
                        >
                          <path
                            d="M17.2 6l-4.5-.3c-.4-.1-.7-.3-.9-.7L10.1.7C9.7-.2 8.5-.2 8.2.6L6.4 4.9c-.2.4-.5.7-.9.7L1 6C0 6.1-.3 7.3.4 8l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2 1 .8 1.7 1.6 1.2l3.9-2.4c.3-.2.8-.2 1.1 0l3.9 2.4c.8.5 1.8-.2 1.6-1.2l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3.1c.7-.6.3-1.8-.6-1.9z"
                            class="st0"
                          ></path>
                          <path
                            d="M9.1 1s.1 0 0 0l1.8 4.4c.3.6.8 1 1.6 1.2h.2l4.4.4v.1l-3.4 3.1c-.5.5-.7 1.3-.6 2l1 4.5v.1H14l-3.9-2.4c-.3-.2-.7-.3-1.1-.3-.4 0-.8.1-1.1.3L4 16.8h-.1v-.1l1-4.5c.2-.7 0-1.5-.6-2.1L1 7.1V7h.1l4.5-.4c.7 0 1.4-.5 1.7-1.3L9.1 1m0-1c-.4 0-.8.2-.9.6L6.4 4.9c-.2.4-.5.7-.9.7L1 6C0 6.1-.3 7.3.4 8l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2.8.4 1.4 1 1.4.2 0 .4-.1.6-.2l3.9-2.4c.1-.1.3-.2.5-.2s.4.1.5.2l3.9 2.4c.2.1.4.2.6.2.6 0 1.2-.6 1-1.4l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3.1c.7-.6.3-1.8-.6-1.9l-4.5-.3c-.3-.1-.6-.3-.8-.7L10.1.7c-.2-.5-.6-.7-1-.7z"
                            class="st1"
                          ></path>
                          <path
                            d="M38.6 6l-4.4-.3c-.4-.1-.7-.3-.9-.7L31.5.7c-.3-.9-1.6-.9-1.9 0L27.8 5c-.2.4-.5.7-.9.7l-4.5.4c-.9 0-1.3 1.3-.6 1.9l3.4 3c.3.3.4.7.3 1.1l-1 4.5c-.2 1 .8 1.7 1.6 1.2l3.9-2.4c.3-.2.8-.2 1.1 0l3.9 2.4c.8.5 1.8-.2 1.6-1.2l-1-4.5c-.1-.4 0-.8.3-1.1l3.3-3c.8-.7.4-1.9-.6-2z"
                            class="st0"
                          ></path>
                          <path
                            d="M30.6 1l1.8 4.4c.3.6.8 1 1.6 1.2h.2l4.3.4c.1 0 .1 0 .1.1s0 .1-.1.2l-3.3 3c-.5.5-.7 1.3-.6 2l1 4.5v.1h-.1l-3.9-2.4c-.3-.2-.7-.3-1.1-.3-.4 0-.8.1-1.1.3l-3.9 2.4h-.1v-.1l1-4.5c.2-.7 0-1.5-.6-2l-3.4-3v-.1l4.6-.5c.7 0 1.4-.5 1.7-1.3L30.6 1c-.1 0-.1 0 0 0m0-1c-.4 0-.8.2-1 .7L27.8 5c-.2.4-.5.7-.9.7l-4.5.4c-.9 0-1.3 1.3-.6 1.9l3.4 3c.3.3.4.7.3 1.1l-1 4.5c-.2.8.4 1.4 1 1.4.2 0 .4-.1.6-.2l3.9-2.4c.1-.1.3-.2.5-.2s.4.1.5.2l3.9 2.4c.2.1.4.2.6.2.6 0 1.2-.6 1-1.4l-1-4.5c-.1-.4 0-.8.3-1.1l3.3-3c.8-.7.4-1.9-.6-2l-4.4-.3c-.4-.1-.7-.3-.9-.7L31.4.7c0-.5-.4-.7-.8-.7z"
                            class="st1"
                          ></path>
                          <path
                            d="M60.1 6l-4.5-.3c-.4-.1-.7-.3-.9-.7L53 .7c-.4-.9-1.6-.9-2 0l-1.7 4.2c-.2.4-.5.7-.9.7l-4.5.4c-.9.1-1.3 1.3-.6 2l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2 1 .8 1.7 1.6 1.2l3.9-2.4c.3-.2.8-.2 1.1 0l3.9 2.4c.8.5 1.8-.2 1.6-1.2l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3c.7-.7.3-1.9-.6-2z"
                            class="st0"
                          ></path>
                          <path
                            d="M52 1s.1 0 0 0l1.8 4.3c.3.6.8 1 1.6 1.2h.2L60 7l.1.1v.2l-3.4 3c-.5.5-.7 1.3-.6 2l1 4.5v.1H57l-3.9-2.4c-.3-.2-.7-.3-1.1-.3-.4 0-.8.1-1.1.3L47 16.9h-.1v-.1l1-4.5c.2-.7 0-1.5-.6-2.1l-3.3-3c-.1-.1-.1-.2-.1-.2h.1l4.5-.4c.7 0 1.4-.5 1.7-1.3L52 1c-.1 0 0 0 0 0m0-1c-.4 0-.8.2-1 .7l-1.7 4.2c-.2.4-.5.7-.9.7l-4.5.4c-.9.1-1.3 1.3-.6 2l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2.8.4 1.4 1 1.4.2 0 .4-.1.6-.2l3.9-2.4c.1-.1.3-.2.5-.2s.4.1.5.2l3.9 2.4c.2.1.4.2.6.2.6 0 1.2-.6 1-1.4l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3c.7-.7.3-1.9-.6-2l-4.5-.3c-.4-.1-.7-.3-.9-.7L53 .7c-.2-.5-.6-.7-1-.7z"
                            class="st1"
                          ></path>
                          <g>
                            <path
                              d="M81.6 6l-4.5-.3c-.4-.1-.7-.3-.9-.7L74.4.7c-.3-.9-1.6-.9-1.8-.1l-1.8 4.3c-.2.4-.5.7-.9.7l-4.5.4c-1 .1-1.4 1.3-.6 2l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2 1 .8 1.7 1.6 1.2l3.9-2.4c.3-.2.8-.2 1.1 0l3.9 2.4c.8.5 1.8-.2 1.6-1.2l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3.1c.7-.6.3-1.8-.6-1.9z"
                              class="st0"
                            ></path>
                            <path
                              d="M73.5 1.1l1.8 4.3c.3.6.8 1 1.6 1.2h.2l4.4.4v.1l-3.4 3.1c-.5.5-.7 1.3-.6 2l1 4.5v.1h-.1l-3.9-2.4c-.3-.2-.7-.3-1.1-.3-.4 0-.8.1-1.1.3l-3.9 2.4h-.1v-.1l1-4.5c.2-.7 0-1.5-.6-2.1l-3.3-3c-.1-.1-.1-.1-.1-.2l.1-.1 4.6-.2c.7 0 1.4-.5 1.7-1.3l1.8-4.2M73.4 0c-.4 0-.7.2-.8.6l-1.8 4.3c-.2.4-.5.7-.9.7l-4.5.4c-1 .1-1.4 1.3-.6 2l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2.8.4 1.4 1 1.4.2 0 .4-.1.6-.2l3.9-2.4c.1-.1.3-.2.5-.2s.4.1.5.2l3.9 2.4c.2.1.4.2.6.2.6 0 1.2-.6 1-1.4l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3.1c.7-.6.3-1.8-.6-1.9L77 5.7c-.4-.1-.7-.3-.9-.7L74.3.7c-.1-.5-.5-.7-.9-.7z"
                              class="st1"
                            ></path>
                          </g>
                          <g>
                            <path
                              d="M103.6 7.9c.7-.6.3-1.8-.6-1.9l-4.4-.3c-.4-.1-.7-.3-.9-.7L95.9.7c-.4-.9-1.6-.9-1.9 0L92.2 5c-.2.4-.5.7-.9.7l-4.5.4c-.9 0-1.3 1.3-.6 1.9l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2 1 .8 1.7 1.6 1.2l3.9-2.4c.3-.2.8-.2 1.1 0l3.9 2.4c.8.5 1.8-.2 1.6-1.2l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3.1z"
                              class="st0"
                            ></path>
                            <path
                              d="M94.9 1c.1 0 .1.1 0 0l1.8 4.3c.3.6.8 1 1.6 1.2h.2l4.4.3v.1L99.5 10c-.5.5-.7 1.3-.6 2l1 4.5v.1h-.1l-3.8-2c-.3-.2-.7-.3-1.1-.3-.4 0-.8.1-1.1.3L89.9 17h-.1v-.1l1-4.5c.2-.7 0-1.5-.6-2.1l-3.3-3v-.1l4.5-.4c.7 0 1.4-.5 1.7-1.3L94.9 1m0-1c-.4 0-.8.2-.9.7L92.2 5c-.2.4-.5.7-.9.7l-4.5.4c-.9 0-1.3 1.3-.6 1.9l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2.8.4 1.4 1 1.4.2 0 .4-.1.6-.2l3.9-2.4c.2-.1.3-.2.6-.2s.4.1.6.2l3.9 2.4c.2.1.4.2.6.2.6 0 1.2-.6 1-1.4l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3.1c.7-.6.3-1.8-.6-1.9l-4.4-.3c-.4-.1-.7-.3-.9-.7L96 .7c-.3-.5-.7-.7-1.1-.7z"
                              class="st1"
                            ></path>
                          </g>
                        </svg>
                        <div
                          class="kkra-stars__filled"
                          style={{ width: "90%" }}
                        >
                          <svg
                            class="filled"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 104 18"
                          >
                            <g transform="translate(-160 -526)">
                              <g transform="translate(64 308)">
                                <g transform="translate(96 218)">
                                  <g>
                                    <path
                                      d="M10.1.7L11.8 5c.2.4.5.6.9.7l4.5.3c.9.1 1.3 1.3.6 1.9L14.4 11c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1L.4 8c-.7-.7-.4-1.9.6-2l4.5-.4c.4 0 .7-.3.9-.7L8.2.6c.3-.8 1.5-.8 1.9.1"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M31.5.7L33.3 5c.2.4.5.6.9.7l4.4.3c1 .1 1.4 1.3.6 2l-3.3 3c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.4-3c-.7-.6-.3-1.9.6-1.9l4.5-.4c.4 0 .7-.3.9-.7L29.6.7c.3-.9 1.6-.9 1.9 0"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M53 .7L54.7 5c.2.4.5.6.9.7l4.5.3c.9.1 1.3 1.3.6 2l-3.4 3c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.3-3c-.7-.7-.3-1.9.6-2l4.5-.4c.4 0 .7-.3.9-.7L51 .7c.4-.9 1.6-.9 2 0"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M74.4.7L76.2 5c.2.4.5.6.9.7l4.5.3c.9.1 1.3 1.3.6 1.9L78.8 11c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2L74 15.4c-.3-.2-.8-.2-1.1 0L69 17.8c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.3-3c-.8-.7-.4-1.9.6-2l4.5-.4c.4 0 .7-.3.9-.7L72.6.6c.2-.8 1.5-.8 1.8.1"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M95.9.7L97.7 5c.2.4.5.6.9.7l4.4.3c.9.1 1.3 1.3.6 1.9l-3.4 3.1c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.3-3c-.7-.6-.3-1.9.6-1.9l4.5-.4c.4 0 .7-.3.9-.7L94 .7c.3-.9 1.5-.9 1.9 0"
                                      class="st20"
                                    ></path>
                                  </g>
                                  <g>
                                    <path
                                      d="M10.1.7L11.8 5c.2.4.5.6.9.7l4.5.3c.9.1 1.3 1.3.6 1.9L14.4 11c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1L.4 8c-.7-.7-.4-1.9.6-2l4.5-.4c.4 0 .7-.3.9-.7L8.2.6c.3-.8 1.5-.8 1.9.1"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M31.5.7L33.3 5c.2.4.5.6.9.7l4.4.3c1 .1 1.4 1.3.6 2l-3.3 3c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.4-3c-.7-.6-.3-1.9.6-1.9l4.5-.4c.4 0 .7-.3.9-.7L29.6.7c.3-.9 1.6-.9 1.9 0"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M53 .7L54.7 5c.2.4.5.6.9.7l4.5.3c.9.1 1.3 1.3.6 2l-3.4 3c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.3-3c-.7-.7-.3-1.9.6-2l4.5-.4c.4 0 .7-.3.9-.7L51 .7c.4-.9 1.6-.9 2 0"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M74.4.7L76.2 5c.2.4.5.6.9.7l4.5.3c.9.1 1.3 1.3.6 1.9L78.8 11c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2L74 15.4c-.3-.2-.8-.2-1.1 0L69 17.8c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.3-3c-.8-.7-.4-1.9.6-2l4.5-.4c.4 0 .7-.3.9-.7L72.6.6c.2-.8 1.5-.8 1.8.1"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M95.9.7L97.7 5c.2.4.5.6.9.7l4.4.3c.9.1 1.3 1.3.6 1.9l-3.4 3.1c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.3-3c-.7-.6-.3-1.9.6-1.9l4.5-.4c.4 0 .7-.3.9-.7L94 .7c.3-.9 1.5-.9 1.9 0"
                                      class="st20"
                                    ></path>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </svg>
                        </div>
                      </div>
                      <span itemprop="ratingValue">4.5 </span>stars out of
                      <span itemprop="ratingCount">383 reviews</span>
                      <div
                        itemprop="itemReviewed"
                        itemscope=""
                        itemtype="http://schema.org/LocalBusiness"
                      >
                        <meta
                          itemprop="image"
                          content="https://kingkong.co/wp-content/themes/kingkong/assets/src/images/site-logo.png"
                        />
                        <meta itemprop="telephone" content="+ 1-888-303-8580" />
                        <meta itemprop="name" content="King Kong" />
                        <meta itemprop="priceRange" content="$$$" />
                        <span
                          itemprop="address"
                          itemscope=""
                          itemtype="http://schema.org/PostalAddress"
                        >
                          <meta
                            itemprop="streetAddress"
                            content="Oppenheimer Tower, 10880 Wilshire Boulevard"
                          />
                          <meta
                            itemprop="addressLocality"
                            content="Los Angeles"
                          />
                          <meta itemprop="addressRegion" content="California" />
                          <meta
                            itemprop="addressCountry"
                            content="United States"
                          />
                          <meta itemprop="postalCode" content="90024" />
                        </span>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section class="text-dark content-text content-global proof-generic content-wysiwyg content-textarea next-has-stripes has-stripes">
            <div class="stripes">
              <div class="stripes-bg"></div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div
              class="background-image-container"
              style={{ backgroundColor: "white" }}
            ></div>
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-12 text-center animate-in">
                  <span class="h1"> Proof! </span>
                </div>
                <div class="col-12 text-center animate-in">
                  <img
                    width="1200"
                    height="2541"
                    src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%201200%202541'%3E%3C/svg%3E"
                    class="attachment-container_width size-container_width"
                    alt=""
                    style={{
                      width: "100%",
                      maxWidth: "1100px",
                    }}
                    data-lazy-srcset="https://kingkong.co/wp-content/uploads/2021/01/proof-1200x2541.png 1200w, https://kingkong.co/wp-content/uploads/2021/01/proof-142x300.png 142w, https://kingkong.co/wp-content/uploads/2021/01/proof-484x1024.png 484w, https://kingkong.co/wp-content/uploads/2021/01/proof-768x1627.png 768w, https://kingkong.co/wp-content/uploads/2021/01/proof-725x1536.png 725w, https://kingkong.co/wp-content/uploads/2021/01/proof-967x2048.png 967w, https://kingkong.co/wp-content/uploads/2021/01/proof.png 1748w"
                    data-lazy-sizes="(max-width: 1200px) 100vw, 1200px"
                    data-lazy-src="https://kingkong.co/wp-content/uploads/2021/01/proof-1200x2541.png"
                  />
                  <noscript>
                    <img
                      width="1200"
                      height="2541"
                      src="https://kingkong.co/wp-content/uploads/2021/01/proof-1200x2541.png"
                      class="attachment-container_width size-container_width"
                      alt=""
                      style={{
                        width: "100%",
                        maxWidth: "1100px",
                      }}
                      srcset="
                https://kingkong.co/wp-content/uploads/2021/01/proof-1200x2541.png 1200w,
                https://kingkong.co/wp-content/uploads/2021/01/proof-142x300.png    142w,
                https://kingkong.co/wp-content/uploads/2021/01/proof-484x1024.png   484w,
                https://kingkong.co/wp-content/uploads/2021/01/proof-768x1627.png   768w,
                https://kingkong.co/wp-content/uploads/2021/01/proof-725x1536.png   725w,
                https://kingkong.co/wp-content/uploads/2021/01/proof-967x2048.png   967w,
                https://kingkong.co/wp-content/uploads/2021/01/proof.png           1748w
              "
                      sizes="(max-width: 1200px) 100vw, 1200px"
                    />
                  </noscript>
                </div>
                <div class="col-xxl-7 col-xl-8 col-md-9">
                  <p>
                    It’s stories like these that (almost) make our eyes sweat.
                  </p>
                  <p>Okay, enough of that….</p>
                  <p>
                    Let me answer the question that’s burning a hole in your
                    head…
                  </p>
                </div>
                <div class="col-lg-10 col-xxl-8 null">
                  <a
                    class="btn btn-doublelayer btn-green"
                    href="/30-minute-strategy-session/"
                  >
                    <span class="btn-doublelayer__maintext">
                      Claim Your Free 30-Minute Strategy Session
                    </span>
                    <span class="btn-doublelayer__subtext">
                      Be quick! FREE spots are almost gone for May
                    </span>
                  </a>
                  <a
                    href="https://kingkong.co/reviews"
                    itemscope=""
                    itemtype="http://schema.org/LocalBusiness"
                    class="kkra-shortstring term-agency"
                  >
                    <meta
                      itemprop="image"
                      content="https://kingkong.co/wp-content/themes/kingkong/assets/src/images/site-logo.png"
                    />
                    <meta itemprop="telephone" content="+ 1-888-303-8580" />
                    <meta itemprop="name" content="King Kong" />
                    <meta itemprop="priceRange" content="$$$" />
                    <span
                      itemprop="address"
                      itemscope=""
                      itemtype="http://schema.org/PostalAddress"
                    >
                      <meta
                        itemprop="streetAddress"
                        content="Oppenheimer Tower, 10880 Wilshire Boulevard"
                      />
                      <meta itemprop="addressLocality" content="Los Angeles" />
                      <meta itemprop="addressRegion" content="California" />
                      <meta itemprop="addressCountry" content="United States" />
                      <meta itemprop="postalCode" content="90024" />
                    </span>
                    <div
                      class="kkra-shortstring__inner"
                      itemprop="aggregateRating"
                      itemscope=""
                      itemtype="http://schema.org/AggregateRating"
                    >
                      <div class="kkra-stars">
                        <svg
                          class="unfilled"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 104 18"
                        >
                          <path
                            d="M17.2 6l-4.5-.3c-.4-.1-.7-.3-.9-.7L10.1.7C9.7-.2 8.5-.2 8.2.6L6.4 4.9c-.2.4-.5.7-.9.7L1 6C0 6.1-.3 7.3.4 8l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2 1 .8 1.7 1.6 1.2l3.9-2.4c.3-.2.8-.2 1.1 0l3.9 2.4c.8.5 1.8-.2 1.6-1.2l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3.1c.7-.6.3-1.8-.6-1.9z"
                            class="st0"
                          ></path>
                          <path
                            d="M9.1 1s.1 0 0 0l1.8 4.4c.3.6.8 1 1.6 1.2h.2l4.4.4v.1l-3.4 3.1c-.5.5-.7 1.3-.6 2l1 4.5v.1H14l-3.9-2.4c-.3-.2-.7-.3-1.1-.3-.4 0-.8.1-1.1.3L4 16.8h-.1v-.1l1-4.5c.2-.7 0-1.5-.6-2.1L1 7.1V7h.1l4.5-.4c.7 0 1.4-.5 1.7-1.3L9.1 1m0-1c-.4 0-.8.2-.9.6L6.4 4.9c-.2.4-.5.7-.9.7L1 6C0 6.1-.3 7.3.4 8l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2.8.4 1.4 1 1.4.2 0 .4-.1.6-.2l3.9-2.4c.1-.1.3-.2.5-.2s.4.1.5.2l3.9 2.4c.2.1.4.2.6.2.6 0 1.2-.6 1-1.4l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3.1c.7-.6.3-1.8-.6-1.9l-4.5-.3c-.3-.1-.6-.3-.8-.7L10.1.7c-.2-.5-.6-.7-1-.7z"
                            class="st1"
                          ></path>
                          <path
                            d="M38.6 6l-4.4-.3c-.4-.1-.7-.3-.9-.7L31.5.7c-.3-.9-1.6-.9-1.9 0L27.8 5c-.2.4-.5.7-.9.7l-4.5.4c-.9 0-1.3 1.3-.6 1.9l3.4 3c.3.3.4.7.3 1.1l-1 4.5c-.2 1 .8 1.7 1.6 1.2l3.9-2.4c.3-.2.8-.2 1.1 0l3.9 2.4c.8.5 1.8-.2 1.6-1.2l-1-4.5c-.1-.4 0-.8.3-1.1l3.3-3c.8-.7.4-1.9-.6-2z"
                            class="st0"
                          ></path>
                          <path
                            d="M30.6 1l1.8 4.4c.3.6.8 1 1.6 1.2h.2l4.3.4c.1 0 .1 0 .1.1s0 .1-.1.2l-3.3 3c-.5.5-.7 1.3-.6 2l1 4.5v.1h-.1l-3.9-2.4c-.3-.2-.7-.3-1.1-.3-.4 0-.8.1-1.1.3l-3.9 2.4h-.1v-.1l1-4.5c.2-.7 0-1.5-.6-2l-3.4-3v-.1l4.6-.5c.7 0 1.4-.5 1.7-1.3L30.6 1c-.1 0-.1 0 0 0m0-1c-.4 0-.8.2-1 .7L27.8 5c-.2.4-.5.7-.9.7l-4.5.4c-.9 0-1.3 1.3-.6 1.9l3.4 3c.3.3.4.7.3 1.1l-1 4.5c-.2.8.4 1.4 1 1.4.2 0 .4-.1.6-.2l3.9-2.4c.1-.1.3-.2.5-.2s.4.1.5.2l3.9 2.4c.2.1.4.2.6.2.6 0 1.2-.6 1-1.4l-1-4.5c-.1-.4 0-.8.3-1.1l3.3-3c.8-.7.4-1.9-.6-2l-4.4-.3c-.4-.1-.7-.3-.9-.7L31.4.7c0-.5-.4-.7-.8-.7z"
                            class="st1"
                          ></path>
                          <path
                            d="M60.1 6l-4.5-.3c-.4-.1-.7-.3-.9-.7L53 .7c-.4-.9-1.6-.9-2 0l-1.7 4.2c-.2.4-.5.7-.9.7l-4.5.4c-.9.1-1.3 1.3-.6 2l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2 1 .8 1.7 1.6 1.2l3.9-2.4c.3-.2.8-.2 1.1 0l3.9 2.4c.8.5 1.8-.2 1.6-1.2l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3c.7-.7.3-1.9-.6-2z"
                            class="st0"
                          ></path>
                          <path
                            d="M52 1s.1 0 0 0l1.8 4.3c.3.6.8 1 1.6 1.2h.2L60 7l.1.1v.2l-3.4 3c-.5.5-.7 1.3-.6 2l1 4.5v.1H57l-3.9-2.4c-.3-.2-.7-.3-1.1-.3-.4 0-.8.1-1.1.3L47 16.9h-.1v-.1l1-4.5c.2-.7 0-1.5-.6-2.1l-3.3-3c-.1-.1-.1-.2-.1-.2h.1l4.5-.4c.7 0 1.4-.5 1.7-1.3L52 1c-.1 0 0 0 0 0m0-1c-.4 0-.8.2-1 .7l-1.7 4.2c-.2.4-.5.7-.9.7l-4.5.4c-.9.1-1.3 1.3-.6 2l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2.8.4 1.4 1 1.4.2 0 .4-.1.6-.2l3.9-2.4c.1-.1.3-.2.5-.2s.4.1.5.2l3.9 2.4c.2.1.4.2.6.2.6 0 1.2-.6 1-1.4l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3c.7-.7.3-1.9-.6-2l-4.5-.3c-.4-.1-.7-.3-.9-.7L53 .7c-.2-.5-.6-.7-1-.7z"
                            class="st1"
                          ></path>
                          <g>
                            <path
                              d="M81.6 6l-4.5-.3c-.4-.1-.7-.3-.9-.7L74.4.7c-.3-.9-1.6-.9-1.8-.1l-1.8 4.3c-.2.4-.5.7-.9.7l-4.5.4c-1 .1-1.4 1.3-.6 2l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2 1 .8 1.7 1.6 1.2l3.9-2.4c.3-.2.8-.2 1.1 0l3.9 2.4c.8.5 1.8-.2 1.6-1.2l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3.1c.7-.6.3-1.8-.6-1.9z"
                              class="st0"
                            ></path>
                            <path
                              d="M73.5 1.1l1.8 4.3c.3.6.8 1 1.6 1.2h.2l4.4.4v.1l-3.4 3.1c-.5.5-.7 1.3-.6 2l1 4.5v.1h-.1l-3.9-2.4c-.3-.2-.7-.3-1.1-.3-.4 0-.8.1-1.1.3l-3.9 2.4h-.1v-.1l1-4.5c.2-.7 0-1.5-.6-2.1l-3.3-3c-.1-.1-.1-.1-.1-.2l.1-.1 4.6-.2c.7 0 1.4-.5 1.7-1.3l1.8-4.2M73.4 0c-.4 0-.7.2-.8.6l-1.8 4.3c-.2.4-.5.7-.9.7l-4.5.4c-1 .1-1.4 1.3-.6 2l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2.8.4 1.4 1 1.4.2 0 .4-.1.6-.2l3.9-2.4c.1-.1.3-.2.5-.2s.4.1.5.2l3.9 2.4c.2.1.4.2.6.2.6 0 1.2-.6 1-1.4l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3.1c.7-.6.3-1.8-.6-1.9L77 5.7c-.4-.1-.7-.3-.9-.7L74.3.7c-.1-.5-.5-.7-.9-.7z"
                              class="st1"
                            ></path>
                          </g>
                          <g>
                            <path
                              d="M103.6 7.9c.7-.6.3-1.8-.6-1.9l-4.4-.3c-.4-.1-.7-.3-.9-.7L95.9.7c-.4-.9-1.6-.9-1.9 0L92.2 5c-.2.4-.5.7-.9.7l-4.5.4c-.9 0-1.3 1.3-.6 1.9l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2 1 .8 1.7 1.6 1.2l3.9-2.4c.3-.2.8-.2 1.1 0l3.9 2.4c.8.5 1.8-.2 1.6-1.2l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3.1z"
                              class="st0"
                            ></path>
                            <path
                              d="M94.9 1c.1 0 .1.1 0 0l1.8 4.3c.3.6.8 1 1.6 1.2h.2l4.4.3v.1L99.5 10c-.5.5-.7 1.3-.6 2l1 4.5v.1h-.1l-3.8-2c-.3-.2-.7-.3-1.1-.3-.4 0-.8.1-1.1.3L89.9 17h-.1v-.1l1-4.5c.2-.7 0-1.5-.6-2.1l-3.3-3v-.1l4.5-.4c.7 0 1.4-.5 1.7-1.3L94.9 1m0-1c-.4 0-.8.2-.9.7L92.2 5c-.2.4-.5.7-.9.7l-4.5.4c-.9 0-1.3 1.3-.6 1.9l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2.8.4 1.4 1 1.4.2 0 .4-.1.6-.2l3.9-2.4c.2-.1.3-.2.6-.2s.4.1.6.2l3.9 2.4c.2.1.4.2.6.2.6 0 1.2-.6 1-1.4l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3.1c.7-.6.3-1.8-.6-1.9l-4.4-.3c-.4-.1-.7-.3-.9-.7L96 .7c-.3-.5-.7-.7-1.1-.7z"
                              class="st1"
                            ></path>
                          </g>
                        </svg>
                        <div
                          class="kkra-stars__filled"
                          style={{ width: "90%" }}
                        >
                          <svg
                            class="filled"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 104 18"
                          >
                            <g transform="translate(-160 -526)">
                              <g transform="translate(64 308)">
                                <g transform="translate(96 218)">
                                  <g>
                                    <path
                                      d="M10.1.7L11.8 5c.2.4.5.6.9.7l4.5.3c.9.1 1.3 1.3.6 1.9L14.4 11c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1L.4 8c-.7-.7-.4-1.9.6-2l4.5-.4c.4 0 .7-.3.9-.7L8.2.6c.3-.8 1.5-.8 1.9.1"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M31.5.7L33.3 5c.2.4.5.6.9.7l4.4.3c1 .1 1.4 1.3.6 2l-3.3 3c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.4-3c-.7-.6-.3-1.9.6-1.9l4.5-.4c.4 0 .7-.3.9-.7L29.6.7c.3-.9 1.6-.9 1.9 0"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M53 .7L54.7 5c.2.4.5.6.9.7l4.5.3c.9.1 1.3 1.3.6 2l-3.4 3c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.3-3c-.7-.7-.3-1.9.6-2l4.5-.4c.4 0 .7-.3.9-.7L51 .7c.4-.9 1.6-.9 2 0"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M74.4.7L76.2 5c.2.4.5.6.9.7l4.5.3c.9.1 1.3 1.3.6 1.9L78.8 11c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2L74 15.4c-.3-.2-.8-.2-1.1 0L69 17.8c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.3-3c-.8-.7-.4-1.9.6-2l4.5-.4c.4 0 .7-.3.9-.7L72.6.6c.2-.8 1.5-.8 1.8.1"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M95.9.7L97.7 5c.2.4.5.6.9.7l4.4.3c.9.1 1.3 1.3.6 1.9l-3.4 3.1c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.3-3c-.7-.6-.3-1.9.6-1.9l4.5-.4c.4 0 .7-.3.9-.7L94 .7c.3-.9 1.5-.9 1.9 0"
                                      class="st20"
                                    ></path>
                                  </g>
                                  <g>
                                    <path
                                      d="M10.1.7L11.8 5c.2.4.5.6.9.7l4.5.3c.9.1 1.3 1.3.6 1.9L14.4 11c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1L.4 8c-.7-.7-.4-1.9.6-2l4.5-.4c.4 0 .7-.3.9-.7L8.2.6c.3-.8 1.5-.8 1.9.1"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M31.5.7L33.3 5c.2.4.5.6.9.7l4.4.3c1 .1 1.4 1.3.6 2l-3.3 3c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.4-3c-.7-.6-.3-1.9.6-1.9l4.5-.4c.4 0 .7-.3.9-.7L29.6.7c.3-.9 1.6-.9 1.9 0"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M53 .7L54.7 5c.2.4.5.6.9.7l4.5.3c.9.1 1.3 1.3.6 2l-3.4 3c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.3-3c-.7-.7-.3-1.9.6-2l4.5-.4c.4 0 .7-.3.9-.7L51 .7c.4-.9 1.6-.9 2 0"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M74.4.7L76.2 5c.2.4.5.6.9.7l4.5.3c.9.1 1.3 1.3.6 1.9L78.8 11c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2L74 15.4c-.3-.2-.8-.2-1.1 0L69 17.8c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.3-3c-.8-.7-.4-1.9.6-2l4.5-.4c.4 0 .7-.3.9-.7L72.6.6c.2-.8 1.5-.8 1.8.1"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M95.9.7L97.7 5c.2.4.5.6.9.7l4.4.3c.9.1 1.3 1.3.6 1.9l-3.4 3.1c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.3-3c-.7-.6-.3-1.9.6-1.9l4.5-.4c.4 0 .7-.3.9-.7L94 .7c.3-.9 1.5-.9 1.9 0"
                                      class="st20"
                                    ></path>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </svg>
                        </div>
                      </div>
                      <span itemprop="ratingValue">4.5 </span>stars out of
                      <span itemprop="ratingCount">383 reviews</span>
                      <div
                        itemprop="itemReviewed"
                        itemscope=""
                        itemtype="http://schema.org/LocalBusiness"
                      >
                        <meta
                          itemprop="image"
                          content="https://kingkong.co/wp-content/themes/kingkong/assets/src/images/site-logo.png"
                        />
                        <meta itemprop="telephone" content="+ 1-888-303-8580" />
                        <meta itemprop="name" content="King Kong" />
                        <meta itemprop="priceRange" content="$$$" />
                        <span
                          itemprop="address"
                          itemscope=""
                          itemtype="http://schema.org/PostalAddress"
                        >
                          <meta
                            itemprop="streetAddress"
                            content="Oppenheimer Tower, 10880 Wilshire Boulevard"
                          />
                          <meta
                            itemprop="addressLocality"
                            content="Los Angeles"
                          />
                          <meta itemprop="addressRegion" content="California" />
                          <meta
                            itemprop="addressCountry"
                            content="United States"
                          />
                          <meta itemprop="postalCode" content="90024" />
                        </span>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section class="text-dark content-text content-global featured-in next-has-stripes has-stripes">
            <div class="stripes">
              <div class="stripes-bg"></div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div
              class="background-image-container"
              style={{ backgroundColor: "#f3f3f3" }}
            ></div>
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-12 h5 text-center animate-in">
                  <span class="eyebrow"> As Featured In </span>
                </div>
                <div class="image-marquee">
                  <div
                    class="image-marquee__gradient--left"
                    style={{
                      background:
                        "linear-gradient(90deg, #f3f3f3 14.9%, rgba(55, 55, 55, 0) 100%)",
                    }}
                  ></div>
                  <div
                    class="image-marquee__gradient--right"
                    style={{
                      background:
                        "linear-gradient(90deg, #f3f3f3 14.9%, rgba(55, 55, 55, 0) 100%)",
                    }}
                  ></div>
                  <div class="image-marquee__scroller">
                    <div class="image-marquee__scroller__item">
                      <img
                        width="1443"
                        height="53"
                        src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%201443%2053'%3E%3C/svg%3E"
                        class=""
                        alt=""
                        data-lazy-src="https://kingkong.co/wp-content/uploads/2021/01/featured-in-row-1.svg"
                      />
                      <noscript>
                        <img
                          width="1443"
                          height="53"
                          src="https://kingkong.co/wp-content/uploads/2021/01/featured-in-row-1.svg"
                          class=""
                          alt=""
                        />
                      </noscript>
                      <img
                        width="1443"
                        height="53"
                        src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%201443%2053'%3E%3C/svg%3E"
                        class=""
                        alt=""
                        data-lazy-src="https://kingkong.co/wp-content/uploads/2021/01/featured-in-row-1.svg"
                      />
                      <noscript>
                        <img
                          width="1443"
                          height="53"
                          src="https://kingkong.co/wp-content/uploads/2021/01/featured-in-row-1.svg"
                          class=""
                          alt=""
                        />
                      </noscript>
                      <img
                        width="1443"
                        height="53"
                        src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%201443%2053'%3E%3C/svg%3E"
                        class=""
                        alt=""
                        data-lazy-src="https://kingkong.co/wp-content/uploads/2021/01/featured-in-row-1.svg"
                      />
                      <noscript>
                        <img
                          width="1443"
                          height="53"
                          src="https://kingkong.co/wp-content/uploads/2021/01/featured-in-row-1.svg"
                          class=""
                          alt=""
                        />
                      </noscript>
                      <img
                        width="1443"
                        height="53"
                        src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%201443%2053'%3E%3C/svg%3E"
                        class=""
                        alt=""
                        data-lazy-src="https://kingkong.co/wp-content/uploads/2021/01/featured-in-row-1.svg"
                      />
                      <noscript>
                        <img
                          width="1443"
                          height="53"
                          src="https://kingkong.co/wp-content/uploads/2021/01/featured-in-row-1.svg"
                          class=""
                          alt=""
                        />
                      </noscript>
                      <img
                        width="1443"
                        height="53"
                        src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%201443%2053'%3E%3C/svg%3E"
                        class=""
                        alt=""
                        data-lazy-src="https://kingkong.co/wp-content/uploads/2021/01/featured-in-row-1.svg"
                      />
                      <noscript>
                        <img
                          width="1443"
                          height="53"
                          src="https://kingkong.co/wp-content/uploads/2021/01/featured-in-row-1.svg"
                          class=""
                          alt=""
                        />
                      </noscript>
                      <img
                        width="1443"
                        height="53"
                        src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%201443%2053'%3E%3C/svg%3E"
                        class=""
                        alt=""
                        data-lazy-src="https://kingkong.co/wp-content/uploads/2021/01/featured-in-row-1.svg"
                      />
                      <noscript>
                        <img
                          width="1443"
                          height="53"
                          src="https://kingkong.co/wp-content/uploads/2021/01/featured-in-row-1.svg"
                          class=""
                          alt=""
                        />
                      </noscript>
                    </div>
                  </div>
                  <div class="image-marquee__scroller">
                    <div class="image-marquee__scroller__item">
                      <img
                        width="1490"
                        height="55"
                        src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%201490%2055'%3E%3C/svg%3E"
                        class=""
                        alt=""
                        data-lazy-src="https://kingkong.co/wp-content/uploads/2021/01/featured-in-row-2.svg"
                      />
                      <noscript>
                        <img
                          width="1490"
                          height="55"
                          src="https://kingkong.co/wp-content/uploads/2021/01/featured-in-row-2.svg"
                          class=""
                          alt=""
                        />
                      </noscript>
                      <img
                        width="1490"
                        height="55"
                        src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%201490%2055'%3E%3C/svg%3E"
                        class=""
                        alt=""
                        data-lazy-src="https://kingkong.co/wp-content/uploads/2021/01/featured-in-row-2.svg"
                      />
                      <noscript>
                        <img
                          width="1490"
                          height="55"
                          src="https://kingkong.co/wp-content/uploads/2021/01/featured-in-row-2.svg"
                          class=""
                          alt=""
                        />
                      </noscript>
                      <img
                        width="1490"
                        height="55"
                        src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%201490%2055'%3E%3C/svg%3E"
                        class=""
                        alt=""
                        data-lazy-src="https://kingkong.co/wp-content/uploads/2021/01/featured-in-row-2.svg"
                      />
                      <noscript>
                        <img
                          width="1490"
                          height="55"
                          src="https://kingkong.co/wp-content/uploads/2021/01/featured-in-row-2.svg"
                          class=""
                          alt=""
                        />
                      </noscript>
                      <img
                        width="1490"
                        height="55"
                        src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%201490%2055'%3E%3C/svg%3E"
                        class=""
                        alt=""
                        data-lazy-src="https://kingkong.co/wp-content/uploads/2021/01/featured-in-row-2.svg"
                      />
                      <noscript>
                        <img
                          width="1490"
                          height="55"
                          src="https://kingkong.co/wp-content/uploads/2021/01/featured-in-row-2.svg"
                          class=""
                          alt=""
                        />
                      </noscript>
                      <img
                        width="1490"
                        height="55"
                        src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%201490%2055'%3E%3C/svg%3E"
                        class=""
                        alt=""
                        data-lazy-src="https://kingkong.co/wp-content/uploads/2021/01/featured-in-row-2.svg"
                      />
                      <noscript>
                        <img
                          width="1490"
                          height="55"
                          src="https://kingkong.co/wp-content/uploads/2021/01/featured-in-row-2.svg"
                          class=""
                          alt=""
                        />
                      </noscript>
                      <img
                        width="1490"
                        height="55"
                        src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%201490%2055'%3E%3C/svg%3E"
                        class=""
                        alt=""
                        data-lazy-src="https://kingkong.co/wp-content/uploads/2021/01/featured-in-row-2.svg"
                      />
                      <noscript>
                        <img
                          width="1490"
                          height="55"
                          src="https://kingkong.co/wp-content/uploads/2021/01/featured-in-row-2.svg"
                          class=""
                          alt=""
                        />
                      </noscript>
                    </div>
                  </div>
                  <div class="image-marquee__scroller">
                    <div class="image-marquee__scroller__item">
                      <img
                        width="1448"
                        height="53"
                        src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%201448%2053'%3E%3C/svg%3E"
                        class=""
                        alt=""
                        data-lazy-src="https://kingkong.co/wp-content/uploads/2021/01/featured-in-row-3.svg"
                      />
                      <noscript>
                        <img
                          width="1448"
                          height="53"
                          src="https://kingkong.co/wp-content/uploads/2021/01/featured-in-row-3.svg"
                          class=""
                          alt=""
                        />
                      </noscript>
                      <img
                        width="1448"
                        height="53"
                        src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%201448%2053'%3E%3C/svg%3E"
                        class=""
                        alt=""
                        data-lazy-src="https://kingkong.co/wp-content/uploads/2021/01/featured-in-row-3.svg"
                      />
                      <noscript>
                        <img
                          width="1448"
                          height="53"
                          src="https://kingkong.co/wp-content/uploads/2021/01/featured-in-row-3.svg"
                          class=""
                          alt=""
                        />
                      </noscript>
                      <img
                        width="1448"
                        height="53"
                        src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%201448%2053'%3E%3C/svg%3E"
                        class=""
                        alt=""
                        data-lazy-src="https://kingkong.co/wp-content/uploads/2021/01/featured-in-row-3.svg"
                      />
                      <noscript>
                        <img
                          width="1448"
                          height="53"
                          src="https://kingkong.co/wp-content/uploads/2021/01/featured-in-row-3.svg"
                          class=""
                          alt=""
                        />
                      </noscript>
                      <img
                        width="1448"
                        height="53"
                        src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%201448%2053'%3E%3C/svg%3E"
                        class=""
                        alt=""
                        data-lazy-src="https://kingkong.co/wp-content/uploads/2021/01/featured-in-row-3.svg"
                      />
                      <noscript>
                        <img
                          width="1448"
                          height="53"
                          src="https://kingkong.co/wp-content/uploads/2021/01/featured-in-row-3.svg"
                          class=""
                          alt=""
                        />
                      </noscript>
                      <img
                        width="1448"
                        height="53"
                        src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%201448%2053'%3E%3C/svg%3E"
                        class=""
                        alt=""
                        data-lazy-src="https://kingkong.co/wp-content/uploads/2021/01/featured-in-row-3.svg"
                      />
                      <noscript>
                        <img
                          width="1448"
                          height="53"
                          src="https://kingkong.co/wp-content/uploads/2021/01/featured-in-row-3.svg"
                          class=""
                          alt=""
                        />
                      </noscript>
                      <img
                        width="1448"
                        height="53"
                        src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%201448%2053'%3E%3C/svg%3E"
                        class=""
                        alt=""
                        data-lazy-src="https://kingkong.co/wp-content/uploads/2021/01/featured-in-row-3.svg"
                      />
                      <noscript>
                        <img
                          width="1448"
                          height="53"
                          src="https://kingkong.co/wp-content/uploads/2021/01/featured-in-row-3.svg"
                          class=""
                          alt=""
                        />
                      </noscript>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class="text-dark content-text content-image content-wysiwyg content-textarea has-stripes">
            <div class="stripes">
              <div class="stripes-bg"></div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div
              class="background-image-container"
              style={{ backgroundColor: "white" }}
            ></div>
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-md-9 mb-5 text-center animate-in">
                  <span class="h2">
                    {" "}
                    How the hell Can We Guarantee Results?{" "}
                  </span>
                </div>
                <div class="col-12 text-center animate-in">
                  <img
                    width="1200"
                    height="810"
                    src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%201200%20810'%3E%3C/svg%3E"
                    class="attachment-container_width size-container_width"
                    alt=""
                    style={{
                      width: "100%",
                      maxWidth: "960px",
                    }}
                    data-lazy-srcset="https://kingkong.co/wp-content/uploads/2021/11/zero-to-10-million-new-1200x810.jpg 1200w, https://kingkong.co/wp-content/uploads/2021/11/zero-to-10-million-new-300x202.jpg 300w, https://kingkong.co/wp-content/uploads/2021/11/zero-to-10-million-new-1024x691.jpg 1024w, https://kingkong.co/wp-content/uploads/2021/11/zero-to-10-million-new-768x518.jpg 768w, https://kingkong.co/wp-content/uploads/2021/11/zero-to-10-million-new-1536x1037.jpg 1536w, https://kingkong.co/wp-content/uploads/2021/11/zero-to-10-million-new.jpg 1913w"
                    data-lazy-sizes="(max-width: 1200px) 100vw, 1200px"
                    data-lazy-src="https://kingkong.co/wp-content/uploads/2021/11/zero-to-10-million-new-1200x810.jpg"
                  />
                  <noscript>
                    <img
                      width="1200"
                      height="810"
                      src="https://kingkong.co/wp-content/uploads/2021/11/zero-to-10-million-new-1200x810.jpg"
                      class="attachment-container_width size-container_width"
                      alt=""
                      style={{
                        width: "100%",
                        maxWidth: "960px",
                      }}
                      srcset="
                https://kingkong.co/wp-content/uploads/2021/11/zero-to-10-million-new-1200x810.jpg  1200w,
                https://kingkong.co/wp-content/uploads/2021/11/zero-to-10-million-new-300x202.jpg    300w,
                https://kingkong.co/wp-content/uploads/2021/11/zero-to-10-million-new-1024x691.jpg  1024w,
                https://kingkong.co/wp-content/uploads/2021/11/zero-to-10-million-new-768x518.jpg    768w,
                https://kingkong.co/wp-content/uploads/2021/11/zero-to-10-million-new-1536x1037.jpg 1536w,
                https://kingkong.co/wp-content/uploads/2021/11/zero-to-10-million-new.jpg           1913w
              "
                      sizes="(max-width: 1200px) 100vw, 1200px"
                    />
                  </noscript>
                </div>
                <div class="col-xxl-7 col-xl-8 col-md-9 mt-4">
                  <p>
                    <strong>It’s very simple.</strong>
                  </p>
                  <p>
                    We’re willing and able to guarantee results because of our
                    unique approach and ‘selling system’.
                  </p>
                  <p>
                    You see, most digital agencies are good at the “technical
                    stuff”, but know nothing whatsoever about selling,
                    especially in today’s new digital economy.
                  </p>
                  <p>Everything we do at King Kong has one goal, to SELL!</p>
                  <p>Heck, we don’t get paid if we don’t.</p>
                  <p>
                    (Can you hear that? That’s the sound of my accountant
                    screaming in the wind!)
                  </p>
                  <p>
                    <strong>But come closer, listen…</strong>
                  </p>
                  <p>
                    We’ve engineered a new and unique “system” to sell almost
                    anything, to any one, at any price point.
                  </p>
                  <p>
                    It’s a combination of “mind hijacking” persuasion
                    techniques, world-class media buying and something we call
                    the “attention-flywheel”.
                  </p>
                  <p>
                    The total combination makes for an unusually effective
                    marketing system, that truly makes it possible for almost
                    any business to sell like crazy!
                  </p>
                  <p>
                    And If it doesn’t, we insist you don’t pay us a single cent.
                  </p>
                  <p>But listen, lemme level with you for a moment…</p>
                  <p>
                    Achieving predictable and consistent results online is never
                    a result of…
                  </p>
                </div>
                <div class="col-lg-10 col-xxl-8 null">
                  <a
                    class="btn btn-doublelayer btn-green"
                    href="/30-minute-strategy-session/"
                  >
                    <span class="btn-doublelayer__maintext">
                      Claim Your Free 30-Minute Strategy Session
                    </span>
                    <span class="btn-doublelayer__subtext">
                      Be quick! FREE spots are almost gone for May
                    </span>
                  </a>
                  <a
                    href="https://kingkong.co/reviews"
                    itemscope=""
                    itemtype="http://schema.org/LocalBusiness"
                    class="kkra-shortstring term-agency"
                  >
                    <meta
                      itemprop="image"
                      content="https://kingkong.co/wp-content/themes/kingkong/assets/src/images/site-logo.png"
                    />
                    <meta itemprop="telephone" content="+ 1-888-303-8580" />
                    <meta itemprop="name" content="King Kong" />
                    <meta itemprop="priceRange" content="$$$" />
                    <span
                      itemprop="address"
                      itemscope=""
                      itemtype="http://schema.org/PostalAddress"
                    >
                      <meta
                        itemprop="streetAddress"
                        content="Oppenheimer Tower, 10880 Wilshire Boulevard"
                      />
                      <meta itemprop="addressLocality" content="Los Angeles" />
                      <meta itemprop="addressRegion" content="California" />
                      <meta itemprop="addressCountry" content="United States" />
                      <meta itemprop="postalCode" content="90024" />
                    </span>
                    <div
                      class="kkra-shortstring__inner"
                      itemprop="aggregateRating"
                      itemscope=""
                      itemtype="http://schema.org/AggregateRating"
                    >
                      <div class="kkra-stars">
                        <svg
                          class="unfilled"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 104 18"
                        >
                          <path
                            d="M17.2 6l-4.5-.3c-.4-.1-.7-.3-.9-.7L10.1.7C9.7-.2 8.5-.2 8.2.6L6.4 4.9c-.2.4-.5.7-.9.7L1 6C0 6.1-.3 7.3.4 8l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2 1 .8 1.7 1.6 1.2l3.9-2.4c.3-.2.8-.2 1.1 0l3.9 2.4c.8.5 1.8-.2 1.6-1.2l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3.1c.7-.6.3-1.8-.6-1.9z"
                            class="st0"
                          ></path>
                          <path
                            d="M9.1 1s.1 0 0 0l1.8 4.4c.3.6.8 1 1.6 1.2h.2l4.4.4v.1l-3.4 3.1c-.5.5-.7 1.3-.6 2l1 4.5v.1H14l-3.9-2.4c-.3-.2-.7-.3-1.1-.3-.4 0-.8.1-1.1.3L4 16.8h-.1v-.1l1-4.5c.2-.7 0-1.5-.6-2.1L1 7.1V7h.1l4.5-.4c.7 0 1.4-.5 1.7-1.3L9.1 1m0-1c-.4 0-.8.2-.9.6L6.4 4.9c-.2.4-.5.7-.9.7L1 6C0 6.1-.3 7.3.4 8l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2.8.4 1.4 1 1.4.2 0 .4-.1.6-.2l3.9-2.4c.1-.1.3-.2.5-.2s.4.1.5.2l3.9 2.4c.2.1.4.2.6.2.6 0 1.2-.6 1-1.4l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3.1c.7-.6.3-1.8-.6-1.9l-4.5-.3c-.3-.1-.6-.3-.8-.7L10.1.7c-.2-.5-.6-.7-1-.7z"
                            class="st1"
                          ></path>
                          <path
                            d="M38.6 6l-4.4-.3c-.4-.1-.7-.3-.9-.7L31.5.7c-.3-.9-1.6-.9-1.9 0L27.8 5c-.2.4-.5.7-.9.7l-4.5.4c-.9 0-1.3 1.3-.6 1.9l3.4 3c.3.3.4.7.3 1.1l-1 4.5c-.2 1 .8 1.7 1.6 1.2l3.9-2.4c.3-.2.8-.2 1.1 0l3.9 2.4c.8.5 1.8-.2 1.6-1.2l-1-4.5c-.1-.4 0-.8.3-1.1l3.3-3c.8-.7.4-1.9-.6-2z"
                            class="st0"
                          ></path>
                          <path
                            d="M30.6 1l1.8 4.4c.3.6.8 1 1.6 1.2h.2l4.3.4c.1 0 .1 0 .1.1s0 .1-.1.2l-3.3 3c-.5.5-.7 1.3-.6 2l1 4.5v.1h-.1l-3.9-2.4c-.3-.2-.7-.3-1.1-.3-.4 0-.8.1-1.1.3l-3.9 2.4h-.1v-.1l1-4.5c.2-.7 0-1.5-.6-2l-3.4-3v-.1l4.6-.5c.7 0 1.4-.5 1.7-1.3L30.6 1c-.1 0-.1 0 0 0m0-1c-.4 0-.8.2-1 .7L27.8 5c-.2.4-.5.7-.9.7l-4.5.4c-.9 0-1.3 1.3-.6 1.9l3.4 3c.3.3.4.7.3 1.1l-1 4.5c-.2.8.4 1.4 1 1.4.2 0 .4-.1.6-.2l3.9-2.4c.1-.1.3-.2.5-.2s.4.1.5.2l3.9 2.4c.2.1.4.2.6.2.6 0 1.2-.6 1-1.4l-1-4.5c-.1-.4 0-.8.3-1.1l3.3-3c.8-.7.4-1.9-.6-2l-4.4-.3c-.4-.1-.7-.3-.9-.7L31.4.7c0-.5-.4-.7-.8-.7z"
                            class="st1"
                          ></path>
                          <path
                            d="M60.1 6l-4.5-.3c-.4-.1-.7-.3-.9-.7L53 .7c-.4-.9-1.6-.9-2 0l-1.7 4.2c-.2.4-.5.7-.9.7l-4.5.4c-.9.1-1.3 1.3-.6 2l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2 1 .8 1.7 1.6 1.2l3.9-2.4c.3-.2.8-.2 1.1 0l3.9 2.4c.8.5 1.8-.2 1.6-1.2l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3c.7-.7.3-1.9-.6-2z"
                            class="st0"
                          ></path>
                          <path
                            d="M52 1s.1 0 0 0l1.8 4.3c.3.6.8 1 1.6 1.2h.2L60 7l.1.1v.2l-3.4 3c-.5.5-.7 1.3-.6 2l1 4.5v.1H57l-3.9-2.4c-.3-.2-.7-.3-1.1-.3-.4 0-.8.1-1.1.3L47 16.9h-.1v-.1l1-4.5c.2-.7 0-1.5-.6-2.1l-3.3-3c-.1-.1-.1-.2-.1-.2h.1l4.5-.4c.7 0 1.4-.5 1.7-1.3L52 1c-.1 0 0 0 0 0m0-1c-.4 0-.8.2-1 .7l-1.7 4.2c-.2.4-.5.7-.9.7l-4.5.4c-.9.1-1.3 1.3-.6 2l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2.8.4 1.4 1 1.4.2 0 .4-.1.6-.2l3.9-2.4c.1-.1.3-.2.5-.2s.4.1.5.2l3.9 2.4c.2.1.4.2.6.2.6 0 1.2-.6 1-1.4l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3c.7-.7.3-1.9-.6-2l-4.5-.3c-.4-.1-.7-.3-.9-.7L53 .7c-.2-.5-.6-.7-1-.7z"
                            class="st1"
                          ></path>
                          <g>
                            <path
                              d="M81.6 6l-4.5-.3c-.4-.1-.7-.3-.9-.7L74.4.7c-.3-.9-1.6-.9-1.8-.1l-1.8 4.3c-.2.4-.5.7-.9.7l-4.5.4c-1 .1-1.4 1.3-.6 2l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2 1 .8 1.7 1.6 1.2l3.9-2.4c.3-.2.8-.2 1.1 0l3.9 2.4c.8.5 1.8-.2 1.6-1.2l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3.1c.7-.6.3-1.8-.6-1.9z"
                              class="st0"
                            ></path>
                            <path
                              d="M73.5 1.1l1.8 4.3c.3.6.8 1 1.6 1.2h.2l4.4.4v.1l-3.4 3.1c-.5.5-.7 1.3-.6 2l1 4.5v.1h-.1l-3.9-2.4c-.3-.2-.7-.3-1.1-.3-.4 0-.8.1-1.1.3l-3.9 2.4h-.1v-.1l1-4.5c.2-.7 0-1.5-.6-2.1l-3.3-3c-.1-.1-.1-.1-.1-.2l.1-.1 4.6-.2c.7 0 1.4-.5 1.7-1.3l1.8-4.2M73.4 0c-.4 0-.7.2-.8.6l-1.8 4.3c-.2.4-.5.7-.9.7l-4.5.4c-1 .1-1.4 1.3-.6 2l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2.8.4 1.4 1 1.4.2 0 .4-.1.6-.2l3.9-2.4c.1-.1.3-.2.5-.2s.4.1.5.2l3.9 2.4c.2.1.4.2.6.2.6 0 1.2-.6 1-1.4l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3.1c.7-.6.3-1.8-.6-1.9L77 5.7c-.4-.1-.7-.3-.9-.7L74.3.7c-.1-.5-.5-.7-.9-.7z"
                              class="st1"
                            ></path>
                          </g>
                          <g>
                            <path
                              d="M103.6 7.9c.7-.6.3-1.8-.6-1.9l-4.4-.3c-.4-.1-.7-.3-.9-.7L95.9.7c-.4-.9-1.6-.9-1.9 0L92.2 5c-.2.4-.5.7-.9.7l-4.5.4c-.9 0-1.3 1.3-.6 1.9l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2 1 .8 1.7 1.6 1.2l3.9-2.4c.3-.2.8-.2 1.1 0l3.9 2.4c.8.5 1.8-.2 1.6-1.2l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3.1z"
                              class="st0"
                            ></path>
                            <path
                              d="M94.9 1c.1 0 .1.1 0 0l1.8 4.3c.3.6.8 1 1.6 1.2h.2l4.4.3v.1L99.5 10c-.5.5-.7 1.3-.6 2l1 4.5v.1h-.1l-3.8-2c-.3-.2-.7-.3-1.1-.3-.4 0-.8.1-1.1.3L89.9 17h-.1v-.1l1-4.5c.2-.7 0-1.5-.6-2.1l-3.3-3v-.1l4.5-.4c.7 0 1.4-.5 1.7-1.3L94.9 1m0-1c-.4 0-.8.2-.9.7L92.2 5c-.2.4-.5.7-.9.7l-4.5.4c-.9 0-1.3 1.3-.6 1.9l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2.8.4 1.4 1 1.4.2 0 .4-.1.6-.2l3.9-2.4c.2-.1.3-.2.6-.2s.4.1.6.2l3.9 2.4c.2.1.4.2.6.2.6 0 1.2-.6 1-1.4l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3.1c.7-.6.3-1.8-.6-1.9l-4.4-.3c-.4-.1-.7-.3-.9-.7L96 .7c-.3-.5-.7-.7-1.1-.7z"
                              class="st1"
                            ></path>
                          </g>
                        </svg>
                        <div
                          class="kkra-stars__filled"
                          style={{ width: "90%" }}
                        >
                          <svg
                            class="filled"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 104 18"
                          >
                            <g transform="translate(-160 -526)">
                              <g transform="translate(64 308)">
                                <g transform="translate(96 218)">
                                  <g>
                                    <path
                                      d="M10.1.7L11.8 5c.2.4.5.6.9.7l4.5.3c.9.1 1.3 1.3.6 1.9L14.4 11c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1L.4 8c-.7-.7-.4-1.9.6-2l4.5-.4c.4 0 .7-.3.9-.7L8.2.6c.3-.8 1.5-.8 1.9.1"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M31.5.7L33.3 5c.2.4.5.6.9.7l4.4.3c1 .1 1.4 1.3.6 2l-3.3 3c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.4-3c-.7-.6-.3-1.9.6-1.9l4.5-.4c.4 0 .7-.3.9-.7L29.6.7c.3-.9 1.6-.9 1.9 0"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M53 .7L54.7 5c.2.4.5.6.9.7l4.5.3c.9.1 1.3 1.3.6 2l-3.4 3c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.3-3c-.7-.7-.3-1.9.6-2l4.5-.4c.4 0 .7-.3.9-.7L51 .7c.4-.9 1.6-.9 2 0"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M74.4.7L76.2 5c.2.4.5.6.9.7l4.5.3c.9.1 1.3 1.3.6 1.9L78.8 11c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2L74 15.4c-.3-.2-.8-.2-1.1 0L69 17.8c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.3-3c-.8-.7-.4-1.9.6-2l4.5-.4c.4 0 .7-.3.9-.7L72.6.6c.2-.8 1.5-.8 1.8.1"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M95.9.7L97.7 5c.2.4.5.6.9.7l4.4.3c.9.1 1.3 1.3.6 1.9l-3.4 3.1c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.3-3c-.7-.6-.3-1.9.6-1.9l4.5-.4c.4 0 .7-.3.9-.7L94 .7c.3-.9 1.5-.9 1.9 0"
                                      class="st20"
                                    ></path>
                                  </g>
                                  <g>
                                    <path
                                      d="M10.1.7L11.8 5c.2.4.5.6.9.7l4.5.3c.9.1 1.3 1.3.6 1.9L14.4 11c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1L.4 8c-.7-.7-.4-1.9.6-2l4.5-.4c.4 0 .7-.3.9-.7L8.2.6c.3-.8 1.5-.8 1.9.1"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M31.5.7L33.3 5c.2.4.5.6.9.7l4.4.3c1 .1 1.4 1.3.6 2l-3.3 3c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.4-3c-.7-.6-.3-1.9.6-1.9l4.5-.4c.4 0 .7-.3.9-.7L29.6.7c.3-.9 1.6-.9 1.9 0"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M53 .7L54.7 5c.2.4.5.6.9.7l4.5.3c.9.1 1.3 1.3.6 2l-3.4 3c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.3-3c-.7-.7-.3-1.9.6-2l4.5-.4c.4 0 .7-.3.9-.7L51 .7c.4-.9 1.6-.9 2 0"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M74.4.7L76.2 5c.2.4.5.6.9.7l4.5.3c.9.1 1.3 1.3.6 1.9L78.8 11c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2L74 15.4c-.3-.2-.8-.2-1.1 0L69 17.8c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.3-3c-.8-.7-.4-1.9.6-2l4.5-.4c.4 0 .7-.3.9-.7L72.6.6c.2-.8 1.5-.8 1.8.1"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M95.9.7L97.7 5c.2.4.5.6.9.7l4.4.3c.9.1 1.3 1.3.6 1.9l-3.4 3.1c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.3-3c-.7-.6-.3-1.9.6-1.9l4.5-.4c.4 0 .7-.3.9-.7L94 .7c.3-.9 1.5-.9 1.9 0"
                                      class="st20"
                                    ></path>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </svg>
                        </div>
                      </div>
                      <span itemprop="ratingValue">4.5 </span>stars out of
                      <span itemprop="ratingCount">383 reviews</span>
                      <div
                        itemprop="itemReviewed"
                        itemscope=""
                        itemtype="http://schema.org/LocalBusiness"
                      >
                        <meta
                          itemprop="image"
                          content="https://kingkong.co/wp-content/themes/kingkong/assets/src/images/site-logo.png"
                        />
                        <meta itemprop="telephone" content="+ 1-888-303-8580" />
                        <meta itemprop="name" content="King Kong" />
                        <meta itemprop="priceRange" content="$$$" />
                        <span
                          itemprop="address"
                          itemscope=""
                          itemtype="http://schema.org/PostalAddress"
                        >
                          <meta
                            itemprop="streetAddress"
                            content="Oppenheimer Tower, 10880 Wilshire Boulevard"
                          />
                          <meta
                            itemprop="addressLocality"
                            content="Los Angeles"
                          />
                          <meta itemprop="addressRegion" content="California" />
                          <meta
                            itemprop="addressCountry"
                            content="United States"
                          />
                          <meta itemprop="postalCode" content="90024" />
                        </span>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section class="text-dark content-image content-text content-wysiwyg content-textarea">
            <div
              class="background-image-container"
              style={{ backgroundColor: "#f3f3f3" }}
            ></div>
            <div class="container">
              <div class="row justify-content-center">
                <div class="emoji-wide text-center animate-in">
                  <img
                    width="557"
                    height="201"
                    src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20557%20201'%3E%3C/svg%3E"
                    class="attachment-container_width size-container_width"
                    alt=""
                    data-lazy-srcset="https://kingkong.co/wp-content/uploads/2021/04/emoji-pixie.png 557w, https://kingkong.co/wp-content/uploads/2021/04/emoji-pixie-300x108.png 300w"
                    data-lazy-sizes="(max-width: 557px) 100vw, 557px"
                    data-lazy-src="https://kingkong.co/wp-content/uploads/2021/04/emoji-pixie.png"
                  />
                  <noscript>
                    <img
                      width="557"
                      height="201"
                      src="https://kingkong.co/wp-content/uploads/2021/04/emoji-pixie.png"
                      class="attachment-container_width size-container_width"
                      alt=""
                      srcset="
                https://kingkong.co/wp-content/uploads/2021/04/emoji-pixie.png         557w,
                https://kingkong.co/wp-content/uploads/2021/04/emoji-pixie-300x108.png 300w
              "
                      sizes="(max-width: 557px) 100vw, 557px"
                    />
                  </noscript>
                </div>
                <div class="col-lg-10 col-xxl-9 my-5 text-center animate-in">
                  <span class="h2">
                    ‘Magic Pixie Dust’ Sprinkled On ‘Crossed Fingers’ With A
                    Dash Of ‘Hope And Pray For The Best’
                  </span>
                </div>
                <div class="col-xxl-7 col-xl-8 col-md-9">
                  <p>
                    It’s not about this…It’s about having a predictable and
                    reliable “system” for selling.
                  </p>
                  <p>
                    And our “selling system” includes a war chest of proven
                    strategies and tactics that most digital agencies don’t know
                    even exist.
                  </p>
                  <p>For example…</p>
                  <ul class="styled-list">
                    <li>
                      <em>
                        <strong>
                          We know how to make a simple tweak to almost any
                          landing page that will increase conversions by up to
                          400%!
                        </strong>{" "}
                      </em>
                      (Think about that. How would you like to increase your
                      leads by 4X whilst reducing what you pay per lead by
                      375%!?)
                    </li>
                    <li>
                      <strong>
                        We’ve discovered how to write Google and Facebook ads
                        that use psychological triggers
                      </strong>
                      that “hijack” your prospect’s attention, practically
                      forcing them to click and take action! (Not one digital
                      agency or “expert” in a hundred even have a clue how to do
                      this!)
                    </li>
                    <li>
                      <strong>
                        We’ve discovered the secret “scroll stopping” strategy
                        for Facebook Ads
                      </strong>
                      that siphons off more traffic for LESS money (Even the
                      world’s most experienced Facebook marketers don’t fully
                      understand how to do this)
                    </li>
                    <li>
                      <strong>
                        We can tell you the biggest mistake made by businesses
                        using Google Ads!
                      </strong>
                      (This mistake costs business owners and marketers a small
                      fortune in lost profits and market share)
                    </li>
                    <li>
                      <strong>
                        We know how to uncover large new “starving crowds”
                      </strong>
                      of prospects in ANY market – who spend like sailors on
                      leave (even when the economy is hurting and you’re far
                      more expensive than the competition)
                    </li>
                  </ul>
                  <p>
                    <em>But that isn’t all, we also know how to…</em>
                  </p>
                </div>
                <div class="col-md-10 col-lg-9 col-xxl-8 my-5 text-center animate-in">
                  <span class="h2">
                    Increase The Pulling Power Of Almost Any Ad By Up To 900%!
                  </span>
                </div>
                <div class="col-xxl-7 col-xl-8 col-md-9">
                  <p>
                    <strong>
                      We’ve used this strategy in hundreds of the most
                      hyper-competitive markets
                    </strong>
                    to outsell and dominate the most ferocious competitors.
                  </p>
                  <ul class="styled-list">
                    <li>
                      <em>
                        <strong>
                          We’ve discovered (from spending $30 million on ads)
                          what you need to do to the ‘appearance’ of your ads
                        </strong>
                      </em>
                      on both Google and Facebook to skyrocket conversions (It’s
                      the opposite to what most people have been told and could
                      DRAMATICALLY increase your sales!)
                    </li>
                    <li>
                      <strong>
                        We’ve developed a proven and predictable way to get
                        highly-qualified prospects coming to you…
                      </strong>
                      ONLY when they’re ready, willing and able to BUY! Forget
                      the usual “digital marketing stuff” that leaves you or
                      your sales team speaking with looky-loos and price
                      shoppers)
                    </li>
                    <li>
                      <strong>
                        We know a way to dramatically increase the quantity of
                        ‘eager-to-buy’ leads that your ads generate…
                      </strong>
                      often while spending the same amount, if not less, on
                      traffic! (not one in a hundred agencies even have a clue
                      this is possible)
                    </li>
                    <li>
                      <strong>
                        We know one small tweak you can make to your Google Ads
                      </strong>
                      which often increases the Click-Through-Rates by 650%!
                      (This drives Google’s algorithm wild and they give you the
                      very best traffic as a reward!)
                    </li>
                    <li>
                      <strong>
                        We know how to make a small ‘bidding adjustment’
                      </strong>
                      change to increase your Google and Facebook Ads profits by
                      50%…even if…you don’t change a word of copy on your ads or
                      landing pages!
                    </li>
                    <li>
                      <strong>We know how to… </strong>you get the idea, don’t
                      you? The point is, because of our deep experience in
                      working in over 1067 different industries and niches, and
                      generating our clients over $7.8 billion in sales…
                    </li>
                  </ul>
                  <p>We truly believe that…</p>
                </div>
                <div class="col-lg-10 col-xxl-8 null">
                  <a
                    class="btn btn-doublelayer btn-green"
                    href="/30-minute-strategy-session/"
                  >
                    <span class="btn-doublelayer__maintext">
                      Claim Your Free 30-Minute Strategy Session
                    </span>
                    <span class="btn-doublelayer__subtext">
                      Be quick! FREE spots are almost gone for May
                    </span>
                  </a>
                  <a
                    href="https://kingkong.co/reviews"
                    itemscope=""
                    itemtype="http://schema.org/LocalBusiness"
                    class="kkra-shortstring term-agency"
                  >
                    <meta
                      itemprop="image"
                      content="https://kingkong.co/wp-content/themes/kingkong/assets/src/images/site-logo.png"
                    />
                    <meta itemprop="telephone" content="+ 1-888-303-8580" />
                    <meta itemprop="name" content="King Kong" />
                    <meta itemprop="priceRange" content="$$$" />
                    <span
                      itemprop="address"
                      itemscope=""
                      itemtype="http://schema.org/PostalAddress"
                    >
                      <meta
                        itemprop="streetAddress"
                        content="Oppenheimer Tower, 10880 Wilshire Boulevard"
                      />
                      <meta itemprop="addressLocality" content="Los Angeles" />
                      <meta itemprop="addressRegion" content="California" />
                      <meta itemprop="addressCountry" content="United States" />
                      <meta itemprop="postalCode" content="90024" />
                    </span>
                    <div
                      class="kkra-shortstring__inner"
                      itemprop="aggregateRating"
                      itemscope=""
                      itemtype="http://schema.org/AggregateRating"
                    >
                      <div class="kkra-stars">
                        <svg
                          class="unfilled"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 104 18"
                        >
                          <path
                            d="M17.2 6l-4.5-.3c-.4-.1-.7-.3-.9-.7L10.1.7C9.7-.2 8.5-.2 8.2.6L6.4 4.9c-.2.4-.5.7-.9.7L1 6C0 6.1-.3 7.3.4 8l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2 1 .8 1.7 1.6 1.2l3.9-2.4c.3-.2.8-.2 1.1 0l3.9 2.4c.8.5 1.8-.2 1.6-1.2l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3.1c.7-.6.3-1.8-.6-1.9z"
                            class="st0"
                          ></path>
                          <path
                            d="M9.1 1s.1 0 0 0l1.8 4.4c.3.6.8 1 1.6 1.2h.2l4.4.4v.1l-3.4 3.1c-.5.5-.7 1.3-.6 2l1 4.5v.1H14l-3.9-2.4c-.3-.2-.7-.3-1.1-.3-.4 0-.8.1-1.1.3L4 16.8h-.1v-.1l1-4.5c.2-.7 0-1.5-.6-2.1L1 7.1V7h.1l4.5-.4c.7 0 1.4-.5 1.7-1.3L9.1 1m0-1c-.4 0-.8.2-.9.6L6.4 4.9c-.2.4-.5.7-.9.7L1 6C0 6.1-.3 7.3.4 8l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2.8.4 1.4 1 1.4.2 0 .4-.1.6-.2l3.9-2.4c.1-.1.3-.2.5-.2s.4.1.5.2l3.9 2.4c.2.1.4.2.6.2.6 0 1.2-.6 1-1.4l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3.1c.7-.6.3-1.8-.6-1.9l-4.5-.3c-.3-.1-.6-.3-.8-.7L10.1.7c-.2-.5-.6-.7-1-.7z"
                            class="st1"
                          ></path>
                          <path
                            d="M38.6 6l-4.4-.3c-.4-.1-.7-.3-.9-.7L31.5.7c-.3-.9-1.6-.9-1.9 0L27.8 5c-.2.4-.5.7-.9.7l-4.5.4c-.9 0-1.3 1.3-.6 1.9l3.4 3c.3.3.4.7.3 1.1l-1 4.5c-.2 1 .8 1.7 1.6 1.2l3.9-2.4c.3-.2.8-.2 1.1 0l3.9 2.4c.8.5 1.8-.2 1.6-1.2l-1-4.5c-.1-.4 0-.8.3-1.1l3.3-3c.8-.7.4-1.9-.6-2z"
                            class="st0"
                          ></path>
                          <path
                            d="M30.6 1l1.8 4.4c.3.6.8 1 1.6 1.2h.2l4.3.4c.1 0 .1 0 .1.1s0 .1-.1.2l-3.3 3c-.5.5-.7 1.3-.6 2l1 4.5v.1h-.1l-3.9-2.4c-.3-.2-.7-.3-1.1-.3-.4 0-.8.1-1.1.3l-3.9 2.4h-.1v-.1l1-4.5c.2-.7 0-1.5-.6-2l-3.4-3v-.1l4.6-.5c.7 0 1.4-.5 1.7-1.3L30.6 1c-.1 0-.1 0 0 0m0-1c-.4 0-.8.2-1 .7L27.8 5c-.2.4-.5.7-.9.7l-4.5.4c-.9 0-1.3 1.3-.6 1.9l3.4 3c.3.3.4.7.3 1.1l-1 4.5c-.2.8.4 1.4 1 1.4.2 0 .4-.1.6-.2l3.9-2.4c.1-.1.3-.2.5-.2s.4.1.5.2l3.9 2.4c.2.1.4.2.6.2.6 0 1.2-.6 1-1.4l-1-4.5c-.1-.4 0-.8.3-1.1l3.3-3c.8-.7.4-1.9-.6-2l-4.4-.3c-.4-.1-.7-.3-.9-.7L31.4.7c0-.5-.4-.7-.8-.7z"
                            class="st1"
                          ></path>
                          <path
                            d="M60.1 6l-4.5-.3c-.4-.1-.7-.3-.9-.7L53 .7c-.4-.9-1.6-.9-2 0l-1.7 4.2c-.2.4-.5.7-.9.7l-4.5.4c-.9.1-1.3 1.3-.6 2l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2 1 .8 1.7 1.6 1.2l3.9-2.4c.3-.2.8-.2 1.1 0l3.9 2.4c.8.5 1.8-.2 1.6-1.2l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3c.7-.7.3-1.9-.6-2z"
                            class="st0"
                          ></path>
                          <path
                            d="M52 1s.1 0 0 0l1.8 4.3c.3.6.8 1 1.6 1.2h.2L60 7l.1.1v.2l-3.4 3c-.5.5-.7 1.3-.6 2l1 4.5v.1H57l-3.9-2.4c-.3-.2-.7-.3-1.1-.3-.4 0-.8.1-1.1.3L47 16.9h-.1v-.1l1-4.5c.2-.7 0-1.5-.6-2.1l-3.3-3c-.1-.1-.1-.2-.1-.2h.1l4.5-.4c.7 0 1.4-.5 1.7-1.3L52 1c-.1 0 0 0 0 0m0-1c-.4 0-.8.2-1 .7l-1.7 4.2c-.2.4-.5.7-.9.7l-4.5.4c-.9.1-1.3 1.3-.6 2l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2.8.4 1.4 1 1.4.2 0 .4-.1.6-.2l3.9-2.4c.1-.1.3-.2.5-.2s.4.1.5.2l3.9 2.4c.2.1.4.2.6.2.6 0 1.2-.6 1-1.4l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3c.7-.7.3-1.9-.6-2l-4.5-.3c-.4-.1-.7-.3-.9-.7L53 .7c-.2-.5-.6-.7-1-.7z"
                            class="st1"
                          ></path>
                          <g>
                            <path
                              d="M81.6 6l-4.5-.3c-.4-.1-.7-.3-.9-.7L74.4.7c-.3-.9-1.6-.9-1.8-.1l-1.8 4.3c-.2.4-.5.7-.9.7l-4.5.4c-1 .1-1.4 1.3-.6 2l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2 1 .8 1.7 1.6 1.2l3.9-2.4c.3-.2.8-.2 1.1 0l3.9 2.4c.8.5 1.8-.2 1.6-1.2l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3.1c.7-.6.3-1.8-.6-1.9z"
                              class="st0"
                            ></path>
                            <path
                              d="M73.5 1.1l1.8 4.3c.3.6.8 1 1.6 1.2h.2l4.4.4v.1l-3.4 3.1c-.5.5-.7 1.3-.6 2l1 4.5v.1h-.1l-3.9-2.4c-.3-.2-.7-.3-1.1-.3-.4 0-.8.1-1.1.3l-3.9 2.4h-.1v-.1l1-4.5c.2-.7 0-1.5-.6-2.1l-3.3-3c-.1-.1-.1-.1-.1-.2l.1-.1 4.6-.2c.7 0 1.4-.5 1.7-1.3l1.8-4.2M73.4 0c-.4 0-.7.2-.8.6l-1.8 4.3c-.2.4-.5.7-.9.7l-4.5.4c-1 .1-1.4 1.3-.6 2l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2.8.4 1.4 1 1.4.2 0 .4-.1.6-.2l3.9-2.4c.1-.1.3-.2.5-.2s.4.1.5.2l3.9 2.4c.2.1.4.2.6.2.6 0 1.2-.6 1-1.4l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3.1c.7-.6.3-1.8-.6-1.9L77 5.7c-.4-.1-.7-.3-.9-.7L74.3.7c-.1-.5-.5-.7-.9-.7z"
                              class="st1"
                            ></path>
                          </g>
                          <g>
                            <path
                              d="M103.6 7.9c.7-.6.3-1.8-.6-1.9l-4.4-.3c-.4-.1-.7-.3-.9-.7L95.9.7c-.4-.9-1.6-.9-1.9 0L92.2 5c-.2.4-.5.7-.9.7l-4.5.4c-.9 0-1.3 1.3-.6 1.9l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2 1 .8 1.7 1.6 1.2l3.9-2.4c.3-.2.8-.2 1.1 0l3.9 2.4c.8.5 1.8-.2 1.6-1.2l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3.1z"
                              class="st0"
                            ></path>
                            <path
                              d="M94.9 1c.1 0 .1.1 0 0l1.8 4.3c.3.6.8 1 1.6 1.2h.2l4.4.3v.1L99.5 10c-.5.5-.7 1.3-.6 2l1 4.5v.1h-.1l-3.8-2c-.3-.2-.7-.3-1.1-.3-.4 0-.8.1-1.1.3L89.9 17h-.1v-.1l1-4.5c.2-.7 0-1.5-.6-2.1l-3.3-3v-.1l4.5-.4c.7 0 1.4-.5 1.7-1.3L94.9 1m0-1c-.4 0-.8.2-.9.7L92.2 5c-.2.4-.5.7-.9.7l-4.5.4c-.9 0-1.3 1.3-.6 1.9l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2.8.4 1.4 1 1.4.2 0 .4-.1.6-.2l3.9-2.4c.2-.1.3-.2.6-.2s.4.1.6.2l3.9 2.4c.2.1.4.2.6.2.6 0 1.2-.6 1-1.4l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3.1c.7-.6.3-1.8-.6-1.9l-4.4-.3c-.4-.1-.7-.3-.9-.7L96 .7c-.3-.5-.7-.7-1.1-.7z"
                              class="st1"
                            ></path>
                          </g>
                        </svg>
                        <div
                          class="kkra-stars__filled"
                          style={{ width: "90%" }}
                        >
                          <svg
                            class="filled"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 104 18"
                          >
                            <g transform="translate(-160 -526)">
                              <g transform="translate(64 308)">
                                <g transform="translate(96 218)">
                                  <g>
                                    <path
                                      d="M10.1.7L11.8 5c.2.4.5.6.9.7l4.5.3c.9.1 1.3 1.3.6 1.9L14.4 11c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1L.4 8c-.7-.7-.4-1.9.6-2l4.5-.4c.4 0 .7-.3.9-.7L8.2.6c.3-.8 1.5-.8 1.9.1"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M31.5.7L33.3 5c.2.4.5.6.9.7l4.4.3c1 .1 1.4 1.3.6 2l-3.3 3c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.4-3c-.7-.6-.3-1.9.6-1.9l4.5-.4c.4 0 .7-.3.9-.7L29.6.7c.3-.9 1.6-.9 1.9 0"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M53 .7L54.7 5c.2.4.5.6.9.7l4.5.3c.9.1 1.3 1.3.6 2l-3.4 3c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.3-3c-.7-.7-.3-1.9.6-2l4.5-.4c.4 0 .7-.3.9-.7L51 .7c.4-.9 1.6-.9 2 0"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M74.4.7L76.2 5c.2.4.5.6.9.7l4.5.3c.9.1 1.3 1.3.6 1.9L78.8 11c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2L74 15.4c-.3-.2-.8-.2-1.1 0L69 17.8c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.3-3c-.8-.7-.4-1.9.6-2l4.5-.4c.4 0 .7-.3.9-.7L72.6.6c.2-.8 1.5-.8 1.8.1"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M95.9.7L97.7 5c.2.4.5.6.9.7l4.4.3c.9.1 1.3 1.3.6 1.9l-3.4 3.1c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.3-3c-.7-.6-.3-1.9.6-1.9l4.5-.4c.4 0 .7-.3.9-.7L94 .7c.3-.9 1.5-.9 1.9 0"
                                      class="st20"
                                    ></path>
                                  </g>
                                  <g>
                                    <path
                                      d="M10.1.7L11.8 5c.2.4.5.6.9.7l4.5.3c.9.1 1.3 1.3.6 1.9L14.4 11c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1L.4 8c-.7-.7-.4-1.9.6-2l4.5-.4c.4 0 .7-.3.9-.7L8.2.6c.3-.8 1.5-.8 1.9.1"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M31.5.7L33.3 5c.2.4.5.6.9.7l4.4.3c1 .1 1.4 1.3.6 2l-3.3 3c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.4-3c-.7-.6-.3-1.9.6-1.9l4.5-.4c.4 0 .7-.3.9-.7L29.6.7c.3-.9 1.6-.9 1.9 0"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M53 .7L54.7 5c.2.4.5.6.9.7l4.5.3c.9.1 1.3 1.3.6 2l-3.4 3c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.3-3c-.7-.7-.3-1.9.6-2l4.5-.4c.4 0 .7-.3.9-.7L51 .7c.4-.9 1.6-.9 2 0"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M74.4.7L76.2 5c.2.4.5.6.9.7l4.5.3c.9.1 1.3 1.3.6 1.9L78.8 11c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2L74 15.4c-.3-.2-.8-.2-1.1 0L69 17.8c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.3-3c-.8-.7-.4-1.9.6-2l4.5-.4c.4 0 .7-.3.9-.7L72.6.6c.2-.8 1.5-.8 1.8.1"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M95.9.7L97.7 5c.2.4.5.6.9.7l4.4.3c.9.1 1.3 1.3.6 1.9l-3.4 3.1c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.3-3c-.7-.6-.3-1.9.6-1.9l4.5-.4c.4 0 .7-.3.9-.7L94 .7c.3-.9 1.5-.9 1.9 0"
                                      class="st20"
                                    ></path>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </svg>
                        </div>
                      </div>
                      <span itemprop="ratingValue">4.5 </span>stars out of
                      <span itemprop="ratingCount">383 reviews</span>
                      <div
                        itemprop="itemReviewed"
                        itemscope=""
                        itemtype="http://schema.org/LocalBusiness"
                      >
                        <meta
                          itemprop="image"
                          content="https://kingkong.co/wp-content/themes/kingkong/assets/src/images/site-logo.png"
                        />
                        <meta itemprop="telephone" content="+ 1-888-303-8580" />
                        <meta itemprop="name" content="King Kong" />
                        <meta itemprop="priceRange" content="$$$" />
                        <span
                          itemprop="address"
                          itemscope=""
                          itemtype="http://schema.org/PostalAddress"
                        >
                          <meta
                            itemprop="streetAddress"
                            content="Oppenheimer Tower, 10880 Wilshire Boulevard"
                          />
                          <meta
                            itemprop="addressLocality"
                            content="Los Angeles"
                          />
                          <meta itemprop="addressRegion" content="California" />
                          <meta
                            itemprop="addressCountry"
                            content="United States"
                          />
                          <meta itemprop="postalCode" content="90024" />
                        </span>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section class="text-dark divider-overlay content-image">
            <div class="background-image-container"></div>
            <div class="container-fluid">
              <div class="row justify-content-center">
                <div class="col-12 text-left animate-in">
                  <img
                    width="1200"
                    height="309"
                    src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%201200%20309'%3E%3C/svg%3E"
                    class="attachment-container_width size-container_width"
                    alt=""
                    data-lazy-srcset="https://kingkong.co/wp-content/uploads/2021/05/divider-row-grey-to-white-1200x309.jpg 1200w, https://kingkong.co/wp-content/uploads/2021/05/divider-row-grey-to-white-300x77.jpg 300w, https://kingkong.co/wp-content/uploads/2021/05/divider-row-grey-to-white-1024x263.jpg 1024w, https://kingkong.co/wp-content/uploads/2021/05/divider-row-grey-to-white-768x197.jpg 768w, https://kingkong.co/wp-content/uploads/2021/05/divider-row-grey-to-white-1536x395.jpg 1536w, https://kingkong.co/wp-content/uploads/2021/05/divider-row-grey-to-white-2048x527.jpg 2048w, https://kingkong.co/wp-content/uploads/2021/05/divider-row-grey-to-white-1920x494.jpg 1920w"
                    data-lazy-sizes="(max-width: 1200px) 100vw, 1200px"
                    data-lazy-src="https://kingkong.co/wp-content/uploads/2021/05/divider-row-grey-to-white-1200x309.jpg"
                  />
                  <noscript>
                    <img
                      width="1200"
                      height="309"
                      src="https://kingkong.co/wp-content/uploads/2021/05/divider-row-grey-to-white-1200x309.jpg"
                      class="attachment-container_width size-container_width"
                      alt=""
                      srcset="
                https://kingkong.co/wp-content/uploads/2021/05/divider-row-grey-to-white-1200x309.jpg 1200w,
                https://kingkong.co/wp-content/uploads/2021/05/divider-row-grey-to-white-300x77.jpg    300w,
                https://kingkong.co/wp-content/uploads/2021/05/divider-row-grey-to-white-1024x263.jpg 1024w,
                https://kingkong.co/wp-content/uploads/2021/05/divider-row-grey-to-white-768x197.jpg   768w,
                https://kingkong.co/wp-content/uploads/2021/05/divider-row-grey-to-white-1536x395.jpg 1536w,
                https://kingkong.co/wp-content/uploads/2021/05/divider-row-grey-to-white-2048x527.jpg 2048w,
                https://kingkong.co/wp-content/uploads/2021/05/divider-row-grey-to-white-1920x494.jpg 1920w
              "
                      sizes="(max-width: 1200px) 100vw, 1200px"
                    />
                  </noscript>
                </div>
              </div>
            </div>
          </section>
          <section class="text-dark content-text content-wysiwyg">
            <div class="background-image-container"></div>
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-lg-8 mb-5 text-center animate-in">
                  <span class="h2">
                    {" "}
                    Here's Some Gossip... Except It's Real{" "}
                  </span>
                </div>
                <div class="col-lg-7">
                  <blockquote>
                    <p style={{ textAlign: "center" }}>
                      “The agency’s results-driven approach is very different to
                      the one used by most marketing agencies. It is a
                      scientific, numbers-driven way of winning business, which
                      is giving King Kong an edge that other agencies may find
                      difficult to compete with in the future.”
                    </p>
                    <p>
                      <img
                        class="aligncenter wp-image-10666"
                        src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20190%2054'%3E%3C/svg%3E"
                        alt="The Sydney Morning Herald"
                        width="190"
                        height="54"
                        data-lazy-srcset="https://kingkong.co/wp-content/uploads/2018/02/smh-logo.png 509w, https://kingkong.co/wp-content/uploads/2018/02/smh-logo-300x85.png 300w"
                        data-lazy-sizes="(max-width: 190px) 100vw, 190px"
                        data-lazy-src="https://kingkong.co/wp-content/uploads/2018/02/smh-logo.png"
                      />
                      <noscript>
                        <img
                          class="aligncenter wp-image-10666"
                          src="https://kingkong.co/wp-content/uploads/2018/02/smh-logo.png"
                          alt="The Sydney Morning Herald"
                          width="190"
                          height="54"
                          srcset="
                    https://kingkong.co/wp-content/uploads/2018/02/smh-logo.png        509w,
                    https://kingkong.co/wp-content/uploads/2018/02/smh-logo-300x85.png 300w
                  "
                          sizes="(max-width: 190px) 100vw, 190px"
                        />
                      </noscript>
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
          </section>
          <section class="text-dark content-text content-wysiwyg">
            <div
              class="background-image-container"
              style={{ backgroundColor: "#f3f3f3" }}
            ></div>
            <div class="container">
              <div class="row justify-content-center">
                <div class="pill--flashing text-center animate-in col-12">
                  <span class="pill__flashing">
                    <span class="eyebrow__flashing-dot"></span>
                    Warning: We reserve the right to revoke this offer at any
                    time
                  </span>
                </div>
                <div class="col-md-10 col-xxl-9 mt-3 mb-2 mt-lg-3 mb-lg-5 text-center animate-in">
                  <span class="h2">
                    We Can Beat Any Agency, Marketer Or Traffic Expert On Planet
                    Earth Or You Won’t Pay Us A Single Cent!
                  </span>
                </div>
                <div class="col-xxl-7 col-xl-8 col-md-9">
                  <p>
                    <em>
                      <strong>Does this offer excite you?</strong> Interested in
                      putting us to the test?
                    </em>
                  </p>
                  <p>Hold on there, tiger…not so fast.</p>
                  <p>Gotta back up a little first.</p>
                  <p>Because this is not for everybody.</p>
                  <p>
                    There are
                    <span style={{ textDecoration: "underline" }}>
                      three important factors
                    </span>
                    which must exist before you should even think about applying
                    for this opportunity:
                  </p>
                  <ol>
                    <li>
                      <strong>
                        You must already be running ads on either Google or
                        Facebook,
                      </strong>
                      and they must be running profitably.This opportunity isn’t
                      for newbies or businesses that aren’t already advertising
                      online. (If you want us to create a funnel and ads from
                      scratch – you can see if you qualify here).
                    </li>
                    <li>
                      <strong>
                        You must be willing to let us create ads we think will
                        sell.
                      </strong>
                      Listen, since we’re doing this all on our own
                      dime…Frankly, we don’t care what you, your business
                      partner, your spouse, your friends or Snow White and the
                      seven dwarfs think about our work.Except for checking the
                      ads for your legal and factual approval – we’ll hold all
                      creative control. Because, if an ad isn’t ours – we can’t
                      guarantee its success.
                    </li>
                    <li>
                      <strong>
                        You must not be selling anything even remotely shady,
                      </strong>
                      illegal, scammy, dangerous or in any way be involved in
                      multi-level marketing. That’s it.
                    </li>
                  </ol>
                  <p>
                    Does this opportunity interest you? Do you meet the above
                    qualifications? If so, go here and book in a 30-minute
                    strategy session with our team to see if we’d be a good fit.
                  </p>
                  <p>
                    <strong>P.S.</strong> Oh, well, hello there…need some more
                    convincing huh?
                  </p>
                  <p>
                    Ok listen up chicken nugget, you could continue to work your
                    arse off, round the clock, dealing with all this stress,
                    hassle and uncertainty…Of constantly worrying about where
                    your next client will come from…
                  </p>
                  <p>
                    Pulling your hair out and dealing with the never-ending shit
                    storm of lying ad agencies, rising ad costs, unreliable
                    staff members, poor conversion rates, and an income that’s a
                    feast one month and famine the next.
                  </p>
                  <p>
                    Or you could click the link below, book a call in with my
                    team and explore how to rapidly and radically grow the sales
                    of your company in the next 6 months. Risk-free!
                  </p>
                  <p>
                    But a word of warning, when you pray for the rain, you have
                    to deal with the mud. So you better have the infrastructure
                    in your business to SCALE!
                  </p>
                  <p>
                    No, “oh can we pause the ads for a week”. Time to put your
                    grown-up pants on, and SCALE.
                  </p>
                  <p>Speak soon.</p>
                  <p>
                    <strong>The TL;DR Version:</strong>
                  </p>
                  <p>
                    We’ll build a campaign to beat your best ads for you – and
                    if we don’t beat them we don’t get paid. But act now before
                    there’s a waiting list, and a strict dress code…because
                    we’re only taking on 8 new clients per month with this
                    offer.
                  </p>
                </div>
                <div class="col-lg-8 null animate-in">
                  <span class="null">
                    <a
                      class="btn btn-doublelayer btn-green"
                      href="/30-minute-strategy-session/"
                    >
                      <span class="btn-doublelayer__maintext">
                        Hit The Damn Button Already
                      </span>
                    </a>
                  </span>
                </div>
                <div class="col-xxl-7 col-xl-8 col-md-9">
                  <p>&nbsp;</p>
                  <p>
                    <strong>P.S.</strong> Oh, well, hello there…need some more
                    convincing huh?
                  </p>
                  <p>
                    Ok listen up chicken nugget, you could continue to work your
                    arse off, round the clock, dealing with all this stress,
                    hassle and uncertainty…Of constantly worrying about where
                    your next client will come from…
                  </p>
                  <p>
                    Pulling your hair out and dealing with the never-ending shit
                    storm of lying ad agencies, rising ad costs, unreliable
                    staff members, poor conversion rates, and an income that’s a
                    feast one month and famine the next.
                  </p>
                  <p>
                    Or you could click the link below, book a call in with my
                    team and explore how to rapidly and radically grow the sales
                    of your company in the next 6 months. Risk-free!
                  </p>
                  <p>
                    But a word of warning, when you pray for the rain, you have
                    to deal with the mud. So you better have the infrastructure
                    in your business to SCALE!
                  </p>
                  <p>
                    No, “oh can we pause the ads for a week”. Time to put your
                    grown-up pants on, and SCALE.
                  </p>
                  <p>Speak soon.</p>
                </div>
              </div>
            </div>
          </section>
          <section class="text-dark content-global testimonial-video-slider">
            <div class="background-image-container"></div>
            <div class="container-fluid">
              <div class="row justify-content-center">
                <div class="col-12 section-videogallery">
                  <div class="swiper-container swiper-container-fade swiper-container-initialized swiper-container-horizontal">
                    <div
                      class="swiper-wrapper"
                      id="swiper-wrapper-56cad80de73497aa"
                      aria-live="polite"
                    >
                      <div
                        class="swiper-slide swiper-slide-active"
                        role="group"
                        aria-label="1 / 12"
                        style={{
                          width: "440px",
                          opacity: 1,
                          transform: "translate3d(0px, 0px, 0px)",
                        }}
                      >
                        <div class="testimonial-content">
                          <blockquote>
                            <img
                              width="250"
                              height="205"
                              src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20250%20205'%3E%3C/svg%3E"
                              class="logo"
                              alt=""
                              data-lazy-src="https://kingkong.co/wp-content/uploads/2020/12/testimoinal-logos-metricon.png"
                            />
                            <noscript>
                              <img
                                width="250"
                                height="205"
                                src="https://kingkong.co/wp-content/uploads/2020/12/testimoinal-logos-metricon.png"
                                class="logo"
                                alt=""
                              />
                            </noscript>
                            “Since partnering with King Kong the results have
                            been nothing short of outstanding. There are three
                            simple words here and that is: Leads! Leads! Leads!”
                            <cite>
                              Domenic Varese, Marketing Manager, Metricon
                            </cite>
                          </blockquote>
                          <span
                            data-wistia-id="g1z2fb083p"
                            style={{ display: "inline", position: "relative" }}
                          >
                            <a class="play play-pulse" href="#">
                              Play
                            </a>
                          </span>
                        </div>
                        <div class="overlay">
                          <img
                            width="1200"
                            height="673"
                            src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%201200%20673'%3E%3C/svg%3E"
                            class="attachment-container_width size-container_width"
                            alt=""
                            data-lazy-srcset="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-metricon-1200x673.jpg 1200w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-metricon-300x168.jpg 300w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-metricon-1024x574.jpg 1024w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-metricon-768x431.jpg 768w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-metricon-1536x861.jpg 1536w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-metricon-2048x1148.jpg 2048w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-metricon-1920x1077.jpg 1920w"
                            data-lazy-sizes="(max-width: 1200px) 100vw, 1200px"
                            data-lazy-src="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-metricon-1200x673.jpg"
                          />
                          <noscript>
                            <img
                              width="1200"
                              height="673"
                              src="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-metricon-1200x673.jpg"
                              class="attachment-container_width size-container_width"
                              alt=""
                              srcset="
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-metricon-1200x673.jpg  1200w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-metricon-300x168.jpg    300w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-metricon-1024x574.jpg  1024w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-metricon-768x431.jpg    768w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-metricon-1536x861.jpg  1536w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-metricon-2048x1148.jpg 2048w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-metricon-1920x1077.jpg 1920w
                      "
                              sizes="(max-width: 1200px) 100vw, 1200px"
                            />
                          </noscript>
                        </div>
                      </div>
                      <div
                        class="swiper-slide swiper-slide-next"
                        role="group"
                        aria-label="2 / 12"
                        style={{
                          width: "440px",
                          opacity: 0,
                          transform: "translate3d(-440px, 0px, 0px)",
                        }}
                      >
                        <div class="testimonial-content">
                          <blockquote>
                            <img
                              width="250"
                              height="205"
                              src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20250%20205'%3E%3C/svg%3E"
                              class="logo"
                              alt=""
                              data-lazy-src="https://kingkong.co/wp-content/uploads/2020/12/testimoinal-logos-little-big-dairy.png"
                            />
                            <noscript>
                              <img
                                width="250"
                                height="205"
                                src="https://kingkong.co/wp-content/uploads/2020/12/testimoinal-logos-little-big-dairy.png"
                                class="logo"
                                alt=""
                              />
                            </noscript>
                            “From Cold-calling To 200 New Customers In 12
                            Months”
                            <cite>
                              Emma &amp; Jim Elliott, Owners, Little Big Dairy
                            </cite>
                          </blockquote>
                          <span
                            data-wistia-id="2btvkffii6"
                            style={{ display: "inline", position: "relative" }}
                          >
                            <a class="play play-pulse" href="#">
                              Play
                            </a>
                          </span>
                        </div>
                        <div class="overlay">
                          <img
                            width="1200"
                            height="673"
                            src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%201200%20673'%3E%3C/svg%3E"
                            class="attachment-container_width size-container_width"
                            alt=""
                            data-lazy-srcset="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-littlebigdairy-1200x673.jpg 1200w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-littlebigdairy-300x168.jpg 300w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-littlebigdairy-1024x574.jpg 1024w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-littlebigdairy-768x431.jpg 768w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-littlebigdairy-1536x861.jpg 1536w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-littlebigdairy-2048x1148.jpg 2048w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-littlebigdairy-1920x1077.jpg 1920w"
                            data-lazy-sizes="(max-width: 1200px) 100vw, 1200px"
                            data-lazy-src="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-littlebigdairy-1200x673.jpg"
                          />
                          <noscript>
                            <img
                              width="1200"
                              height="673"
                              src="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-littlebigdairy-1200x673.jpg"
                              class="attachment-container_width size-container_width"
                              alt=""
                              srcset="
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-littlebigdairy-1200x673.jpg  1200w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-littlebigdairy-300x168.jpg    300w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-littlebigdairy-1024x574.jpg  1024w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-littlebigdairy-768x431.jpg    768w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-littlebigdairy-1536x861.jpg  1536w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-littlebigdairy-2048x1148.jpg 2048w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-littlebigdairy-1920x1077.jpg 1920w
                      "
                              sizes="(max-width: 1200px) 100vw, 1200px"
                            />
                          </noscript>
                        </div>
                      </div>
                      <div
                        class="swiper-slide"
                        role="group"
                        aria-label="3 / 12"
                        style={{
                          width: "440px",
                          opacity: 0,
                          transform: "translate3d(-880px, 0px, 0px)",
                        }}
                      >
                        <div class="testimonial-content">
                          <blockquote>
                            <img
                              width="250"
                              height="205"
                              src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20250%20205'%3E%3C/svg%3E"
                              class="logo"
                              alt=""
                              data-lazy-src="https://kingkong.co/wp-content/uploads/2020/12/testimoinal-logos-lets-get-care.png"
                            />
                            <noscript>
                              <img
                                width="250"
                                height="205"
                                src="https://kingkong.co/wp-content/uploads/2020/12/testimoinal-logos-lets-get-care.png"
                                class="logo"
                                alt=""
                              />
                            </noscript>
                            “From $4 Million To $25,000,000+ In 18 Months”
                            <cite>
                              Michael Argent, Managing Director, Let's Get Care
                            </cite>
                          </blockquote>
                          <span
                            data-wistia-id="oor3h5mpvl"
                            style={{ display: "inline", position: "relative" }}
                          >
                            <a class="play play-pulse" href="#">
                              Play
                            </a>
                          </span>
                        </div>
                        <div class="overlay">
                          <img
                            width="1200"
                            height="673"
                            src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%201200%20673'%3E%3C/svg%3E"
                            class="attachment-container_width size-container_width"
                            alt=""
                            data-lazy-srcset="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-letsgetcare-1200x673.jpg 1200w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-letsgetcare-300x168.jpg 300w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-letsgetcare-1024x574.jpg 1024w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-letsgetcare-768x431.jpg 768w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-letsgetcare-1536x861.jpg 1536w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-letsgetcare-2048x1148.jpg 2048w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-letsgetcare-1920x1077.jpg 1920w"
                            data-lazy-sizes="(max-width: 1200px) 100vw, 1200px"
                            data-lazy-src="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-letsgetcare-1200x673.jpg"
                          />
                          <noscript>
                            <img
                              width="1200"
                              height="673"
                              src="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-letsgetcare-1200x673.jpg"
                              class="attachment-container_width size-container_width"
                              alt=""
                              srcset="
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-letsgetcare-1200x673.jpg  1200w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-letsgetcare-300x168.jpg    300w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-letsgetcare-1024x574.jpg  1024w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-letsgetcare-768x431.jpg    768w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-letsgetcare-1536x861.jpg  1536w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-letsgetcare-2048x1148.jpg 2048w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-letsgetcare-1920x1077.jpg 1920w
                      "
                              sizes="(max-width: 1200px) 100vw, 1200px"
                            />
                          </noscript>
                        </div>
                      </div>
                      <div
                        class="swiper-slide"
                        role="group"
                        aria-label="4 / 12"
                        style={{
                          width: "440px",
                          opacity: 0,
                          transform: "translate3d(-1320px, 0px, 0px)",
                        }}
                      >
                        <div class="testimonial-content">
                          <blockquote>
                            <img
                              width="250"
                              height="205"
                              src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20250%20205'%3E%3C/svg%3E"
                              class="logo"
                              alt=""
                              data-lazy-src="https://kingkong.co/wp-content/uploads/2020/12/testimoinal-logos-project-everest.png"
                            />
                            <noscript>
                              <img
                                width="250"
                                height="205"
                                src="https://kingkong.co/wp-content/uploads/2020/12/testimoinal-logos-project-everest.png"
                                class="logo"
                                alt=""
                              />
                            </noscript>
                            “Our Pipeline Got Completely Flooded Overnight”
                            <cite>
                              Wade Tink, Managing Director, Project Everest
                            </cite>
                          </blockquote>
                          <span
                            data-wistia-id="g4dgw3ziwn"
                            style={{ display: "inline", position: "relative" }}
                          >
                            <a class="play play-pulse" href="#">
                              Play
                            </a>
                          </span>
                        </div>
                        <div class="overlay">
                          <img
                            width="1200"
                            height="673"
                            src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%201200%20673'%3E%3C/svg%3E"
                            class="attachment-container_width size-container_width"
                            alt=""
                            data-lazy-srcset="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-projecteverest-1200x673.jpg 1200w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-projecteverest-300x168.jpg 300w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-projecteverest-1024x574.jpg 1024w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-projecteverest-768x431.jpg 768w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-projecteverest-1536x861.jpg 1536w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-projecteverest-2048x1148.jpg 2048w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-projecteverest-1920x1077.jpg 1920w"
                            data-lazy-sizes="(max-width: 1200px) 100vw, 1200px"
                            data-lazy-src="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-projecteverest-1200x673.jpg"
                          />
                          <noscript>
                            <img
                              width="1200"
                              height="673"
                              src="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-projecteverest-1200x673.jpg"
                              class="attachment-container_width size-container_width"
                              alt=""
                              srcset="
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-projecteverest-1200x673.jpg  1200w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-projecteverest-300x168.jpg    300w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-projecteverest-1024x574.jpg  1024w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-projecteverest-768x431.jpg    768w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-projecteverest-1536x861.jpg  1536w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-projecteverest-2048x1148.jpg 2048w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-projecteverest-1920x1077.jpg 1920w
                      "
                              sizes="(max-width: 1200px) 100vw, 1200px"
                            />
                          </noscript>
                        </div>
                      </div>
                      <div
                        class="swiper-slide"
                        role="group"
                        aria-label="5 / 12"
                        style={{
                          width: "440px",
                          opacity: 0,
                          transform: "translate3d(-1760px, 0px, 0px)",
                        }}
                      >
                        <div class="testimonial-content">
                          <blockquote>
                            <img
                              width="250"
                              height="205"
                              src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20250%20205'%3E%3C/svg%3E"
                              class="logo"
                              alt=""
                              data-lazy-src="https://kingkong.co/wp-content/uploads/2020/12/testimoinal-logos-new-sensation-homes.png"
                            />
                            <noscript>
                              <img
                                width="250"
                                height="205"
                                src="https://kingkong.co/wp-content/uploads/2020/12/testimoinal-logos-new-sensation-homes.png"
                                class="logo"
                                alt=""
                              />
                            </noscript>
                            "From $0 To $18 Million In 18 Months (During A
                            Recession)"
                            <cite>
                              New Sensation Homes, Danny Coyne, New Sensation
                              Homes
                            </cite>
                          </blockquote>
                          <span
                            data-wistia-id="kj655bl9q8"
                            style={{ display: "inline", position: "relative" }}
                          >
                            <a class="play play-pulse" href="#">
                              Play
                            </a>
                          </span>
                        </div>
                        <div class="overlay">
                          <img
                            width="1200"
                            height="673"
                            src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%201200%20673'%3E%3C/svg%3E"
                            class="attachment-container_width size-container_width"
                            alt=""
                            data-lazy-srcset="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-newsensastionhomes-1200x673.jpg 1200w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-newsensastionhomes-300x168.jpg 300w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-newsensastionhomes-1024x574.jpg 1024w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-newsensastionhomes-768x431.jpg 768w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-newsensastionhomes-1536x861.jpg 1536w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-newsensastionhomes-2048x1148.jpg 2048w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-newsensastionhomes-1920x1077.jpg 1920w"
                            data-lazy-sizes="(max-width: 1200px) 100vw, 1200px"
                            data-lazy-src="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-newsensastionhomes-1200x673.jpg"
                          />
                          <noscript>
                            <img
                              width="1200"
                              height="673"
                              src="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-newsensastionhomes-1200x673.jpg"
                              class="attachment-container_width size-container_width"
                              alt=""
                              srcset="
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-newsensastionhomes-1200x673.jpg  1200w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-newsensastionhomes-300x168.jpg    300w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-newsensastionhomes-1024x574.jpg  1024w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-newsensastionhomes-768x431.jpg    768w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-newsensastionhomes-1536x861.jpg  1536w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-newsensastionhomes-2048x1148.jpg 2048w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-newsensastionhomes-1920x1077.jpg 1920w
                      "
                              sizes="(max-width: 1200px) 100vw, 1200px"
                            />
                          </noscript>
                        </div>
                      </div>
                      <div
                        class="swiper-slide"
                        role="group"
                        aria-label="6 / 12"
                        style={{
                          width: "440px",
                          opacity: 0,
                          transform: "translate3d(-2200px, 0px, 0px)",
                        }}
                      >
                        <div class="testimonial-content">
                          <blockquote>
                            <img
                              width="250"
                              height="205"
                              src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20250%20205'%3E%3C/svg%3E"
                              class="logo"
                              alt=""
                              data-lazy-src="https://kingkong.co/wp-content/uploads/2020/12/testimoinal-logos-nsr.png"
                            />
                            <noscript>
                              <img
                                width="250"
                                height="205"
                                src="https://kingkong.co/wp-content/uploads/2020/12/testimoinal-logos-nsr.png"
                                class="logo"
                                alt=""
                              />
                            </noscript>
                            "Working With King Kong Has Transformed Our Whole
                            Lives. The Growth That We've Experienced Has Been
                            Astronomical."
                            <cite>
                              Marco Maisano, CEO &amp; Founder, NSR Australia
                              &amp; NZ
                            </cite>
                          </blockquote>
                          <span
                            data-wistia-id="s1x0kpd6g8"
                            style={{ display: "inline", position: "relative" }}
                          >
                            <a class="play play-pulse" href="#">
                              Play
                            </a>
                          </span>
                        </div>
                        <div class="overlay">
                          <img
                            width="1200"
                            height="673"
                            src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%201200%20673'%3E%3C/svg%3E"
                            class="attachment-container_width size-container_width"
                            alt=""
                            data-lazy-srcset="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-nsr-1200x673.jpg 1200w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-nsr-300x168.jpg 300w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-nsr-1024x574.jpg 1024w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-nsr-768x431.jpg 768w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-nsr-1536x861.jpg 1536w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-nsr-2048x1148.jpg 2048w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-nsr-1920x1077.jpg 1920w"
                            data-lazy-sizes="(max-width: 1200px) 100vw, 1200px"
                            data-lazy-src="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-nsr-1200x673.jpg"
                          />
                          <noscript>
                            <img
                              width="1200"
                              height="673"
                              src="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-nsr-1200x673.jpg"
                              class="attachment-container_width size-container_width"
                              alt=""
                              srcset="
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-nsr-1200x673.jpg  1200w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-nsr-300x168.jpg    300w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-nsr-1024x574.jpg  1024w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-nsr-768x431.jpg    768w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-nsr-1536x861.jpg  1536w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-nsr-2048x1148.jpg 2048w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-nsr-1920x1077.jpg 1920w
                      "
                              sizes="(max-width: 1200px) 100vw, 1200px"
                            />
                          </noscript>
                        </div>
                      </div>
                      <div
                        class="swiper-slide"
                        role="group"
                        aria-label="7 / 12"
                        style={{
                          width: "440px",
                          opacity: 0,
                          transform: "translate3d(-2640px, 0px, 0px)",
                        }}
                      >
                        <div class="testimonial-content">
                          <blockquote>
                            <img
                              width="250"
                              height="205"
                              src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20250%20205'%3E%3C/svg%3E"
                              class="logo"
                              alt=""
                              data-lazy-src="https://kingkong.co/wp-content/uploads/2020/12/testimoinal-logos-geds.png"
                            />
                            <noscript>
                              <img
                                width="250"
                                height="205"
                                src="https://kingkong.co/wp-content/uploads/2020/12/testimoinal-logos-geds.png"
                                class="logo"
                                alt=""
                              />
                            </noscript>
                            "Initially I was worried about where my first job
                            was coming from. After using King Kong I was more
                            worried about how I was going to manage the job. My
                            business has increased 200%, so I got my return on
                            investment 10-20 times over."
                            <cite>Ged, Owner, Ged's Mobile Car Cleaning</cite>
                          </blockquote>
                          <span
                            data-wistia-id="v8yfa1o1e8"
                            style={{
                              display: "inline",
                              position: "relative",
                            }}
                          >
                            <a class="play play-pulse" href="#">
                              Play
                            </a>
                          </span>
                        </div>
                        <div class="overlay">
                          <img
                            width="1200"
                            height="673"
                            src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%201200%20673'%3E%3C/svg%3E"
                            class="attachment-container_width size-container_width"
                            alt=""
                            data-lazy-srcset="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-gedscarcleaning-1200x673.jpg 1200w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-gedscarcleaning-300x168.jpg 300w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-gedscarcleaning-1024x574.jpg 1024w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-gedscarcleaning-768x431.jpg 768w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-gedscarcleaning-1536x861.jpg 1536w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-gedscarcleaning-2048x1148.jpg 2048w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-gedscarcleaning-1920x1077.jpg 1920w"
                            data-lazy-sizes="(max-width: 1200px) 100vw, 1200px"
                            data-lazy-src="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-gedscarcleaning-1200x673.jpg"
                          />
                          <noscript>
                            <img
                              width="1200"
                              height="673"
                              src="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-gedscarcleaning-1200x673.jpg"
                              class="attachment-container_width size-container_width"
                              alt=""
                              srcset="
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-gedscarcleaning-1200x673.jpg  1200w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-gedscarcleaning-300x168.jpg    300w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-gedscarcleaning-1024x574.jpg  1024w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-gedscarcleaning-768x431.jpg    768w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-gedscarcleaning-1536x861.jpg  1536w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-gedscarcleaning-2048x1148.jpg 2048w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-gedscarcleaning-1920x1077.jpg 1920w
                      "
                              sizes="(max-width: 1200px) 100vw, 1200px"
                            />
                          </noscript>
                        </div>
                      </div>
                      <div
                        class="swiper-slide"
                        role="group"
                        aria-label="8 / 12"
                        style={{
                          width: "440px",
                          opacity: 0,
                          transform: "translate3d(-3080px, 0px, 0px)",
                        }}
                      >
                        <div class="testimonial-content">
                          <blockquote>
                            <img
                              width="250"
                              height="205"
                              src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20250%20205'%3E%3C/svg%3E"
                              class="logo"
                              alt=""
                              data-lazy-src="https://kingkong.co/wp-content/uploads/2020/12/testimoinal-logos-breathe.png"
                            />
                            <noscript>
                              <img
                                width="250"
                                height="205"
                                src="https://kingkong.co/wp-content/uploads/2020/12/testimoinal-logos-breathe.png"
                                class="logo"
                                alt=""
                              />
                            </noscript>
                            “Since starting work with King Kong 9 months ago our
                            sales have tripled and we've expanded into 3 other
                            states. These guys are amazing!”
                            <cite>
                              Raphael Bender, Founder, Breathe Education
                            </cite>
                          </blockquote>
                          <span
                            data-wistia-id="jn3thejtd8"
                            style={{
                              display: "inline",
                              position: "relative",
                            }}
                          >
                            <a class="play play-pulse" href="#">
                              Play
                            </a>
                          </span>
                        </div>
                        <div class="overlay">
                          <img
                            width="1200"
                            height="673"
                            src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%201200%20673'%3E%3C/svg%3E"
                            class="attachment-container_width size-container_width"
                            alt=""
                            data-lazy-srcset="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-breatheeducation-1200x673.jpg 1200w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-breatheeducation-300x168.jpg 300w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-breatheeducation-1024x574.jpg 1024w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-breatheeducation-768x431.jpg 768w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-breatheeducation-1536x861.jpg 1536w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-breatheeducation-2048x1148.jpg 2048w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-breatheeducation-1920x1077.jpg 1920w"
                            data-lazy-sizes="(max-width: 1200px) 100vw, 1200px"
                            data-lazy-src="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-breatheeducation-1200x673.jpg"
                          />
                          <noscript>
                            <img
                              width="1200"
                              height="673"
                              src="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-breatheeducation-1200x673.jpg"
                              class="attachment-container_width size-container_width"
                              alt=""
                              srcset="
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-breatheeducation-1200x673.jpg  1200w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-breatheeducation-300x168.jpg    300w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-breatheeducation-1024x574.jpg  1024w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-breatheeducation-768x431.jpg    768w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-breatheeducation-1536x861.jpg  1536w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-breatheeducation-2048x1148.jpg 2048w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-breatheeducation-1920x1077.jpg 1920w
                      "
                              sizes="(max-width: 1200px) 100vw, 1200px"
                            />
                          </noscript>
                        </div>
                      </div>
                      <div
                        class="swiper-slide"
                        role="group"
                        aria-label="9 / 12"
                        style={{
                          width: "440px",
                          opacity: 0,
                          transform: "translate3d(-3520px, 0px, 0px)",
                        }}
                      >
                        <div class="testimonial-content">
                          <blockquote>
                            <img
                              width="250"
                              height="205"
                              src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20250%20205'%3E%3C/svg%3E"
                              class="logo"
                              alt=""
                              data-lazy-src="https://kingkong.co/wp-content/uploads/2020/12/testimoinal-logos-discover-stradbroke.png"
                            />
                            <noscript>
                              <img
                                width="250"
                                height="205"
                                src="https://kingkong.co/wp-content/uploads/2020/12/testimoinal-logos-discover-stradbroke.png"
                                class="logo"
                                alt=""
                              />
                            </noscript>
                            “We’ve Increased Our Revenue By 35-40% By Working
                            With King Kong”
                            <cite>
                              Colin Battersby, Principal, Discover Stradbroke
                            </cite>
                          </blockquote>
                          <span
                            data-wistia-id="y7pv2nz2u2"
                            style={{
                              display: "inline",
                              position: "relative",
                            }}
                          >
                            <a class="play play-pulse" href="#">
                              Play
                            </a>
                          </span>
                        </div>
                        <div class="overlay">
                          <img
                            width="1200"
                            height="673"
                            src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%201200%20673'%3E%3C/svg%3E"
                            class="attachment-container_width size-container_width"
                            alt=""
                            data-lazy-srcset="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-discoverstradbroke-1200x673.jpg 1200w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-discoverstradbroke-300x168.jpg 300w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-discoverstradbroke-1024x574.jpg 1024w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-discoverstradbroke-768x431.jpg 768w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-discoverstradbroke-1536x861.jpg 1536w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-discoverstradbroke-2048x1148.jpg 2048w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-discoverstradbroke-1920x1077.jpg 1920w"
                            data-lazy-sizes="(max-width: 1200px) 100vw, 1200px"
                            data-lazy-src="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-discoverstradbroke-1200x673.jpg"
                          />
                          <noscript>
                            <img
                              width="1200"
                              height="673"
                              src="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-discoverstradbroke-1200x673.jpg"
                              class="attachment-container_width size-container_width"
                              alt=""
                              srcset="
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-discoverstradbroke-1200x673.jpg  1200w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-discoverstradbroke-300x168.jpg    300w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-discoverstradbroke-1024x574.jpg  1024w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-discoverstradbroke-768x431.jpg    768w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-discoverstradbroke-1536x861.jpg  1536w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-discoverstradbroke-2048x1148.jpg 2048w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-discoverstradbroke-1920x1077.jpg 1920w
                      "
                              sizes="(max-width: 1200px) 100vw, 1200px"
                            />
                          </noscript>
                        </div>
                      </div>
                      <div
                        class="swiper-slide"
                        role="group"
                        aria-label="10 / 12"
                        style={{
                          width: "440px",
                          opacity: 0,
                          transform: "translate3d(-3960px, 0px, 0px)",
                        }}
                      >
                        <div class="testimonial-content">
                          <blockquote>
                            <img
                              width="250"
                              height="205"
                              src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20250%20205'%3E%3C/svg%3E"
                              class="logo"
                              alt=""
                              data-lazy-src="https://kingkong.co/wp-content/uploads/2020/12/testimoinal-logos-aged-care-fs.png"
                            />
                            <noscript>
                              <img
                                width="250"
                                height="205"
                                src="https://kingkong.co/wp-content/uploads/2020/12/testimoinal-logos-aged-care-fs.png"
                                class="logo"
                                alt=""
                              />
                            </noscript>
                            “After 18 Months, We’ve Got A 49% Increase In Leads.
                            I’d Never Go Back To Not Using King Kong.”
                            <cite>
                              Beth Hourigan, Director, Aged Care Financial
                              Services
                            </cite>
                          </blockquote>
                          <span
                            data-wistia-id="pzird5dezi"
                            style={{
                              display: "inline",
                              position: "relative",
                            }}
                          >
                            <a class="play play-pulse" href="#">
                              Play
                            </a>
                          </span>
                        </div>
                        <div class="overlay">
                          <img
                            width="1200"
                            height="673"
                            src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%201200%20673'%3E%3C/svg%3E"
                            class="attachment-container_width size-container_width"
                            alt=""
                            data-lazy-srcset="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-agedcarefinancial-1200x673.jpg 1200w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-agedcarefinancial-300x168.jpg 300w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-agedcarefinancial-1024x574.jpg 1024w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-agedcarefinancial-768x431.jpg 768w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-agedcarefinancial-1536x861.jpg 1536w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-agedcarefinancial-2048x1148.jpg 2048w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-agedcarefinancial-1920x1077.jpg 1920w"
                            data-lazy-sizes="(max-width: 1200px) 100vw, 1200px"
                            data-lazy-src="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-agedcarefinancial-1200x673.jpg"
                          />
                          <noscript>
                            <img
                              width="1200"
                              height="673"
                              src="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-agedcarefinancial-1200x673.jpg"
                              class="attachment-container_width size-container_width"
                              alt=""
                              srcset="
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-agedcarefinancial-1200x673.jpg  1200w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-agedcarefinancial-300x168.jpg    300w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-agedcarefinancial-1024x574.jpg  1024w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-agedcarefinancial-768x431.jpg    768w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-agedcarefinancial-1536x861.jpg  1536w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-agedcarefinancial-2048x1148.jpg 2048w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-agedcarefinancial-1920x1077.jpg 1920w
                      "
                              sizes="(max-width: 1200px) 100vw, 1200px"
                            />
                          </noscript>
                        </div>
                      </div>
                      <div
                        class="swiper-slide"
                        role="group"
                        aria-label="11 / 12"
                        style={{
                          width: "440px",
                          opacity: 0,
                          transform: "translate3d(-4400px, 0px, 0px)",
                        }}
                      >
                        <div class="testimonial-content">
                          <blockquote>
                            <img
                              width="250"
                              height="205"
                              src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20250%20205'%3E%3C/svg%3E"
                              class="logo"
                              alt=""
                              data-lazy-src="https://kingkong.co/wp-content/uploads/2020/12/testimoinal-logos-gold-key.png"
                            />
                            <noscript>
                              <img
                                width="250"
                                height="205"
                                src="https://kingkong.co/wp-content/uploads/2020/12/testimoinal-logos-gold-key.png"
                                class="logo"
                                alt=""
                              />
                            </noscript>
                            "From $3 Million To $50 Million In 12 Months With
                            King Kong"{" "}
                            <cite>Dylan Trickey, Marketing Coordinator</cite>
                          </blockquote>
                          <span
                            data-wistia-id="h1s375j4bv"
                            style={{
                              display: "inline",
                              position: "relative",
                            }}
                          >
                            <a class="play play-pulse" href="#">
                              Play
                            </a>
                          </span>
                        </div>
                        <div class="overlay">
                          <img
                            width="1200"
                            height="673"
                            src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%201200%20673'%3E%3C/svg%3E"
                            class="attachment-container_width size-container_width"
                            alt=""
                            data-lazy-srcset="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-goldkeyhomes-1200x673.jpg 1200w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-goldkeyhomes-300x168.jpg 300w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-goldkeyhomes-1024x574.jpg 1024w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-goldkeyhomes-768x431.jpg 768w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-goldkeyhomes-1536x861.jpg 1536w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-goldkeyhomes-2048x1148.jpg 2048w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-goldkeyhomes-1920x1077.jpg 1920w"
                            data-lazy-sizes="(max-width: 1200px) 100vw, 1200px"
                            data-lazy-src="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-goldkeyhomes-1200x673.jpg"
                          />
                          <noscript>
                            <img
                              width="1200"
                              height="673"
                              src="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-goldkeyhomes-1200x673.jpg"
                              class="attachment-container_width size-container_width"
                              alt=""
                              srcset="
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-goldkeyhomes-1200x673.jpg  1200w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-goldkeyhomes-300x168.jpg    300w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-goldkeyhomes-1024x574.jpg  1024w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-goldkeyhomes-768x431.jpg    768w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-goldkeyhomes-1536x861.jpg  1536w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-goldkeyhomes-2048x1148.jpg 2048w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-goldkeyhomes-1920x1077.jpg 1920w
                      "
                              sizes="(max-width: 1200px) 100vw, 1200px"
                            />
                          </noscript>
                        </div>
                      </div>
                      <div
                        class="swiper-slide"
                        role="group"
                        aria-label="12 / 12"
                        style={{
                          width: "440px",
                          opacity: 0,
                          transform: "translate3d(-4840px, 0px, 0px)",
                        }}
                      >
                        <div class="testimonial-content">
                          <blockquote>
                            <img
                              width="250"
                              height="205"
                              src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20250%20205'%3E%3C/svg%3E"
                              class="logo"
                              alt=""
                              data-lazy-src="https://kingkong.co/wp-content/uploads/2020/12/testimoinal-logos-enso.png"
                            />
                            <noscript>
                              <img
                                width="250"
                                height="205"
                                src="https://kingkong.co/wp-content/uploads/2020/12/testimoinal-logos-enso.png"
                                class="logo"
                                alt=""
                              />
                            </noscript>
                            “The goal was to get 8 sales in the first year. It’s
                            been 11 months now and we’ve had 23 sales. Which is
                            $7 million in revenue. The money I spent with King
                            Kong I’ve seen a return on investment of five to six
                            times.”
                            <cite>Lee Selkrig, Founder, Enso Homes</cite>
                          </blockquote>
                          <span
                            data-wistia-id="sksg23xja4"
                            style={{
                              display: "inline",
                              position: "relative",
                            }}
                          >
                            <a class="play play-pulse" href="#">
                              Play
                            </a>
                          </span>
                        </div>
                        <div class="overlay">
                          <img
                            width="1200"
                            height="673"
                            src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%201200%20673'%3E%3C/svg%3E"
                            class="attachment-container_width size-container_width"
                            alt=""
                            data-lazy-srcset="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-ensohomes-1200x673.jpg 1200w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-ensohomes-300x168.jpg 300w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-ensohomes-1024x574.jpg 1024w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-ensohomes-768x431.jpg 768w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-ensohomes-1536x861.jpg 1536w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-ensohomes-2048x1148.jpg 2048w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-ensohomes-1920x1077.jpg 1920w"
                            data-lazy-sizes="(max-width: 1200px) 100vw, 1200px"
                            data-lazy-src="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-ensohomes-1200x673.jpg"
                          />
                          <noscript>
                            <img
                              width="1200"
                              height="673"
                              src="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-ensohomes-1200x673.jpg"
                              class="attachment-container_width size-container_width"
                              alt=""
                              srcset="
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-ensohomes-1200x673.jpg  1200w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-ensohomes-300x168.jpg    300w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-ensohomes-1024x574.jpg  1024w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-ensohomes-768x431.jpg    768w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-ensohomes-1536x861.jpg  1536w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-ensohomes-2048x1148.jpg 2048w,
                        https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-ensohomes-1920x1077.jpg 1920w
                      "
                              sizes="(max-width: 1200px) 100vw, 1200px"
                            />
                          </noscript>
                        </div>
                      </div>
                    </div>
                    <span
                      class="swiper-notification"
                      aria-live="assertive"
                      aria-atomic="true"
                    ></span>
                  </div>
                  <div
                    class="swiper-container control swiper-container-initialized swiper-container-horizontal swiper-container-thumbs"
                    style={{ cursor: "grab" }}
                  >
                    <div
                      class="swiper-wrapper"
                      id="swiper-wrapper-4e10210358b649d4ef"
                      aria-live="polite"
                      style={{
                        transitionDuration: "0ms",
                        transform: "translate3d(0px, 0px, 0px)",
                      }}
                    >
                      <div
                        class="swiper-slide swiper-slide-active swiper-slide-thumb-active"
                        role="group"
                        aria-label="1 / 12"
                        style={{
                          width: "197.5px",
                          marginRight: "15px",
                        }}
                      >
                        <img
                          width="250"
                          height="205"
                          src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20250%20205'%3E%3C/svg%3E"
                          class="logo"
                          alt=""
                          data-lazy-src="https://kingkong.co/wp-content/uploads/2020/12/testimoinal-logos-metricon.png"
                        />
                        <noscript>
                          <img
                            width="250"
                            height="205"
                            src="https://kingkong.co/wp-content/uploads/2020/12/testimoinal-logos-metricon.png"
                            class="logo"
                            alt=""
                          />
                        </noscript>
                        <img
                          width="1024"
                          height="574"
                          src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%201024%20574'%3E%3C/svg%3E"
                          class="attachment-large size-large"
                          alt=""
                          data-lazy-srcset="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-metricon-1024x574.jpg 1024w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-metricon-300x168.jpg 300w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-metricon-768x431.jpg 768w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-metricon-1536x861.jpg 1536w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-metricon-2048x1148.jpg 2048w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-metricon-1920x1077.jpg 1920w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-metricon-1200x673.jpg 1200w"
                          data-lazy-sizes="(max-width: 1024px) 100vw, 1024px"
                          data-lazy-src="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-metricon-1024x574.jpg"
                        />
                        <noscript>
                          <img
                            width="1024"
                            height="574"
                            src="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-metricon-1024x574.jpg"
                            class="attachment-large size-large"
                            alt=""
                            srcset="
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-metricon-1024x574.jpg  1024w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-metricon-300x168.jpg    300w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-metricon-768x431.jpg    768w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-metricon-1536x861.jpg  1536w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-metricon-2048x1148.jpg 2048w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-metricon-1920x1077.jpg 1920w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-metricon-1200x673.jpg  1200w
                    "
                            sizes="(max-width: 1024px) 100vw, 1024px"
                          />
                        </noscript>
                        <p class="thumb-name">Domenic Varese</p>
                      </div>
                      <div
                        class="swiper-slide swiper-slide-next"
                        role="group"
                        aria-label="2 / 12"
                        style={{
                          width: "197.5px",
                          marginRight: "15px",
                        }}
                      >
                        <img
                          width="250"
                          height="205"
                          src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20250%20205'%3E%3C/svg%3E"
                          class="logo"
                          alt=""
                          data-lazy-src="https://kingkong.co/wp-content/uploads/2020/12/testimoinal-logos-little-big-dairy.png"
                        />
                        <noscript>
                          <img
                            width="250"
                            height="205"
                            src="https://kingkong.co/wp-content/uploads/2020/12/testimoinal-logos-little-big-dairy.png"
                            class="logo"
                            alt=""
                          />
                        </noscript>
                        <img
                          width="1024"
                          height="574"
                          src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%201024%20574'%3E%3C/svg%3E"
                          class="attachment-large size-large"
                          alt=""
                          data-lazy-srcset="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-littlebigdairy-1024x574.jpg 1024w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-littlebigdairy-300x168.jpg 300w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-littlebigdairy-768x431.jpg 768w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-littlebigdairy-1536x861.jpg 1536w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-littlebigdairy-2048x1148.jpg 2048w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-littlebigdairy-1920x1077.jpg 1920w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-littlebigdairy-1200x673.jpg 1200w"
                          data-lazy-sizes="(max-width: 1024px) 100vw, 1024px"
                          data-lazy-src="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-littlebigdairy-1024x574.jpg"
                        />
                        <noscript>
                          <img
                            width="1024"
                            height="574"
                            src="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-littlebigdairy-1024x574.jpg"
                            class="attachment-large size-large"
                            alt=""
                            srcset="
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-littlebigdairy-1024x574.jpg  1024w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-littlebigdairy-300x168.jpg    300w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-littlebigdairy-768x431.jpg    768w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-littlebigdairy-1536x861.jpg  1536w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-littlebigdairy-2048x1148.jpg 2048w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-littlebigdairy-1920x1077.jpg 1920w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-littlebigdairy-1200x673.jpg  1200w
                    "
                            sizes="(max-width: 1024px) 100vw, 1024px"
                          />
                        </noscript>
                        <p class="thumb-name">Emma &amp; Jim Elliott</p>
                      </div>
                      <div
                        class="swiper-slide"
                        role="group"
                        aria-label="3 / 12"
                        style={{
                          width: "197.5px",
                          marginRight: "15px",
                        }}
                      >
                        <img
                          width="250"
                          height="205"
                          src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20250%20205'%3E%3C/svg%3E"
                          class="logo"
                          alt=""
                          data-lazy-src="https://kingkong.co/wp-content/uploads/2020/12/testimoinal-logos-lets-get-care.png"
                        />
                        <noscript>
                          <img
                            width="250"
                            height="205"
                            src="https://kingkong.co/wp-content/uploads/2020/12/testimoinal-logos-lets-get-care.png"
                            class="logo"
                            alt=""
                          />
                        </noscript>
                        <img
                          width="1024"
                          height="574"
                          src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%201024%20574'%3E%3C/svg%3E"
                          class="attachment-large size-large"
                          alt=""
                          data-lazy-srcset="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-letsgetcare-1024x574.jpg 1024w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-letsgetcare-300x168.jpg 300w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-letsgetcare-768x431.jpg 768w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-letsgetcare-1536x861.jpg 1536w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-letsgetcare-2048x1148.jpg 2048w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-letsgetcare-1920x1077.jpg 1920w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-letsgetcare-1200x673.jpg 1200w"
                          data-lazy-sizes="(max-width: 1024px) 100vw, 1024px"
                          data-lazy-src="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-letsgetcare-1024x574.jpg"
                        />
                        <noscript>
                          <img
                            width="1024"
                            height="574"
                            src="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-letsgetcare-1024x574.jpg"
                            class="attachment-large size-large"
                            alt=""
                            srcset="
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-letsgetcare-1024x574.jpg  1024w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-letsgetcare-300x168.jpg    300w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-letsgetcare-768x431.jpg    768w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-letsgetcare-1536x861.jpg  1536w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-letsgetcare-2048x1148.jpg 2048w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-letsgetcare-1920x1077.jpg 1920w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-letsgetcare-1200x673.jpg  1200w
                    "
                            sizes="(max-width: 1024px) 100vw, 1024px"
                          />
                        </noscript>
                        <p class="thumb-name">Michael Argent</p>
                      </div>
                      <div
                        class="swiper-slide"
                        role="group"
                        aria-label="4 / 12"
                        style={{
                          width: "197.5px",
                          marginRight: "15px",
                        }}
                      >
                        <img
                          width="250"
                          height="205"
                          src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20250%20205'%3E%3C/svg%3E"
                          class="logo"
                          alt=""
                          data-lazy-src="https://kingkong.co/wp-content/uploads/2020/12/testimoinal-logos-project-everest.png"
                        />
                        <noscript>
                          <img
                            width="250"
                            height="205"
                            src="https://kingkong.co/wp-content/uploads/2020/12/testimoinal-logos-project-everest.png"
                            class="logo"
                            alt=""
                          />
                        </noscript>
                        <img
                          width="1024"
                          height="574"
                          src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%201024%20574'%3E%3C/svg%3E"
                          class="attachment-large size-large"
                          alt=""
                          data-lazy-srcset="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-projecteverest-1024x574.jpg 1024w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-projecteverest-300x168.jpg 300w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-projecteverest-768x431.jpg 768w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-projecteverest-1536x861.jpg 1536w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-projecteverest-2048x1148.jpg 2048w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-projecteverest-1920x1077.jpg 1920w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-projecteverest-1200x673.jpg 1200w"
                          data-lazy-sizes="(max-width: 1024px) 100vw, 1024px"
                          data-lazy-src="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-projecteverest-1024x574.jpg"
                        />
                        <noscript>
                          <img
                            width="1024"
                            height="574"
                            src="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-projecteverest-1024x574.jpg"
                            class="attachment-large size-large"
                            alt=""
                            srcset="
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-projecteverest-1024x574.jpg  1024w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-projecteverest-300x168.jpg    300w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-projecteverest-768x431.jpg    768w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-projecteverest-1536x861.jpg  1536w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-projecteverest-2048x1148.jpg 2048w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-projecteverest-1920x1077.jpg 1920w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-projecteverest-1200x673.jpg  1200w
                    "
                            sizes="(max-width: 1024px) 100vw, 1024px"
                          />
                        </noscript>
                        <p class="thumb-name">Wade Tink</p>
                      </div>
                      <div
                        class="swiper-slide"
                        role="group"
                        aria-label="5 / 12"
                        style={{
                          width: "197.5px",
                          marginRight: "15px",
                        }}
                      >
                        <img
                          width="250"
                          height="205"
                          src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20250%20205'%3E%3C/svg%3E"
                          class="logo"
                          alt=""
                          data-lazy-src="https://kingkong.co/wp-content/uploads/2020/12/testimoinal-logos-new-sensation-homes.png"
                        />
                        <noscript>
                          <img
                            width="250"
                            height="205"
                            src="https://kingkong.co/wp-content/uploads/2020/12/testimoinal-logos-new-sensation-homes.png"
                            class="logo"
                            alt=""
                          />
                        </noscript>
                        <img
                          width="1024"
                          height="574"
                          src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%201024%20574'%3E%3C/svg%3E"
                          class="attachment-large size-large"
                          alt=""
                          data-lazy-srcset="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-newsensastionhomes-1024x574.jpg 1024w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-newsensastionhomes-300x168.jpg 300w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-newsensastionhomes-768x431.jpg 768w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-newsensastionhomes-1536x861.jpg 1536w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-newsensastionhomes-2048x1148.jpg 2048w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-newsensastionhomes-1920x1077.jpg 1920w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-newsensastionhomes-1200x673.jpg 1200w"
                          data-lazy-sizes="(max-width: 1024px) 100vw, 1024px"
                          data-lazy-src="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-newsensastionhomes-1024x574.jpg"
                        />
                        <noscript>
                          <img
                            width="1024"
                            height="574"
                            src="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-newsensastionhomes-1024x574.jpg"
                            class="attachment-large size-large"
                            alt=""
                            srcset="
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-newsensastionhomes-1024x574.jpg  1024w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-newsensastionhomes-300x168.jpg    300w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-newsensastionhomes-768x431.jpg    768w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-newsensastionhomes-1536x861.jpg  1536w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-newsensastionhomes-2048x1148.jpg 2048w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-newsensastionhomes-1920x1077.jpg 1920w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-newsensastionhomes-1200x673.jpg  1200w
                    "
                            sizes="(max-width: 1024px) 100vw, 1024px"
                          />
                        </noscript>
                        <p class="thumb-name">New Sensation Homes</p>
                      </div>
                      <div
                        class="swiper-slide"
                        role="group"
                        aria-label="6 / 12"
                        style={{
                          width: "197.5px",
                          marginRight: "15px",
                        }}
                      >
                        <img
                          width="250"
                          height="205"
                          src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20250%20205'%3E%3C/svg%3E"
                          class="logo"
                          alt=""
                          data-lazy-src="https://kingkong.co/wp-content/uploads/2020/12/testimoinal-logos-nsr.png"
                        />
                        <noscript>
                          <img
                            width="250"
                            height="205"
                            src="https://kingkong.co/wp-content/uploads/2020/12/testimoinal-logos-nsr.png"
                            class="logo"
                            alt=""
                          />
                        </noscript>
                        <img
                          width="1024"
                          height="574"
                          src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%201024%20574'%3E%3C/svg%3E"
                          class="attachment-large size-large"
                          alt=""
                          data-lazy-srcset="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-nsr-1024x574.jpg 1024w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-nsr-300x168.jpg 300w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-nsr-768x431.jpg 768w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-nsr-1536x861.jpg 1536w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-nsr-2048x1148.jpg 2048w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-nsr-1920x1077.jpg 1920w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-nsr-1200x673.jpg 1200w"
                          data-lazy-sizes="(max-width: 1024px) 100vw, 1024px"
                          data-lazy-src="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-nsr-1024x574.jpg"
                        />
                        <noscript>
                          <img
                            width="1024"
                            height="574"
                            src="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-nsr-1024x574.jpg"
                            class="attachment-large size-large"
                            alt=""
                            srcset="
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-nsr-1024x574.jpg  1024w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-nsr-300x168.jpg    300w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-nsr-768x431.jpg    768w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-nsr-1536x861.jpg  1536w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-nsr-2048x1148.jpg 2048w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-nsr-1920x1077.jpg 1920w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-nsr-1200x673.jpg  1200w
                    "
                            sizes="(max-width: 1024px) 100vw, 1024px"
                          />
                        </noscript>
                        <p class="thumb-name">Marco Maisano</p>
                      </div>
                      <div
                        class="swiper-slide"
                        role="group"
                        aria-label="7 / 12"
                        style={{
                          width: "197.5px",
                          marginRight: "15px",
                        }}
                      >
                        <img
                          width="250"
                          height="205"
                          src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20250%20205'%3E%3C/svg%3E"
                          class="logo"
                          alt=""
                          data-lazy-src="https://kingkong.co/wp-content/uploads/2020/12/testimoinal-logos-geds.png"
                        />
                        <noscript>
                          <img
                            width="250"
                            height="205"
                            src="https://kingkong.co/wp-content/uploads/2020/12/testimoinal-logos-geds.png"
                            class="logo"
                            alt=""
                          />
                        </noscript>
                        <img
                          width="1024"
                          height="574"
                          src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%201024%20574'%3E%3C/svg%3E"
                          class="attachment-large size-large"
                          alt=""
                          data-lazy-srcset="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-gedscarcleaning-1024x574.jpg 1024w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-gedscarcleaning-300x168.jpg 300w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-gedscarcleaning-768x431.jpg 768w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-gedscarcleaning-1536x861.jpg 1536w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-gedscarcleaning-2048x1148.jpg 2048w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-gedscarcleaning-1920x1077.jpg 1920w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-gedscarcleaning-1200x673.jpg 1200w"
                          data-lazy-sizes="(max-width: 1024px) 100vw, 1024px"
                          data-lazy-src="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-gedscarcleaning-1024x574.jpg"
                        />
                        <noscript>
                          <img
                            width="1024"
                            height="574"
                            src="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-gedscarcleaning-1024x574.jpg"
                            class="attachment-large size-large"
                            alt=""
                            srcset="
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-gedscarcleaning-1024x574.jpg  1024w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-gedscarcleaning-300x168.jpg    300w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-gedscarcleaning-768x431.jpg    768w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-gedscarcleaning-1536x861.jpg  1536w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-gedscarcleaning-2048x1148.jpg 2048w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-gedscarcleaning-1920x1077.jpg 1920w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-gedscarcleaning-1200x673.jpg  1200w
                    "
                            sizes="(max-width: 1024px) 100vw, 1024px"
                          />
                        </noscript>
                        <p class="thumb-name">Ged</p>
                      </div>
                      <div
                        class="swiper-slide"
                        role="group"
                        aria-label="8 / 12"
                        style={{
                          width: "197.5px",
                          marginRight: "15px",
                        }}
                      >
                        <img
                          width="250"
                          height="205"
                          src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20250%20205'%3E%3C/svg%3E"
                          class="logo"
                          alt=""
                          data-lazy-src="https://kingkong.co/wp-content/uploads/2020/12/testimoinal-logos-breathe.png"
                        />
                        <noscript>
                          <img
                            width="250"
                            height="205"
                            src="https://kingkong.co/wp-content/uploads/2020/12/testimoinal-logos-breathe.png"
                            class="logo"
                            alt=""
                          />
                        </noscript>
                        <img
                          width="1024"
                          height="574"
                          src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%201024%20574'%3E%3C/svg%3E"
                          class="attachment-large size-large"
                          alt=""
                          data-lazy-srcset="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-breatheeducation-1024x574.jpg 1024w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-breatheeducation-300x168.jpg 300w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-breatheeducation-768x431.jpg 768w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-breatheeducation-1536x861.jpg 1536w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-breatheeducation-2048x1148.jpg 2048w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-breatheeducation-1920x1077.jpg 1920w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-breatheeducation-1200x673.jpg 1200w"
                          data-lazy-sizes="(max-width: 1024px) 100vw, 1024px"
                          data-lazy-src="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-breatheeducation-1024x574.jpg"
                        />
                        <noscript>
                          <img
                            width="1024"
                            height="574"
                            src="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-breatheeducation-1024x574.jpg"
                            class="attachment-large size-large"
                            alt=""
                            srcset="
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-breatheeducation-1024x574.jpg  1024w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-breatheeducation-300x168.jpg    300w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-breatheeducation-768x431.jpg    768w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-breatheeducation-1536x861.jpg  1536w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-breatheeducation-2048x1148.jpg 2048w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-breatheeducation-1920x1077.jpg 1920w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-breatheeducation-1200x673.jpg  1200w
                    "
                            sizes="(max-width: 1024px) 100vw, 1024px"
                          />
                        </noscript>
                        <p class="thumb-name">Raphael Bender</p>
                      </div>
                      <div
                        class="swiper-slide"
                        role="group"
                        aria-label="9 / 12"
                        style={{
                          width: "197.5px",
                          marginRight: "15px",
                        }}
                      >
                        <img
                          width="250"
                          height="205"
                          src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20250%20205'%3E%3C/svg%3E"
                          class="logo"
                          alt=""
                          data-lazy-src="https://kingkong.co/wp-content/uploads/2020/12/testimoinal-logos-discover-stradbroke.png"
                        />
                        <noscript>
                          <img
                            width="250"
                            height="205"
                            src="https://kingkong.co/wp-content/uploads/2020/12/testimoinal-logos-discover-stradbroke.png"
                            class="logo"
                            alt=""
                          />
                        </noscript>
                        <img
                          width="1024"
                          height="574"
                          src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%201024%20574'%3E%3C/svg%3E"
                          class="attachment-large size-large"
                          alt=""
                          data-lazy-srcset="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-discoverstradbroke-1024x574.jpg 1024w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-discoverstradbroke-300x168.jpg 300w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-discoverstradbroke-768x431.jpg 768w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-discoverstradbroke-1536x861.jpg 1536w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-discoverstradbroke-2048x1148.jpg 2048w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-discoverstradbroke-1920x1077.jpg 1920w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-discoverstradbroke-1200x673.jpg 1200w"
                          data-lazy-sizes="(max-width: 1024px) 100vw, 1024px"
                          data-lazy-src="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-discoverstradbroke-1024x574.jpg"
                        />
                        <noscript>
                          <img
                            width="1024"
                            height="574"
                            src="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-discoverstradbroke-1024x574.jpg"
                            class="attachment-large size-large"
                            alt=""
                            srcset="
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-discoverstradbroke-1024x574.jpg  1024w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-discoverstradbroke-300x168.jpg    300w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-discoverstradbroke-768x431.jpg    768w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-discoverstradbroke-1536x861.jpg  1536w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-discoverstradbroke-2048x1148.jpg 2048w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-discoverstradbroke-1920x1077.jpg 1920w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-discoverstradbroke-1200x673.jpg  1200w
                    "
                            sizes="(max-width: 1024px) 100vw, 1024px"
                          />
                        </noscript>
                        <p class="thumb-name">Colin Battersby</p>
                      </div>
                      <div
                        class="swiper-slide"
                        role="group"
                        aria-label="10 / 12"
                        style={{
                          width: "197.5px",
                          marginRight: "15px",
                        }}
                      >
                        <img
                          width="250"
                          height="205"
                          src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20250%20205'%3E%3C/svg%3E"
                          class="logo"
                          alt=""
                          data-lazy-src="https://kingkong.co/wp-content/uploads/2020/12/testimoinal-logos-aged-care-fs.png"
                        />
                        <noscript>
                          <img
                            width="250"
                            height="205"
                            src="https://kingkong.co/wp-content/uploads/2020/12/testimoinal-logos-aged-care-fs.png"
                            class="logo"
                            alt=""
                          />
                        </noscript>
                        <img
                          width="1024"
                          height="574"
                          src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%201024%20574'%3E%3C/svg%3E"
                          class="attachment-large size-large"
                          alt=""
                          data-lazy-srcset="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-agedcarefinancial-1024x574.jpg 1024w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-agedcarefinancial-300x168.jpg 300w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-agedcarefinancial-768x431.jpg 768w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-agedcarefinancial-1536x861.jpg 1536w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-agedcarefinancial-2048x1148.jpg 2048w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-agedcarefinancial-1920x1077.jpg 1920w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-agedcarefinancial-1200x673.jpg 1200w"
                          data-lazy-sizes="(max-width: 1024px) 100vw, 1024px"
                          data-lazy-src="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-agedcarefinancial-1024x574.jpg"
                        />
                        <noscript>
                          <img
                            width="1024"
                            height="574"
                            src="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-agedcarefinancial-1024x574.jpg"
                            class="attachment-large size-large"
                            alt=""
                            srcset="
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-agedcarefinancial-1024x574.jpg  1024w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-agedcarefinancial-300x168.jpg    300w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-agedcarefinancial-768x431.jpg    768w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-agedcarefinancial-1536x861.jpg  1536w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-agedcarefinancial-2048x1148.jpg 2048w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-agedcarefinancial-1920x1077.jpg 1920w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-agedcarefinancial-1200x673.jpg  1200w
                    "
                            sizes="(max-width: 1024px) 100vw, 1024px"
                          />
                        </noscript>
                        <p class="thumb-name">Beth Hourigan</p>
                      </div>
                      <div
                        class="swiper-slide"
                        role="group"
                        aria-label="11 / 12"
                        style={{
                          width: "197.5px",
                          marginRight: "15px",
                        }}
                      >
                        <img
                          width="250"
                          height="205"
                          src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20250%20205'%3E%3C/svg%3E"
                          class="logo"
                          alt=""
                          data-lazy-src="https://kingkong.co/wp-content/uploads/2020/12/testimoinal-logos-gold-key.png"
                        />
                        <noscript>
                          <img
                            width="250"
                            height="205"
                            src="https://kingkong.co/wp-content/uploads/2020/12/testimoinal-logos-gold-key.png"
                            class="logo"
                            alt=""
                          />
                        </noscript>
                        <img
                          width="1024"
                          height="574"
                          src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%201024%20574'%3E%3C/svg%3E"
                          class="attachment-large size-large"
                          alt=""
                          data-lazy-srcset="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-goldkeyhomes-1024x574.jpg 1024w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-goldkeyhomes-300x168.jpg 300w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-goldkeyhomes-768x431.jpg 768w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-goldkeyhomes-1536x861.jpg 1536w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-goldkeyhomes-2048x1148.jpg 2048w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-goldkeyhomes-1920x1077.jpg 1920w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-goldkeyhomes-1200x673.jpg 1200w"
                          data-lazy-sizes="(max-width: 1024px) 100vw, 1024px"
                          data-lazy-src="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-goldkeyhomes-1024x574.jpg"
                        />
                        <noscript>
                          <img
                            width="1024"
                            height="574"
                            src="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-goldkeyhomes-1024x574.jpg"
                            class="attachment-large size-large"
                            alt=""
                            srcset="
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-goldkeyhomes-1024x574.jpg  1024w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-goldkeyhomes-300x168.jpg    300w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-goldkeyhomes-768x431.jpg    768w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-goldkeyhomes-1536x861.jpg  1536w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-goldkeyhomes-2048x1148.jpg 2048w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-goldkeyhomes-1920x1077.jpg 1920w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-goldkeyhomes-1200x673.jpg  1200w
                    "
                            sizes="(max-width: 1024px) 100vw, 1024px"
                          />
                        </noscript>
                        <p class="thumb-name">Dylan Trickey</p>
                      </div>
                      <div
                        class="swiper-slide"
                        role="group"
                        aria-label="12 / 12"
                        style={{
                          width: "197.5px",
                          marginRight: "15px",
                        }}
                      >
                        <img
                          width="250"
                          height="205"
                          src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20250%20205'%3E%3C/svg%3E"
                          class="logo"
                          alt=""
                          data-lazy-src="https://kingkong.co/wp-content/uploads/2020/12/testimoinal-logos-enso.png"
                        />
                        <noscript>
                          <img
                            width="250"
                            height="205"
                            src="https://kingkong.co/wp-content/uploads/2020/12/testimoinal-logos-enso.png"
                            class="logo"
                            alt=""
                          />
                        </noscript>
                        <img
                          width="1024"
                          height="574"
                          src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%201024%20574'%3E%3C/svg%3E"
                          class="attachment-large size-large"
                          alt=""
                          data-lazy-srcset="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-ensohomes-1024x574.jpg 1024w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-ensohomes-300x168.jpg 300w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-ensohomes-768x431.jpg 768w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-ensohomes-1536x861.jpg 1536w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-ensohomes-2048x1148.jpg 2048w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-ensohomes-1920x1077.jpg 1920w, https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-ensohomes-1200x673.jpg 1200w"
                          data-lazy-sizes="(max-width: 1024px) 100vw, 1024px"
                          data-lazy-src="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-ensohomes-1024x574.jpg"
                        />
                        <noscript>
                          <img
                            width="1024"
                            height="574"
                            src="https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-ensohomes-1024x574.jpg"
                            class="attachment-large size-large"
                            alt=""
                            srcset="
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-ensohomes-1024x574.jpg  1024w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-ensohomes-300x168.jpg    300w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-ensohomes-768x431.jpg    768w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-ensohomes-1536x861.jpg  1536w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-ensohomes-2048x1148.jpg 2048w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-ensohomes-1920x1077.jpg 1920w,
                      https://kingkong.co/wp-content/uploads/2021/01/testimonial-overlay-ensohomes-1200x673.jpg  1200w
                    "
                            sizes="(max-width: 1024px) 100vw, 1024px"
                          />
                        </noscript>
                        <p class="thumb-name">Lee Selkrig</p>
                      </div>
                    </div>
                    <span
                      class="swiper-notification"
                      aria-live="assertive"
                      aria-atomic="true"
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class="text-dark content-text content-wysiwyg">
            <div class="background-image-container"></div>
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-12 text-center animate-in">
                  <span class="h2"> The TL;DR Version: </span>
                </div>
                <div class="col-xxl-7 col-xl-8 col-md-9">
                  <p>
                    We’ll build a campaign to beat your best ads for you – and
                    if we don’t beat them we don’t get paid. But act now before
                    there’s a waiting list, and a strict dress code…because
                    we’re only taking on 8 new clients per month with this
                    offer.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section class="text-light content-global hit-the-damn-button">
            <div
              class="background-image-container"
              style={{ backgroundColor: "#000000" }}
            ></div>
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-sm-10 col-md-9 col-lg-8 col-xl-7 text-center animate-in">
                  <span class="h2">
                    I guess what we’re trying to say is that you should hit the
                    damn button
                  </span>
                </div>
                <div class="col-sm-9 col-md-7 col-lg-6 col-xl-6 null animate-in">
                  <span class="null">
                    <a
                      class="btn btn-doublelayer btn-green"
                      href="/30-minute-strategy-session/"
                    >
                      <span class="btn-doublelayer__maintext">
                        Hit the damn button
                      </span>
                    </a>
                  </span>
                </div>
                <div class="floating-icons">
                  <i class="icon-facebook"></i>
                  <i class="icon-adwords"></i>
                  <i class="icon-seo"></i>
                  <i class="icon-instagram"></i>
                  <i class="icon-activecampaign"></i>
                  <i class="icon-wordpress"></i>
                  <i class="icon-shopify"></i>
                  <i class="icon-youtube"></i>
                  <i class="icon-analytics"></i>
                  <i class="icon-kk"></i>
                </div>
              </div>
            </div>
          </section>
        </main>
        <footer class="site-footer" id="footer-container">
          <div class="container">
            <div class="footer-columns">
              <div class="row justify-content-center">
                <div class="col footer-column-1">
                  <div
                    id="custom_html-4"
                    class="widget_text widget widget_custom_html footer-widgets-1"
                  >
                    <div class="textwidget custom-html-widget">
                      <svg
                        class="logo svg-primary"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 1624.7 219.7"
                      >
                        <path
                          class="st0"
                          d="M119.3 6.9h61.6S99.1 123.5 98.8 123.5l86 90.9h-72l-57.4-62.6v62.5H0V6.9h55.5v94.6l63.8-94.6zm80.5 0h55.5v207.5h-55.5V6.9zm138.9 104v103.4h-52.2V4.1c0-1.8.9-3.4 4.3-.6l130.3 107.4V6.9h52.2v210.5c0 2.4-.9 3.4-4.3.6L338.7 110.9zM605 144.5v-42.7h98c0 1.2.3 4.6.3 8.9 0 70.8-40.6 106.8-101.9 106.8-58.9 0-108.3-44.2-108.3-106.8S541.9 4.4 600.5 4.4c40 0 71.7 17.7 87.9 46.7L644.2 78c-7.9-14-21.4-25.3-44.5-25.3-33 0-49.7 25.3-49.7 58s17.7 58.6 51.9 58.6c25.3 0 36.9-9.2 43.3-24.7l-40.2-.1zM901.2 4.9h62.1s-82.4 117.4-82.7 117.4l86.7 91.6h-72.6l-57.8-63v63H781V4.9h56v95.3l64.2-95.3z"
                        ></path>
                        <path
                          class="st0"
                          d="M947.9 109.4c0-63 52-107 112.2-107s112.2 44 112.2 107-52 107.3-112.2 107.3-112.2-44.3-112.2-107.3zm167.3 0c0-32.9-20-58.4-55-58.4s-55 25.5-55 58.4 20 58.7 55 58.7 55-25.8 55-58.7zm129.7.4V214h-52.6V2.1c0-1.9.9-3.4 4.3-.6l131.3 108.3V4.9h52.6V217c0 2.5-.9 3.4-4.3.6l-131.3-107.8zm268.4 33.7v-43h98.7c0 1.2.3 4.6.3 8.9 0 71.3-40.9 107.6-102.7 107.6-59.3 0-109.1-44.6-109.1-107.6s49.2-107 108.2-107c40.3 0 72.2 17.8 88.6 47l-44.6 27.1c-8-14.1-21.5-25.5-44.9-25.5-33.2 0-50.1 25.5-50.1 58.4s17.8 59 52.3 59c25.5 0 37.2-9.2 43.7-24.9h-40.4z"
                        ></path>
                        <g id="Dfeiu3.tif">
                          <path
                            class="st0"
                            d="M1607.9 5H1609.3c1.5.1 2.9.3 4.3.8 4.6 1.6 7.9 4.6 9.8 9.1.7 1.5 1.1 3.1 1.2 4.8 0 .3.1.6.1.9v1.5c-.1 1.4-.3 2.7-.7 4-1.5 4.6-4.4 7.9-8.8 9.9-1.7.8-3.5 1.2-5.4 1.4h-1.9-.1c-.4 0-.7 0-1.1-.1-1.6-.2-3.1-.6-4.5-1.2-2.6-1.1-4.8-2.8-6.5-5.1-1.9-2.5-3-5.4-3.3-8.6 0-.2 0-.4-.1-.6v-1.3-.2c.1-1.2.3-2.4.6-3.6 1.5-5.1 5.2-9.1 10.2-10.8 1.2-.4 2.5-.7 3.8-.8.4-.1.7-.1 1-.1zm-12.3 16.2c0 7.1 5.8 12.9 12.9 12.9 7.1 0 12.9-5.8 12.9-12.9 0-7.1-5.8-12.9-12.9-12.9-7.1 0-12.9 5.8-12.9 12.9z"
                          ></path>
                          <path
                            class="st0"
                            d="M1605.8 22.9v6.4h-4.4V12.4h7c1.1 0 2.2.1 3.3.5.7.2 1.3.6 1.8 1.1.5.6.8 1.3.9 2.1.1.9.1 1.7-.1 2.6-.4 1.2-1.2 2-2.3 2.5-.2.1-.5.2-.7.3.1 0 .2.1.3.1.7.2 1.3.7 1.7 1.3.4.5.7 1.1 1 1.6.8 1.6 1.6 3.2 2.4 4.7 0 0 0 .1.1.1h-4.5c-.1 0-.2 0-.2-.1-.7-1.4-1.4-2.9-2.1-4.3-.2-.4-.5-.8-.7-1.1-.4-.5-1-.8-1.6-.8-.7-.1-1.3-.1-1.9-.1zm0-3h2.2c.3 0 .5-.1.8-.1.6-.2 1.1-.6 1.3-1.2.1-.5.1-1.1 0-1.6-.2-.6-.5-1-1.1-1.2-.3-.1-.5-.1-.8-.2h-2.4-.1c.1 1.4.1 2.8.1 4.3z"
                          ></path>
                        </g>
                      </svg>
                    </div>
                  </div>
                  <div id="text-2" class="widget widget_text footer-widgets-1">
                    <div class="textwidget">
                      <p>
                        The most ruthlessly effective platform for rapidly
                        scaling businesses on planet earth.
                      </p>
                    </div>
                  </div>
                  <div
                    id="kk_address_widget-2"
                    class="widget widget_kk_address_widget footer-widgets-1"
                  >
                    520 Broadway
                    <br />
                    Santa Monica, CA 90401
                    <br />
                    United States
                    <br />
                    Phone: <a href="tel:+18883038580">+1-888-303-8580</a>
                  </div>
                  <div
                    id="custom_html-2"
                    class="widget_text widget widget_custom_html footer-widgets-1"
                  >
                    <div class="textwidget custom-html-widget">
                      <a
                        href="https://facebook.com/kingkongco/"
                        rel="nofollow"
                        class="social-icon"
                        aria-label="Our Facebook"
                      >
                        <svg
                          height="512"
                          viewBox="0 0 24 24"
                          width="512"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M15.997 3.985h2.191V.169C17.81.117 16.51 0 14.996 0c-3.159 0-5.323 1.987-5.323 5.639V9H6.187v4.266h3.486V24h4.274V13.267h3.345l.531-4.266h-3.877V6.062c.001-1.233.333-2.077 2.051-2.077z"></path>
                        </svg>
                      </a>
                      <a
                        href="https://www.instagram.com/kingkong.com.au/"
                        rel="nofollow"
                        class="social-icon"
                        aria-label="Our Instagram"
                      >
                        <svg
                          height="511pt"
                          viewBox="0 0 511 511.9"
                          width="511pt"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M510.95 150.5c-1.2-27.2-5.598-45.898-11.9-62.102-6.5-17.199-16.5-32.597-29.6-45.398-12.802-13-28.302-23.102-45.302-29.5-16.296-6.3-34.898-10.7-62.097-11.898C334.648.3 325.949 0 256.449 0s-78.199.3-105.5 1.5c-27.199 1.2-45.898 5.602-62.097 11.898-17.204 6.5-32.602 16.5-45.403 29.602-13 12.8-23.097 28.3-29.5 45.3-6.3 16.302-10.699 34.9-11.898 62.098C.75 177.801.449 186.5.449 256s.301 78.2 1.5 105.5c1.2 27.2 5.602 45.898 11.903 62.102 6.5 17.199 16.597 32.597 29.597 45.398 12.801 13 28.301 23.102 45.301 29.5 16.3 6.3 34.898 10.7 62.102 11.898 27.296 1.204 36 1.5 105.5 1.5s78.199-.296 105.5-1.5c27.199-1.199 45.898-5.597 62.097-11.898a130.934 130.934 0 0 0 74.903-74.898c6.296-16.301 10.699-34.903 11.898-62.102 1.2-27.3 1.5-36 1.5-105.5s-.102-78.2-1.3-105.5zm-46.098 209c-1.102 25-5.301 38.5-8.801 47.5-8.602 22.3-26.301 40-48.602 48.602-9 3.5-22.597 7.699-47.5 8.796-27 1.204-35.097 1.5-103.398 1.5s-76.5-.296-103.403-1.5c-25-1.097-38.5-5.296-47.5-8.796C94.551 451.5 84.45 445 76.25 436.5c-8.5-8.3-15-18.3-19.102-29.398-3.5-9-7.699-22.602-8.796-47.5-1.204-27-1.5-35.102-1.5-103.403s.296-76.5 1.5-103.398c1.097-25 5.296-38.5 8.796-47.5C61.25 94.199 67.75 84.1 76.352 75.898c8.296-8.5 18.296-15 29.398-19.097 9-3.5 22.602-7.7 47.5-8.801 27-1.2 35.102-1.5 103.398-1.5 68.403 0 76.5.3 103.403 1.5 25 1.102 38.5 5.3 47.5 8.8 11.097 4.098 21.199 10.598 29.398 19.098 8.5 8.301 15 18.301 19.102 29.403 3.5 9 7.699 22.597 8.8 47.5 1.2 27 1.5 35.097 1.5 103.398s-.3 76.301-1.5 103.301zm0 0"></path>
                          <path d="M256.45 124.5c-72.598 0-131.5 58.898-131.5 131.5s58.902 131.5 131.5 131.5c72.6 0 131.5-58.898 131.5-131.5s-58.9-131.5-131.5-131.5zm0 216.8c-47.098 0-85.302-38.198-85.302-85.3s38.204-85.3 85.301-85.3c47.102 0 85.301 38.198 85.301 85.3s-38.2 85.3-85.3 85.3zm167.402-222c0 16.954-13.747 30.7-30.704 30.7-16.953 0-30.699-13.746-30.699-30.7 0-16.956 13.746-30.698 30.7-30.698 16.956 0 30.703 13.742 30.703 30.699zm0 0"></path>
                        </svg>
                      </a>
                      <a
                        href="https://www.linkedin.com/company/kingkong/"
                        rel="nofollow"
                        class="social-icon"
                        aria-label="Our LinkedIn"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 504.4 504.4"
                        >
                          <path d="M377.6.2H126.4C56.8.2 0 57 0 126.6v251.6c0 69.2 56.8 126 126.4 126H378c69.6 0 126.4-56.8 126.4-126.4V126.6C504 57 447.2.2 377.6.2zM168 408.2H96v-208h72v208zm-36.4-240c-20.4 0-36.8-16.4-36.8-36.8s16.4-36.8 36.8-36.8 36.8 16.4 36.8 36.8c-.4 20.4-16.8 36.8-36.8 36.8zm276.8 240H348V307.4c0-24.4-3.2-55.6-36.4-55.6-34 0-39.6 26.4-39.6 54v102.4h-60v-208h56v28h1.6c8.8-16 29.2-28.4 61.2-28.4 66 0 77.6 38 77.6 94.4v114z"></path>
                        </svg>
                      </a>
                      <a
                        href="https://www.youtube.com/channel/UCwDi1NwScPpDYyvwPvHBj9g"
                        rel="nofollow"
                        class="social-icon"
                        aria-label="Our Youtube"
                      >
                        <svg
                          height="682pt"
                          viewBox="-21 -117 682.667 682"
                          width="682pt"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M626.813 64.035c-7.375-27.418-28.993-49.031-56.407-56.414C520.324-6.082 319.992-6.082 319.992-6.082s-200.324 0-250.406 13.184c-26.887 7.375-49.031 29.52-56.406 56.933C0 114.113 0 217.97 0 217.97s0 104.379 13.18 153.933c7.382 27.414 28.992 49.028 56.41 56.41C120.195 442.02 320 442.02 320 442.02s200.324 0 250.406-13.184c27.418-7.379 49.032-28.992 56.414-56.406 13.176-50.082 13.176-153.934 13.176-153.934s.527-104.383-13.183-154.46zM256.21 313.915V122.022l166.586 95.946zm0 0"></path>
                        </svg>
                      </a>
                      <a
                        href="https://open.spotify.com/user/12147390775/playlist/6p7sfcY8NgLASvan5kY5KX"
                        rel="nofollow"
                        class="social-icon"
                        aria-label="Our Spotify Playlist"
                      >
                        <svg
                          height="512"
                          viewBox="0 0 24 24"
                          width="512"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M12 24c6.624 0 12-5.376 12-12S18.624 0 12 0 0 5.376 0 12s5.376 12 12 12zm4.872-6.344v.001c-.807 0-3.356-2.828-10.52-1.36-.189.049-.436.126-.576.126-.915 0-1.09-1.369-.106-1.578 3.963-.875 8.013-.798 11.467 1.268.824.526.474 1.543-.265 1.543zm1.303-3.173c-.113-.03-.08.069-.597-.203-3.025-1.79-7.533-2.512-11.545-1.423-.232.063-.358.126-.576.126-1.071 0-1.355-1.611-.188-1.94 4.716-1.325 9.775-.552 13.297 1.543.392.232.547.533.547.953a.938.938 0 0 1-.938.944zM4.548 6.998c4.523-1.324 11.368-.906 15.624 1.578 1.091.629.662 2.22-.498 2.22l-.001-.001c-.252 0-.407-.063-.625-.189C15.605 8.55 9.444 8.057 5.458 9.17c-.175.048-.393.125-.625.125-.639 0-1.127-.499-1.127-1.142 0-.657.407-1.029.842-1.155z"></path>
                        </svg>
                      </a>
                    </div>
                  </div>
                  <button
                    class="siteswitcher__button"
                    data-js-modal-trigger="swModal"
                  >
                    <img
                      width="35"
                      height="18"
                      src="https://kingkong.co/wp-content/themes/kingkong/assets/prod/images/flag-united-states.svg"
                      alt="Flag of United States"
                    />
                    <span>United States</span>
                  </button>
                  <div class="js-modal siteswitcher" data-js-modal-id="swModal">
                    <div class="modal">
                      <div class="js-close close-x">
                        <svg
                          viewBox="0 0 329.269 329"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M194.8 164.77L323.013 36.555c8.343-8.34 8.343-21.825 0-30.164-8.34-8.34-21.825-8.34-30.164 0L164.633 134.605 36.422 6.391c-8.344-8.34-21.824-8.34-30.164 0-8.344 8.34-8.344 21.824 0 30.164l128.21 128.215L6.259 292.984c-8.344 8.34-8.344 21.825 0 30.164a21.266 21.266 0 0 0 15.082 6.25c5.46 0 10.922-2.09 15.082-6.25l128.21-128.214 128.216 128.214a21.273 21.273 0 0 0 15.082 6.25c5.46 0 10.922-2.09 15.082-6.25 8.343-8.34 8.343-21.824 0-30.164zm0 0"></path>
                        </svg>
                      </div>
                      <div class="content">
                        <p class="h3">Select Your Country</p>
                        <ul>
                          <li>
                            <button class="active" data-link="?cs=">
                              <img
                                width="35"
                                height="18"
                                src="https://kingkong.co/wp-content/themes/kingkong/assets/prod/images/flag-united-states.svg"
                                alt="Flag of United States"
                              />
                              United States
                            </button>
                          </li>
                          <li>
                            <button class="" data-link="?cs=AU">
                              <img
                                width="35"
                                height="18"
                                src="https://kingkong.co/wp-content/themes/kingkong/assets/prod/images/flag-australia.svg"
                                alt="Flag of Australia"
                              />
                              Australia
                            </button>
                          </li>
                          <li>
                            <button class="" data-link="?cs=UK">
                              <img
                                width="35"
                                height="18"
                                src="https://kingkong.co/wp-content/themes/kingkong/assets/prod/images/flag-united-kingdom.svg"
                                alt="Flag of United Kingdom"
                              />
                              United Kingdom
                            </button>
                          </li>
                          <li>
                            <button class="" data-link="?cs=CA">
                              <img
                                width="35"
                                height="18"
                                src="https://kingkong.co/wp-content/themes/kingkong/assets/prod/images/flag-canada.svg"
                                alt="Flag of Canada"
                              />
                              Canada
                            </button>
                          </li>
                          <li>
                            <button class="" data-link="?cs=NZ">
                              <img
                                width="35"
                                height="18"
                                src="https://kingkong.co/wp-content/themes/kingkong/assets/prod/images/flag-new-zealand.svg"
                                alt="Flag of New Zealand"
                              />
                              New Zealand
                            </button>
                          </li>
                          <li>
                            <button class="" data-link="?cs=IE">
                              <img
                                width="35"
                                height="18"
                                src="https://kingkong.co/wp-content/themes/kingkong/assets/prod/images/flag-ireland.svg"
                                alt="Flag of Ireland"
                              />
                              Ireland
                            </button>
                          </li>
                          <li>
                            <button class="" data-link="?cs=AE">
                              <img
                                width="35"
                                height="18"
                                src="https://kingkong.co/wp-content/themes/kingkong/assets/prod/images/flag-united-arab-emirates.svg"
                                alt="Flag of United Arab Emirates"
                              />
                              United Arab Emirates
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col footer-column-2">
                  <div
                    id="nav_menu-2"
                    class="widget widget_nav_menu footer-widgets-2"
                  >
                    <p class="widget-title">Company</p>
                    <div class="menu-company-container">
                      <ul id="menu-company" class="menu">
                        <li
                          id="menu-item-12074"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-12074"
                        >
                          <a href="https://kingkong.co/about-us/">About Us</a>
                        </li>
                        <li
                          id="menu-item-12070"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-12070"
                        >
                          <a href="https://kingkong.co/contact/">Contact Us</a>
                        </li>
                        <li
                          id="menu-item-12071"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-12071"
                        >
                          <a href="https://kingkong.co/blog/">Blog</a>
                        </li>
                        <li
                          id="menu-item-12072"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-12072"
                        >
                          <a href="https://kingkong.co/reviews/">Reviews</a>
                        </li>
                        <li
                          id="menu-item-12073"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-12073"
                        >
                          <a href="https://kingkong.co/careers/">Careers</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col footer-column-3">
                  <div
                    id="nav_menu-3"
                    class="widget widget_nav_menu footer-widgets-3"
                  >
                    <p class="widget-title">Services</p>
                    <div class="menu-services-container">
                      <ul id="menu-services" class="menu">
                        <li
                          id="menu-item-12079"
                          class="footer-seo-trigger menu-item menu-item-type-post_type menu-item-object-page menu-item-12079"
                        >
                          <a href="https://kingkong.co/seo-agency/">SEO</a>
                          <span
                            class="seo-trigger-master"
                            data-seo-trigger="seo"
                          >
                            +
                          </span>
                        </li>
                        <li
                          id="menu-item-12075"
                          class="footer-cro-trigger menu-item menu-item-type-post_type menu-item-object-page menu-item-12075"
                        >
                          <a href="https://kingkong.co/conversion-rate-optimization-agency/">
                            Conversion Rate Optimization
                          </a>
                        </li>
                        <li
                          id="menu-item-12078"
                          class="footer-ppc-trigger menu-item menu-item-type-post_type menu-item-object-page menu-item-12078"
                        >
                          <a href="https://kingkong.co/ppc-management-agency/">
                            Google Ads
                          </a>
                          <span
                            class="seo-trigger-master"
                            data-seo-trigger="ppc"
                          >
                            +
                          </span>
                        </li>
                        <li
                          id="menu-item-12076"
                          class="footer-fb-trigger menu-item menu-item-type-post_type menu-item-object-page menu-item-12076"
                        >
                          <a href="https://kingkong.co/facebook-advertising-agency/">
                            Facebook Ads
                          </a>
                          <span
                            class="seo-trigger-master"
                            data-seo-trigger="fb"
                          >
                            +
                          </span>
                        </li>
                        <li
                          id="menu-item-12077"
                          class="footer-lp-trigger menu-item menu-item-type-post_type menu-item-object-page menu-item-12077"
                        >
                          <a href="https://kingkong.co/landing-page-agency/">
                            Landing Pages
                          </a>
                        </li>
                        <li
                          id="menu-item-12080"
                          class="footer-webdesign-trigger menu-item menu-item-type-post_type menu-item-object-page menu-item-12080"
                        >
                          <a href="https://kingkong.co/web-design-agency/">
                            Web Design
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col footer-column-4">
                  <div
                    id="nav_menu-4"
                    class="widget widget_nav_menu footer-widgets-4"
                  >
                    <p class="widget-title">Solutions</p>
                    <div class="menu-solutions-container">
                      <ul id="menu-solutions" class="menu">
                        <li
                          id="menu-item-12537"
                          class="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-12401 current_page_item menu-item-12537"
                        >
                          <a
                            href="https://kingkong.co/agency/"
                            aria-current="page"
                          >
                            Agency
                          </a>
                        </li>
                        <li
                          id="menu-item-24991"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-24991"
                        >
                          <a href="https://kingkong.co/quantum-growth/">
                            Courses
                          </a>
                        </li>
                        <li
                          id="menu-item-12083"
                          class="menu-item menu-item-type-custom menu-item-object-custom menu-item-12083"
                        >
                          <a href="https://selllikecrazybook.com">Books</a>
                        </li>
                        <li
                          id="menu-item-12084"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-12084"
                        >
                          <a href="https://kingkong.co/blog/">Blog</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col footer-column-5">
                  <div id="text-3" class="widget widget_text footer-widgets-5">
                    <p class="widget-title">Want some free money?</p>
                    <div class="textwidget">
                      <p>
                        Get million dollar marketing strategies sent straight to
                        your inbox every week. Just enter your email below.
                      </p>
                    </div>
                  </div>
                  <div
                    id="custom_html-3"
                    class="widget_text widget widget_custom_html footer-widgets-5"
                  >
                    <div class="textwidget custom-html-widget">
                      <form class="round-form">
                        <input
                          type="text"
                          placeholder="Get some free money now"
                        />
                        <input type="submit" value="Hit me" />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="footer-seo"
              data-seo-name="seo"
              style={{ height: "0px" }}
            >
              <div class="row justify-content-left">
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Alabama
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/alabama/">
                        Alabama SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/alabama/birmingham/">
                        Birmingham SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/alabama/montgomery/">
                        Montgomery SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/alabama/huntsville/">
                        Huntsville SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/alabama/mobile/">
                        Mobile SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/alabama/tuscaloosa/">
                        Tuscaloosa SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/alabama/dothan/">
                        Dothan SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/alabama/hoover/">
                        Hoover SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Washington
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/washington/">
                        Washington SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/washington/auburn/">
                        Auburn SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/washington/lakewood/">
                        Lakewood SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/washington/everett/">
                        Everett SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/washington/bellevue/">
                        Bellevue SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/washington/seattle/">
                        Seattle SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/washington/spokane/">
                        Spokane SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/washington/tacoma/">
                        Tacoma SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/washington/vancouver/">
                        Vancouver SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/washington/federal-way/">
                        Federal Way SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/washington/bellingham/">
                        Bellingham SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/washington/pasco/">
                        Pasco SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/washington/sammamish/">
                        Sammamish SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/washington/richland/">
                        Richland SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/washington/burien/">
                        Burien SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/washington/renton/">
                        Renton SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/washington/yakima/">
                        Yakima SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/washington/kennewick/">
                        Kennewick SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/washington/marysville/">
                        Marysville SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/washington/south-hill/">
                        South Hill SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/washington/shoreline/">
                        Shoreline SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/washington/lacey/">
                        Lacey SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/washington/kent/">
                        Kent SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/washington/spokane-valley/">
                        Spokane Valley SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/washington/kirkland/">
                        Kirkland SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/washington/redmond/">
                        Redmond SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/washington/olympia/">
                        Olympia SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Illinois
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/illinois/">
                        Illinois SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/illinois/decatur/">
                        Decatur SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/illinois/peoria/">
                        Peoria SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/illinois/aurora/">
                        Aurora SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/illinois/chicago/">
                        Chicago SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/illinois/joliet/">
                        Joliet SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/illinois/naperville/">
                        Naperville SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/illinois/rockford/">
                        Rockford SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/illinois/cicero/">
                        Cicero SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/illinois/evanston/">
                        Evanston SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/illinois/des-plaines/">
                        Des Plaines SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/illinois/mount-prospect/">
                        Mount Prospect SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/illinois/oak-park/">
                        Oak Park SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/illinois/hoffman-estates/">
                        Hoffman Estates SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/illinois/elgin/">
                        Elgin SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/illinois/champaign/">
                        Champaign SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/illinois/schaumburg/">
                        Schaumburg SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/illinois/arlington-heights/">
                        Arlington Heights SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/illinois/skokie/">
                        Skokie SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/illinois/orland-park/">
                        Orland Park SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/illinois/berwyn/">
                        Berwyn SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/illinois/downers-grove/">
                        Downers Grove SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/illinois/waukegan/">
                        Waukegan SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/illinois/bolingbrook/">
                        Bolingbrook SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/illinois/palatine/">
                        Palatine SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/illinois/oak-lawn/">
                        Oak Lawn SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/illinois/tinley-park/">
                        Tinley Park SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/illinois/normal/">
                        Normal SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Wisconsin
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/wisconsin/">
                        Wisconsin SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/wisconsin/madison/">
                        Madison SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/wisconsin/milwaukee/">
                        Milwaukee SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/wisconsin/green-bay/">
                        Green Bay SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/wisconsin/kenosha/">
                        Kenosha SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/wisconsin/waukesha/">
                        Waukesha SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/wisconsin/janesville/">
                        Janesville SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/wisconsin/sheboygan/">
                        Sheboygan SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/wisconsin/racine/">
                        Racine SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/wisconsin/eau-claire/">
                        Eau Claire SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/wisconsin/west-allis/">
                        West Allis SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/wisconsin/wauwatosa/">
                        Wauwatosa SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/wisconsin/appleton/">
                        Appleton SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/wisconsin/oshkosh/">
                        Oshkosh SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/wisconsin/la-crosse/">
                        La Crosse SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Alaska
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/alaska/">
                        Alaska SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/alaska/anchorage/">
                        Anchorage SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/alaska/juneau/">
                        Juneau SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/alaska/fairbanks/">
                        Fairbanks SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Arizona
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/arizona/">
                        Arizona SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/arizona/phoenix/">
                        Phoenix SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/arizona/tucson/">
                        Tucson SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/arizona/mesa/">
                        Mesa SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/arizona/flagstaff/">
                        Flagstaff SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/arizona/chandler/">
                        Chandler SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/arizona/scottsdale/">
                        Scottsdale SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/arizona/surprise/">
                        Surprise SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/arizona/yuma/">
                        Yuma SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/arizona/queen-creek/">
                        Queen Creek SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/arizona/prescott/">
                        Prescott SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/arizona/gilbert/">
                        Gilbert SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/arizona/san-tan-valley/">
                        San Tan Valley SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/arizona/avondale/">
                        Avondale SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/arizona/lake-havasu/">
                        Lake Havasu SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/arizona/kingman/">
                        Kingman SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/arizona/tempe/">
                        Tempe SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/arizona/goodyear/">
                        Goodyear SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/arizona/maricopa/">
                        Maricopa SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/arizona/casa-grande/">
                        Casa Grande SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      California
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/california/">
                        California SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/glendale/">
                        Glendale SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/los-angeles/">
                        Los Angeles SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/san-diego/">
                        San Diego SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/san-jose/">
                        San Jose SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/fresno/">
                        Fresno SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/san-francisco/">
                        San Francisco SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/sacramento/">
                        Sacramento SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/oakland/">
                        Oakland SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/riverside/">
                        Riverside SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/san-bernardino/">
                        San Bernardino SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/huntington-beach/">
                        Huntington Beach SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/lancaster/">
                        Lancaster SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/hayward/">
                        Hayward SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/escondido/">
                        Escondido SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/orange/">
                        Orange SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/thousand-oaks/">
                        Thousand Oaks SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/fairfield/">
                        Fairfield SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/vacaville/">
                        Vacaville SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/santa-barbara/">
                        Santa Barbara SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/upland/">
                        Upland SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/yorba-linda/">
                        Yorba Linda SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/gilroy/">
                        Gilroy SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/paramount/">
                        Paramount SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/rancho-santa-margarita/">
                        Rancho Santa Margarita SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/culver-city/">
                        Culver City SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/bakersfield/">
                        Bakersfield SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/santa-ana/">
                        Santa Ana SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/modesto/">
                        Modesto SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/rancho-cucamonga/">
                        Rancho Cucamonga SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/salinas/">
                        Salinas SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/pomona/">
                        Pomona SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/simi-valley/">
                        Simi Valley SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/citrus-heights/">
                        Citrus Heights SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/napa/">
                        Napa SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/castro-valley/">
                        Castro Valley SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/gardena/">
                        Gardena SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/novato/">
                        Novato SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/la-mirada/">
                        La Mirada SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/san-gabriel/">
                        San Gabriel SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/anaheim/">
                        Anaheim SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/chula-vista/">
                        Chula Vista SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/moreno-valley/">
                        Moreno Valley SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/elk-grove/">
                        Elk Grove SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/oceanside/">
                        Oceanside SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/sunnyvale/">
                        Sunnyvale SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/torrance/">
                        Torrance SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/victorville/">
                        Victorville SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/berkeley/">
                        Berkeley SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/antioch/">
                        Antioch SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/hesperia/">
                        Hesperia SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/merced/">
                        Merced SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/bellflower/">
                        Bellflower SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/lodi/">
                        Lodi SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/santee/">
                        Santee SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/florin/">
                        Florin SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/vineyard/">
                        Vineyard SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/adelanto/">
                        Adelanto SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/irvine/">
                        Irvine SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/santa-clarita/">
                        Santa Clarita SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/fontana/">
                        Fontana SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/santa-rosa/">
                        Santa Rosa SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/palmsdale/">
                        Palmsdale SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/corona/">
                        Corona SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/fullerton/">
                        Fullerton SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/santa-clara/">
                        Santa Clara SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/vista/">
                        Vista SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/whittier/">
                        Whittier SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/alameda/">
                        Alameda SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/madera/">
                        Madera SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/monterey-park/">
                        Monterey Park SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/glendora/">
                        Glendora SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/san-luis-obispo/">
                        San Luis Obispo SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/bell-gardens/">
                        Bell Gardens SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/stockton/">
                        Stockton SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/fremont/">
                        Fremont SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/oxnard/">
                        Oxnard SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/ontario/">
                        Ontario SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/garden-grove/">
                        Garden Grove SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/roseville/">
                        Roseville SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/visalia/">
                        Visalia SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/vallejo/">
                        Vallejo SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/clovis/">
                        Clovis SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/downey/">
                        Downey SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/lake-forest/">
                        Lake Forest SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/chino-hills/">
                        Chino Hills SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/davis/">
                        Davis SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/cupertino/">
                        Cupertino SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/aliso-viejo/">
                        Aliso Viejo SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/los-banos/">
                        Los Banos SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/pacifica/">
                        Pacifica SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/rialto/">
                        Rialto SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/livermore/">
                        Livermore SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/tustin/">
                        Tustin SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/pico-rivera/">
                        Pico Rivera SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/huntington-park/">
                        Huntington Park SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/cerritos/">
                        Cerritos SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/poway/">
                        Poway SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/murrieta/">
                        Murrieta SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/carson/">
                        Carson SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/san-ramon/">
                        San Ramon SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/pittsburg/">
                        Pittsburg SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/petaluma/">
                        Petaluma SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/watsonville/">
                        Watsonville SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/morgan-hill/">
                        Morgan Hill SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/calexico/">
                        Calexico SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/chico/">
                        Chico SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/hawthorne/">
                        Hawthorne SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/perris/">
                        Perris SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/palo-alto/">
                        Palo Alto SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/florence-graham/">
                        Florence-Graham SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/hacienda-heights/">
                        Hacienda Heights SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/antelope/">
                        Antelope SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/brea/">
                        Brea SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/rowland-heights/">
                        Rowland Heights SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/costa-mesa/">
                        Costa Mesa SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/compton/">
                        Compton SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/manteca/">
                        Manteca SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/dublin/">
                        Dublin SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/lynwood/">
                        Lynwood SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/hanford/">
                        Hanford SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/el-dorado-hills/">
                        El Dorado Hills SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/rohnert-park/">
                        Rohnert Park SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/monrovia/">
                        Monrovia SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/temecula/">
                        Temecula SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/arden-arcade/">
                        Arden-Arcade SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/redwood-city/">
                        Redwood City SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/highland/">
                        Highland SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/placentia/">
                        Placentia SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/oakley/">
                        Oakley SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/montclair/">
                        Montclair SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/santa-maria/">
                        Santa Maria SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/tracy/">
                        Tracy SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/newport-beach/">
                        Newport Beach SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/rocklin/">
                        Rocklin SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/laguna-niguel/">
                        Laguna Niguel SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/fountain-valley/">
                        Fountain Valley SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/covina/">
                        Covina SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/campbell/">
                        Campbell SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/ventura/">
                        Ventura SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/redding/">
                        Redding SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/buena-park/">
                        Buena Park SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/redlands/">
                        Redlands SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/san-clemente/">
                        San Clemente SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/arcadia/">
                        Arcadia SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/palm-desert/">
                        Palm Desert SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/lompoc/">
                        Lompoc SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/west-covina/">
                        West Covina SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/chino/">
                        Chino SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/mountain-view/">
                        Mountain View SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/lake-elsinore/">
                        Lake Elsinore SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/porterville/">
                        Porterville SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/cathedral-city/">
                        Cathedral City SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/el-centro/">
                        El Centro SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/el-monte/">
                        El Monte SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/santa-monica/">
                        Santa Monica SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/carmichael/">
                        Carmichael SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/turlock/">
                        Turlock SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/la-habra/">
                        La Habra SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/south-whittier/">
                        South Whittier SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/palm-springs/">
                        Palm Springs SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/burbank/">
                        Burbank SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/mission-viejo/">
                        Mission Viejo SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/redondo-beach/">
                        Redondo Beach SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/santa-cruz/">
                        Santa Cruz SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/san-jacinto/">
                        San Jacinto SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/delano/">
                        Delano SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/san-bruno/">
                        San Bruno SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/el-cajon/">
                        El Cajon SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/south-gate/">
                        South Gate SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/milpitas/">
                        Milpitas SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/camarillo/">
                        Camarillo SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/montebello/">
                        Montebello SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/national-city/">
                        National City SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/azusa/">
                        Azusa SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/danville/">
                        Danville SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/menifee/">
                        Menifee SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/san-leandro/">
                        San Leandro SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/alhambra/">
                        Alhambra SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/baldwin-park/">
                        Baldwin Park SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/encinitas/">
                        Encinitas SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/yucaipa/">
                        Yucaipa SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/rosemead/">
                        Rosemead SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/hollister/">
                        Hollister SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/inglewood/">
                        Inglewood SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/hemet/">
                        Hemet SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/rancho-cordova/">
                        Rancho Cordova SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/walnut-creek/">
                        Walnut Creek SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/woodland/">
                        Woodland SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/diamond-bar/">
                        Diamond Bar SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/north-highlands/">
                        North Highlands SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/altadena/">
                        Altadena SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/san-mateo/">
                        San Mateo SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/indio/">
                        Indio SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/folsom/">
                        Folsom SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/yuba-city/">
                        Yuba City SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/san-rafael/">
                        San Rafael SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/west-sacramento/">
                        West Sacramento SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/cypress/">
                        Cypress SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/rancho-palos-verdes/">
                        Rancho Palos Verdes SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/daly-city/">
                        Daly City SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/pleasanton/">
                        Pleasanton SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/tulare/">
                        Tulare SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/la-mesa/">
                        La Mesa SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/colton/">
                        Colton SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/ceres/">
                        Ceres SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/california/coachella/">
                        Coachella SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Arkansas
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/arkansas/">
                        Arkansas SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/arkansas/little-rock/">
                        Little Rock SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/arkansas/fort-smith/">
                        Fort Smith SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/arkansas/springdale/">
                        Springdale SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/arkansas/conway/">
                        Conway SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/arkansas/jonesboro/">
                        Jonesboro SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/arkansas/bentonville/">
                        Bentonville SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/arkansas/rogers/">
                        Rogers SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      North Carolina
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/north-carolina/">
                        North Carolina SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/north-carolina/fayetteville/">
                        Fayetteville SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/north-carolina/concord/">
                        Concord SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/north-carolina/wilmington/">
                        Wilmington SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/north-carolina/jacksonville/">
                        Jacksonville SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/north-carolina/charlotte/">
                        Charlotte SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/north-carolina/raleigh/">
                        Raleigh SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/north-carolina/greensboro/">
                        Greensboro SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/north-carolina/durham/">
                        Durham SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/north-carolina/cary/">
                        Cary SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/north-carolina/gastonia/">
                        Gastonia SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/north-carolina/huntersville/">
                        Huntersville SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/north-carolina/rocky-mount/">
                        Rocky Mount SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/north-carolina/winston-salem/">
                        Winston-Salem SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/north-carolina/apex/">
                        Apex SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/north-carolina/high-point/">
                        High Point SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/north-carolina/asheville/">
                        Asheville SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/north-carolina/chapel-hill/">
                        Chapel Hill SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      New York
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/new-york/">
                        New York SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-york/long-beach/">
                        Long Beach SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-york/brentwood/">
                        Brentwood SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-york/troy/">
                        Troy SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-york/rochester/">
                        Rochester SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-york/new-york-city/">
                        New York City SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-york/buffalo/">
                        Buffalo SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-york/yonkers/">
                        Yonkers SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-york/syracuse/">
                        Syracuse SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-york/cheektowaga/">
                        Cheektowaga SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-york/utica/">
                        Utica SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-york/white-plains/">
                        White Plains SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-york/irondequoit/">
                        Irondequoit SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-york/west-seneca/">
                        West Seneca SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-york/west-babylon/">
                        West Babylon SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-york/east-meadow/">
                        East Meadow SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-york/mount-vernon/">
                        Mount Vernon SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-york/levittown/">
                        Levittown SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-york/niagara-falls/">
                        Niagara Falls SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-york/freeport/">
                        Freeport SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-york/valley-stream/">
                        Valley Stream SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-york/brighton/">
                        Brighton SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-york/new-rochelle/">
                        New Rochelle SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-york/schenectady/">
                        Schenectady SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-york/hempstead/">
                        Hempstead SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-york/binghamton/">
                        Binghamton SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-york/hicksville/">
                        Hicksville SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-york/coram/">
                        Coram SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-york/kiryas-joel/">
                        Kiryas Joel SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-york/huntington-station/">
                        Huntington Station SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-york/commack/">
                        Commack SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-york/baldwin/">
                        Baldwin SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-york/elmont/">
                        Elmont SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-york/central-islip/">
                        Central Islip SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Texas
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/texas/">
                        Texas SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/pasadena/">
                        Pasadena SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/san-marcos/">
                        San Marcos SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/beaumont/">
                        Beaumont SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/houston/">
                        Houston SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/san-antonio/">
                        San Antonio SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/dallas/">
                        Dallas SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/austin/">
                        Austin SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/fort-worth/">
                        Fort Worth SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/el-paso/">
                        El Paso SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/corpus-christi/">
                        Corpus Christi SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/plano/">
                        Plano SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/frisco/">
                        Frisco SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/waco/">
                        Waco SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/san-angelo/">
                        San Angelo SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/flower-mound/">
                        Flower Mound SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/north-richland-hills/">
                        North Richland Hills SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/euless/">
                        Euless SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/bedford/">
                        Bedford SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/laredo/">
                        Laredo SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/brownsville/">
                        Brownsville SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/carrollton/">
                        Carrollton SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/college-station/">
                        College Station SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/edinburg/">
                        Edinburg SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/pharr/">
                        Pharr SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/victoria/">
                        Victoria SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/port-arthur/">
                        Port Arthur SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/lubbock/">
                        Lubbock SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/round-rock/">
                        Round Rock SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/the-woodlands/">
                        The Woodlands SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/conroe/">
                        Conroe SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/cedar-park/">
                        Cedar Park SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/rowlett/">
                        Rowlett SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/grapevine/">
                        Grapevine SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/irving/">
                        Irving SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/killeen/">
                        Killeen SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/pearland/">
                        Pearland SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/lewisville/">
                        Lewisville SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/bryan/">
                        Bryan SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/temple/">
                        Temple SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/harlingen/">
                        Harlingen SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/desoto/">
                        DeSoto SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/garland/">
                        Garland SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/mcallen/">
                        McAllen SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/abilene/">
                        Abilene SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/tyler/">
                        Tyler SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/new-braunfels/">
                        New Braunfels SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/baytown/">
                        Baytown SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/wylie/">
                        Wylie SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/amarillo/">
                        Amarillo SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/mesquite-city/">
                        Mesquite city SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/odessa/">
                        Odessa SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/league-city/">
                        League City SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/mission/">
                        Mission SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/georgetown/">
                        Georgetown SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/pflugerville/">
                        Pflugerville SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/galveston/">
                        Galveston SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/grand-prairie/">
                        Grand Prairie SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/midland/">
                        Midland SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/richardson/">
                        Richardson SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/wichita-falls/">
                        Wichita Falls SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/longview/">
                        Longview SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/missouri-city/">
                        Missouri City SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/spring/">
                        Spring SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/little-elm/">
                        Little Elm SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/mckinney/">
                        McKinney SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/denton/">
                        Denton SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/sugar-land/">
                        Sugar Land SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/allen/">
                        Allen SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/atascocita/">
                        Atascocita SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/mansfield/">
                        Mansfield SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/leander/">
                        Leander SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/texas/texas-city/">
                        Texas City SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Virginia
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/virginia/">
                        Virginia SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/virginia/richmond/">
                        Richmond SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/virginia/arlington/">
                        Arlington SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/virginia/virginia-beach/">
                        Virginia Beach SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/virginia/norfolk/">
                        Norfolk SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/virginia/chesapeake/">
                        Chesapeake SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/virginia/alexandria/">
                        Alexandria SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/virginia/portsmouth/">
                        Portsmouth SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/virginia/centreville/">
                        Centreville SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/virginia/leesburg/">
                        Leesburg SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/virginia/tuckahoe/">
                        Tuckahoe SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/virginia/hampton/">
                        Hampton SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/virginia/suffolk/">
                        Suffolk SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/virginia/dale-city/">
                        Dale City SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/virginia/newport-news/">
                        Newport News SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/virginia/roanoke/">
                        Roanoke SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/virginia/lynchburg/">
                        Lynchburg SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/virginia/reston/">
                        Reston SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/virginia/charlottesville/">
                        Charlottesville SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/virginia/lake-ridge/">
                        Lake Ridge SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/virginia/harrisonburg/">
                        Harrisonburg SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/virginia/mclean/">
                        McLean SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Connecticut
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/connecticut/">
                        Connecticut SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/connecticut/norwalk/">
                        Norwalk SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/connecticut/bridgeport/">
                        Bridgeport SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/connecticut/new-haven/">
                        New Haven SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/connecticut/stamford/">
                        Stamford SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/connecticut/hartford/">
                        Hartford SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/connecticut/danbury/">
                        Danbury SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/connecticut/waterbury/">
                        Waterbury SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/connecticut/new-britain/">
                        New Britain SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/connecticut/meriden/">
                        Meriden SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/connecticut/milford/">
                        Milford SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/connecticut/west-haven/">
                        West Haven SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      New Mexico
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/new-mexico/">
                        New Mexico SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-mexico/carlsbad/">
                        Carlsbad SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-mexico/roswell/">
                        Roswell SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-mexico/albuquerque/">
                        Albuquerque SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-mexico/las-cruces/">
                        Las Cruces SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-mexico/rio-rancho/">
                        Rio Rancho SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-mexico/santa-fe/">
                        Santa Fe SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-mexico/farmington/">
                        Farmington SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      New Jersey
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/new-jersey/">
                        New Jersey SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-jersey/union-city/">
                        Union City SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-jersey/newark/">
                        Newark SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-jersey/jersey-city/">
                        Jersey City SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-jersey/paterson/">
                        Paterson SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-jersey/elizabeth/">
                        Elizabeth SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-jersey/clifton/">
                        Clifton SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-jersey/passaic/">
                        Passaic SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-jersey/perth-amboy/">
                        Perth Amboy SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-jersey/west-new-york/">
                        West New York SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-jersey/toms-river/">
                        Toms River SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-jersey/bayonne/">
                        Bayonne SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-jersey/camden/">
                        Camden SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-jersey/hoboken/">
                        Hoboken SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-jersey/plainfield/">
                        Plainfield SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-jersey/hackensack/">
                        Hackensack SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-jersey/trenton/">
                        Trenton SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-jersey/east-orange/">
                        East Orange SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-jersey/vineland/">
                        Vineland SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-jersey/new-brunswick/">
                        New Brunswick SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Minnesota
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/minnesota/">
                        Minnesota SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/minnesota/apple-valley/">
                        Apple Valley SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/minnesota/st-cloud/">
                        St. Cloud SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/minnesota/bloomington/">
                        Bloomington SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/minnesota/minneapolis/">
                        Minneapolis SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/minnesota/st-paul/">
                        St. Paul SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/minnesota/plymouth/">
                        Plymouth SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/minnesota/lakeville/">
                        Lakeville SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/minnesota/coon-rapids/">
                        Coon Rapids SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/minnesota/minnetonka/">
                        Minnetonka SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/minnesota/brooklyn-park/">
                        Brooklyn Park SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/minnesota/woodbury/">
                        Woodbury SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/minnesota/maple-grove/">
                        Maple Grove SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/minnesota/burnsville/">
                        Burnsville SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/minnesota/st-louis-park/">
                        St. Louis Park SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/minnesota/duluth/">
                        Duluth SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/minnesota/blaine/">
                        Blaine SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/minnesota/eagan/">
                        Eagan SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/minnesota/eden-prairie/">
                        Eden Prairie SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/minnesota/edina/">
                        Edina SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Nebraska
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/nebraska/">
                        Nebraska SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/nebraska/lincoln/">
                        Lincoln SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/nebraska/omaha/">
                        Omaha SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/nebraska/grand-island/">
                        Grand Island SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Colorado
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/colorado/">
                        Colorado SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/colorado/westminster/">
                        Westminster SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/colorado/denver/">
                        Denver SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/colorado/colorado-springs/">
                        Colorado Springs SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/colorado/fort-collins/">
                        Fort Collins SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/colorado/arvada/">
                        Arvada SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/colorado/greeley/">
                        Greeley SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/colorado/highlands-ranch/">
                        Highlands Ranch SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/colorado/castle-rock/">
                        Castle Rock SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/colorado/commerce-city/">
                        Commerce City SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/colorado/boulder/">
                        Boulder SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/colorado/longmont/">
                        Longmont SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/colorado/broomfield/">
                        Broomfield SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/colorado/parker/">
                        Parker SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/colorado/thornton/">
                        Thornton SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/colorado/pueblo/">
                        Pueblo SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/colorado/centennial/">
                        Centennial SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/colorado/loveland/">
                        Loveland SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/colorado/grand-junction/">
                        Grand Junction SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/colorado/littleton/">
                        Littleton SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Pennsylvania
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/pennsylvania/">
                        Pennsylvania SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/pennsylvania/bristol/">
                        Bristol SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/pennsylvania/philadelphia/">
                        Philadelphia SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/pennsylvania/pittsburgh/">
                        Pittsburgh SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/pennsylvania/allentown/">
                        Allentown SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/pennsylvania/erie/">
                        Erie SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/pennsylvania/scranton/">
                        Scranton SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/pennsylvania/lancaster-city/">
                        Lancaster city SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/pennsylvania/haverford/">
                        Haverford SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/pennsylvania/reading-city/">
                        Reading city SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/pennsylvania/bensalem/">
                        Bensalem SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/pennsylvania/bethlehem-city/">
                        Bethlehem city SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/pennsylvania/harrisburg/">
                        Harrisburg SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/pennsylvania/upper-darby/">
                        Upper Darby SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/pennsylvania/lower-merion/">
                        Lower Merion SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/pennsylvania/abington/">
                        Abington SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/pennsylvania/lower-paxton/">
                        Lower Paxton SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Delaware
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/delaware/">
                        Delaware SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/delaware/dover/">
                        Dover SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Florida
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/florida/">
                        Florida SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/miami/">
                        Miami SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/tampa/">
                        Tampa SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/orlando/">
                        Orlando SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/st-petersburg/">
                        St. Petersburg SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/cape-coral/">
                        Cape Coral SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/fort-lauderdale/">
                        Fort Lauderdale SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/miramar/">
                        Miramar SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/lehigh-acres/">
                        Lehigh Acres SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/lakeland/">
                        Lakeland SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/davie/">
                        Davie SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/alafaya/">
                        Alafaya SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/deerfield-beach/">
                        Deerfield Beach SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/the-villages/">
                        The Villages SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/doral/">
                        Doral SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/daytona-beach/">
                        Daytona Beach SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/weston/">
                        Weston SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/ocala/">
                        Ocala SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/sanford/">
                        Sanford SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/port-charlotte/">
                        Port Charlotte SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/north-miami/">
                        North Miami SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/apopka/">
                        Apopka SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/sarasota/">
                        Sarasota SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/hialeah/">
                        Hialeah SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/tallahassee/">
                        Tallahassee SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/pembroke-pines/">
                        Pembroke Pines SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/coral-springs/">
                        Coral Springs SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/clearwater/">
                        Clearwater SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/riverview/">
                        Riverview SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/boca-raton/">
                        Boca Raton SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/plantation/">
                        Plantation SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/town-n-country/">
                        Town 'n' Country SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/largo/">
                        Largo SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/kendall/">
                        Kendall SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/tamarac/">
                        Tamarac SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/delray-beach/">
                        Delray Beach SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/port-orange/">
                        Port Orange SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/four-corners/">
                        Four Corners SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/westchester/">
                        Westchester SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/fountainebleau/">
                        Fountainebleau SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/bradenton/">
                        Bradenton SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/pensacola/">
                        Pensacola SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/port-st-lucie/">
                        Port St. Lucie SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/hollywood/">
                        Hollywood SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/palm-bay/">
                        Palm Bay SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/brandon/">
                        Brandon SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/pompano-beach/">
                        Pompano Beach SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/sunrise/">
                        Sunrise SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/palm-coast/">
                        Palm Coast SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/melbourne/">
                        Melbourne SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/kissimmee/">
                        Kissimmee SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/north-port/">
                        North Port SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/poinciana/">
                        Poinciana SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/pine-hills/">
                        Pine Hills SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/jupiter/">
                        Jupiter SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/palm-beach-gardens/">
                        Palm Beach Gardens SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/margate/">
                        Margate SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/bonita-springs/">
                        Bonita Springs SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/tamiami/">
                        Tamiami SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/university/">
                        University SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/winter-haven/">
                        Winter Haven SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/gainesville/">
                        Gainesville SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/west-palm-beach/">
                        West Palm Beach SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/spring-hill/">
                        Spring Hill SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/miami-gardens/">
                        Miami Gardens SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/deltona/">
                        Deltona SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/fort-myers/">
                        Fort Myers SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/homestead/">
                        Homestead SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/boynton-beach/">
                        Boynton Beach SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/lauderhill/">
                        Lauderhill SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/wesley-chapel/">
                        Wesley Chapel SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/horizon-west/">
                        Horizon West SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/wellington/">
                        Wellington SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/palm-harbor/">
                        Palm Harbor SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/the-hammocks/">
                        The Hammocks SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/coconut-creek/">
                        Coconut Creek SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/kendale-lakes/">
                        Kendale Lakes SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/florida/pinellas-park/">
                        Pinellas Park SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Georgia
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/georgia/">
                        Georgia SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/georgia/altanta/">
                        Altanta SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/georgia/augusta/">
                        Augusta SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/georgia/savannah/">
                        Savannah SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/georgia/athens/">
                        Athens SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/georgia/johns-creek/">
                        Johns Creek SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/georgia/alpharetta/">
                        Alpharetta SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/georgia/valdosta/">
                        Valdosta SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/georgia/sandy-springs/">
                        Sandy Springs SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/georgia/warner-robins/">
                        Warner Robins SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/georgia/marietta/">
                        Marietta SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/georgia/dunwoody/">
                        Dunwoody SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Ohio
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/ohio/">
                        Ohio SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/ohio/columbus/">
                        Columbus SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/ohio/cleveland/">
                        Cleveland SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/ohio/cincinnati/">
                        Cincinnati SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/ohio/toledo/">
                        Toledo SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/ohio/parma/">
                        Parma SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/ohio/lorain/">
                        Lorain SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/ohio/kettering/">
                        Kettering SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/ohio/akron/">
                        Akron SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/ohio/canton/">
                        Canton SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/ohio/hamilton/">
                        Hamilton SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/ohio/elyria/">
                        Elyria SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/ohio/dayton/">
                        Dayton SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/ohio/youngstown/">
                        Youngstown SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Oregon
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/oregon/">
                        Oregon SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/oregon/albany/">
                        Albany SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/oregon/springfield/">
                        Springfield SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/oregon/portland/">
                        Portland SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/oregon/medford/">
                        Medford SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/oregon/salem/">
                        Salem SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/oregon/eugene/">
                        Eugene SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/oregon/gresham/">
                        Gresham SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/oregon/bend/">
                        Bend SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/oregon/corvallis/">
                        Corvallis SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/oregon/aloha/">
                        Aloha SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/oregon/hillsboro/">
                        Hillsboro SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/oregon/beaverton/">
                        Beaverton SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/oregon/tigard/">
                        Tigard SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Tennessee
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/tennessee/">
                        Tennessee SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/tennessee/smyrna/">
                        Smyrna SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/tennessee/jackson/">
                        Jackson SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/tennessee/nashville/">
                        Nashville SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/tennessee/memphis/">
                        Memphis SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/tennessee/knoxville/">
                        Knoxville SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/tennessee/chattanooga/">
                        Chattanooga SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/tennessee/franklin/">
                        Franklin SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/tennessee/bartlett/">
                        Bartlett SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/tennessee/clarksville/">
                        Clarksville SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/tennessee/hendersonville/">
                        Hendersonville SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/tennessee/collierville/">
                        Collierville SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/tennessee/murfreesboro/">
                        Murfreesboro SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/tennessee/johnson-city/">
                        Johnson City SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/tennessee/kingsport/">
                        Kingsport SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Hawaii
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/hawaii/">
                        Hawaii SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/hawaii/honolulu/">
                        Honolulu SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/hawaii/pearl-city/">
                        Pearl City SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/hawaii/hilo/">
                        Hilo SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/hawaii/waipahu/">
                        Waipahu SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/hawaii/kailua/">
                        Kailua SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/hawaii/kaneohe/">
                        Kaneohe SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Idaho
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/idaho/">
                        Idaho SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/idaho/boise/">
                        Boise SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/idaho/meridian/">
                        Meridian SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/idaho/nampa/">
                        Nampa SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/idaho/idaho-falls/">
                        Idaho Falls SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/idaho/coeur-d-alene/">
                        Coeur d'Alene SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/idaho/caldwell/">
                        Caldwell SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/idaho/twin-falls/">
                        Twin Falls SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/idaho/pocatello/">
                        Pocatello SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Maryland
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/maryland/">
                        Maryland SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/maryland/wheaton/">
                        Wheaton SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/maryland/baltimore/">
                        Baltimore SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/maryland/germantown/">
                        Germantown SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/maryland/waldorf/">
                        Waldorf SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/maryland/ellicott-city/">
                        Ellicott City SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/maryland/bethesda/">
                        Bethesda SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/maryland/towson/">
                        Towson SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/maryland/bowie/">
                        Bowie SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/maryland/silver-spring/">
                        Silver Spring SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/maryland/glen-burnie/">
                        Glen Burnie SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/maryland/dundalk/">
                        Dundalk SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/maryland/severn/">
                        Severn SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/maryland/frederick/">
                        Frederick SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/maryland/gaithersburg/">
                        Gaithersburg SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/maryland/rockville/">
                        Rockville SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/maryland/bel-air-south/">
                        Bel Air South SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/maryland/aspen-hill/">
                        Aspen Hill SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Indiana
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/indiana/">
                        Indiana SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/indiana/indianapolis/">
                        Indianapolis SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/indiana/fort-wayne/">
                        Fort Wayne SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/indiana/evansville/">
                        Evansville SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/indiana/south-bend/">
                        South Bend SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/indiana/hammond/">
                        Hammond SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/indiana/gary/">
                        Gary SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/indiana/kokomo/">
                        Kokomo SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/indiana/elkhart/">
                        Elkhart SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/indiana/jeffersonville/">
                        Jeffersonville SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/indiana/carmel/">
                        Carmel SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/indiana/noblesville/">
                        Noblesville SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/indiana/greenwood/">
                        Greenwood SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/indiana/terre-haute/">
                        Terre Haute SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/indiana/muncie/">
                        Muncie SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/indiana/anderson/">
                        Anderson SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/indiana/mishawaka/">
                        Mishawaka SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Massachusetts
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/massachusetts/">
                        Massachusetts SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/massachusetts/lawrence/">
                        Lawrence SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/massachusetts/boston/">
                        Boston SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/massachusetts/worcester/">
                        Worcester SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/massachusetts/cambridge/">
                        Cambridge SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/massachusetts/quincy/">
                        Quincy SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/massachusetts/fall-river/">
                        Fall River SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/massachusetts/somerville/">
                        Somerville SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/massachusetts/waltham/">
                        Waltham SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/massachusetts/chicopee/">
                        Chicopee SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/massachusetts/lowell/">
                        Lowell SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/massachusetts/lynn/">
                        Lynn SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/massachusetts/haverhill/">
                        Haverhill SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/massachusetts/revere/">
                        Revere SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/massachusetts/taunton/">
                        Taunton SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/massachusetts/peabody/">
                        Peabody SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/massachusetts/brockton/">
                        Brockton SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/massachusetts/new-bedford/">
                        New Bedford SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/massachusetts/newton/">
                        Newton SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/massachusetts/malden/">
                        Malden SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/massachusetts/brookline/">
                        Brookline SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/massachusetts/weymouth-town/">
                        Weymouth Town SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/massachusetts/methuen-town/">
                        Methuen Town SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Louisiana
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/louisiana/">
                        Louisiana SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/louisiana/lafayette/">
                        Lafayette SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/louisiana/new-orleans/">
                        New Orleans SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/louisiana/baton-rouge/">
                        Baton Rouge SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/louisiana/shreveport/">
                        Shreveport SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/louisiana/metairie/">
                        Metairie SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/louisiana/kenner/">
                        Kenner SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/louisiana/bossier-city/">
                        Bossier City SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/louisiana/lake-charles/">
                        Lake Charles SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Iowa
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/iowa/">
                        Iowa SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/iowa/des-moines/">
                        Des Moines SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/iowa/cedar-rapids/">
                        Cedar Rapids SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/iowa/davenport/">
                        Davenport SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/iowa/sioux-city/">
                        Sioux City SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/iowa/ames/">
                        Ames SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/iowa/dubuque/">
                        Dubuque SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/iowa/iowa-city/">
                        Iowa City SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/iowa/waterloo/">
                        Waterloo SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/iowa/ankeny/">
                        Ankeny SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/iowa/council-bluffs/">
                        Council Bluffs SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Kansas
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/kansas/">
                        Kansas SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/kansas/wichita/">
                        Wichita SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/kansas/overland-park/">
                        Overland Park SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/kansas/olathe/">
                        Olathe SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/kansas/shawnee/">
                        Shawnee SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/kansas/topeka/">
                        Topeka SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/kansas/lenexa/">
                        Lenexa SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/kansas/manhattan/">
                        Manhattan SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Missouri
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/missouri/">
                        Missouri SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/missouri/kansas-city/">
                        Kansas City SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/missouri/st-louis/">
                        St Louis SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/missouri/o-fallon/">
                        O'Fallon SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/missouri/blue-springs/">
                        Blue Springs SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/missouri/joplin/">
                        Joplin SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/missouri/independence/">
                        Independence SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/missouri/st-joseph/">
                        St. Joseph SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/missouri/st-peters/">
                        St. Peters SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/missouri/chesterfield/">
                        Chesterfield SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/missouri/lees-summit/">
                        Lee's Summit SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/missouri/st-charles/">
                        St. Charles SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/missouri/florissant/">
                        Florissant SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/missouri/wentzville/">
                        Wentzville SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Kentucky
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/kentucky/">
                        Kentucky SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/kentucky/louisville/">
                        Louisville SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/kentucky/lexington-fayette/">
                        Lexington-Fayette SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/kentucky/bowling-green/">
                        Bowling Green SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/kentucky/owensboro/">
                        Owensboro SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Maine
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/maine/">
                        Maine SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/maine/lewiston/">
                        Lewiston SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/maine/bangor/">
                        Bangor SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      South Carolina
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/south-carolina/">
                        South Carolina SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/south-carolina/columbia/">
                        Columbia SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/south-carolina/greenville/">
                        Greenville SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/south-carolina/mount-pleasant/">
                        Mount Pleasant SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/south-carolina/summerville/">
                        Summerville SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/south-carolina/rock-hill/">
                        Rock Hill SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Michigan
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/michigan/">
                        Michigan SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/michigan/detroit/">
                        Detroit SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/michigan/grand-rapids/">
                        Rapids City SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/michigan/warren/">
                        Warren SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/michigan/sterling-heights/">
                        Sterling Heights SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/michigan/lansing/">
                        Lansing SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/michigan/westland/">
                        Westland SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/michigan/kalamazoo/">
                        Kalamazoo SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/michigan/taylor/">
                        Taylor SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/michigan/royal-oak/">
                        Royal Oak SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/michigan/ann-arbor/">
                        Ann Arbor SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/michigan/livonia/">
                        Livonia SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/michigan/farmington-hills/">
                        Farmington Hills SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/michigan/rochester-hills/">
                        Rochester Hills SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/michigan/novi/">
                        Novi SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/michigan/pontiac/">
                        Pontiac SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/michigan/kentwood/">
                        Kentwood SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/michigan/dearborn/">
                        Dearborn SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/michigan/southfield/">
                        Southfield SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/michigan/flint/">
                        Flint SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/michigan/dearborn-heights/">
                        Dearborn Heights SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/michigan/st-clair-shores/">
                        St. Clair Shores SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/michigan/battle-creek/">
                        Battle Creek SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Wyoming
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/wyoming/">
                        Wyoming SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/wyoming/cheyenne/">
                        Cheyenne SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/wyoming/casper/">
                        Casper SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/wyoming/gillette/">
                        Gillette SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/wyoming/laramie/">
                        Laramie SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Mississippi
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/mississippi/">
                        Mississippi SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/mississippi/gulfport/">
                        Gulfport SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/mississippi/southaven/">
                        Southaven SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/mississippi/biloxi/">
                        Biloxi SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/mississippi/hattiesburg/">
                        Hattiesburg SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/mississippi/olive-branch/">
                        Olive Branch SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Montana
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/montana/">
                        Montana SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/montana/billings/">
                        Billings SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/montana/missoula/">
                        Missoula SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/montana/great-falls/">
                        Great Falls SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/montana/bozeman/">
                        Bozeman SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Nevada
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/nevada/">
                        Nevada SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/nevada/las-vegas/">
                        Las Vegas SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/nevada/henderson/">
                        Henderson SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/nevada/reno/">
                        Reno SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/nevada/enterprise/">
                        Enterprise SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/nevada/paradise/">
                        Paradise SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/nevada/whitney/">
                        Whitney SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/nevada/spring-valley/">
                        Spring Valley SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/nevada/sparks/">
                        Sparks SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/nevada/pahrump/">
                        Pahrump SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/nevada/sunrise-manor/">
                        Sunrise Manor SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/nevada/carson-city/">
                        Carson City SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/nevada/winchester/">
                        Winchester SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      New Hampshire
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/new-hampshire/">
                        New Hampshire SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-hampshire/manchester/">
                        Manchester SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/new-hampshire/nashua/">
                        Nashua SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Vermont
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/vermont/">
                        Vermont SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/vermont/burlington/">
                        Burlington SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/vermont/essex/">
                        Essex SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/vermont/south-burlington/">
                        South Burlington SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      North Dakota
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/north-dakota/">
                        North Dakota SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/north-dakota/fargo/">
                        Fargo SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/north-dakota/bismarck/">
                        Bismarck SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/north-dakota/grand-forks/">
                        Grand Forks SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/north-dakota/minot/">
                        Minot SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Oklahoma
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/oklahoma/">
                        Oklahoma SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/oklahoma/oklahoma-city/">
                        Oklahoma City SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/oklahoma/tulsa/">
                        Tulsa SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/oklahoma/norman/">
                        Norman SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/oklahoma/broken-arrow/">
                        Broken Arrow SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/oklahoma/moore/">
                        Moore SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/oklahoma/enid/">
                        Enid SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/oklahoma/lawton/">
                        Lawton SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/oklahoma/midwest-city/">
                        Midwest City SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/oklahoma/edmond/">
                        Edmond SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/oklahoma/stillwater/">
                        Stillwater SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Utah
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/utah/">
                        Utah SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/utah/millcreek/">
                        Millcreek SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/utah/salt-lake-city/">
                        Salt Lake City SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/utah/west-valley-city/">
                        West Valley City SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/utah/provo/">
                        Provo SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/utah/west-jordan/">
                        West Jordan SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/utah/st-george/">
                        St. George SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/utah/south-jordan/">
                        South Jordan SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/utah/taylorsville/">
                        Taylorsville SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/utah/draper/">
                        Draper SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/utah/orem/">
                        Orem SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/utah/ogden/">
                        Ogden SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/utah/lehi/">
                        Lehi SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/utah/logan/">
                        Logan SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/utah/sandy/">
                        Sandy SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/utah/layton/">
                        Layton SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/utah/murray/">
                        Murray SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Rhode Island
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/rhode-island/">
                        Rhode Island SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/rhode-island/providence/">
                        Providence SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/rhode-island/cranston/">
                        Cranston SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/rhode-island/warwick/">
                        Warwick SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/rhode-island/pawtucket/">
                        Pawtucket SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      West Virginia
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/west-virginia/">
                        West Virginia SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/west-virginia/charleston/">
                        Charleston SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/west-virginia/huntington/">
                        Huntington SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/west-virginia/morgantown/">
                        Morgantown SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      South Dakota
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/south-dakota/">
                        South Dakota SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/south-dakota/sioux-falls/">
                        Sioux Falls SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/south-dakota/rapid-city/">
                        Rapid City SEO Agency
                      </a>
                      <a href="https://kingkong.co/seo-agency/south-dakota/aberdeen/">
                        Aberdeen SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Washington DC
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/seo-agency/washington-dc/">
                        Washington DC SEO Agency
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="footer-seo"
              data-seo-name="fb"
              style={{ height: "0px" }}
            >
              <div class="row justify-content-left">
                <div class="col-6 col-lg-3 footer-seo__col"></div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Alabama
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/alabama/">
                        Alabama Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/alabama/birmingham/">
                        Birmingham Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/alabama/montgomery/">
                        Montgomery Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/alabama/huntsville/">
                        Huntsville Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Alaska
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/alaska/">
                        Alaska Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/alaska/anchorage/">
                        Anchorage Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/alaska/juneau/">
                        Juneau Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/alaska/fairbanks/">
                        Fairbanks Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Arizona
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/arizona/">
                        Arizona Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/arizona/phoenix/">
                        Phoenix Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/arizona/tucson/">
                        Tucson Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/arizona/mesa/">
                        Mesa Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/arizona/flagstaff/">
                        Flagstaff Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Arkansas
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/arkansas/">
                        Arkansas Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/arkansas/little-rock/">
                        Little Rock Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/arkansas/fort-smith/">
                        Fort Smith Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/arkansas/fayetteville/">
                        Fayetteville Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      California
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/california/">
                        California Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/california/los-angeles/">
                        Los Angeles Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/california/san-diego/">
                        San Diego Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/california/san-jose/">
                        San Jose Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/california/fresno/">
                        Fresno Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/california/san-francisco/">
                        San Francisco Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/california/sacramento/">
                        Sacramento Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/california/oakland/">
                        Oakland Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Colorado
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/colorado/">
                        Colorado Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/colorado/denver/">
                        Denver Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/colorado/colorado-springs/">
                        Colorado Springs Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Illinois
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/illinois/">
                        Illinois Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/illinois/aurora/">
                        Aurora Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/illinois/chicago/">
                        Chicago Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/illinois/joliet/">
                        Joliet Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Connecticut
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/connecticut/">
                        Connecticut Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/connecticut/bridgeport/">
                        Bridgeport Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/connecticut/new-haven/">
                        New Haven Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/connecticut/stamford/">
                        Stamford Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Delaware
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/delaware/">
                        Delaware Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/delaware/wilmington/">
                        Wilmington Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/delaware/dover/">
                        Dover Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      New Jersey
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/new-jersey/">
                        New Jersey Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/new-jersey/newark/">
                        Newark Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/new-jersey/jersey-city/">
                        Jersey City Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/new-jersey/paterson/">
                        Paterson Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Florida
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/florida/">
                        Florida Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/florida/jacksonville/">
                        Jacksonville Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/florida/miami/">
                        Miami Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/florida/tampa/">
                        Tampa Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/florida/orlando/">
                        Orlando Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Georgia
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/georgia/">
                        Georgia Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/georgia/altanta/">
                        Altanta Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/georgia/augusta/">
                        Augusta Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Ohio
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/ohio/">
                        Ohio Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/ohio/columbus/">
                        Columbus Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/ohio/cleveland/">
                        Cleveland Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/ohio/cincinnati/">
                        Cincinnati Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Hawaii
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/hawaii/">
                        Hawaii Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/hawaii/honolulu/">
                        Honolulu Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/hawaii/pearl-city/">
                        Pearl City Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/hawaii/hilo/">
                        Hilo Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Idaho
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/idaho/">
                        Idaho Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/idaho/boise/">
                        Boise Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/idaho/meridian/">
                        Meridian Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/idaho/nampa/">
                        Nampa Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Indiana
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/indiana/">
                        Indiana Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/indiana/indianapolis/">
                        Indianapolis Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/indiana/fort-wayne/">
                        Fort Wayne Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/indiana/evansville/">
                        Evansville Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Iowa
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/iowa/">
                        Iowa Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/iowa/des-moines/">
                        Des Moines Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/iowa/cedar-rapids/">
                        Cedar Rapids Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/iowa/davenport/">
                        Davenport Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Kansas
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/kansas/">
                        Kansas Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/kansas/wichita/">
                        Wichita Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/kansas/overland-park/">
                        Overland Park Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Missouri
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/missouri/">
                        Missouri Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/missouri/kansas-city/">
                        Kansas City Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/missouri/springfield/">
                        Springfield Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/missouri/st-louis/">
                        St Louis Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Kentucky
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/kentucky/">
                        Kentucky Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/kentucky/louisville/">
                        Louisville Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/kentucky/lexington-fayette/">
                        Lexington-Fayette Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/kentucky/bowling-green/">
                        Bowling Green Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Louisiana
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/louisiana/">
                        Louisiana Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/louisiana/new-orleans/">
                        New Orleans Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/louisiana/baton-rouge/">
                        Baton Rouge Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/louisiana/shreveport/">
                        Shreveport Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Maine
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/maine/">
                        Maine Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/maine/lewiston/">
                        Lewiston Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/maine/bangor/">
                        Bangor Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Oregon
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/oregon/">
                        Oregon Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/oregon/portland/">
                        Portland Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/oregon/salem/">
                        Salem Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/oregon/eugene/">
                        Eugene Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Maryland
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/maryland/">
                        Maryland Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/maryland/baltimore/">
                        Baltimore Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/maryland/germantown/">
                        Germantown Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      South Carolina
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/south-carolina/">
                        South Carolina Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/south-carolina/columbia/">
                        Columbia Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/south-carolina/mount-pleasant/">
                        Mount Pleasant Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Massachusetts
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/massachusetts/">
                        Massachusetts Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/massachusetts/boston/">
                        Boston Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/massachusetts/worcester/">
                        Worcester Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Michigan
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/michigan/">
                        Michigan Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/michigan/detroit/">
                        Detroit Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/michigan/rapids-city/">
                        Rapids City Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/michigan/warren-city/">
                        Warren City Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Minnesota
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/minnesota/">
                        Minnesota Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/minnesota/minneapolis/">
                        Minneapolis Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/minnesota/st-paul/">
                        St. Paul Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/minnesota/rochester/">
                        Rochester Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Mississippi
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/mississippi/">
                        Mississippi Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/mississippi/jackson/">
                        Jackson Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/mississippi/gulfport/">
                        Gulfport Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/mississippi/southaven/">
                        Southaven Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Montana
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/montana/">
                        Montana Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/montana/billings/">
                        Billings Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/montana/missoula/">
                        Missoula Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/montana/great-falls/">
                        Great Falls Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Nebraska
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/nebraska/">
                        Nebraska Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/nebraska/omaha/">
                        Omaha Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/nebraska/lincoln/">
                        Lincoln Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/nebraska/bellevue/">
                        Bellevue Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Nevada
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/nevada/">
                        Nevada Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/nevada/las-vegas/">
                        Las Vegas Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/nevada/henderson/">
                        Henderson Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/nevada/reno/">
                        Reno Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      New Hampshire
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/new-hampshire/">
                        New Hampshire Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/new-hampshire/manchester/">
                        Manchester Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/new-hampshire/nashua/">
                        Nashua Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/new-hampshire/concord/">
                        Concord Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      New Mexico
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/new-mexico/">
                        New Mexico Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/new-mexico/albuquerque/">
                        Albuquerque Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/new-mexico/las-cruces/">
                        Las Cruces Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/new-mexico/rio-rancho/">
                        Rio Rancho Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      New York
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/new-york/">
                        New York Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/new-york/new-york-city/">
                        New York City Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/new-york/buffalo/">
                        Buffalo Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/new-york/yonkers/">
                        Yonkers Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/new-york/syracuse/">
                        Syracuse Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      North Carolina
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/north-carolina/">
                        North Carolina Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/north-carolina/charlotte/">
                        Charlotte Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/north-carolina/raleigh/">
                        Raleigh Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/north-carolina/greensboro/">
                        Greensboro Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      North Dakota
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/north-dakota/">
                        North Dakota Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/north-dakota/fargo/">
                        Fargo Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/north-dakota/bismark/">
                        Bismark Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/north-dakota/grand-forks/">
                        Grand Forks Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Oklahoma
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/oklahoma/">
                        Oklahoma Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/oklahoma/oklahoma-city/">
                        Oklahoma City Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/oklahoma/tulsa/">
                        Tulsa Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/oklahoma/norman/">
                        Norman Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Pennsylvania
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/pennsylvania/">
                        Pennsylvania Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/pennsylvania/philadelphia/">
                        Philadelphia Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/pennsylvania/pittsburgh/">
                        Pittsburgh Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/pennsylvania/allentown/">
                        Allentown Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Rhode Island
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/rhode-island/">
                        Rhode Island Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/rhode-island/providence/">
                        Providence Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/rhode-island/cranston/">
                        Cranston Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/rhode-island/warwick/">
                        Warwick Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      West Virginia
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/west-virginia/">
                        West Virginia Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/west-virginia/charleston/">
                        Charleston Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/west-virginia/huntington/">
                        Huntington Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/west-virginia/morgantown/">
                        Morgantown Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      South Dakota
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/south-dakota/">
                        South Dakota Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/south-dakota/sioux-falls/">
                        Sioux Falls Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/south-dakota/rapid-city/">
                        Rapid City Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/south-dakota/aberdeen/">
                        Aberdeen Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Tennessee
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/tennessee/">
                        Tennessee Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/tennessee/nashville/">
                        Nashville Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/tennessee/memphis/">
                        Memphis Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/tennessee/knoxville/">
                        Knoxville Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Texas
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/texas/">
                        Texas Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/texas/houston/">
                        Houston Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/texas/san-antonio/">
                        San Antonio Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/texas/dallas/">
                        Dallas Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/texas/austin/">
                        Austin Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/texas/fort-worth/">
                        Fort Worth Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/texas/el-paso/">
                        El Paso Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/texas/arlington/">
                        Arlington Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/texas/corpus-christi/">
                        Corpus Christi Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Utah
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/utah/">
                        Utah Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/utah/salt-lake-city/">
                        Salt Lake City Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/utah/west-valley-city/">
                        West Valley City Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/utah/provo/">
                        Provo Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Vermont
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/vermont/">
                        Vermont Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/vermont/burlington/">
                        Burlington Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/vermont/essex/">
                        Essex Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/vermont/south-burlington/">
                        South Burlington Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Virginia
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/virginia/">
                        Virginia Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/virginia/virginia-beach/">
                        Virginia Beach Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/virginia/norfolk/">
                        Norfolk Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/virginia/chesapeake/">
                        Chesapeake Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Washington
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/washington/">
                        Washington Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/washington/seattle/">
                        Seattle Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/washington/spokane/">
                        Spokane Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/washington/tacoma/">
                        Tacoma Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Wisconsin
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/wisconsin/">
                        Wisconsin Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/wisconsin/milwaukee/">
                        Milwaukee Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/wisconsin/madison/">
                        Madison Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/wisconsin/green-bay/">
                        Green Bay Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Wyoming
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/wyoming/">
                        Wyoming Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/wyoming/cheyenne/">
                        Cheyenne Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/wyoming/casper/">
                        Casper Social Media <br />
                        Marketing Agency
                      </a>
                      <a href="https://kingkong.co/facebook-advertising-agency/wyoming/gillette/">
                        Gillette Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Washington DC
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/facebook-advertising-agency/washington-dc/">
                        Washington DC Social Media <br />
                        Marketing Agency
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="footer-seo"
              data-seo-name="ppc"
              style={{ height: "0px" }}
            >
              <div class="row justify-content-left">
                <div class="col-6 col-lg-3 footer-seo__col"></div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Alabama
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/alabama/">
                        Alabama Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/alabama/birmingham/">
                        Birmingham Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/alabama/montgomery/">
                        Montgomery Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/alabama/huntsville/">
                        Huntsville Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Alaska
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/alaska/">
                        Alaska Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/alaska/anchorage/">
                        Anchorage Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/alaska/juneau/">
                        Juneau Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/alaska/fairbanks/">
                        Fairbanks Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Arizona
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/arizona/">
                        Arizona Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/arizona/phoenix/">
                        Phoenix Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/arizona/tucson/">
                        Tucson Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/arizona/mesa/">
                        Mesa Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/arizona/flagstaff/">
                        Flagstaff Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Arkansas
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/arkansas/">
                        Arkansas Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/arkansas/little-rock/">
                        Little Rock Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/arkansas/fort-smith/">
                        Fort Smith Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/arkansas/fayetteville/">
                        Fayetteville Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      California
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/california/">
                        California Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/california/los-angeles/">
                        Los Angeles Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/california/san-diego/">
                        San Diego Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/california/san-jose/">
                        San Jose Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/california/fresno/">
                        Fresno Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/california/san-francisco/">
                        San Francisco Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/california/sacramento/">
                        Sacramento Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/california/oakland/">
                        Oakland Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Colorado
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/colorado/">
                        Colorado Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/colorado/denver/">
                        Denver Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/colorado/colorado-springs/">
                        Colorado Springs Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Illinois
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/illinois/">
                        Illinois Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/illinois/aurora/">
                        Aurora Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/illinois/chicago/">
                        Chicago Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/illinois/joliet/">
                        Joliet Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Connecticut
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/connecticut/">
                        Connecticut Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/connecticut/bridgeport/">
                        Bridgeport Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/connecticut/new-haven/">
                        New Haven Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/connecticut/stamford/">
                        Stamford Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Delaware
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/delaware/">
                        Delaware Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/delaware/wilmington/">
                        Wilmington Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/delaware/dover/">
                        Dover Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      New Jersey
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/new-jersey/">
                        New Jersey Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/new-jersey/newark/">
                        Newark Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/new-jersey/jersey-city/">
                        Jersey City Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/new-jersey/paterson/">
                        Paterson Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Florida
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/florida/">
                        Florida Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/florida/jacksonville/">
                        Jacksonville Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/florida/miami/">
                        Miami Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/florida/tampa/">
                        Tampa Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/florida/orlando/">
                        Orlando Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Georgia
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/georgia/">
                        Georgia Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/georgia/altanta/">
                        Altanta Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/georgia/augusta/">
                        Augusta Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Ohio
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/ohio/">
                        Ohio Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/ohio/columbus/">
                        Columbus Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/ohio/cleveland/">
                        Cleveland Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/ohio/cincinnati/">
                        Cincinnati Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Hawaii
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/hawaii/">
                        Hawaii Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/hawaii/honolulu/">
                        Honolulu Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/hawaii/pearl-city/">
                        Pearl City Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/hawaii/hilo/">
                        Hilo Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Idaho
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/idaho/">
                        Idaho Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/idaho/boise/">
                        Boise Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/idaho/meridian/">
                        Meridian Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/idaho/nampa/">
                        Nampa Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Indiana
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/indiana/">
                        Indiana Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/indiana/indianapolis/">
                        Indianapolis Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/indiana/fort-wayne/">
                        Fort Wayne Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/indiana/evansville/">
                        Evansville Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Iowa
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/iowa/">
                        Iowa Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/iowa/des-moines/">
                        Des Moines Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/iowa/cedar-rapids/">
                        Cedar Rapids Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/iowa/davenport/">
                        Davenport Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Kansas
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/kansas/">
                        Kansas Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/kansas/wichita/">
                        Wichita Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/kansas/overland-park/">
                        Overland Park Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Missouri
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/missouri/">
                        Missouri Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/missouri/kansas-city/">
                        Kansas City Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/missouri/springfield/">
                        Springfield Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/missouri/st-louis/">
                        St Louis Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Kentucky
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/kentucky/">
                        Kentucky Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/kentucky/louisville/">
                        Louisville Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/kentucky/lexington-fayette/">
                        Lexington-Fayette Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/kentucky/bowling-green/">
                        Bowling Green Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Louisiana
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/louisiana/">
                        Louisiana Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/louisiana/new-orleans/">
                        New Orleans Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/louisiana/baton-rouge/">
                        Baton Rouge Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/louisiana/shreveport/">
                        Shreveport Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Maine
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/maine/">
                        Maine Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/maine/lewiston/">
                        Lewiston Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/maine/bangor/">
                        Bangor Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Oregon
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/oregon/">
                        Oregon Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/oregon/portland/">
                        Portland Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/oregon/salem/">
                        Salem Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/oregon/eugene/">
                        Eugene Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Maryland
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/maryland/">
                        Maryland Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/maryland/baltimore/">
                        Baltimore Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/maryland/germantown/">
                        Germantown Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      South Carolina
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/south-carolina/">
                        South Carolina Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/south-carolina/columbia/">
                        Columbia Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/south-carolina/mount-pleasant/">
                        Mount Pleasant Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Massachusetts
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/massachusetts/">
                        Massachusetts Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/massachusetts/boston/">
                        Boston Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/massachusetts/worcester/">
                        Worcester Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Michigan
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/michigan/">
                        Michigan Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/michigan/detroit/">
                        Detroit Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/michigan/rapids-city/">
                        Rapids City Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/michigan/warren-city/">
                        Warren City Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Minnesota
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/minnesota/">
                        Minnesota Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/minnesota/minneapolis/">
                        Minneapolis Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/minnesota/st-paul/">
                        St. Paul Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/minnesota/rochester/">
                        Rochester Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Mississippi
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/mississippi/">
                        Mississippi Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/mississippi/jackson/">
                        Jackson Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/mississippi/gulfport/">
                        Gulfport Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/mississippi/southaven/">
                        Southaven Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Montana
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/montana/">
                        Montana Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/montana/billings/">
                        Billings Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/montana/missoula/">
                        Missoula Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/montana/great-falls/">
                        Great Falls Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Nebraska
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/nebraska/">
                        Nebraska Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/nebraska/omaha/">
                        Omaha Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/nebraska/lincoln/">
                        Lincoln Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/nebraska/bellevue/">
                        Bellevue Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Nevada
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/nevada/">
                        Nevada Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/nevada/las-vegas/">
                        Las Vegas Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/nevada/henderson/">
                        Henderson Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/nevada/reno/">
                        Reno Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      New Hampshire
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/new-hampshire/">
                        New Hampshire Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/new-hampshire/manchester/">
                        Manchester Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/new-hampshire/nashua/">
                        Nashua Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/new-hampshire/concord/">
                        Concord Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      New Mexico
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/new-mexico/">
                        New Mexico Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/new-mexico/albuquerque/">
                        Albuquerque Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/new-mexico/las-cruces/">
                        Las Cruces Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/new-mexico/rio-rancho/">
                        Rio Rancho Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      New York
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/new-york/">
                        New York Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/new-york/new-york-city/">
                        New York City Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/new-york/buffalo/">
                        Buffalo Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/new-york/yonkers/">
                        Yonkers Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/new-york/syracuse/">
                        Syracuse Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      North Carolina
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/north-carolina/">
                        North Carolina Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/north-carolina/charlotte/">
                        Charlotte Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/north-carolina/raleigh/">
                        Raleigh Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/north-carolina/greensboro/">
                        Greensboro Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      North Dakota
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/north-dakota/">
                        North Dakota Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/north-dakota/fargo/">
                        Fargo Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/north-dakota/bismark/">
                        Bismark Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/north-dakota/grand-forks/">
                        Grand Forks Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Oklahoma
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/oklahoma/">
                        Oklahoma Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/oklahoma/oklahoma-city/">
                        Oklahoma City Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/oklahoma/tulsa/">
                        Tulsa Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/oklahoma/norman/">
                        Norman Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Pennsylvania
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/pennsylvania/">
                        Pennsylvania Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/pennsylvania/philadelphia/">
                        Philadelphia Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/pennsylvania/pittsburgh/">
                        Pittsburgh Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/pennsylvania/allentown/">
                        Allentown Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Rhode Island
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/rhode-island/">
                        Rhode Island Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/rhode-island/providence/">
                        Providence Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/rhode-island/cranston/">
                        Cranston Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/rhode-island/warwick/">
                        Warwick Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      West Virginia
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/west-virginia/">
                        West Virginia Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/west-virginia/charleston/">
                        Charleston Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/west-virginia/huntington/">
                        Huntington Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/west-virginia/morgantown/">
                        Morgantown Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      South Dakota
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/south-dakota/">
                        South Dakota Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/south-dakota/sioux-falls/">
                        Sioux Falls Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/south-dakota/rapid-city/">
                        Rapid City Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/south-dakota/aberdeen/">
                        Aberdeen Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Tennessee
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/tennessee/">
                        Tennessee Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/tennessee/nashville/">
                        Nashville Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/tennessee/memphis/">
                        Memphis Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/tennessee/knoxville/">
                        Knoxville Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Texas
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/texas/">
                        Texas Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/texas/houston/">
                        Houston Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/texas/san-antonio/">
                        San Antonio Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/texas/dallas/">
                        Dallas Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/texas/austin/">
                        Austin Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/texas/fort-worth/">
                        Fort Worth Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/texas/el-paso/">
                        El Paso Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/texas/arlington/">
                        Arlington Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/texas/corpus-christi/">
                        Corpus Christi Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Utah
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/utah/">
                        Utah Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/utah/salt-lake-city/">
                        Salt Lake City Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/utah/west-valley-city/">
                        West Valley City Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/utah/provo/">
                        Provo Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Vermont
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/vermont/">
                        Vermont Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/vermont/burlington/">
                        Burlington Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/vermont/essex/">
                        Essex Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/vermont/south-burlington/">
                        South Burlington Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Virginia
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/virginia/">
                        Virginia Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/virginia/virginia-beach/">
                        Virginia Beach Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/virginia/norfolk/">
                        Norfolk Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/virginia/chesapeake/">
                        Chesapeake Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Washington
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/washington/">
                        Washington Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/washington/seattle/">
                        Seattle Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/washington/spokane/">
                        Spokane Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/washington/tacoma/">
                        Tacoma Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Wisconsin
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/wisconsin/">
                        Wisconsin Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/wisconsin/milwaukee/">
                        Milwaukee Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/wisconsin/madison/">
                        Madison Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/wisconsin/green-bay/">
                        Green Bay Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Wyoming
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/wyoming/">
                        Wyoming Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/wyoming/cheyenne/">
                        Cheyenne Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/wyoming/casper/">
                        Casper Google Ads &amp; <br />
                        PPC Agency
                      </a>
                      <a href="https://kingkong.co/ppc-management-agency/wyoming/gillette/">
                        Gillette Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-3 footer-seo__col">
                  <div class="footer-seo__state">
                    <div class="seo-trigger-child">
                      Washington DC
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline
                          fill="none"
                          stroke="#FFF"
                          stroke-width="1.5"
                          points="16 7 10 13 4 7"
                        ></polyline>
                      </svg>
                    </div>
                    <div class="seo-section-state">
                      <a href="https://kingkong.co/ppc-management-agency/washington-dc/">
                        Washington DC Google Ads &amp; <br />
                        PPC Agency
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="footer-bottom text-center">
              <div class="copyright">
                <div class="kk-partners">
                  <a
                    href="https://www.google.com/partners/agency?id=4299618094"
                    target="_blank"
                  >
                    <img
                      src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                      data-lazy-src="https://www.gstatic.com/partners/badge/images/2022/PremierBadgeClickable.svg"
                    />
                    <noscript>
                      <img src="https://www.gstatic.com/partners/badge/images/2022/PremierBadgeClickable.svg" />
                    </noscript>
                  </a>
                  <img
                    src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20362%20102'%3E%3C/svg%3E"
                    alt=""
                    height="102"
                    width="362"
                    data-lazy-src="https://kingkong.co/wp-content/themes/kingkong/assets/prod/images/footer-badges-keap-klaviyo-meta.png"
                  />
                  <noscript>
                    <img
                      src="https://kingkong.co/wp-content/themes/kingkong/assets/prod/images/footer-badges-keap-klaviyo-meta.png"
                      alt=""
                      height="102"
                      width="362"
                    />
                  </noscript>
                </div>
              </div>
            </div>
          </div>
          <div class="copyright-line text-center">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  Copyright © King Kong 2024. All Rights Reserved.
                  <br class="d-none d-md-block" />
                  Protected by all the possible laws. If you tryna copy we gonna
                  come after your family. Liam Neeson style.
                  <br class="d-none d-md-block" />
                  <a href="/terms-of-use/">Terms Of Use</a> |
                  <a href="/privacy-policy/">Privacy Policy</a> |
                  <a href="/earnings-disclaimer/">Earnings Disclaimer</a> |
                  Enjoy the rest of your Sunday!
                </div>
              </div>
            </div>
          </div>
        </footer>

        <div class="wistia-popup">
          <div class="wistia-popup__inner">
            <button
              class="wistia-popup__close"
              alt="Close"
              role="button"
              tabindex="0"
              aria-label="Close"
            >
              <img
                alt="Click to close video"
                src="data:image/gif;base64,R0lGODlhRABEAIABAP///////yH5BAEAAAEALAAAAABEAEQAAAKVjI+py+0Po5y02oszBPxyoGFfR41gWJlnpKJWu5muJzvw/NbLjefjruvRfgiecPg5GI/IzpLZfEKjyelMtbKisFoXltQVfcHhkkxaZtzQ6WIwwG4/42E03Rq/M+/6Xr9/RTTxVkc2aNiWqLjI2Oj4CBkpOUlZaXmJmam5ydnp+QkaKjpKWmp6ipqqusra6voKGyvbUwAAOw=="
                data-lazy-src="data:image/gif;base64,R0lGODlhRABEAIABAP///////yH5BAEAAAEALAAAAABEAEQAAAKVjI+py+0Po5y02oszBPxyoGFfR41gWJlnpKJWu5muJzvw/NbLjefjruvRfgiecPg5GI/IzpLZfEKjyelMtbKisFoXltQVfcHhkkxaZtzQ6WIwwG4/42E03Rq/M+/6Xr9/RTTxVkc2aNiWqLjI2Oj4CBkpOUlZaXmJmam5ydnp+QkaKjpKWmp6ipqqusra6voKGyvbUwAAOw=="
                data-ll-status="loaded"
                class="entered lazyloaded"
              />
              <noscript>
                <img
                  alt="Click to close video"
                  src="data:image/gif;base64,R0lGODlhRABEAIABAP///////yH5BAEAAAEALAAAAABEAEQAAAKVjI+py+0Po5y02oszBPxyoGFfR41gWJlnpKJWu5muJzvw/NbLjefjruvRfgiecPg5GI/IzpLZfEKjyelMtbKisFoXltQVfcHhkkxaZtzQ6WIwwG4/42E03Rq/M+/6Xr9/RTTxVkc2aNiWqLjI2Oj4CBkpOUlZaXmJmam5ydnp+QkaKjpKWmp6ipqqusra6voKGyvbUwAAOw=="
                />
              </noscript>
            </button>
            <div class="wistia-popup__embed">
              <div class="wistia-popup__video"></div>
              <div class="wistia-popup__preloader">
                <svg
                  style={{
                    margin: "auto",
                    background: "rgba(0, 0, 0, 0) none repeat scroll 0% 0%",
                    display: "block",
                    shapeRendering: "auto",
                  }}
                  width="200px"
                  height="200px"
                  viewBox="0 0 100 100"
                  preserveAspectRatio="xMidYMid"
                >
                  <circle
                    cx="50"
                    cy="50"
                    fill="none"
                    stroke="#ffffff"
                    stroke-width="10"
                    r="35"
                    stroke-dasharray="164.93361431346415 56.97787143782138"
                  >
                    <animateTransform
                      attributeName="transform"
                      type="rotate"
                      repeatCount="indefinite"
                      dur="1s"
                      values="0 50 50;360 50 50"
                      keyTimes="0;1"
                    ></animateTransform>
                  </circle>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div class="js-modal siteswitcher" data-js-modal-id="autosModal">
          <div class="modal">
            <div class="js-close close-x">
              <svg viewBox="0 0 329.269 329" xmlns="http://www.w3.org/2000/svg">
                <path d="M194.8 164.77L323.013 36.555c8.343-8.34 8.343-21.825 0-30.164-8.34-8.34-21.825-8.34-30.164 0L164.633 134.605 36.422 6.391c-8.344-8.34-21.824-8.34-30.164 0-8.344 8.34-8.344 21.824 0 30.164l128.21 128.215L6.259 292.984c-8.344 8.34-8.344 21.825 0 30.164a21.266 21.266 0 0 0 15.082 6.25c5.46 0 10.922-2.09 15.082-6.25l128.21-128.214 128.216 128.214a21.273 21.273 0 0 0 15.082 6.25c5.46 0 10.922-2.09 15.082-6.25 8.343-8.34 8.343-21.824 0-30.164zm0 0"></path>
              </svg>
            </div>
            <div class="content">
              <p class="h3">Hey!</p>
              <p class="mb-0" id="sw-suggestiontext">
                It looks like you're browsing in{" "}
                <span class="your-country"></span>. Would you like to switch
                over to the
                <span class="suggested-country-code"></span> website?
              </p>
              <a
                href="https://kingkong.co/agency/"
                class="siteswitcher__autobutton"
              >
                <img
                  src="https://kingkong.co/wp-content/themes/kingkong/assets/prod/images/flag-united-states.svg"
                  width="35"
                  height="18"
                  alt="Flag of United States"
                  onerror="this.onerror=null; this.src='https://kingkong.co/wp-content/themes/kingkong/assets/prod/images/flag-generic.svg'"
                />
                Go to <span class="suggested-country"></span>
              </a>
              <button
                class="siteswitcher__cancel"
                data-js-modal-trigger="autosModal"
              >
                No thanks, stay here
              </button>
            </div>
          </div>
        </div>
      </body>
    </>
  );
};

export default Banner;
