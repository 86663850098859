import "./MultiStep.css"; // Import CSS file for styling
import React, { useState, useRef } from "react";
import emailjs from "emailjs-com";
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import axios from "axios";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAzT9fhRjphD5zVUU0ZnvJTTSW7me-F_uw",
  authDomain: "talentiverse.firebaseapp.com",
  projectId: "talentiverse",
  storageBucket: "talentiverse.appspot.com",
  messagingSenderId: "941705645389",
  appId: "1:941705645389:web:df80291c8e673d470f3b04",
  measurementId: "G-RHYFQGL8Y8",
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const storage = getStorage(app);

// const analytics = getAnalytics(app);

// Initialize Firebase
const MultiStep = () => {
  const [step, setStep] = useState(1);
  const formRef = useRef();
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    jobtitle: "",
    phone: "",
    briefsummary: "",
    others: "",
    cv: null, // Updated to null for Cloudinary
    cvUrl: "", // Store the URL of the uploaded file
  });
  const [modalVisible, setModalVisible] = useState(false);

  const handleNext = (e) => {
    e.preventDefault();
    if (step < 7) {
      setStep(step + 1);
    } else {
      handleSubmit(e);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setFormData({
        ...formData,
        [name]: files[0], // Update file field with uploaded file
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     // Upload file to Cloudinary
  //     const fileData = new FormData();
  //     fileData.append("file", formData.cv);
  //     fileData.append("upload_preset", "upload"); // Replace with your upload preset name

  //     const response = await fetch(
  //       "https://api.cloudinary.com/v1_1/dftygokow/raw/upload",
  //       {
  //         method: "POST",
  //         body: fileData,
  //       }
  //     );

  //     if (response.ok) {
  //       const cloudinaryResponse = await response.json();
  //       const uploadedFileUrl = cloudinaryResponse.secure_url;
  //       console.log("Uploaded file URL:", uploadedFileUrl);

  //       // Prepare data for EmailJS, excluding the file
  //       const formDataToSendEmail = {
  //         email: formData.email,
  //         name: formData.name,
  //         jobtitle: formData.jobtitle,
  //         phone: formData.phone,
  //         briefsummary: formData.briefsummary,
  //         others: formData.others,
  //         cv_url: uploadedFileUrl, // Append the file URL
  //       };

  //       console.log("FormDataToSend:", formDataToSendEmail); // Log the formDataToSend
  //       const totalSize = new Blob([JSON.stringify(formDataToSendEmail)]).size;
  //       console.log("Total form data size:", totalSize, "bytes");

  //       if (totalSize <= 50000) {
  //         // Check if the total size is within 50Kb

  //         const result = await emailjs.send(
  //           "service_3q99uzm",
  //           "template_pqu32wg",
  //           formDataToSendEmail,
  //           "zOv1F2sO0zSxreqZd"
  //         );
  //         console.log(result.text);
  //         alert("Form submitted successfully!");
  //       } else {
  //         throw new Error("Form data size exceeds 50Kb limit");
  //       }
  //     } else {
  //       throw new Error("Failed to upload file to Cloudinary");
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     alert("Failed to submit form");
  //   }
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     // Upload file to Firebase Storage
  //     const file = formData.cv;
  //     const storageRef = ref(storage, `uploads/${file.name}`);
  //     await uploadBytes(storageRef, file);
  //     const fileUrl = await getDownloadURL(storageRef);

  //     console.log("Uploaded file URL:", fileUrl);

  //     // Prepare form data
  //     const formDataToSend = {
  //       email: formData.email,
  //       name: formData.name,
  //       address: formData.address,
  //       phone: formData.phone,
  //       education: formData.education,
  //       skills: formData.skills,
  //       cv_url: fileUrl, // Append the file URL
  //     };

  //     alert("Form submitted successfully!");
  //   } catch (error) {
  //     console.error("Error:", error);
  //     alert("Failed to submit form");
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Authenticate the user
      const email = "talentiverseuk@gmail.com";
      const password = "Talentiverse.com"; // Use a secure method to handle the password
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      if (userCredential.user) {
        console.log("User authenticated:", userCredential.user.uid);
        const file = formData.cv;

        const storageRef = ref(storage, `uploads/${file.name}`);
        await uploadBytes(storageRef, file);
        const fileUrl = await getDownloadURL(storageRef);

        console.log("Uploaded file URL:", fileUrl);
        const encodedFileUrl = encodeURIComponent(fileUrl);

        // Prepare form data
        const formDataToSend = {
          email: formData.email,
          name: formData.name,
          jobtitle: formData.jobtitle,
          phone: formData.phone,
          briefsummary: formData.briefsummary,
          others: formData.others,
          cvUrl: encodedFileUrl, // Append the file URL
        };

        // Send form data to backend
        await axios.post(
          "https://backendtwo-omega.vercel.app/send-email",
          formDataToSend
        );

        // Show modal on successful submission
        setModalVisible(true);
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Failed to submit form");
    }
  };
  return (
    <>
      {modalVisible && (
        <div className="modals">
          <div className="modals-content">
            <h2>Thank You!</h2>
            <p>Thank you for submitting. We will get back to you via email.</p>
            <button onClick={() => setModalVisible(false)}>Close</button>
          </div>
        </div>
      )}
      <form
        ref={formRef}
        id="hero-multistep"
        acceptCharset="UTF-8"
        noValidate="novalidate"
        onSubmit={handleSubmit}
        style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }}
      >
        <div style={{ display: step === 1 ? "block" : "none" }}>
          <small className="d-block  text-center">
            Enter your email here...let's go
          </small>
          <input
            id="your-email"
            name="email"
            type="email"
            placeholder="Enter your email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <button
            type="button"
            onClick={handleNext}
            style={{ backgroundColor: "#325345" }}
          >
            Next
          </button>
        </div>

        <div style={{ display: step === 2 ? "block" : "none" }}>
          <small className="d-block  text-center">Enter your Full Name</small>
          <input
            id="your-name"
            name="name"
            type="text"
            placeholder="Please also enter your name..."
            value={formData.name}
            onChange={handleChange}
            required
          />
          <button type="button" onClick={handleNext}>
            Next
          </button>
        </div>
        <div style={{ display: step === 3 ? "block" : "none" }}>
          <small className="d-block  text-center">
            Enter your correct phone number
          </small>
          <input
            id="your-phone"
            name="phone"
            type="number"
            style={{ width: "100%" }}
            placeholder="Enter your phone number"
            value={formData.phone}
            onChange={handleChange}
            required
          />
          <button type="button" onClick={handleNext}>
            Next
          </button>
        </div>

        <div style={{ display: step === 4 ? "block" : "none" }}>
          <small className="d-block  text-center">
            Job titles you are looking to get
          </small>
          <textarea
            id="your-jobtitle"
            style={{ width: "100%" }}
            className="long-textarea"
            name="jobtitle"
            placeholder="Job Title"
            value={formData.jobtitle}
            onChange={handleChange}
            required
          />
          <button type="button" onClick={handleNext}>
            Next
          </button>
        </div>

        <div style={{ display: step === 5 ? "block" : "none" }}>
          <small className="d-block  text-center">
            Give a brief summary of your strengths, skills, qualification and
            everything that will help us make your cv
          </small>
          <textarea
            id="your-briefsummary"
            style={{ width: "100%" }}
            className="long-textarea"
            name="briefsummary"
            placeholder="Brief Summary"
            value={formData.briefsummary}
            onChange={handleChange}
            required
          />
          <button type="button" onClick={handleNext}>
            Next
          </button>
        </div>

        <div style={{ display: step === 6 ? "block" : "none" }}>
          <small className="d-block  text-center">Upload your current CV</small>
          <input
            type="file"
            accept=".pdf,.doc,.docx"
            onChange={handleChange}
            required
            name="cv"
            style={{ width: "100%" }}
          />
          {formData.cv && formData.cv.name && (
            <div>Selected File: {formData.cv.name}</div>
          )}
          {formData.cvUrl && (
            <div>
              <a
                href={formData.cvUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                View Uploaded File
              </a>
            </div>
          )}
          <button type="button" onClick={handleNext}>
            Next
          </button>
        </div>
        <div style={{ display: step === 7 ? "block" : "none" }}>
          <small className="d-block d-md-none text-center">
            Any other thing you want us to know about?
          </small>
          <textarea
            id="your-others"
            style={{ width: "100%" }}
            className="long-textarea"
            name="others"
            placeholder="Tell us something"
            value={formData.others}
            onChange={handleChange}
            required
          />
          <button type="submit">Submit</button>
        </div>
      </form>
    </>
  );
};

export default MultiStep;
