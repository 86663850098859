import React, { Fragment, useEffect, useState, useRef } from "react";
import Nav from "./Nav";
import Footer from "./Footer";
import siemen from "./siemen.png";
import adz from "./adz.png";
import direct from "./direct.jpeg";
import ey from "./ey2.png";
import lol from "./lol.png";
import reck from "./reck.png";
import nhs from "./nhs.jpg";
import nhss from "./nhs.jpg";
import "./ClientReviews.css";
import uni from "./uni.png";
import vision from "./vision.jpeg";
import aaaa from "./a.png";
import w1 from "./w1.png";
import "./logocss.css";
import MultiStep from "./MultiStep";

import image1 from "./ww.jpg"; // Update with your actual image paths
import image2 from "./ww.jpg";
const reviews = [
  {
    name: "Louise FitzRoy",
    date: "November 10, 2022",
    type: "Agency",
    content:
      "I have been able to reach hundreds more potential clients who can now enjoy the benefits of the From Paddock to Plate school resources, thanks to the solid team effort from Yaser, Liz, Ena and Tyce.",
    imageUrl:
      "https://kingkong.co/wp-content/uploads/reviews/louise-fitzroy.jpg",
    tickUrl:
      "https://kingkong.co/wp-content/plugins/kk-reviews/public//assets/images/tick.png",
  },
  {
    name: "John Doe",
    date: "December 5, 2022",
    type: "Consultant",
    content:
      "Working with this team has significantly increased my outreach and client base. Their dedication and expertise are unmatched.",
    imageUrl: "https://via.placeholder.com/120",
    tickUrl:
      "https://kingkong.co/wp-content/plugins/kk-reviews/public//assets/images/tick.png",
  },
  {
    name: "Jane Smith",
    date: "January 15, 2023",
    type: "Freelancer",
    content:
      "Thanks to the excellent support from the team, I have managed to grow my business and connect with many new clients.",
    imageUrl: "https://via.placeholder.com/120",
    tickUrl:
      "https://kingkong.co/wp-content/plugins/kk-reviews/public//assets/images/tick.png",
  },
];
const About = () => {
  const images = [image1];
  const [currentSlide, setCurrentSlide] = useState(0);

  const reviews = [
    {
      text: "“Thanks to the outstanding CV writing service, I am now a Data Analyst at NHS. The professionalism and precision in creating my CV were remarkable. It showcased my abilities and achievements effectively, leading to a successful job offer. I also got a Visa Sponsorship. I highly recommend this service!”",
      imgSrc: nhs,
      altText: "NHS",
    },
    {
      text: "“The CV crafted for me was exceptional and instrumental in landing my job as a financial analyst at Siemens. The tean understood my career aspiration and empasised my strengths, making my application stand out. I am also grateful for their guidiance through the UK Visa sponsorship process. Their service is truly top notch”",
      imgSrc: siemen,
      altText: "Siemen",
    },
    {
      text: "“Although i didnt secure the position, getting to the interview stage for a Tax Analyst at EY was a significant milestone for me, and it wouldn't have been possible without the outstanding CV from Talentiverse. The level of customization and the strategic presentation of my qualifications were impressive. It definitely helped me get notied and i feel more confident about my future application”",
      imgSrc: ey,
      altText: "EY",
    },
    // {
    //   text: "“This service helped me to present my skills in the best possible way. I secured a position at Microsoft as a Product Manager. Highly recommended!”",
    //   imgSrc: nhs,
    //   altText: "Microsoft",
    // },
  ];

  const showSlide = (index) => {
    if (index >= reviews.length) {
      setCurrentSlide(0);
    } else if (index < 0) {
      setCurrentSlide(reviews.length - 1);
    } else {
      setCurrentSlide(index);
    }
  };
  return (
    <>
      <body class="page-template-default page page-id-12401 wp-custom-logo page-agency">
        <main class="site-content">
          <section class="text-light pb-0 content-text content-textarea next-has-stripes has-stripes">
            <div class="stripes">
              <div class="stripes-bg"></div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div class="background-image-container">
              <div style={{ backgroundColor: "#13111c" }}>
                <img
                  width="1920"
                  height="1495"
                  src="https://kingkong.co/wp-content/uploads/2020/12/home-hero-1920x1495.jpg"
                  class="attachment-max_width size-max_width entered lazyloaded"
                  alt=""
                  style={{ opacity: "0.18" }}
                  data-lazy-srcset="https://kingkong.co/wp-content/uploads/2020/12/home-hero-1920x1495.jpg 1920w, https://kingkong.co/wp-content/uploads/2020/12/home-hero-300x234.jpg 300w, https://kingkong.co/wp-content/uploads/2020/12/home-hero-1024x797.jpg 1024w, https://kingkong.co/wp-content/uploads/2020/12/home-hero-768x598.jpg 768w, https://kingkong.co/wp-content/uploads/2020/12/home-hero-1536x1196.jpg 1536w, https://kingkong.co/wp-content/uploads/2020/12/home-hero-2048x1595.jpg 2048w, https://kingkong.co/wp-content/uploads/2020/12/home-hero-1200x934.jpg 1200w"
                  data-lazy-sizes="(max-width: 1920px) 100vw, 1920px"
                  data-lazy-src="https://kingkong.co/wp-content/uploads/2020/12/home-hero-1920x1495.jpg"
                  data-ll-status="loaded"
                  sizes="(max-width: 1920px) 100vw, 1920px"
                  srcset="
            https://kingkong.co/wp-content/uploads/2020/12/home-hero-1920x1495.jpg 1920w,
            https://kingkong.co/wp-content/uploads/2020/12/home-hero-300x234.jpg    300w,
            https://kingkong.co/wp-content/uploads/2020/12/home-hero-1024x797.jpg  1024w,
            https://kingkong.co/wp-content/uploads/2020/12/home-hero-768x598.jpg    768w,
            https://kingkong.co/wp-content/uploads/2020/12/home-hero-1536x1196.jpg 1536w,
            https://kingkong.co/wp-content/uploads/2020/12/home-hero-2048x1595.jpg 2048w,
            https://kingkong.co/wp-content/uploads/2020/12/home-hero-1200x934.jpg  1200w
          "
                />
                <noscript>
                  <img
                    width="1920"
                    height="1495"
                    src="https://kingkong.co/wp-content/uploads/2020/12/home-hero-1920x1495.jpg"
                    class="attachment-max_width size-max_width"
                    alt=""
                    style={{ opacity: "0.18" }}
                    srcset="
              https://kingkong.co/wp-content/uploads/2020/12/home-hero-1920x1495.jpg 1920w,
              https://kingkong.co/wp-content/uploads/2020/12/home-hero-300x234.jpg    300w,
              https://kingkong.co/wp-content/uploads/2020/12/home-hero-1024x797.jpg  1024w,
              https://kingkong.co/wp-content/uploads/2020/12/home-hero-768x598.jpg    768w,
              https://kingkong.co/wp-content/uploads/2020/12/home-hero-1536x1196.jpg 1536w,
              https://kingkong.co/wp-content/uploads/2020/12/home-hero-2048x1595.jpg 2048w,
              https://kingkong.co/wp-content/uploads/2020/12/home-hero-1200x934.jpg  1200w
            "
                    sizes="(max-width: 1920px) 100vw, 1920px"
                  />
                </noscript>
              </div>
            </div>
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-12 col-md-10 col-lg-10">
                  <p
                    class="h2"
                    style={{
                      textAlign: "center",
                      fontFamily: "Futura LT Condensed, sans-serif !important",
                    }}
                  >
                    Get to know <br />
                    more About
                    <br />
                    Talentiverse<sup>*</sup>
                  </p>
                </div>
                <div class="col-12 col-md-10 col-lg-9 col-xl-8 mb-5 text-center">
                  <p class="mb-0">
                    We are trying to get people to that dream Job and make it a
                    whole lot easier, less stressful and better result for
                    people to get that Job.
                  </p>
                </div>

                <div class="col-12 col-md-9 col-lg-8 col-xl-6 null">
                  <div class="hero-video">
                    <span class="wistia-close">+</span>
                    <div
                      class="wistia-embed"
                      data-inline=""
                      data-wistia-id="pjvyj3bhy2"
                    >
                      <div class="wistia-embed__inner">
                        <video
                          width="1920"
                          height="1080"
                          controls
                          autoplay
                          muted
                          loop
                        >
                          <source
                            src="https://res.cloudinary.com/dftygokow/video/upload/v1716846071/WhatsApp_Video_2024-05-27_at_07.57.15_r58ues.mp4"
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                        <div class="wistia-embed__button wistia-embed__button--orange">
                          <div class="wistia-embed__play"></div>
                        </div>
                        <div class="wistia-embed__time">7:47</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class="text-dark content-global awards next-has-stripes has-stripes">
            <div class="stripes">
              <div class="stripes-bg"></div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div
              class="background-image-container"
              style={{ backgroundColor: "#ffffff" }}
            ></div>
            <div class="container">
              <div class="row justify-content-center">
                <div class="image-marquee image-marquee--double">
                  <div
                    class="image-marquee__gradient--left"
                    style={{
                      background:
                        "linear-gradient(90deg, #ffffff 14.9%, rgba(55, 55, 55, 0) 100%)",
                    }}
                  ></div>
                  <div
                    class="image-marquee__gradient--right"
                    style={{
                      background:
                        "linear-gradient(90deg, #ffffff 14.9%, rgba(55, 55, 55, 0) 100%)",
                    }}
                  ></div>
                  <div class="image-marquee__scroller">
                    <div class="image-marquee__scroller__item">
                      <img
                        width="2109"
                        height="50"
                        src={siemen}
                        class="entered lazyloaded"
                        alt=""
                        data-lazy-src={siemen}
                      />

                      <img
                        width="2109"
                        height="50"
                        src={adz}
                        class="entered lazyloaded"
                        alt=""
                        data-ll-status="loaded"
                      />
                      <img
                        width="2109"
                        height="50"
                        src={aaaa}
                        class="entered lazyloaded"
                        alt=""
                        data-ll-status="loaded"
                      />
                      <img
                        width="2109"
                        height="50"
                        src={nhss}
                        class="entered lazyloaded"
                        alt=""
                        data-ll-status="loaded"
                      />
                      <img
                        width="2109"
                        height="50"
                        src={direct}
                        class="entered lazyloaded"
                        alt=""
                        data-ll-status="loaded"
                      />

                      <img
                        width="2109"
                        height="50"
                        src={ey}
                        class="entered lazyloaded"
                        alt=""
                        data-ll-status="loaded"
                      />

                      <img
                        width="2109"
                        height="50"
                        src={lol}
                        class="entered lazyloaded"
                        alt=""
                        data-ll-status="loaded"
                      />

                      <img
                        width="2109"
                        height="50"
                        src={reck}
                        class="entered lazyloaded"
                        alt=""
                        data-ll-status="loaded"
                      />

                      <img
                        width="2109"
                        height="50"
                        src={uni}
                        class="entered lazyloaded"
                        alt=""
                      />

                      <img
                        width="2109"
                        height="50"
                        src={vision}
                        class=""
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="text-dark content-text content-code content-wysiwyg content-textarea next-has-stripes has-stripes">
            <div class="stripes">
              <div class="stripes-bg"></div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div
              class="background-image-container"
              style={{ backgroundColor: "#ffffff" }}
            ></div>
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-12 text-center fade-in">
                  <span class="pill" style={{ color: "white" }}>
                    About Us
                  </span>
                </div>
                <div class="col-lg-8 text-center fade-in">
                  <span class="h2">Welcome to Talentiverse</span>
                </div>
                <div class="col-md-10 col-lg-8">
                  <p style={{ textAlign: "center" }}>
                    <span style={{ color: "#626162" }}>
                      At Talentiverse, we believe that every international
                      student has the potential to achieve greatness in their
                      career. Our mission is simple: to transform your job
                      search journey and help you land your dream job with visa
                      sponsorship. How do we do that? By creating standout CVs
                      that catch the eye of recruiters and open doors to
                      opportunities worldwide.
                    </span>
                  </p>
                </div>
                <div class="col-lg-8 text-center fade-in">
                  <span class="h2">Our Story</span>
                </div>
                <div class="col-md-10 col-lg-8">
                  <p style={{ textAlign: "center" }}>
                    <span style={{ color: "#626162" }}>
                      Talentiverse was born out of a deep understanding of the
                      unique challenges faced by international students. From
                      navigating visa requirements to overcoming cultural
                      barriers, we know it's not easy. Our founder, inspired by
                      his own experiences and those of friends and colleagues,
                      decided to create a service that bridges the gap between
                      talented individuals and their dream careers.
                    </span>
                  </p>
                </div>
                <div class="col-lg-8 text-center fade-in">
                  <span class="h2">What we do</span>
                </div>
                <div class="col-md-10 col-lg-8">
                  <p style={{ textAlign: "center" }}>
                    <span style={{ color: "#626162" }}>
                      We specialize in crafting bespoke CV tailored to your
                      industry, skills, and aspirations. Our team of industry
                      experts brings a wealth of knowledge and experience to the
                      table, ensuring your CV is not just a document, but a
                      powerful tool that showcases your strengths and potential.
                    </span>
                  </p>
                </div>
                <div class="col-lg-8 text-center fade-in">
                  <span class="h2">Why Choose Talentiverse</span>
                </div>
                <div className="filters-container text-center">
                  <div className="kkra-global" id="why-choose-us">
                    <div className="kkra-global__inner">
                      <div className="kkra-global__item term-agency">
                        <div className="kkra-global__item__inner">
                          <div className="kkra-global__meta">
                            <div className="kkra-global__content">
                              <div className="review-sub active">
                                <h3>Expert CV Writing</h3>
                                <p>
                                  Our CV are meticulously crafted by
                                  professionals who understand what recruiters
                                  are looking for.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="kkra-global__item term-agency">
                        <div className="kkra-global__item__inner">
                          <div className="kkra-global__meta">
                            <div className="kkra-global__content">
                              <div className="review-sub active">
                                <h3>High Success Rate</h3>
                                <p>
                                  With a 97% success rate in securing interview
                                  calls after 20+ applications, we know what
                                  works.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="kkra-global__item term-agency">
                        <div className="kkra-global__item__inner">
                          <div className="kkra-global__meta">
                            <div className="kkra-global__content">
                              <div className="review-sub active">
                                <h3>Work Experience Opportunities</h3>
                                <p>
                                  We go beyond CV writing by offering
                                  opportunities to gain relevant work
                                  experience, making your profile even more
                                  attractive.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="kkra-global__item term-agency">
                        <div className="kkra-global__item__inner">
                          <div className="kkra-global__meta">
                            <div className="kkra-global__content">
                              <div className="review-sub active">
                                <h3>Personalized Service</h3>
                                <p>
                                  We take the time to understand your unique
                                  background and career goals, delivering a
                                  customized CV that stands out.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="kkra-global__item term-agency">
                        <div className="kkra-global__item__inner">
                          <div className="kkra-global__meta">
                            <div className="kkra-global__content">
                              <div className="review-sub active">
                                <h3>Proven Results</h3>
                                <p>
                                  Our clients have successfully secured
                                  positions in top companies worldwide, and
                                  we’re just getting started.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="kkra-global__item term-agency">
                        <div className="kkra-global__item__inner">
                          <div className="kkra-global__meta">
                            <div className="kkra-global__content">
                              <div className="review-sub active">
                                <h3>Proven Results</h3>
                                <p>
                                  Our clients have successfully secured
                                  positions in top companies worldwide, and
                                  we’re just getting started.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/*}   <section class="text-dark content-text content-wysiwyg">
            <div
              class="background-image-container"
              style={{ backgroundColor: "#f3f3f3" }}
            ></div>
            <div class="container">
              <div class="row justify-content-center">
                <div class="pill--flashing text-center col-12">
                  <span class="pill__flashing">
                    <span class="eyebrow__flashing-dot"></span>
                    Warning: We reserve the right to revoke this offer at any
                    time
                  </span>
                </div>
                <div class="col-md-10 col-xxl-9 mt-3 mb-2 mt-lg-3 mb-lg-5 text-center">
                  <span class="h2">
                    Features designed to help you win your dream job
                  </span>
                </div>
                <div class="col-xxl-7 col-xl-8 col-md-9">
                  <p>
                    There are
                    <span style={{ textDecoration: "underline" }}>
                      three important factors
                    </span>
                    which must exist before you should even think about applying
                    for this opportunity:
                  </p>
                  <ol>
                    <li>
                      <strong>
                        Make a resume that wins interviews(sign up)
                      </strong>
                      <br></br>
                      Use our resume maker with its advanced creation tools to
                      tell a professional story that engages recruiters, hiring
                      managers and even CEOs.
                    </li>
                    <li>
                      <strong>Resume writing made easy(create)</strong>
                      <br></br>
                      Resume writing has never been this effortless.
                      Pre-generated text, visual designs and more - all already
                      integrated into the resume maker. Just fill in your
                      details.
                    </li>
                    <li>
                      <strong>
                        A recruiter-tested CV maker tool(download)
                      </strong>
                      <br></br>
                      Our resume builder and its pre-generated content are
                      tested by recruiters and IT experts. We help your resume
                      become truly competitive in the hiring proces
                    </li>
                  </ol>
                </div>
                <div class="col-lg-8 null ">
                  <span class="null">
                    <a class="btn btn-doublelayer btn-green" href="/">
                      <span class="btn-doublelayer__maintext">
                        Create your Resume
                      </span>
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </section>
                      */}
          <div class="row justify-content-center">
            <div class="col-lg-8 text-center fade-in">
              <span class="h2">Our Promise</span>
            </div>
            <div class="col-md-10 col-lg-8">
              <p style={{ textAlign: "center" }}>
                <span style={{ color: "#626162" }}>
                  Talentiverse was born out of a deep understanding of the
                  unique challenges faced by international students. From
                  navigating visa requirements to overcoming cultural barriers,
                  we know it's not easy. Our founder, inspired by his own
                  experiences and those of friends and colleagues, decided to
                  create a service that bridges the gap between talented
                  individuals and their dream careers.
                </span>
              </p>
            </div>
            <div class="col-lg-8 text-center fade-in">
              <span class="h2">Join the Talentiverse Community</span>
            </div>
            <div class="col-md-10 col-lg-8">
              <p style={{ textAlign: "center" }}>
                <span style={{ color: "#626162" }}>
                  When you work with us, you become part of a vibrant community
                  of ambitious individuals from around the globe. Connect with
                  like-minded peers, gain insights from industry experts, and
                  unlock opportunities that take you closer to your dream job.
                </span>
              </p>
            </div>
          </div>
          <section class="text-light content-global hit-the-damn-button">
            <div
              class="background-image-container"
              style={{ backgroundColor: "#000000" }}
            ></div>
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-sm-10 col-md-9 col-lg-8 col-xl-7 text-center ">
                  <span class="h2">
                    Ready to transform your career? Submit your CV today for a
                    free review and rewrite
                  </span>
                </div>
                <div class="col-sm-9 col-md-7 col-lg-6 col-xl-6 null ">
                  <span class="null">
                    <a class="btn btn-doublelayer btn-green" href="/">
                      <span class="btn-doublelayer__maintext">
                        Get in touch
                      </span>
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </section>
        </main>
        <footer class="site-footer" id="footer-container">
          <div class="container">
            <div class="footer-columns">
              <div class="row justify-content-center">
                <div class="col footer-column-1">
                  <div
                    id="custom_html-4"
                    class="widget_text widget widget_custom_html footer-widgets-1"
                  >
                    <div class="textwidget custom-html-widget">
                      <img
                        src={w1}
                        style={{ width: "180px", height: "50px" }}
                      />
                    </div>
                  </div>
                  <div id="text-2" class="widget widget_text footer-widgets-1">
                    <div class="textwidget">
                      <p>
                        Securing a job with visa sponsorship is tough. We make
                        it a whole lot easier, more attainable, less stressful,
                        and more rewarding.
                      </p>
                    </div>
                  </div>
                  <div
                    id="kk_address_widget-2"
                    class="widget widget_kk_address_widget footer-widgets-1"
                  >
                    Submit your CV and get a professional makeover from industry
                    experts - Free for the first 60 people. Limited Offer!
                    Phone: <a href="tel:+18883038580">+447867266523</a>
                  </div>
                  <div
                    id="custom_html-2"
                    class="widget_text widget widget_custom_html footer-widgets-1"
                  >
                    <div class="textwidget custom-html-widget">
                      <a
                        href="https://facebook.com/kingkongco/"
                        rel="nofollow"
                        class="social-icon"
                        aria-label="Our Facebook"
                      >
                        <svg
                          height="512"
                          viewBox="0 0 24 24"
                          width="512"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M15.997 3.985h2.191V.169C17.81.117 16.51 0 14.996 0c-3.159 0-5.323 1.987-5.323 5.639V9H6.187v4.266h3.486V24h4.274V13.267h3.345l.531-4.266h-3.877V6.062c.001-1.233.333-2.077 2.051-2.077z"></path>
                        </svg>
                      </a>
                      <a
                        href="https://www.instagram.com/kingkong.com.au/"
                        rel="nofollow"
                        class="social-icon"
                        aria-label="Our Instagram"
                      >
                        <svg
                          height="511pt"
                          viewBox="0 0 511 511.9"
                          width="511pt"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M510.95 150.5c-1.2-27.2-5.598-45.898-11.9-62.102-6.5-17.199-16.5-32.597-29.6-45.398-12.802-13-28.302-23.102-45.302-29.5-16.296-6.3-34.898-10.7-62.097-11.898C334.648.3 325.949 0 256.449 0s-78.199.3-105.5 1.5c-27.199 1.2-45.898 5.602-62.097 11.898-17.204 6.5-32.602 16.5-45.403 29.602-13 12.8-23.097 28.3-29.5 45.3-6.3 16.302-10.699 34.9-11.898 62.098C.75 177.801.449 186.5.449 256s.301 78.2 1.5 105.5c1.2 27.2 5.602 45.898 11.903 62.102 6.5 17.199 16.597 32.597 29.597 45.398 12.801 13 28.301 23.102 45.301 29.5 16.3 6.3 34.898 10.7 62.102 11.898 27.296 1.204 36 1.5 105.5 1.5s78.199-.296 105.5-1.5c27.199-1.199 45.898-5.597 62.097-11.898a130.934 130.934 0 0 0 74.903-74.898c6.296-16.301 10.699-34.903 11.898-62.102 1.2-27.3 1.5-36 1.5-105.5s-.102-78.2-1.3-105.5zm-46.098 209c-1.102 25-5.301 38.5-8.801 47.5-8.602 22.3-26.301 40-48.602 48.602-9 3.5-22.597 7.699-47.5 8.796-27 1.204-35.097 1.5-103.398 1.5s-76.5-.296-103.403-1.5c-25-1.097-38.5-5.296-47.5-8.796C94.551 451.5 84.45 445 76.25 436.5c-8.5-8.3-15-18.3-19.102-29.398-3.5-9-7.699-22.602-8.796-47.5-1.204-27-1.5-35.102-1.5-103.403s.296-76.5 1.5-103.398c1.097-25 5.296-38.5 8.796-47.5C61.25 94.199 67.75 84.1 76.352 75.898c8.296-8.5 18.296-15 29.398-19.097 9-3.5 22.602-7.7 47.5-8.801 27-1.2 35.102-1.5 103.398-1.5 68.403 0 76.5.3 103.403 1.5 25 1.102 38.5 5.3 47.5 8.8 11.097 4.098 21.199 10.598 29.398 19.098 8.5 8.301 15 18.301 19.102 29.403 3.5 9 7.699 22.597 8.8 47.5 1.2 27 1.5 35.097 1.5 103.398s-.3 76.301-1.5 103.301zm0 0"></path>
                          <path d="M256.45 124.5c-72.598 0-131.5 58.898-131.5 131.5s58.902 131.5 131.5 131.5c72.6 0 131.5-58.898 131.5-131.5s-58.9-131.5-131.5-131.5zm0 216.8c-47.098 0-85.302-38.198-85.302-85.3s38.204-85.3 85.301-85.3c47.102 0 85.301 38.198 85.301 85.3s-38.2 85.3-85.3 85.3zm167.402-222c0 16.954-13.747 30.7-30.704 30.7-16.953 0-30.699-13.746-30.699-30.7 0-16.956 13.746-30.698 30.7-30.698 16.956 0 30.703 13.742 30.703 30.699zm0 0"></path>
                        </svg>
                      </a>
                      <a
                        href="https://www.linkedin.com/company/kingkong/"
                        rel="nofollow"
                        class="social-icon"
                        aria-label="Our LinkedIn"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 504.4 504.4"
                        >
                          <path d="M377.6.2H126.4C56.8.2 0 57 0 126.6v251.6c0 69.2 56.8 126 126.4 126H378c69.6 0 126.4-56.8 126.4-126.4V126.6C504 57 447.2.2 377.6.2zM168 408.2H96v-208h72v208zm-36.4-240c-20.4 0-36.8-16.4-36.8-36.8s16.4-36.8 36.8-36.8 36.8 16.4 36.8 36.8c-.4 20.4-16.8 36.8-36.8 36.8zm276.8 240H348V307.4c0-24.4-3.2-55.6-36.4-55.6-34 0-39.6 26.4-39.6 54v102.4h-60v-208h56v28h1.6c8.8-16 29.2-28.4 61.2-28.4 66 0 77.6 38 77.6 94.4v114z"></path>
                        </svg>
                      </a>
                      <a
                        href="https://www.youtube.com/channel/UCwDi1NwScPpDYyvwPvHBj9g"
                        rel="nofollow"
                        class="social-icon"
                        aria-label="Our Youtube"
                      >
                        <svg
                          height="682pt"
                          viewBox="-21 -117 682.667 682"
                          width="682pt"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M626.813 64.035c-7.375-27.418-28.993-49.031-56.407-56.414C520.324-6.082 319.992-6.082 319.992-6.082s-200.324 0-250.406 13.184c-26.887 7.375-49.031 29.52-56.406 56.933C0 114.113 0 217.97 0 217.97s0 104.379 13.18 153.933c7.382 27.414 28.992 49.028 56.41 56.41C120.195 442.02 320 442.02 320 442.02s200.324 0 250.406-13.184c27.418-7.379 49.032-28.992 56.414-56.406 13.176-50.082 13.176-153.934 13.176-153.934s.527-104.383-13.183-154.46zM256.21 313.915V122.022l166.586 95.946zm0 0"></path>
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col footer-column-2">
                  <div
                    id="nav_menu-2"
                    class="widget widget_nav_menu footer-widgets-2"
                  >
                    <p class="widget-title">Company</p>
                    <div class="menu-company-container">
                      <ul id="menu-company" class="menu">
                        <li
                          id="menu-item-12074"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-12074"
                        >
                          <a href="about">About Us</a>
                        </li>
                        <li
                          id="menu-item-12070"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-12070"
                        >
                          <a href="contact">Contact Us</a>
                        </li>
                        <li
                          id="menu-item-12071"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-12071"
                        >
                          <a href="blog">Blog</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col footer-column-3">
                  <div
                    id="nav_menu-3"
                    class="widget widget_nav_menu footer-widgets-3"
                  >
                    <p class="widget-title">Services</p>
                    <div class="menu-services-container">
                      <ul id="menu-services" class="menu">
                        <li
                          id="menu-item-12075"
                          class="footer-cro-trigger menu-item menu-item-type-post_type menu-item-object-page menu-item-12075"
                        >
                          <a>We help you prepare your resume</a>
                        </li>
                        <li
                          id="menu-item-12078"
                          class="footer-ppc-trigger menu-item menu-item-type-post_type menu-item-object-page menu-item-12078"
                        >
                          <a>We help you get the Job</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col footer-column-4">
                  <div
                    id="nav_menu-4"
                    class="widget widget_nav_menu footer-widgets-4"
                  >
                    <p class="widget-title">More</p>
                    <div class="menu-solutions-container"></div>
                  </div>
                </div>
                <div class="col footer-column-5">
                  <div id="text-3" class="widget widget_text footer-widgets-5">
                    <p class="widget-title">Want to get started?</p>
                    <div class="textwidget">
                      <p>Fill in this form and lets help you get started</p>
                    </div>
                  </div>
                  <div
                    id="custom_html-3"
                    class="widget_text widget widget_custom_html footer-widgets-5"
                  >
                    <div class="textwidget custom-html-widget">
                      <form class="round-form">
                        <input type="text" placeholder="Enter your full name" />
                        <input
                          type="submit"
                          value="Hit me"
                          style={{ color: "white" }}
                        />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="copyright-line text-center">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  Copyright
                  <br class="d-none d-md-block" />
                </div>
              </div>
            </div>
          </div>
        </footer>
      </body>
    </>
  );
};

export default About;
