import React from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./component/Home";
import About from "./component/About";
import Banner from "./component/Banner";
import Contact from "./component/Contact";
// import MouseFollower from "./MouseFollower";

const App = () => {
  return (
    <>
      {/*<MouseFollower />*/}
      <BrowserRouter>
        <Routes>
          <Route exact path="/about" element={<About />} />
          <Route exact path="/" element={<Home />} />
          <Route exact path="/homes" element={<Banner />} />
          <Route exact path="/contact" element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
