import React, { Fragment, useEffect, useState, useRef } from "react";
import Nav from "./Nav";
import Footer from "./Footer";
import siemen from "./siemen.png";
import adz from "./adz.png";
import direct from "./direct.jpeg";
import ey from "./ey2.png";
import lol from "./lol.png";
import reck from "./reck.png";
import nhs from "./nhs.jpg";
import nhss from "./nhs.jpg";
import "./ClientReviews.css";
import uni from "./uni.png";
import aaaa from "./a.png";
import vision from "./vision.jpeg";
import w1 from "./w1.png";
import "./logocss.css";
import MultiStep from "./MultiStep";

import image1 from "./job.png"; // Update with your actual image paths
import image2 from "./ww.jpg";

const Home = () => {
  const [activeAccordion, setActiveAccordion] = useState(null);
  const videoRef = useRef(null);

  const handlePlayVideo = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
      }
    }
  };

  // Function to toggle the active accordion item
  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  // Accordion data
  const accordionData = [
    {
      question: "What does Talentiverse actually do?",
      answer:
        "Talentiverse specializes in creating expertly crafted CVs tailored to your industry. We provide comprehensive CV reviews, rewrites, and career advice to help international students secure jobs with visa sponsorships.",
    },
    {
      question: "How do I know that I will get a return on my investment?",
      answer:
        "Our CVs have a proven success rate. 97% of our clients receive interview calls after 20+ applications. We focus on making your CV stand out to recruiters, increasing your chances of securing a job with visa sponsorship.",
    },
    {
      question: "Do you work with students from all industries?",
      answer:
        "Yes, we work with students from various industries. Our team of industry experts ensures that your CV is tailored to meet the specific requirements of your field, highlighting your strengths and experiences.",
    },
    {
      question: "How quickly can I get my CV reviewed and rewritten?",
      answer:
        "For a limited time, we offer a free CV review and rewrite within 48 hours. Submit your CV on our website, and our experts will transform it to help you stand out to potential employers.",
    },
    {
      question:
        "What makes Talentiverse different from other CV writing services?",
      answer:
        "Unlike generic CV writing websites, our CVs are crafted by industry experts and tailored to your specific field. We also provide opportunities for relevant work experience and have a high success rate in helping clients secure jobs with visa sponsorships.",
    },
    {
      question:
        "Can you guarantee that I will get a job with visa sponsorship?",
      answer:
        "While we cannot guarantee a job, our CVs significantly increase your chances of getting interview calls. Our clients have a high success rate in securing jobs with visa sponsorships, thanks to our expert CV writing services and career advice.",
    },
  ];
  const images = [image1];
  const [currentSlide, setCurrentSlide] = useState(0);

  const reviews = [
    {
      text: "“Thanks to the outstanding CV writing service, I am now a Data Analyst at NHS. The professionalism and precision in creating my CV were remarkable. It showcased my abilities and achievements effectively, leading to a successful job offer. I also got a Visa Sponsorship. I highly recommend this service!”",
      imgSrc: nhs,
      altText: "NHS",
    },
    {
      text: "“The CV crafted for me was exceptional and instrumental in landing my job as a financial analyst at Siemens. The tean understood my career aspiration and empasised my strengths, making my application stand out. I am also grateful for their guidiance through the UK Visa sponsorship process. Their service is truly top notch”",
      imgSrc: siemen,
      altText: "Siemen",
    },
    {
      text: "“Although i didnt secure the position, getting to the interview stage for a Tax Analyst at EY was a significant milestone for me, and it wouldn't have been possible without the outstanding CV from Talentiverse. The level of customization and the strategic presentation of my qualifications were impressive. It definitely helped me get notied and i feel more confident about my future application”",
      imgSrc: ey,
      altText: "EY",
    },
    // {
    //   text: "“This service helped me to present my skills in the best possible way. I secured a position at Microsoft as a Product Manager. Highly recommended!”",
    //   imgSrc: nhs,
    //   altText: "Microsoft",
    // },
  ];

  const showSlide = (index) => {
    if (index >= reviews.length) {
      setCurrentSlide(0);
    } else if (index < 0) {
      setCurrentSlide(reviews.length - 1);
    } else {
      setCurrentSlide(index);
    }
  };
  return (
    <>
      <body class="page-template-default page page-id-12401 wp-custom-logo page-agency">
        <main class="site-content">
          <section class="text-light pb-0 content-text content-textarea next-has-stripes has-stripes">
            <div class="stripes">
              <div class="stripes-bg"></div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div class="background-image-container">
              <div style={{ backgroundColor: "#13111c" }}>
                <img
                  width="1920"
                  height="1495"
                  src="https://kingkong.co/wp-content/uploads/2020/12/home-hero-1920x1495.jpg"
                  class="attachment-max_width size-max_width entered lazyloaded"
                  alt=""
                  style={{ opacity: "0.18" }}
                  data-lazy-srcset="https://kingkong.co/wp-content/uploads/2020/12/home-hero-1920x1495.jpg 1920w, https://kingkong.co/wp-content/uploads/2020/12/home-hero-300x234.jpg 300w, https://kingkong.co/wp-content/uploads/2020/12/home-hero-1024x797.jpg 1024w, https://kingkong.co/wp-content/uploads/2020/12/home-hero-768x598.jpg 768w, https://kingkong.co/wp-content/uploads/2020/12/home-hero-1536x1196.jpg 1536w, https://kingkong.co/wp-content/uploads/2020/12/home-hero-2048x1595.jpg 2048w, https://kingkong.co/wp-content/uploads/2020/12/home-hero-1200x934.jpg 1200w"
                  data-lazy-sizes="(max-width: 1920px) 100vw, 1920px"
                  data-lazy-src="https://kingkong.co/wp-content/uploads/2020/12/home-hero-1920x1495.jpg"
                  data-ll-status="loaded"
                  sizes="(max-width: 1920px) 100vw, 1920px"
                  srcset="
            https://kingkong.co/wp-content/uploads/2020/12/home-hero-1920x1495.jpg 1920w,
            https://kingkong.co/wp-content/uploads/2020/12/home-hero-300x234.jpg    300w,
            https://kingkong.co/wp-content/uploads/2020/12/home-hero-1024x797.jpg  1024w,
            https://kingkong.co/wp-content/uploads/2020/12/home-hero-768x598.jpg    768w,
            https://kingkong.co/wp-content/uploads/2020/12/home-hero-1536x1196.jpg 1536w,
            https://kingkong.co/wp-content/uploads/2020/12/home-hero-2048x1595.jpg 2048w,
            https://kingkong.co/wp-content/uploads/2020/12/home-hero-1200x934.jpg  1200w
          "
                />
                <noscript>
                  <img
                    width="1920"
                    height="1495"
                    src="https://kingkong.co/wp-content/uploads/2020/12/home-hero-1920x1495.jpg"
                    class="attachment-max_width size-max_width"
                    alt=""
                    style={{ opacity: "0.18" }}
                    srcset="
              https://kingkong.co/wp-content/uploads/2020/12/home-hero-1920x1495.jpg 1920w,
              https://kingkong.co/wp-content/uploads/2020/12/home-hero-300x234.jpg    300w,
              https://kingkong.co/wp-content/uploads/2020/12/home-hero-1024x797.jpg  1024w,
              https://kingkong.co/wp-content/uploads/2020/12/home-hero-768x598.jpg    768w,
              https://kingkong.co/wp-content/uploads/2020/12/home-hero-1536x1196.jpg 1536w,
              https://kingkong.co/wp-content/uploads/2020/12/home-hero-2048x1595.jpg 2048w,
              https://kingkong.co/wp-content/uploads/2020/12/home-hero-1200x934.jpg  1200w
            "
                    sizes="(max-width: 1920px) 100vw, 1920px"
                  />
                </noscript>
              </div>
            </div>
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-12 col-md-10 col-lg-10">
                  <p
                    class="h2"
                    style={{
                      textAlign: "center",
                      fontFamily: "Futura LT Condensed, sans-serif !important",
                    }}
                  >
                    Like a ticket <br />
                    to your
                    <br />
                    Dream Job<sup>*</sup>
                  </p>
                </div>
                <div class="col-12 col-md-10 col-lg-9 col-xl-8 mb-5 text-center">
                  <p class="mb-0">
                    Securing a job with visa sponsorship is tough. We make it a
                    whole lot easier, more attainable, less stressful, and more
                    rewarding. Submit your CV and get a professional makeover
                    from industry experts - Free for the first 60 people.
                    Limited Offer!
                  </p>
                </div>
                <div class="col-12">
                  <MultiStep />
                </div>
                <div class="col-md-11 col-lg-9 subheadline mb-5 text-center">
                  Our CV have a 97% success rate for getting recruiter calls
                  after 20+ applications
                </div>
                <div className="col-12 col-md-9 col-lg-8 col-xl-6 null">
                  <div
                    className="hero-video"
                    id="video-container"
                    onClick={handlePlayVideo}
                  >
                    <span className="wistia-close">+</span>
                    <div
                      className="wistia-embed"
                      data-inline=""
                      data-wistia-id="pjvyj3bhy2"
                    >
                      <div className="wistia-embed__inner">
                        <video
                          ref={videoRef}
                          width="1920"
                          height="1080"
                          controls
                          autoPlay
                          muted
                          loop
                        >
                          <source
                            src="https://res.cloudinary.com/dftygokow/video/upload/v1716846071/WhatsApp_Video_2024-05-27_at_07.57.15_r58ues.mp4"
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                        <div className="wistia-embed__button wistia-embed__button--orange">
                          <div className="wistia-embed__play"></div>
                        </div>
                        <div className="wistia-embed__time">7:47</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class="text-dark content-global awards next-has-stripes has-stripes">
            <div class="stripes">
              <div class="stripes-bg"></div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div
              class="background-image-container"
              style={{ backgroundColor: "#ffffff" }}
            ></div>
            <div class="container">
              <div class="row justify-content-center">
                <div class="image-marquee image-marquee--double">
                  <div
                    class="image-marquee__gradient--left"
                    style={{
                      background:
                        "linear-gradient(90deg, #ffffff 14.9%, rgba(55, 55, 55, 0) 100%)",
                    }}
                  ></div>
                  <div
                    class="image-marquee__gradient--right"
                    style={{
                      background:
                        "linear-gradient(90deg, #ffffff 14.9%, rgba(55, 55, 55, 0) 100%)",
                    }}
                  ></div>
                  <div class="image-marquee__scroller">
                    <div class="image-marquee__scroller__item">
                      <img
                        width="1109"
                        height="30"
                        src={siemen}
                        class="entered lazyloaded"
                        alt=""
                        data-lazy-src={siemen}
                      />

                      <img
                        width="1109"
                        height="30"
                        src={adz}
                        class="entered lazyloaded"
                        alt=""
                        data-ll-status="loaded"
                      />

                      <img
                        width="1109"
                        height="30"
                        src={direct}
                        class="entered lazyloaded"
                        alt=""
                        data-ll-status="loaded"
                      />

                      <img
                        width="1109"
                        height="30"
                        src={ey}
                        class="entered lazyloaded"
                        alt=""
                        data-ll-status="loaded"
                      />

                      <img
                        width="1109"
                        height="30"
                        src={lol}
                        class="entered lazyloaded"
                        alt=""
                        data-ll-status="loaded"
                      />
                      <img
                        width="1109"
                        height="30"
                        src={aaaa}
                        class="entered lazyloaded"
                        alt=""
                        data-ll-status="loaded"
                      />
                      <img
                        width="1109"
                        height="30"
                        src={nhss}
                        class="entered lazyloaded"
                        alt=""
                        data-ll-status="loaded"
                      />

                      <img
                        width="1109"
                        height="30"
                        src={reck}
                        class="entered lazyloaded"
                        alt=""
                        data-ll-status="loaded"
                      />

                      <img
                        width="1109"
                        height="30"
                        src={uni}
                        class="entered lazyloaded"
                        alt=""
                      />

                      <img
                        width="1109"
                        height="30"
                        src={vision}
                        class=""
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="text-dark content-text content-code content-wysiwyg content-textarea next-has-stripes has-stripes">
            <div class="stripes">
              <div class="stripes-bg"></div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div
              class="background-image-container"
              style={{ backgroundColor: "#CDE1D9" }}
            ></div>
            <div class="container">
              <div class="row justify-content-center">
                <div class="row justify-content-center">
                  <div class="col-xxl-7 col-xl-8 col-md-9 love">
                    <p>Dear future professional,</p>
                    <p>We get it.</p>
                    <p>
                      Securing a job with visa sponsorship is tough. Really
                      tough.
                    </p>
                    <p>
                      You’re probably stressing about where your next
                      opportunity will come from.
                    </p>
                    <p>
                      Anxious and losing sleep like you’re cramming for finals
                      every night.
                    </p>
                    <p>
                      As your inbox fills up with rejection emails and your hope
                      dims a little more each day.
                    </p>
                    <p>And to top it all off.</p>
                    <p>
                      You’re being pulled in a million different directions.
                    </p>
                    <p>Ohmygawd!</p>
                    <p>
                      Should I tailor my CV? Write a cover letter? Network on
                      LinkedIn?
                    </p>
                    <p>
                      Do I need to learn new skills or just optimize my current
                      ones?
                    </p>
                    <p>What about the job market?</p>
                    <p>Errrrrrbody’s talkin’ ‘bout the job market.</p>
                    <p>Should I apply to more jobs?</p>
                    <p>Get another degree?</p>
                    <p>Submit applications 10 times per day?</p>
                    <p>somebody please help me out here</p>
                    <p>
                      Look, most international job seekers face a relentless
                      struggle.
                    </p>
                    <p>
                      Mixed with a dash of uncertainty... and a sprinkle of
                      “Will I ever get there?”
                    </p>
                    <p>
                      And they end up trying a little bit of everything… a
                      little bit of that.
                    </p>
                    <p>
                      Soon, they’re overwhelmed, stuck in a loop of despair with
                      all the things they "should" do.
                    </p>
                    <p>We call this nightmare a rat race.</p>
                    <p>Also known as exhausting.</p>
                    <p>It’s draining and daunting.</p>
                    <p>
                      But
                      <em>there’s a light at the end of the tunnel.</em>.
                    </p>
                    <p>
                      Imagine having a CV that stands out. One that screams
                      “Hire me, I’m the best fit!”
                    </p>
                    <p>
                      Crafted by industry experts, tailored specifically for
                      you.
                    </p>
                    <p>
                      Because we know what recruiters want. We know what gets
                      results.
                    </p>
                    <p>
                      Submit your CV to us. We’ll transform it within 48 hours.
                    </p>
                    <p>For free. Yes, you read that right. Free.</p>
                    <p>
                      <strong>
                        But hurry, this offer is limited. Don’t miss out.
                      </strong>
                    </p>
                    <p>Because your dream job is out there..</p>
                    <p>
                      <span style={{ textDecoration: "underline" }}>
                        <strong>And it’s waiting for you.</strong>
                      </span>
                    </p>
                    <p>
                      [Submit My CV Now] ( insert same box for them to fill
                      their email
                    </p>
                  </div>
                </div>

                <div class="col-lg-10 col-xxl-8 null">
                  <a class="btn btn-doublelayer btn-green" href="/">
                    <span class="btn-doublelayer__maintext">
                      Create your resume for free
                    </span>
                  </a>
                  <a
                    href="https://kingkong.co/reviews"
                    itemscope=""
                    itemtype="http://schema.org/LocalBusiness"
                    class="kkra-shortstring term-agency"
                  >
                    <meta
                      itemprop="image"
                      content="https://kingkong.co/wp-content/themes/kingkong/assets/src/images/site-logo.png"
                    />
                    <meta itemprop="telephone" content="+ 1-888-303-8580" />
                    <meta itemprop="name" content="King Kong" />
                    <meta itemprop="priceRange" content="$$$" />
                    <span
                      itemprop="address"
                      itemscope=""
                      itemtype="http://schema.org/PostalAddress"
                    >
                      <meta
                        itemprop="streetAddress"
                        content="Oppenheimer Tower, 10880 Wilshire Boulevard"
                      />
                      <meta itemprop="addressLocality" content="Los Angeles" />
                      <meta itemprop="addressRegion" content="California" />
                      <meta itemprop="addressCountry" content="United States" />
                      <meta itemprop="postalCode" content="90024" />
                    </span>
                    <div
                      class="kkra-shortstring__inner"
                      itemprop="aggregateRating"
                      itemscope=""
                      itemtype="http://schema.org/AggregateRating"
                    >
                      <div class="kkra-stars">
                        <svg
                          class="unfilled"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 104 18"
                        >
                          <path
                            d="M17.2 6l-4.5-.3c-.4-.1-.7-.3-.9-.7L10.1.7C9.7-.2 8.5-.2 8.2.6L6.4 4.9c-.2.4-.5.7-.9.7L1 6C0 6.1-.3 7.3.4 8l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2 1 .8 1.7 1.6 1.2l3.9-2.4c.3-.2.8-.2 1.1 0l3.9 2.4c.8.5 1.8-.2 1.6-1.2l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3.1c.7-.6.3-1.8-.6-1.9z"
                            class="st0"
                          ></path>
                          <path
                            d="M9.1 1s.1 0 0 0l1.8 4.4c.3.6.8 1 1.6 1.2h.2l4.4.4v.1l-3.4 3.1c-.5.5-.7 1.3-.6 2l1 4.5v.1H14l-3.9-2.4c-.3-.2-.7-.3-1.1-.3-.4 0-.8.1-1.1.3L4 16.8h-.1v-.1l1-4.5c.2-.7 0-1.5-.6-2.1L1 7.1V7h.1l4.5-.4c.7 0 1.4-.5 1.7-1.3L9.1 1m0-1c-.4 0-.8.2-.9.6L6.4 4.9c-.2.4-.5.7-.9.7L1 6C0 6.1-.3 7.3.4 8l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2.8.4 1.4 1 1.4.2 0 .4-.1.6-.2l3.9-2.4c.1-.1.3-.2.5-.2s.4.1.5.2l3.9 2.4c.2.1.4.2.6.2.6 0 1.2-.6 1-1.4l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3.1c.7-.6.3-1.8-.6-1.9l-4.5-.3c-.3-.1-.6-.3-.8-.7L10.1.7c-.2-.5-.6-.7-1-.7z"
                            class="st1"
                          ></path>
                          <path
                            d="M38.6 6l-4.4-.3c-.4-.1-.7-.3-.9-.7L31.5.7c-.3-.9-1.6-.9-1.9 0L27.8 5c-.2.4-.5.7-.9.7l-4.5.4c-.9 0-1.3 1.3-.6 1.9l3.4 3c.3.3.4.7.3 1.1l-1 4.5c-.2 1 .8 1.7 1.6 1.2l3.9-2.4c.3-.2.8-.2 1.1 0l3.9 2.4c.8.5 1.8-.2 1.6-1.2l-1-4.5c-.1-.4 0-.8.3-1.1l3.3-3c.8-.7.4-1.9-.6-2z"
                            class="st0"
                          ></path>
                          <path
                            d="M30.6 1l1.8 4.4c.3.6.8 1 1.6 1.2h.2l4.3.4c.1 0 .1 0 .1.1s0 .1-.1.2l-3.3 3c-.5.5-.7 1.3-.6 2l1 4.5v.1h-.1l-3.9-2.4c-.3-.2-.7-.3-1.1-.3-.4 0-.8.1-1.1.3l-3.9 2.4h-.1v-.1l1-4.5c.2-.7 0-1.5-.6-2l-3.4-3v-.1l4.6-.5c.7 0 1.4-.5 1.7-1.3L30.6 1c-.1 0-.1 0 0 0m0-1c-.4 0-.8.2-1 .7L27.8 5c-.2.4-.5.7-.9.7l-4.5.4c-.9 0-1.3 1.3-.6 1.9l3.4 3c.3.3.4.7.3 1.1l-1 4.5c-.2.8.4 1.4 1 1.4.2 0 .4-.1.6-.2l3.9-2.4c.1-.1.3-.2.5-.2s.4.1.5.2l3.9 2.4c.2.1.4.2.6.2.6 0 1.2-.6 1-1.4l-1-4.5c-.1-.4 0-.8.3-1.1l3.3-3c.8-.7.4-1.9-.6-2l-4.4-.3c-.4-.1-.7-.3-.9-.7L31.4.7c0-.5-.4-.7-.8-.7z"
                            class="st1"
                          ></path>
                          <path
                            d="M60.1 6l-4.5-.3c-.4-.1-.7-.3-.9-.7L53 .7c-.4-.9-1.6-.9-2 0l-1.7 4.2c-.2.4-.5.7-.9.7l-4.5.4c-.9.1-1.3 1.3-.6 2l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2 1 .8 1.7 1.6 1.2l3.9-2.4c.3-.2.8-.2 1.1 0l3.9 2.4c.8.5 1.8-.2 1.6-1.2l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3c.7-.7.3-1.9-.6-2z"
                            class="st0"
                          ></path>
                          <path
                            d="M52 1s.1 0 0 0l1.8 4.3c.3.6.8 1 1.6 1.2h.2L60 7l.1.1v.2l-3.4 3c-.5.5-.7 1.3-.6 2l1 4.5v.1H57l-3.9-2.4c-.3-.2-.7-.3-1.1-.3-.4 0-.8.1-1.1.3L47 16.9h-.1v-.1l1-4.5c.2-.7 0-1.5-.6-2.1l-3.3-3c-.1-.1-.1-.2-.1-.2h.1l4.5-.4c.7 0 1.4-.5 1.7-1.3L52 1c-.1 0 0 0 0 0m0-1c-.4 0-.8.2-1 .7l-1.7 4.2c-.2.4-.5.7-.9.7l-4.5.4c-.9.1-1.3 1.3-.6 2l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2.8.4 1.4 1 1.4.2 0 .4-.1.6-.2l3.9-2.4c.1-.1.3-.2.5-.2s.4.1.5.2l3.9 2.4c.2.1.4.2.6.2.6 0 1.2-.6 1-1.4l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3c.7-.7.3-1.9-.6-2l-4.5-.3c-.4-.1-.7-.3-.9-.7L53 .7c-.2-.5-.6-.7-1-.7z"
                            class="st1"
                          ></path>
                          <g>
                            <path
                              d="M81.6 6l-4.5-.3c-.4-.1-.7-.3-.9-.7L74.4.7c-.3-.9-1.6-.9-1.8-.1l-1.8 4.3c-.2.4-.5.7-.9.7l-4.5.4c-1 .1-1.4 1.3-.6 2l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2 1 .8 1.7 1.6 1.2l3.9-2.4c.3-.2.8-.2 1.1 0l3.9 2.4c.8.5 1.8-.2 1.6-1.2l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3.1c.7-.6.3-1.8-.6-1.9z"
                              class="st0"
                            ></path>
                            <path
                              d="M73.5 1.1l1.8 4.3c.3.6.8 1 1.6 1.2h.2l4.4.4v.1l-3.4 3.1c-.5.5-.7 1.3-.6 2l1 4.5v.1h-.1l-3.9-2.4c-.3-.2-.7-.3-1.1-.3-.4 0-.8.1-1.1.3l-3.9 2.4h-.1v-.1l1-4.5c.2-.7 0-1.5-.6-2.1l-3.3-3c-.1-.1-.1-.1-.1-.2l.1-.1 4.6-.2c.7 0 1.4-.5 1.7-1.3l1.8-4.2M73.4 0c-.4 0-.7.2-.8.6l-1.8 4.3c-.2.4-.5.7-.9.7l-4.5.4c-1 .1-1.4 1.3-.6 2l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2.8.4 1.4 1 1.4.2 0 .4-.1.6-.2l3.9-2.4c.1-.1.3-.2.5-.2s.4.1.5.2l3.9 2.4c.2.1.4.2.6.2.6 0 1.2-.6 1-1.4l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3.1c.7-.6.3-1.8-.6-1.9L77 5.7c-.4-.1-.7-.3-.9-.7L74.3.7c-.1-.5-.5-.7-.9-.7z"
                              class="st1"
                            ></path>
                          </g>
                          <g>
                            <path
                              d="M103.6 7.9c.7-.6.3-1.8-.6-1.9l-4.4-.3c-.4-.1-.7-.3-.9-.7L95.9.7c-.4-.9-1.6-.9-1.9 0L92.2 5c-.2.4-.5.7-.9.7l-4.5.4c-.9 0-1.3 1.3-.6 1.9l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2 1 .8 1.7 1.6 1.2l3.9-2.4c.3-.2.8-.2 1.1 0l3.9 2.4c.8.5 1.8-.2 1.6-1.2l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3.1z"
                              class="st0"
                            ></path>
                            <path
                              d="M94.9 1c.1 0 .1.1 0 0l1.8 4.3c.3.6.8 1 1.6 1.2h.2l4.4.3v.1L99.5 10c-.5.5-.7 1.3-.6 2l1 4.5v.1h-.1l-3.8-2c-.3-.2-.7-.3-1.1-.3-.4 0-.8.1-1.1.3L89.9 17h-.1v-.1l1-4.5c.2-.7 0-1.5-.6-2.1l-3.3-3v-.1l4.5-.4c.7 0 1.4-.5 1.7-1.3L94.9 1m0-1c-.4 0-.8.2-.9.7L92.2 5c-.2.4-.5.7-.9.7l-4.5.4c-.9 0-1.3 1.3-.6 1.9l3.3 3c.3.3.4.7.3 1.1l-1 4.5c-.2.8.4 1.4 1 1.4.2 0 .4-.1.6-.2l3.9-2.4c.2-.1.3-.2.6-.2s.4.1.6.2l3.9 2.4c.2.1.4.2.6.2.6 0 1.2-.6 1-1.4l-1-4.5c-.1-.4 0-.8.3-1.1l3.4-3.1c.7-.6.3-1.8-.6-1.9l-4.4-.3c-.4-.1-.7-.3-.9-.7L96 .7c-.3-.5-.7-.7-1.1-.7z"
                              class="st1"
                            ></path>
                          </g>
                        </svg>
                        <div
                          class="kkra-stars__filled"
                          style={{ width: "90%" }}
                        >
                          <svg
                            class="filled"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 104 18"
                          >
                            <g transform="translate(-160 -526)">
                              <g transform="translate(64 308)">
                                <g transform="translate(96 218)">
                                  <g>
                                    <path
                                      d="M10.1.7L11.8 5c.2.4.5.6.9.7l4.5.3c.9.1 1.3 1.3.6 1.9L14.4 11c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1L.4 8c-.7-.7-.4-1.9.6-2l4.5-.4c.4 0 .7-.3.9-.7L8.2.6c.3-.8 1.5-.8 1.9.1"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M31.5.7L33.3 5c.2.4.5.6.9.7l4.4.3c1 .1 1.4 1.3.6 2l-3.3 3c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.4-3c-.7-.6-.3-1.9.6-1.9l4.5-.4c.4 0 .7-.3.9-.7L29.6.7c.3-.9 1.6-.9 1.9 0"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M53 .7L54.7 5c.2.4.5.6.9.7l4.5.3c.9.1 1.3 1.3.6 2l-3.4 3c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.3-3c-.7-.7-.3-1.9.6-2l4.5-.4c.4 0 .7-.3.9-.7L51 .7c.4-.9 1.6-.9 2 0"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M74.4.7L76.2 5c.2.4.5.6.9.7l4.5.3c.9.1 1.3 1.3.6 1.9L78.8 11c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2L74 15.4c-.3-.2-.8-.2-1.1 0L69 17.8c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.3-3c-.8-.7-.4-1.9.6-2l4.5-.4c.4 0 .7-.3.9-.7L72.6.6c.2-.8 1.5-.8 1.8.1"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M95.9.7L97.7 5c.2.4.5.6.9.7l4.4.3c.9.1 1.3 1.3.6 1.9l-3.4 3.1c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.3-3c-.7-.6-.3-1.9.6-1.9l4.5-.4c.4 0 .7-.3.9-.7L94 .7c.3-.9 1.5-.9 1.9 0"
                                      class="st20"
                                    ></path>
                                  </g>
                                  <g>
                                    <path
                                      d="M10.1.7L11.8 5c.2.4.5.6.9.7l4.5.3c.9.1 1.3 1.3.6 1.9L14.4 11c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1L.4 8c-.7-.7-.4-1.9.6-2l4.5-.4c.4 0 .7-.3.9-.7L8.2.6c.3-.8 1.5-.8 1.9.1"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M31.5.7L33.3 5c.2.4.5.6.9.7l4.4.3c1 .1 1.4 1.3.6 2l-3.3 3c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.4-3c-.7-.6-.3-1.9.6-1.9l4.5-.4c.4 0 .7-.3.9-.7L29.6.7c.3-.9 1.6-.9 1.9 0"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M53 .7L54.7 5c.2.4.5.6.9.7l4.5.3c.9.1 1.3 1.3.6 2l-3.4 3c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.3-3c-.7-.7-.3-1.9.6-2l4.5-.4c.4 0 .7-.3.9-.7L51 .7c.4-.9 1.6-.9 2 0"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M74.4.7L76.2 5c.2.4.5.6.9.7l4.5.3c.9.1 1.3 1.3.6 1.9L78.8 11c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2L74 15.4c-.3-.2-.8-.2-1.1 0L69 17.8c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.3-3c-.8-.7-.4-1.9.6-2l4.5-.4c.4 0 .7-.3.9-.7L72.6.6c.2-.8 1.5-.8 1.8.1"
                                      class="st20"
                                    ></path>
                                    <path
                                      d="M95.9.7L97.7 5c.2.4.5.6.9.7l4.4.3c.9.1 1.3 1.3.6 1.9l-3.4 3.1c-.3.3-.4.7-.3 1.1l1 4.5c.2 1-.8 1.7-1.6 1.2l-3.9-2.4c-.3-.2-.8-.2-1.1 0l-3.9 2.4c-.8.5-1.8-.2-1.6-1.2l1-4.5c.1-.4 0-.8-.3-1.1l-3.3-3c-.7-.6-.3-1.9.6-1.9l4.5-.4c.4 0 .7-.3.9-.7L94 .7c.3-.9 1.5-.9 1.9 0"
                                      class="st20"
                                    ></path>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </svg>
                        </div>
                      </div>
                      <span itemprop="ratingValue">4.5 </span>stars
                      <span itemprop="ratingCount">reviews</span>
                      <div
                        itemprop="itemReviewed"
                        itemscope=""
                        itemtype="http://schema.org/LocalBusiness"
                      >
                        <meta
                          itemprop="image"
                          content="https://kingkong.co/wp-content/themes/kingkong/assets/src/images/site-logo.png"
                        />
                        <meta itemprop="telephone" content="+ 1-888-303-8580" />
                        <meta itemprop="name" content="King Kong" />
                        <meta itemprop="priceRange" content="$$$" />
                        <span
                          itemprop="address"
                          itemscope=""
                          itemtype="http://schema.org/PostalAddress"
                        >
                          <meta
                            itemprop="streetAddress"
                            content="Oppenheimer Tower, 10880 Wilshire Boulevard"
                          />
                          <meta
                            itemprop="addressLocality"
                            content="Los Angeles"
                          />
                          <meta itemprop="addressRegion" content="California" />
                          <meta
                            itemprop="addressCountry"
                            content="United States"
                          />
                          <meta itemprop="postalCode" content="90024" />
                        </span>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </section>

          <section class="text-dark content-text content-global proof-generic content-wysiwyg content-textarea next-has-stripes has-stripes">
            <div class="stripes">
              <div class="stripes-bg"></div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div
              class="background-image-container"
              style={{ backgroundColor: "#F3F3F3" }}
            ></div>
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-12 text-center ">
                  <span class="h1"> Proof! </span>
                </div>
                <div className="col-12 text-center">
                  {images.map((image, index) => (
                    <img
                      key={index}
                      width="1200"
                      height="auto"
                      src={image}
                      className="attachment-container_width size-container_width"
                      alt={`Image ${index + 1}`}
                      style={{
                        width: "100%",
                        maxWidth: "1100px",
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
          </section>

          <section class="text-dark content-image content-text content-wysiwyg content-textarea">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-md-10 col-lg-9 col-xxl-8 my-5 text-center ">
                  <span class="h2">
                    Create perfect resumes for the modern job market
                  </span>
                </div>
                <div class="col-xxl-7 col-xl-8 col-md-9">
                  <p>
                    <strong>
                      Getting that Job has never been this easy! In simple
                      steps, let's help you create the perfect cv to impress
                      hiring managers and employers. Minimum time, maximum
                      professional quality.
                    </strong>
                  </p>
                  <ul class="styled-list">
                    <li>
                      <strong>1. Enter your email</strong>
                      <br></br>
                      Please provide your email address so we can contact you
                      with important updates about your job application.
                    </li>
                    <li>
                      <strong>2. Enter your full name</strong>
                      <br></br>
                      Enter your full name as it appears on your official
                      documents. This helps us personalize your application
                      process.
                    </li>
                    <li>
                      <strong>3. Share phone number</strong>
                      <br></br>
                      Share your phone number for direct communication regarding
                      your application status and any further steps.
                    </li>
                    <li>
                      <strong>4. Tell us your experience</strong>
                      <br></br>
                      Briefly describe your work experiences. Include roles,
                      responsibilities, and any notable achievements that
                      demonstrate your expertise
                    </li>
                    <li>
                      <strong>5. Educational background</strong>
                      <br></br>
                      List your key skills and educational qualifications. This
                      information helps us match you with suitable job
                      opportunities
                    </li>
                    <li>
                      <strong>6. Upload your current CV</strong>
                      <br></br>
                      Please upload your latest cover letter. This document
                      should outline your career goals and why you’re a perfect
                      fit for the roles you’re applying for
                    </li>
                    <li>
                      <strong>7. Submit</strong>
                      <br></br>
                      Review all the information provided, and click submit to
                      complete your application. Our team will review your
                      details and get back to you shortly.
                    </li>
                  </ul>
                </div>
                <div class="col-lg-10 col-xxl-8 null">
                  <a class="btn btn-doublelayer btn-green" href="/">
                    <span class="btn-doublelayer__maintext">
                      Create your resume for free
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </section>

          <section className="text-dark content-text content-wysiwyg">
            <div className="background-image-container"></div>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8 mb-5 text-center">
                  <span className="h2">
                    Here's what our client says (Review)
                  </span>
                </div>
                <div className="col-lg-7">
                  <div className="carousel">
                    <button
                      className="carousel-control prev"
                      onClick={() => showSlide(currentSlide - 1)}
                    >
                      &#10094;
                    </button>
                    <div className="carousel-inner">
                      {reviews.map((review, index) => (
                        <blockquote
                          key={index}
                          className={index === currentSlide ? "active" : ""}
                        >
                          <p style={{ textAlign: "center" }}>{review.text}</p>
                          <p>
                            <img
                              className="aligncenter wp-image-10666"
                              src={review.imgSrc}
                              alt={review.altText}
                              width="190"
                              height="54"
                            />
                          </p>
                        </blockquote>
                      ))}
                    </div>
                    <button
                      className="carousel-control next"
                      onClick={() => showSlide(currentSlide + 1)}
                    >
                      &#10095;
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*}   <section class="text-dark content-text content-wysiwyg">
            <div
              class="background-image-container"
              style={{ backgroundColor: "#f3f3f3" }}
            ></div>
            <div class="container">
              <div class="row justify-content-center">
                <div class="pill--flashing text-center col-12">
                  <span class="pill__flashing">
                    <span class="eyebrow__flashing-dot"></span>
                    Warning: We reserve the right to revoke this offer at any
                    time
                  </span>
                </div>
                <div class="col-md-10 col-xxl-9 mt-3 mb-2 mt-lg-3 mb-lg-5 text-center">
                  <span class="h2">
                    Features designed to help you win your dream job
                  </span>
                </div>
                <div class="col-xxl-7 col-xl-8 col-md-9">
                  <p>
                    There are
                    <span style={{ textDecoration: "underline" }}>
                      three important factors
                    </span>
                    which must exist before you should even think about applying
                    for this opportunity:
                  </p>
                  <ol>
                    <li>
                      <strong>
                        Make a resume that wins interviews(sign up)
                      </strong>
                      <br></br>
                      Use our resume maker with its advanced creation tools to
                      tell a professional story that engages recruiters, hiring
                      managers and even CEOs.
                    </li>
                    <li>
                      <strong>Resume writing made easy(create)</strong>
                      <br></br>
                      Resume writing has never been this effortless.
                      Pre-generated text, visual designs and more - all already
                      integrated into the resume maker. Just fill in your
                      details.
                    </li>
                    <li>
                      <strong>
                        A recruiter-tested CV maker tool(download)
                      </strong>
                      <br></br>
                      Our resume builder and its pre-generated content are
                      tested by recruiters and IT experts. We help your resume
                      become truly competitive in the hiring proces
                    </li>
                  </ol>
                </div>
                <div class="col-lg-8 null ">
                  <span class="null">
                    <a class="btn btn-doublelayer btn-green" href="/">
                      <span class="btn-doublelayer__maintext">
                        Create your Resume
                      </span>
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </section>
                      */}
          <section className="text-dark content-text content-accordion">
            <div className="background-image-container"></div>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 text-center">
                  <span className="h2">
                    YOU'VE GOT QUESTIONS,
                    <br />
                    WE'VE GOT ANSWERS.
                  </span>
                </div>
                <div className="col-lg-10">
                  <dl className="accordions">
                    {accordionData.map((item, index) => (
                      <div className="accordion" key={index}>
                        <dt
                          className={`accordion__intro ${
                            activeAccordion === index ? "active" : ""
                          }`}
                          onClick={() => toggleAccordion(index)}
                        >
                          {item.question}
                        </dt>
                        <dd
                          className={`accordion__content ${
                            activeAccordion === index ? "active" : ""
                          }`}
                        >
                          <div className="accordion__text">
                            <p>{item.answer}</p>
                          </div>
                        </dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>
            </div>
          </section>
          <section class="text-light content-global hit-the-damn-button">
            <div
              class="background-image-container"
              style={{ backgroundColor: "#000000" }}
            ></div>
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-sm-10 col-md-9 col-lg-8 col-xl-7 text-center ">
                  <span class="h2">
                    Try our resume builder today, it is for free, be among
                    people that will get that job
                  </span>
                </div>
                <div class="col-sm-9 col-md-7 col-lg-6 col-xl-6 null ">
                  <span class="null">
                    <a class="btn btn-doublelayer btn-green" href="/">
                      <span class="btn-doublelayer__maintext">
                        Create your resume
                      </span>
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </section>
        </main>
        <footer class="site-footer" id="footer-container">
          <div class="container">
            <div class="footer-columns">
              <div class="row justify-content-center">
                <div class="col footer-column-1">
                  <div
                    id="custom_html-4"
                    class="widget_text widget widget_custom_html footer-widgets-1"
                  >
                    <div class="textwidget custom-html-widget">
                      <img
                        src={w1}
                        style={{ width: "180px", height: "50px" }}
                      />
                    </div>
                  </div>
                  <div id="text-2" class="widget widget_text footer-widgets-1">
                    <div class="textwidget">
                      <p>
                        Securing a job with visa sponsorship is tough. We make
                        it a whole lot easier, more attainable, less stressful,
                        and more rewarding.
                      </p>
                    </div>
                  </div>
                  <div
                    id="kk_address_widget-2"
                    class="widget widget_kk_address_widget footer-widgets-1"
                  >
                    Submit your CV and get a professional makeover from industry
                    experts - Free for the first 60 people. Limited Offer!
                    Phone: <a href="tel:+18883038580">+447867266523</a>
                  </div>
                  <div
                    id="custom_html-2"
                    class="widget_text widget widget_custom_html footer-widgets-1"
                  >
                    <div class="textwidget custom-html-widget">
                      <a
                        href="https://facebook.com/kingkongco/"
                        rel="nofollow"
                        class="social-icon"
                        aria-label="Our Facebook"
                        style={{ color: "white !important" }}
                      >
                        <svg
                          height="512"
                          viewBox="0 0 24 24"
                          width="512"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ color: "white !important" }}
                        >
                          <path d="M15.997 3.985h2.191V.169C17.81.117 16.51 0 14.996 0c-3.159 0-5.323 1.987-5.323 5.639V9H6.187v4.266h3.486V24h4.274V13.267h3.345l.531-4.266h-3.877V6.062c.001-1.233.333-2.077 2.051-2.077z"></path>
                        </svg>
                      </a>
                      <a
                        href="https://www.instagram.com/kingkong.com.au/"
                        rel="nofollow"
                        class="social-icon"
                        aria-label="Our Instagram"
                      >
                        <svg
                          height="511pt"
                          viewBox="0 0 511 511.9"
                          width="511pt"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M510.95 150.5c-1.2-27.2-5.598-45.898-11.9-62.102-6.5-17.199-16.5-32.597-29.6-45.398-12.802-13-28.302-23.102-45.302-29.5-16.296-6.3-34.898-10.7-62.097-11.898C334.648.3 325.949 0 256.449 0s-78.199.3-105.5 1.5c-27.199 1.2-45.898 5.602-62.097 11.898-17.204 6.5-32.602 16.5-45.403 29.602-13 12.8-23.097 28.3-29.5 45.3-6.3 16.302-10.699 34.9-11.898 62.098C.75 177.801.449 186.5.449 256s.301 78.2 1.5 105.5c1.2 27.2 5.602 45.898 11.903 62.102 6.5 17.199 16.597 32.597 29.597 45.398 12.801 13 28.301 23.102 45.301 29.5 16.3 6.3 34.898 10.7 62.102 11.898 27.296 1.204 36 1.5 105.5 1.5s78.199-.296 105.5-1.5c27.199-1.199 45.898-5.597 62.097-11.898a130.934 130.934 0 0 0 74.903-74.898c6.296-16.301 10.699-34.903 11.898-62.102 1.2-27.3 1.5-36 1.5-105.5s-.102-78.2-1.3-105.5zm-46.098 209c-1.102 25-5.301 38.5-8.801 47.5-8.602 22.3-26.301 40-48.602 48.602-9 3.5-22.597 7.699-47.5 8.796-27 1.204-35.097 1.5-103.398 1.5s-76.5-.296-103.403-1.5c-25-1.097-38.5-5.296-47.5-8.796C94.551 451.5 84.45 445 76.25 436.5c-8.5-8.3-15-18.3-19.102-29.398-3.5-9-7.699-22.602-8.796-47.5-1.204-27-1.5-35.102-1.5-103.403s.296-76.5 1.5-103.398c1.097-25 5.296-38.5 8.796-47.5C61.25 94.199 67.75 84.1 76.352 75.898c8.296-8.5 18.296-15 29.398-19.097 9-3.5 22.602-7.7 47.5-8.801 27-1.2 35.102-1.5 103.398-1.5 68.403 0 76.5.3 103.403 1.5 25 1.102 38.5 5.3 47.5 8.8 11.097 4.098 21.199 10.598 29.398 19.098 8.5 8.301 15 18.301 19.102 29.403 3.5 9 7.699 22.597 8.8 47.5 1.2 27 1.5 35.097 1.5 103.398s-.3 76.301-1.5 103.301zm0 0"></path>
                          <path d="M256.45 124.5c-72.598 0-131.5 58.898-131.5 131.5s58.902 131.5 131.5 131.5c72.6 0 131.5-58.898 131.5-131.5s-58.9-131.5-131.5-131.5zm0 216.8c-47.098 0-85.302-38.198-85.302-85.3s38.204-85.3 85.301-85.3c47.102 0 85.301 38.198 85.301 85.3s-38.2 85.3-85.3 85.3zm167.402-222c0 16.954-13.747 30.7-30.704 30.7-16.953 0-30.699-13.746-30.699-30.7 0-16.956 13.746-30.698 30.7-30.698 16.956 0 30.703 13.742 30.703 30.699zm0 0"></path>
                        </svg>
                      </a>
                      <a
                        href="https://www.linkedin.com/company/kingkong/"
                        rel="nofollow"
                        class="social-icon"
                        aria-label="Our LinkedIn"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 504.4 504.4"
                        >
                          <path d="M377.6.2H126.4C56.8.2 0 57 0 126.6v251.6c0 69.2 56.8 126 126.4 126H378c69.6 0 126.4-56.8 126.4-126.4V126.6C504 57 447.2.2 377.6.2zM168 408.2H96v-208h72v208zm-36.4-240c-20.4 0-36.8-16.4-36.8-36.8s16.4-36.8 36.8-36.8 36.8 16.4 36.8 36.8c-.4 20.4-16.8 36.8-36.8 36.8zm276.8 240H348V307.4c0-24.4-3.2-55.6-36.4-55.6-34 0-39.6 26.4-39.6 54v102.4h-60v-208h56v28h1.6c8.8-16 29.2-28.4 61.2-28.4 66 0 77.6 38 77.6 94.4v114z"></path>
                        </svg>
                      </a>
                      <a
                        href="https://www.youtube.com/channel/UCwDi1NwScPpDYyvwPvHBj9g"
                        rel="nofollow"
                        class="social-icon"
                        aria-label="Our Youtube"
                      >
                        <svg
                          height="682pt"
                          viewBox="-21 -117 682.667 682"
                          width="682pt"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M626.813 64.035c-7.375-27.418-28.993-49.031-56.407-56.414C520.324-6.082 319.992-6.082 319.992-6.082s-200.324 0-250.406 13.184c-26.887 7.375-49.031 29.52-56.406 56.933C0 114.113 0 217.97 0 217.97s0 104.379 13.18 153.933c7.382 27.414 28.992 49.028 56.41 56.41C120.195 442.02 320 442.02 320 442.02s200.324 0 250.406-13.184c27.418-7.379 49.032-28.992 56.414-56.406 13.176-50.082 13.176-153.934 13.176-153.934s.527-104.383-13.183-154.46zM256.21 313.915V122.022l166.586 95.946zm0 0"></path>
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col footer-column-2">
                  <div
                    id="nav_menu-2"
                    class="widget widget_nav_menu footer-widgets-2"
                  >
                    <p class="widget-title">Company</p>
                    <div class="menu-company-container">
                      <ul id="menu-company" class="menu">
                        <li
                          id="menu-item-12074"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-12074"
                        >
                          <a href="about">About Us</a>
                        </li>
                        <li
                          id="menu-item-12070"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-12070"
                        >
                          <a href="contact">Contact Us</a>
                        </li>
                        <li
                          id="menu-item-12071"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-12071"
                        >
                          <a href="blog">Blog</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col footer-column-3">
                  <div
                    id="nav_menu-3"
                    class="widget widget_nav_menu footer-widgets-3"
                  >
                    <p class="widget-title">Services</p>
                    <div class="menu-services-container">
                      <ul id="menu-services" class="menu">
                        <li
                          id="menu-item-12075"
                          class="footer-cro-trigger menu-item menu-item-type-post_type menu-item-object-page menu-item-12075"
                        >
                          <a>We help you prepare your resume</a>
                        </li>
                        <li
                          id="menu-item-12078"
                          class="footer-ppc-trigger menu-item menu-item-type-post_type menu-item-object-page menu-item-12078"
                        >
                          <a>We help you get the Job</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col footer-column-4">
                  <div
                    id="nav_menu-4"
                    class="widget widget_nav_menu footer-widgets-4"
                  >
                    <p class="widget-title">More</p>
                    <div class="menu-solutions-container"></div>
                  </div>
                </div>
                <div class="col footer-column-5">
                  <div id="text-3" class="widget widget_text footer-widgets-5">
                    <p class="widget-title">Want to get started?</p>
                    <div class="textwidget">
                      <p>Fill in this form and lets help you get started</p>
                    </div>
                  </div>
                  <div
                    id="custom_html-3"
                    class="widget_text widget widget_custom_html footer-widgets-5"
                  >
                    <div class="textwidget custom-html-widget">
                      <form class="round-form">
                        <input type="text" placeholder="Enter your full name" />
                        <input
                          type="submit"
                          value="Hit me"
                          style={{ color: "white" }}
                        />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="copyright-line text-center">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  Copyright
                  <br class="d-none d-md-block" />
                </div>
              </div>
            </div>
          </div>
        </footer>
      </body>
    </>
  );
};

export default Home;
