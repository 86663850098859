import React, { Fragment, useEffect, useState, useRef } from "react";
import Nav from "./Nav";
import Footer from "./Footer";
import siemen from "./siemen.png";

import direct from "./direct.jpeg";
import ey from "./ey.png";
import lol from "./lol.png";
import reck from "./reck.png";
import nhs from "./nhs.jpg";
import "./ClientReviews.css";
import uni from "./uni.png";
import vision from "./vision.jpeg";
import w1 from "./w1.png";
import "./logocss.css";
import MultiStep from "./MultiStep";

import image1 from "./ww.jpg"; // Update with your actual image paths
import image2 from "./ww.jpg";

const Contact = () => {
  const images = [image1];
  const [currentSlide, setCurrentSlide] = useState(0);

  const [activeAccordion, setActiveAccordion] = useState(null);

  // Function to toggle the active accordion item
  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  // Accordion data
  const accordionData = [
    {
      question: "What does Talentiverse actually do?",
      answer:
        "Talentiverse specializes in creating expertly crafted CVs tailored to your industry. We provide comprehensive CV reviews, rewrites, and career advice to help international students secure jobs with visa sponsorships.",
    },
    {
      question: "How do I know that I will get a return on my investment?",
      answer:
        "Our CVs have a proven success rate. 97% of our clients receive interview calls after 20+ applications. We focus on making your CV stand out to recruiters, increasing your chances of securing a job with visa sponsorship.",
    },
    {
      question: "Do you work with students from all industries?",
      answer:
        "Yes, we work with students from various industries. Our team of industry experts ensures that your CV is tailored to meet the specific requirements of your field, highlighting your strengths and experiences.",
    },
    {
      question: "How quickly can I get my CV reviewed and rewritten?",
      answer:
        "For a limited time, we offer a free CV review and rewrite within 48 hours. Submit your CV on our website, and our experts will transform it to help you stand out to potential employers.",
    },
    {
      question:
        "What makes Talentiverse different from other CV writing services?",
      answer:
        "Unlike generic CV writing websites, our CVs are crafted by industry experts and tailored to your specific field. We also provide opportunities for relevant work experience and have a high success rate in helping clients secure jobs with visa sponsorships.",
    },
    {
      question:
        "Can you guarantee that I will get a job with visa sponsorship?",
      answer:
        "While we cannot guarantee a job, our CVs significantly increase your chances of getting interview calls. Our clients have a high success rate in securing jobs with visa sponsorships, thanks to our expert CV writing services and career advice.",
    },
  ];

  const reviews = [
    {
      text: "“Thanks to the outstanding CV writing service, I am now a Data Analyst at NHS. The professionalism and precision in creating my CV were remarkable. It showcased my abilities and achievements effectively, leading to a successful job offer. I also got a Visa Sponsorship. I highly recommend this service!”",
      imgSrc: nhs,
      altText: "NHS",
    },
    {
      text: "“The CV crafted for me was exceptional and instrumental in landing my job as a financial analyst at Siemens. The tean understood my career aspiration and empasised my strengths, making my application stand out. I am also grateful for their guidiance through the UK Visa sponsorship process. Their service is truly top notch”",
      imgSrc: siemen,
      altText: "Siemen",
    },
    {
      text: "“Although i didnt secure the position, getting to the interview stage for a Tax Analyst at EY was a significant milestone for me, and it wouldn't have been possible without the outstanding CV from Talentiverse. The level of customization and the strategic presentation of my qualifications were impressive. It definitely helped me get notied and i feel more confident about my future application”",
      imgSrc: ey,
      altText: "EY",
    },
    // {
    //   text: "“This service helped me to present my skills in the best possible way. I secured a position at Microsoft as a Product Manager. Highly recommended!”",
    //   imgSrc: nhs,
    //   altText: "Microsoft",
    // },
  ];

  const showSlide = (index) => {
    if (index >= reviews.length) {
      setCurrentSlide(0);
    } else if (index < 0) {
      setCurrentSlide(reviews.length - 1);
    } else {
      setCurrentSlide(index);
    }
  };
  return (
    <>
      <body class="page-template-default page page-id-12401 wp-custom-logo page-contact">
        <main class="site-content">
          <section class="text-light hero hero-bg  content-code content-text content-textarea next-has-stripes has-stripes">
            <div class="stripes">
              <div class="stripes-bg"></div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>{" "}
            <div
              class="background-image-container"
              style={{ backgroundColor: "#474747" }}
            ></div>
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-lg-10 hero-ratings">
                  <div class="hero-stars d-lg-flex justify-content-around mb-3 text-center">
                    <div class="mb-5"></div>
                    <div class="mb-5 d-none d-md-block"></div>
                    <div class="mb-5 d-none d-md-block"></div>
                  </div>
                </div>
                <div class="col-md-10 text-center fade-in">
                  <span class="h1">This Is Really Exciting</span>
                </div>
                <div class="col-lg-8 subheadline text-center">
                  We don’t take you reaching out to us lightly. In fact, you've
                  just turned us into a bunch of excited, motivated
                  professionals ready to help you conquer th job market. Your
                  career journey is about to get a whole lot more interesting!
                </div>
              </div>
            </div>
          </section>

          <section class="text-dark content-text content-code content-wysiwyg content-textarea next-has-stripes has-stripes">
            <div
              class="background-image-container"
              style={{ backgroundColor: "#ffffff" }}
            ></div>
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-lg-7">
                  <form
                    accept-charset="UTF-8"
                    action="https://wg216.infusionsoft.com/app/form/process/229ed7a77dc095d1243b7a8c6f87eb20"
                    class="infusion-form kk-validation"
                    id="inf_form_229ed7a77dc095d1243b7a8c6f87eb20"
                    method="POST"
                    novalidate="true"
                  >
                    <input
                      name="inf_form_xid"
                      type="hidden"
                      value="229ed7a77dc095d1243b7a8c6f87eb20"
                    />
                    <input
                      name="inf_form_name"
                      type="hidden"
                      value="Contact Us Form For&amp;#a;Global
Website"
                    />
                    <input
                      name="infusionsoft_version"
                      type="hidden"
                      value="1.70.0.358781"
                    />
                    <div class="infusion-field">
                      <label class="d-none" for="inf_field_FirstName">
                        First Name *
                      </label>
                      <input
                        id="inf_field_FirstName"
                        name="inf_field_FirstName"
                        placeholder="First Name *"
                        type="text"
                        required=""
                      />
                    </div>
                    <div class="infusion-field">
                      <label class="d-none" for="inf_field_LastName">
                        Last Name *
                      </label>
                      <input
                        id="inf_field_LastName"
                        name="inf_field_LastName"
                        placeholder="Last Name *"
                        type="text"
                        required=""
                      />
                    </div>
                    <div class="infusion-field">
                      <label class="d-none" for="inf_field_Email">
                        Email *
                      </label>
                      <input
                        id="inf_field_Email"
                        name="inf_field_Email"
                        placeholder="Email *"
                        type="email"
                        required=""
                      />
                    </div>
                    <div class="infusion-field">
                      <label class="d-none" for="inf_field_Phone1">
                        Phone 1 *
                      </label>
                      <div class="iti iti--allow-dropdown iti--separate-dial-code">
                        <div class="iti__flag-container">
                          <div
                            class="iti__selected-flag"
                            role="combobox"
                            aria-controls="iti-0__country-listbox"
                            aria-owns="iti-0__country-listbox"
                            aria-expanded="false"
                            tabindex="0"
                            title="Nigeria: +234"
                            aria-activedescendant="iti-0__item-ng"
                          >
                            <div class="iti__flag iti__ng"></div>
                            <div class="iti__selected-dial-code">+234</div>
                            <div class="iti__arrow"></div>
                          </div>
                          <ul
                            class="iti__country-list iti__hide"
                            id="iti-0__country-listbox"
                            role="listbox"
                            aria-label="List of countries"
                          >
                            <li
                              class="iti__country iti__preferred"
                              tabindex="-1"
                              id="iti-0__item-us-preferred"
                              role="option"
                              data-dial-code="1"
                              data-country-code="us"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__us"></div>
                              </div>
                              <span class="iti__country-name">
                                United States
                              </span>
                              <span class="iti__dial-code">+1</span>
                            </li>
                            <li
                              class="iti__country iti__preferred"
                              tabindex="-1"
                              id="iti-0__item-ca-preferred"
                              role="option"
                              data-dial-code="1"
                              data-country-code="ca"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__ca"></div>
                              </div>
                              <span class="iti__country-name">Canada</span>
                              <span class="iti__dial-code">+1</span>
                            </li>
                            <li
                              class="iti__country iti__preferred"
                              tabindex="-1"
                              id="iti-0__item-au-preferred"
                              role="option"
                              data-dial-code="61"
                              data-country-code="au"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__au"></div>
                              </div>
                              <span class="iti__country-name">Australia</span>
                              <span class="iti__dial-code">+61</span>
                            </li>
                            <li
                              class="iti__country iti__preferred"
                              tabindex="-1"
                              id="iti-0__item-nz-preferred"
                              role="option"
                              data-dial-code="64"
                              data-country-code="nz"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__nz"></div>
                              </div>
                              <span class="iti__country-name">New Zealand</span>
                              <span class="iti__dial-code">+64</span>
                            </li>
                            <li
                              class="iti__country iti__preferred"
                              tabindex="-1"
                              id="iti-0__item-ie-preferred"
                              role="option"
                              data-dial-code="353"
                              data-country-code="ie"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__ie"></div>
                              </div>
                              <span class="iti__country-name">Ireland</span>
                              <span class="iti__dial-code">+353</span>
                            </li>
                            <li
                              class="iti__divider"
                              role="separator"
                              aria-disabled="true"
                            ></li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-af"
                              role="option"
                              data-dial-code="93"
                              data-country-code="af"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__af"></div>
                              </div>
                              <span class="iti__country-name">
                                Afghanistan (‫افغانستان‬‎)
                              </span>
                              <span class="iti__dial-code">+93</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-al"
                              role="option"
                              data-dial-code="355"
                              data-country-code="al"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__al"></div>
                              </div>
                              <span class="iti__country-name">
                                Albania (Shqipëri)
                              </span>
                              <span class="iti__dial-code">+355</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-dz"
                              role="option"
                              data-dial-code="213"
                              data-country-code="dz"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__dz"></div>
                              </div>
                              <span class="iti__country-name">
                                Algeria (‫الجزائر‬‎)
                              </span>
                              <span class="iti__dial-code">+213</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-as"
                              role="option"
                              data-dial-code="1"
                              data-country-code="as"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__as"></div>
                              </div>
                              <span class="iti__country-name">
                                American Samoa
                              </span>
                              <span class="iti__dial-code">+1</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-ad"
                              role="option"
                              data-dial-code="376"
                              data-country-code="ad"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__ad"></div>
                              </div>
                              <span class="iti__country-name">Andorra</span>
                              <span class="iti__dial-code">+376</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-ao"
                              role="option"
                              data-dial-code="244"
                              data-country-code="ao"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__ao"></div>
                              </div>
                              <span class="iti__country-name">Angola</span>
                              <span class="iti__dial-code">+244</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-ai"
                              role="option"
                              data-dial-code="1"
                              data-country-code="ai"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__ai"></div>
                              </div>
                              <span class="iti__country-name">Anguilla</span>
                              <span class="iti__dial-code">+1</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-ag"
                              role="option"
                              data-dial-code="1"
                              data-country-code="ag"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__ag"></div>
                              </div>
                              <span class="iti__country-name">
                                Antigua and Barbuda
                              </span>
                              <span class="iti__dial-code">+1</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-ar"
                              role="option"
                              data-dial-code="54"
                              data-country-code="ar"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__ar"></div>
                              </div>
                              <span class="iti__country-name">Argentina</span>
                              <span class="iti__dial-code">+54</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-am"
                              role="option"
                              data-dial-code="374"
                              data-country-code="am"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__am"></div>
                              </div>
                              <span class="iti__country-name">
                                Armenia (Հայաստան)
                              </span>
                              <span class="iti__dial-code">+374</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-aw"
                              role="option"
                              data-dial-code="297"
                              data-country-code="aw"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__aw"></div>
                              </div>
                              <span class="iti__country-name">Aruba</span>
                              <span class="iti__dial-code">+297</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-ac"
                              role="option"
                              data-dial-code="247"
                              data-country-code="ac"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__ac"></div>
                              </div>
                              <span class="iti__country-name">
                                Ascension Island
                              </span>
                              <span class="iti__dial-code">+247</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-au"
                              role="option"
                              data-dial-code="61"
                              data-country-code="au"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__au"></div>
                              </div>
                              <span class="iti__country-name">Australia</span>
                              <span class="iti__dial-code">+61</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-at"
                              role="option"
                              data-dial-code="43"
                              data-country-code="at"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__at"></div>
                              </div>
                              <span class="iti__country-name">
                                Austria (Österreich)
                              </span>
                              <span class="iti__dial-code">+43</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-az"
                              role="option"
                              data-dial-code="994"
                              data-country-code="az"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__az"></div>
                              </div>
                              <span class="iti__country-name">
                                Azerbaijan (Azərbaycan)
                              </span>
                              <span class="iti__dial-code">+994</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-bs"
                              role="option"
                              data-dial-code="1"
                              data-country-code="bs"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__bs"></div>
                              </div>
                              <span class="iti__country-name">Bahamas</span>
                              <span class="iti__dial-code">+1</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-bh"
                              role="option"
                              data-dial-code="973"
                              data-country-code="bh"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__bh"></div>
                              </div>
                              <span class="iti__country-name">
                                Bahrain (‫البحرين‬‎)
                              </span>
                              <span class="iti__dial-code">+973</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-bd"
                              role="option"
                              data-dial-code="880"
                              data-country-code="bd"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__bd"></div>
                              </div>
                              <span class="iti__country-name">
                                Bangladesh (বাংলাদেশ)
                              </span>
                              <span class="iti__dial-code">+880</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-bb"
                              role="option"
                              data-dial-code="1"
                              data-country-code="bb"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__bb"></div>
                              </div>
                              <span class="iti__country-name">Barbados</span>
                              <span class="iti__dial-code">+1</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-by"
                              role="option"
                              data-dial-code="375"
                              data-country-code="by"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__by"></div>
                              </div>
                              <span class="iti__country-name">
                                Belarus (Беларусь)
                              </span>
                              <span class="iti__dial-code">+375</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-be"
                              role="option"
                              data-dial-code="32"
                              data-country-code="be"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__be"></div>
                              </div>
                              <span class="iti__country-name">
                                Belgium (België)
                              </span>
                              <span class="iti__dial-code">+32</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-bz"
                              role="option"
                              data-dial-code="501"
                              data-country-code="bz"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__bz"></div>
                              </div>
                              <span class="iti__country-name">Belize</span>
                              <span class="iti__dial-code">+501</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-bj"
                              role="option"
                              data-dial-code="229"
                              data-country-code="bj"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__bj"></div>
                              </div>
                              <span class="iti__country-name">
                                Benin (Bénin)
                              </span>
                              <span class="iti__dial-code">+229</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-bm"
                              role="option"
                              data-dial-code="1"
                              data-country-code="bm"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__bm"></div>
                              </div>
                              <span class="iti__country-name">Bermuda</span>
                              <span class="iti__dial-code">+1</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-bt"
                              role="option"
                              data-dial-code="975"
                              data-country-code="bt"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__bt"></div>
                              </div>
                              <span class="iti__country-name">
                                Bhutan (འབྲུག)
                              </span>
                              <span class="iti__dial-code">+975</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-bo"
                              role="option"
                              data-dial-code="591"
                              data-country-code="bo"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__bo"></div>
                              </div>
                              <span class="iti__country-name">Bolivia</span>
                              <span class="iti__dial-code">+591</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-ba"
                              role="option"
                              data-dial-code="387"
                              data-country-code="ba"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__ba"></div>
                              </div>
                              <span class="iti__country-name">
                                Bosnia and Herzegovina (Босна и Херцеговина)
                              </span>
                              <span class="iti__dial-code">+387</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-bw"
                              role="option"
                              data-dial-code="267"
                              data-country-code="bw"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__bw"></div>
                              </div>
                              <span class="iti__country-name">Botswana</span>
                              <span class="iti__dial-code">+267</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-br"
                              role="option"
                              data-dial-code="55"
                              data-country-code="br"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__br"></div>
                              </div>
                              <span class="iti__country-name">
                                Brazil (Brasil)
                              </span>
                              <span class="iti__dial-code">+55</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-io"
                              role="option"
                              data-dial-code="246"
                              data-country-code="io"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__io"></div>
                              </div>
                              <span class="iti__country-name">
                                British Indian Ocean Territory
                              </span>
                              <span class="iti__dial-code">+246</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-vg"
                              role="option"
                              data-dial-code="1"
                              data-country-code="vg"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__vg"></div>
                              </div>
                              <span class="iti__country-name">
                                British Virgin Islands
                              </span>
                              <span class="iti__dial-code">+1</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-bn"
                              role="option"
                              data-dial-code="673"
                              data-country-code="bn"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__bn"></div>
                              </div>
                              <span class="iti__country-name">Brunei</span>
                              <span class="iti__dial-code">+673</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-bg"
                              role="option"
                              data-dial-code="359"
                              data-country-code="bg"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__bg"></div>
                              </div>
                              <span class="iti__country-name">
                                Bulgaria (България)
                              </span>
                              <span class="iti__dial-code">+359</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-bf"
                              role="option"
                              data-dial-code="226"
                              data-country-code="bf"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__bf"></div>
                              </div>
                              <span class="iti__country-name">
                                Burkina Faso
                              </span>
                              <span class="iti__dial-code">+226</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-bi"
                              role="option"
                              data-dial-code="257"
                              data-country-code="bi"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__bi"></div>
                              </div>
                              <span class="iti__country-name">
                                Burundi (Uburundi)
                              </span>
                              <span class="iti__dial-code">+257</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-kh"
                              role="option"
                              data-dial-code="855"
                              data-country-code="kh"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__kh"></div>
                              </div>
                              <span class="iti__country-name">
                                Cambodia (កម្ពុជា)
                              </span>
                              <span class="iti__dial-code">+855</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-cm"
                              role="option"
                              data-dial-code="237"
                              data-country-code="cm"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__cm"></div>
                              </div>
                              <span class="iti__country-name">
                                Cameroon (Cameroun)
                              </span>
                              <span class="iti__dial-code">+237</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-ca"
                              role="option"
                              data-dial-code="1"
                              data-country-code="ca"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__ca"></div>
                              </div>
                              <span class="iti__country-name">Canada</span>
                              <span class="iti__dial-code">+1</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-cv"
                              role="option"
                              data-dial-code="238"
                              data-country-code="cv"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__cv"></div>
                              </div>
                              <span class="iti__country-name">
                                Cape Verde (Kabu Verdi)
                              </span>
                              <span class="iti__dial-code">+238</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-bq"
                              role="option"
                              data-dial-code="599"
                              data-country-code="bq"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__bq"></div>
                              </div>
                              <span class="iti__country-name">
                                Caribbean Netherlands
                              </span>
                              <span class="iti__dial-code">+599</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-ky"
                              role="option"
                              data-dial-code="1"
                              data-country-code="ky"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__ky"></div>
                              </div>
                              <span class="iti__country-name">
                                Cayman Islands
                              </span>
                              <span class="iti__dial-code">+1</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-cf"
                              role="option"
                              data-dial-code="236"
                              data-country-code="cf"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__cf"></div>
                              </div>
                              <span class="iti__country-name">
                                Central African Republic (République
                                centrafricaine)
                              </span>
                              <span class="iti__dial-code">+236</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-td"
                              role="option"
                              data-dial-code="235"
                              data-country-code="td"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__td"></div>
                              </div>
                              <span class="iti__country-name">
                                Chad (Tchad)
                              </span>
                              <span class="iti__dial-code">+235</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-cl"
                              role="option"
                              data-dial-code="56"
                              data-country-code="cl"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__cl"></div>
                              </div>
                              <span class="iti__country-name">Chile</span>
                              <span class="iti__dial-code">+56</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-cn"
                              role="option"
                              data-dial-code="86"
                              data-country-code="cn"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__cn"></div>
                              </div>
                              <span class="iti__country-name">
                                China (中国)
                              </span>
                              <span class="iti__dial-code">+86</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-cx"
                              role="option"
                              data-dial-code="61"
                              data-country-code="cx"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__cx"></div>
                              </div>
                              <span class="iti__country-name">
                                Christmas Island
                              </span>
                              <span class="iti__dial-code">+61</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-cc"
                              role="option"
                              data-dial-code="61"
                              data-country-code="cc"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__cc"></div>
                              </div>
                              <span class="iti__country-name">
                                Cocos (Keeling) Islands
                              </span>
                              <span class="iti__dial-code">+61</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-co"
                              role="option"
                              data-dial-code="57"
                              data-country-code="co"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__co"></div>
                              </div>
                              <span class="iti__country-name">Colombia</span>
                              <span class="iti__dial-code">+57</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-km"
                              role="option"
                              data-dial-code="269"
                              data-country-code="km"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__km"></div>
                              </div>
                              <span class="iti__country-name">
                                Comoros (‫جزر القمر‬‎)
                              </span>
                              <span class="iti__dial-code">+269</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-cd"
                              role="option"
                              data-dial-code="243"
                              data-country-code="cd"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__cd"></div>
                              </div>
                              <span class="iti__country-name">
                                Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)
                              </span>
                              <span class="iti__dial-code">+243</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-cg"
                              role="option"
                              data-dial-code="242"
                              data-country-code="cg"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__cg"></div>
                              </div>
                              <span class="iti__country-name">
                                Congo (Republic) (Congo-Brazzaville)
                              </span>
                              <span class="iti__dial-code">+242</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-ck"
                              role="option"
                              data-dial-code="682"
                              data-country-code="ck"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__ck"></div>
                              </div>
                              <span class="iti__country-name">
                                Cook Islands
                              </span>
                              <span class="iti__dial-code">+682</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-cr"
                              role="option"
                              data-dial-code="506"
                              data-country-code="cr"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__cr"></div>
                              </div>
                              <span class="iti__country-name">Costa Rica</span>
                              <span class="iti__dial-code">+506</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-ci"
                              role="option"
                              data-dial-code="225"
                              data-country-code="ci"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__ci"></div>
                              </div>
                              <span class="iti__country-name">
                                Côte d’Ivoire
                              </span>
                              <span class="iti__dial-code">+225</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-hr"
                              role="option"
                              data-dial-code="385"
                              data-country-code="hr"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__hr"></div>
                              </div>
                              <span class="iti__country-name">
                                Croatia (Hrvatska)
                              </span>
                              <span class="iti__dial-code">+385</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-cu"
                              role="option"
                              data-dial-code="53"
                              data-country-code="cu"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__cu"></div>
                              </div>
                              <span class="iti__country-name">Cuba</span>
                              <span class="iti__dial-code">+53</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-cw"
                              role="option"
                              data-dial-code="599"
                              data-country-code="cw"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__cw"></div>
                              </div>
                              <span class="iti__country-name">Curaçao</span>
                              <span class="iti__dial-code">+599</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-cy"
                              role="option"
                              data-dial-code="357"
                              data-country-code="cy"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__cy"></div>
                              </div>
                              <span class="iti__country-name">
                                Cyprus (Κύπρος)
                              </span>
                              <span class="iti__dial-code">+357</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-cz"
                              role="option"
                              data-dial-code="420"
                              data-country-code="cz"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__cz"></div>
                              </div>
                              <span class="iti__country-name">
                                Czech Republic (Česká republika)
                              </span>
                              <span class="iti__dial-code">+420</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-dk"
                              role="option"
                              data-dial-code="45"
                              data-country-code="dk"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__dk"></div>
                              </div>
                              <span class="iti__country-name">
                                Denmark (Danmark)
                              </span>
                              <span class="iti__dial-code">+45</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-dj"
                              role="option"
                              data-dial-code="253"
                              data-country-code="dj"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__dj"></div>
                              </div>
                              <span class="iti__country-name">Djibouti</span>
                              <span class="iti__dial-code">+253</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-dm"
                              role="option"
                              data-dial-code="1"
                              data-country-code="dm"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__dm"></div>
                              </div>
                              <span class="iti__country-name">Dominica</span>
                              <span class="iti__dial-code">+1</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-do"
                              role="option"
                              data-dial-code="1"
                              data-country-code="do"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__do"></div>
                              </div>
                              <span class="iti__country-name">
                                Dominican Republic (República Dominicana)
                              </span>
                              <span class="iti__dial-code">+1</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-ec"
                              role="option"
                              data-dial-code="593"
                              data-country-code="ec"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__ec"></div>
                              </div>
                              <span class="iti__country-name">Ecuador</span>
                              <span class="iti__dial-code">+593</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-eg"
                              role="option"
                              data-dial-code="20"
                              data-country-code="eg"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__eg"></div>
                              </div>
                              <span class="iti__country-name">
                                Egypt (‫مصر‬‎)
                              </span>
                              <span class="iti__dial-code">+20</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-sv"
                              role="option"
                              data-dial-code="503"
                              data-country-code="sv"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__sv"></div>
                              </div>
                              <span class="iti__country-name">El Salvador</span>
                              <span class="iti__dial-code">+503</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-gq"
                              role="option"
                              data-dial-code="240"
                              data-country-code="gq"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__gq"></div>
                              </div>
                              <span class="iti__country-name">
                                Equatorial Guinea (Guinea Ecuatorial)
                              </span>
                              <span class="iti__dial-code">+240</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-er"
                              role="option"
                              data-dial-code="291"
                              data-country-code="er"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__er"></div>
                              </div>
                              <span class="iti__country-name">Eritrea</span>
                              <span class="iti__dial-code">+291</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-ee"
                              role="option"
                              data-dial-code="372"
                              data-country-code="ee"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__ee"></div>
                              </div>
                              <span class="iti__country-name">
                                Estonia (Eesti)
                              </span>
                              <span class="iti__dial-code">+372</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-sz"
                              role="option"
                              data-dial-code="268"
                              data-country-code="sz"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__sz"></div>
                              </div>
                              <span class="iti__country-name">Eswatini</span>
                              <span class="iti__dial-code">+268</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-et"
                              role="option"
                              data-dial-code="251"
                              data-country-code="et"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__et"></div>
                              </div>
                              <span class="iti__country-name">Ethiopia</span>
                              <span class="iti__dial-code">+251</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-fk"
                              role="option"
                              data-dial-code="500"
                              data-country-code="fk"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__fk"></div>
                              </div>
                              <span class="iti__country-name">
                                Falkland Islands (Islas Malvinas)
                              </span>
                              <span class="iti__dial-code">+500</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-fo"
                              role="option"
                              data-dial-code="298"
                              data-country-code="fo"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__fo"></div>
                              </div>
                              <span class="iti__country-name">
                                Faroe Islands (Føroyar)
                              </span>
                              <span class="iti__dial-code">+298</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-fj"
                              role="option"
                              data-dial-code="679"
                              data-country-code="fj"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__fj"></div>
                              </div>
                              <span class="iti__country-name">Fiji</span>
                              <span class="iti__dial-code">+679</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-fi"
                              role="option"
                              data-dial-code="358"
                              data-country-code="fi"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__fi"></div>
                              </div>
                              <span class="iti__country-name">
                                Finland (Suomi)
                              </span>
                              <span class="iti__dial-code">+358</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-fr"
                              role="option"
                              data-dial-code="33"
                              data-country-code="fr"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__fr"></div>
                              </div>
                              <span class="iti__country-name">France</span>
                              <span class="iti__dial-code">+33</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-gf"
                              role="option"
                              data-dial-code="594"
                              data-country-code="gf"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__gf"></div>
                              </div>
                              <span class="iti__country-name">
                                French Guiana (Guyane française)
                              </span>
                              <span class="iti__dial-code">+594</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-pf"
                              role="option"
                              data-dial-code="689"
                              data-country-code="pf"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__pf"></div>
                              </div>
                              <span class="iti__country-name">
                                French Polynesia (Polynésie française)
                              </span>
                              <span class="iti__dial-code">+689</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-ga"
                              role="option"
                              data-dial-code="241"
                              data-country-code="ga"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__ga"></div>
                              </div>
                              <span class="iti__country-name">Gabon</span>
                              <span class="iti__dial-code">+241</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-gm"
                              role="option"
                              data-dial-code="220"
                              data-country-code="gm"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__gm"></div>
                              </div>
                              <span class="iti__country-name">Gambia</span>
                              <span class="iti__dial-code">+220</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-ge"
                              role="option"
                              data-dial-code="995"
                              data-country-code="ge"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__ge"></div>
                              </div>
                              <span class="iti__country-name">
                                Georgia (საქართველო)
                              </span>
                              <span class="iti__dial-code">+995</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-de"
                              role="option"
                              data-dial-code="49"
                              data-country-code="de"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__de"></div>
                              </div>
                              <span class="iti__country-name">
                                Germany (Deutschland)
                              </span>
                              <span class="iti__dial-code">+49</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-gh"
                              role="option"
                              data-dial-code="233"
                              data-country-code="gh"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__gh"></div>
                              </div>
                              <span class="iti__country-name">
                                Ghana (Gaana)
                              </span>
                              <span class="iti__dial-code">+233</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-gi"
                              role="option"
                              data-dial-code="350"
                              data-country-code="gi"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__gi"></div>
                              </div>
                              <span class="iti__country-name">Gibraltar</span>
                              <span class="iti__dial-code">+350</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-gr"
                              role="option"
                              data-dial-code="30"
                              data-country-code="gr"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__gr"></div>
                              </div>
                              <span class="iti__country-name">
                                Greece (Ελλάδα)
                              </span>
                              <span class="iti__dial-code">+30</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-gl"
                              role="option"
                              data-dial-code="299"
                              data-country-code="gl"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__gl"></div>
                              </div>
                              <span class="iti__country-name">
                                Greenland (Kalaallit Nunaat)
                              </span>
                              <span class="iti__dial-code">+299</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-gd"
                              role="option"
                              data-dial-code="1"
                              data-country-code="gd"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__gd"></div>
                              </div>
                              <span class="iti__country-name">Grenada</span>
                              <span class="iti__dial-code">+1</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-gp"
                              role="option"
                              data-dial-code="590"
                              data-country-code="gp"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__gp"></div>
                              </div>
                              <span class="iti__country-name">Guadeloupe</span>
                              <span class="iti__dial-code">+590</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-gu"
                              role="option"
                              data-dial-code="1"
                              data-country-code="gu"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__gu"></div>
                              </div>
                              <span class="iti__country-name">Guam</span>
                              <span class="iti__dial-code">+1</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-gt"
                              role="option"
                              data-dial-code="502"
                              data-country-code="gt"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__gt"></div>
                              </div>
                              <span class="iti__country-name">Guatemala</span>
                              <span class="iti__dial-code">+502</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-gg"
                              role="option"
                              data-dial-code="44"
                              data-country-code="gg"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__gg"></div>
                              </div>
                              <span class="iti__country-name">Guernsey</span>
                              <span class="iti__dial-code">+44</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-gn"
                              role="option"
                              data-dial-code="224"
                              data-country-code="gn"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__gn"></div>
                              </div>
                              <span class="iti__country-name">
                                Guinea (Guinée)
                              </span>
                              <span class="iti__dial-code">+224</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-gw"
                              role="option"
                              data-dial-code="245"
                              data-country-code="gw"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__gw"></div>
                              </div>
                              <span class="iti__country-name">
                                Guinea-Bissau (Guiné Bissau)
                              </span>
                              <span class="iti__dial-code">+245</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-gy"
                              role="option"
                              data-dial-code="592"
                              data-country-code="gy"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__gy"></div>
                              </div>
                              <span class="iti__country-name">Guyana</span>
                              <span class="iti__dial-code">+592</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-ht"
                              role="option"
                              data-dial-code="509"
                              data-country-code="ht"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__ht"></div>
                              </div>
                              <span class="iti__country-name">Haiti</span>
                              <span class="iti__dial-code">+509</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-hn"
                              role="option"
                              data-dial-code="504"
                              data-country-code="hn"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__hn"></div>
                              </div>
                              <span class="iti__country-name">Honduras</span>
                              <span class="iti__dial-code">+504</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-hk"
                              role="option"
                              data-dial-code="852"
                              data-country-code="hk"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__hk"></div>
                              </div>
                              <span class="iti__country-name">
                                Hong Kong (香港)
                              </span>
                              <span class="iti__dial-code">+852</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-hu"
                              role="option"
                              data-dial-code="36"
                              data-country-code="hu"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__hu"></div>
                              </div>
                              <span class="iti__country-name">
                                Hungary (Magyarország)
                              </span>
                              <span class="iti__dial-code">+36</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-is"
                              role="option"
                              data-dial-code="354"
                              data-country-code="is"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__is"></div>
                              </div>
                              <span class="iti__country-name">
                                Iceland (Ísland)
                              </span>
                              <span class="iti__dial-code">+354</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-in"
                              role="option"
                              data-dial-code="91"
                              data-country-code="in"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__in"></div>
                              </div>
                              <span class="iti__country-name">
                                India (भारत)
                              </span>
                              <span class="iti__dial-code">+91</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-id"
                              role="option"
                              data-dial-code="62"
                              data-country-code="id"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__id"></div>
                              </div>
                              <span class="iti__country-name">Indonesia</span>
                              <span class="iti__dial-code">+62</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-ir"
                              role="option"
                              data-dial-code="98"
                              data-country-code="ir"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__ir"></div>
                              </div>
                              <span class="iti__country-name">
                                Iran (‫ایران‬‎)
                              </span>
                              <span class="iti__dial-code">+98</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-iq"
                              role="option"
                              data-dial-code="964"
                              data-country-code="iq"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__iq"></div>
                              </div>
                              <span class="iti__country-name">
                                Iraq (‫العراق‬‎)
                              </span>
                              <span class="iti__dial-code">+964</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-ie"
                              role="option"
                              data-dial-code="353"
                              data-country-code="ie"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__ie"></div>
                              </div>
                              <span class="iti__country-name">Ireland</span>
                              <span class="iti__dial-code">+353</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-im"
                              role="option"
                              data-dial-code="44"
                              data-country-code="im"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__im"></div>
                              </div>
                              <span class="iti__country-name">Isle of Man</span>
                              <span class="iti__dial-code">+44</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-il"
                              role="option"
                              data-dial-code="972"
                              data-country-code="il"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__il"></div>
                              </div>
                              <span class="iti__country-name">
                                Israel (‫ישראל‬‎)
                              </span>
                              <span class="iti__dial-code">+972</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-it"
                              role="option"
                              data-dial-code="39"
                              data-country-code="it"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__it"></div>
                              </div>
                              <span class="iti__country-name">
                                Italy (Italia)
                              </span>
                              <span class="iti__dial-code">+39</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-jm"
                              role="option"
                              data-dial-code="1"
                              data-country-code="jm"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__jm"></div>
                              </div>
                              <span class="iti__country-name">Jamaica</span>
                              <span class="iti__dial-code">+1</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-jp"
                              role="option"
                              data-dial-code="81"
                              data-country-code="jp"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__jp"></div>
                              </div>
                              <span class="iti__country-name">
                                Japan (日本)
                              </span>
                              <span class="iti__dial-code">+81</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-je"
                              role="option"
                              data-dial-code="44"
                              data-country-code="je"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__je"></div>
                              </div>
                              <span class="iti__country-name">Jersey</span>
                              <span class="iti__dial-code">+44</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-jo"
                              role="option"
                              data-dial-code="962"
                              data-country-code="jo"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__jo"></div>
                              </div>
                              <span class="iti__country-name">
                                Jordan (‫الأردن‬‎)
                              </span>
                              <span class="iti__dial-code">+962</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-kz"
                              role="option"
                              data-dial-code="7"
                              data-country-code="kz"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__kz"></div>
                              </div>
                              <span class="iti__country-name">
                                Kazakhstan (Казахстан)
                              </span>
                              <span class="iti__dial-code">+7</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-ke"
                              role="option"
                              data-dial-code="254"
                              data-country-code="ke"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__ke"></div>
                              </div>
                              <span class="iti__country-name">Kenya</span>
                              <span class="iti__dial-code">+254</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-ki"
                              role="option"
                              data-dial-code="686"
                              data-country-code="ki"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__ki"></div>
                              </div>
                              <span class="iti__country-name">Kiribati</span>
                              <span class="iti__dial-code">+686</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-xk"
                              role="option"
                              data-dial-code="383"
                              data-country-code="xk"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__xk"></div>
                              </div>
                              <span class="iti__country-name">Kosovo</span>
                              <span class="iti__dial-code">+383</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-kw"
                              role="option"
                              data-dial-code="965"
                              data-country-code="kw"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__kw"></div>
                              </div>
                              <span class="iti__country-name">
                                Kuwait (‫الكويت‬‎)
                              </span>
                              <span class="iti__dial-code">+965</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-kg"
                              role="option"
                              data-dial-code="996"
                              data-country-code="kg"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__kg"></div>
                              </div>
                              <span class="iti__country-name">
                                Kyrgyzstan (Кыргызстан)
                              </span>
                              <span class="iti__dial-code">+996</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-la"
                              role="option"
                              data-dial-code="856"
                              data-country-code="la"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__la"></div>
                              </div>
                              <span class="iti__country-name">Laos (ລາວ)</span>
                              <span class="iti__dial-code">+856</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-lv"
                              role="option"
                              data-dial-code="371"
                              data-country-code="lv"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__lv"></div>
                              </div>
                              <span class="iti__country-name">
                                Latvia (Latvija)
                              </span>
                              <span class="iti__dial-code">+371</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-lb"
                              role="option"
                              data-dial-code="961"
                              data-country-code="lb"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__lb"></div>
                              </div>
                              <span class="iti__country-name">
                                Lebanon (‫لبنان‬‎)
                              </span>
                              <span class="iti__dial-code">+961</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-ls"
                              role="option"
                              data-dial-code="266"
                              data-country-code="ls"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__ls"></div>
                              </div>
                              <span class="iti__country-name">Lesotho</span>
                              <span class="iti__dial-code">+266</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-lr"
                              role="option"
                              data-dial-code="231"
                              data-country-code="lr"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__lr"></div>
                              </div>
                              <span class="iti__country-name">Liberia</span>
                              <span class="iti__dial-code">+231</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-ly"
                              role="option"
                              data-dial-code="218"
                              data-country-code="ly"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__ly"></div>
                              </div>
                              <span class="iti__country-name">
                                Libya (‫ليبيا‬‎)
                              </span>
                              <span class="iti__dial-code">+218</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-li"
                              role="option"
                              data-dial-code="423"
                              data-country-code="li"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__li"></div>
                              </div>
                              <span class="iti__country-name">
                                Liechtenstein
                              </span>
                              <span class="iti__dial-code">+423</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-lt"
                              role="option"
                              data-dial-code="370"
                              data-country-code="lt"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__lt"></div>
                              </div>
                              <span class="iti__country-name">
                                Lithuania (Lietuva)
                              </span>
                              <span class="iti__dial-code">+370</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-lu"
                              role="option"
                              data-dial-code="352"
                              data-country-code="lu"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__lu"></div>
                              </div>
                              <span class="iti__country-name">Luxembourg</span>
                              <span class="iti__dial-code">+352</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-mo"
                              role="option"
                              data-dial-code="853"
                              data-country-code="mo"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__mo"></div>
                              </div>
                              <span class="iti__country-name">
                                Macau (澳門)
                              </span>
                              <span class="iti__dial-code">+853</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-mk"
                              role="option"
                              data-dial-code="389"
                              data-country-code="mk"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__mk"></div>
                              </div>
                              <span class="iti__country-name">
                                North Macedonia (Македонија)
                              </span>
                              <span class="iti__dial-code">+389</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-mg"
                              role="option"
                              data-dial-code="261"
                              data-country-code="mg"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__mg"></div>
                              </div>
                              <span class="iti__country-name">
                                Madagascar (Madagasikara)
                              </span>
                              <span class="iti__dial-code">+261</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-mw"
                              role="option"
                              data-dial-code="265"
                              data-country-code="mw"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__mw"></div>
                              </div>
                              <span class="iti__country-name">Malawi</span>
                              <span class="iti__dial-code">+265</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-my"
                              role="option"
                              data-dial-code="60"
                              data-country-code="my"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__my"></div>
                              </div>
                              <span class="iti__country-name">Malaysia</span>
                              <span class="iti__dial-code">+60</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-mv"
                              role="option"
                              data-dial-code="960"
                              data-country-code="mv"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__mv"></div>
                              </div>
                              <span class="iti__country-name">Maldives</span>
                              <span class="iti__dial-code">+960</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-ml"
                              role="option"
                              data-dial-code="223"
                              data-country-code="ml"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__ml"></div>
                              </div>
                              <span class="iti__country-name">Mali</span>
                              <span class="iti__dial-code">+223</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-mt"
                              role="option"
                              data-dial-code="356"
                              data-country-code="mt"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__mt"></div>
                              </div>
                              <span class="iti__country-name">Malta</span>
                              <span class="iti__dial-code">+356</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-mh"
                              role="option"
                              data-dial-code="692"
                              data-country-code="mh"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__mh"></div>
                              </div>
                              <span class="iti__country-name">
                                Marshall Islands
                              </span>
                              <span class="iti__dial-code">+692</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-mq"
                              role="option"
                              data-dial-code="596"
                              data-country-code="mq"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__mq"></div>
                              </div>
                              <span class="iti__country-name">Martinique</span>
                              <span class="iti__dial-code">+596</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-mr"
                              role="option"
                              data-dial-code="222"
                              data-country-code="mr"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__mr"></div>
                              </div>
                              <span class="iti__country-name">
                                Mauritania (‫موريتانيا‬‎)
                              </span>
                              <span class="iti__dial-code">+222</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-mu"
                              role="option"
                              data-dial-code="230"
                              data-country-code="mu"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__mu"></div>
                              </div>
                              <span class="iti__country-name">
                                Mauritius (Moris)
                              </span>
                              <span class="iti__dial-code">+230</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-yt"
                              role="option"
                              data-dial-code="262"
                              data-country-code="yt"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__yt"></div>
                              </div>
                              <span class="iti__country-name">Mayotte</span>
                              <span class="iti__dial-code">+262</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-mx"
                              role="option"
                              data-dial-code="52"
                              data-country-code="mx"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__mx"></div>
                              </div>
                              <span class="iti__country-name">
                                Mexico (México)
                              </span>
                              <span class="iti__dial-code">+52</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-fm"
                              role="option"
                              data-dial-code="691"
                              data-country-code="fm"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__fm"></div>
                              </div>
                              <span class="iti__country-name">Micronesia</span>
                              <span class="iti__dial-code">+691</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-md"
                              role="option"
                              data-dial-code="373"
                              data-country-code="md"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__md"></div>
                              </div>
                              <span class="iti__country-name">
                                Moldova (Republica Moldova)
                              </span>
                              <span class="iti__dial-code">+373</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-mc"
                              role="option"
                              data-dial-code="377"
                              data-country-code="mc"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__mc"></div>
                              </div>
                              <span class="iti__country-name">Monaco</span>
                              <span class="iti__dial-code">+377</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-mn"
                              role="option"
                              data-dial-code="976"
                              data-country-code="mn"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__mn"></div>
                              </div>
                              <span class="iti__country-name">
                                Mongolia (Монгол)
                              </span>
                              <span class="iti__dial-code">+976</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-me"
                              role="option"
                              data-dial-code="382"
                              data-country-code="me"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__me"></div>
                              </div>
                              <span class="iti__country-name">
                                Montenegro (Crna Gora)
                              </span>
                              <span class="iti__dial-code">+382</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-ms"
                              role="option"
                              data-dial-code="1"
                              data-country-code="ms"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__ms"></div>
                              </div>
                              <span class="iti__country-name">Montserrat</span>
                              <span class="iti__dial-code">+1</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-ma"
                              role="option"
                              data-dial-code="212"
                              data-country-code="ma"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__ma"></div>
                              </div>
                              <span class="iti__country-name">
                                Morocco (‫المغرب‬‎)
                              </span>
                              <span class="iti__dial-code">+212</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-mz"
                              role="option"
                              data-dial-code="258"
                              data-country-code="mz"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__mz"></div>
                              </div>
                              <span class="iti__country-name">
                                Mozambique (Moçambique)
                              </span>
                              <span class="iti__dial-code">+258</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-mm"
                              role="option"
                              data-dial-code="95"
                              data-country-code="mm"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__mm"></div>
                              </div>
                              <span class="iti__country-name">
                                Myanmar (Burma) (မြန်မာ)
                              </span>
                              <span class="iti__dial-code">+95</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-na"
                              role="option"
                              data-dial-code="264"
                              data-country-code="na"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__na"></div>
                              </div>
                              <span class="iti__country-name">
                                Namibia (Namibië)
                              </span>
                              <span class="iti__dial-code">+264</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-nr"
                              role="option"
                              data-dial-code="674"
                              data-country-code="nr"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__nr"></div>
                              </div>
                              <span class="iti__country-name">Nauru</span>
                              <span class="iti__dial-code">+674</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-np"
                              role="option"
                              data-dial-code="977"
                              data-country-code="np"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__np"></div>
                              </div>
                              <span class="iti__country-name">
                                Nepal (नेपाल)
                              </span>
                              <span class="iti__dial-code">+977</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-nl"
                              role="option"
                              data-dial-code="31"
                              data-country-code="nl"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__nl"></div>
                              </div>
                              <span class="iti__country-name">
                                Netherlands (Nederland)
                              </span>
                              <span class="iti__dial-code">+31</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-nc"
                              role="option"
                              data-dial-code="687"
                              data-country-code="nc"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__nc"></div>
                              </div>
                              <span class="iti__country-name">
                                New Caledonia (Nouvelle-Calédonie)
                              </span>
                              <span class="iti__dial-code">+687</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-nz"
                              role="option"
                              data-dial-code="64"
                              data-country-code="nz"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__nz"></div>
                              </div>
                              <span class="iti__country-name">New Zealand</span>
                              <span class="iti__dial-code">+64</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-ni"
                              role="option"
                              data-dial-code="505"
                              data-country-code="ni"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__ni"></div>
                              </div>
                              <span class="iti__country-name">Nicaragua</span>
                              <span class="iti__dial-code">+505</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-ne"
                              role="option"
                              data-dial-code="227"
                              data-country-code="ne"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__ne"></div>
                              </div>
                              <span class="iti__country-name">
                                Niger (Nijar)
                              </span>
                              <span class="iti__dial-code">+227</span>
                            </li>
                            <li
                              class="iti__country iti__standard iti__active"
                              tabindex="-1"
                              id="iti-0__item-ng"
                              role="option"
                              data-dial-code="234"
                              data-country-code="ng"
                              aria-selected="true"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__ng"></div>
                              </div>
                              <span class="iti__country-name">Nigeria</span>
                              <span class="iti__dial-code">+234</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-nu"
                              role="option"
                              data-dial-code="683"
                              data-country-code="nu"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__nu"></div>
                              </div>
                              <span class="iti__country-name">Niue</span>
                              <span class="iti__dial-code">+683</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-nf"
                              role="option"
                              data-dial-code="672"
                              data-country-code="nf"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__nf"></div>
                              </div>
                              <span class="iti__country-name">
                                Norfolk Island
                              </span>
                              <span class="iti__dial-code">+672</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-kp"
                              role="option"
                              data-dial-code="850"
                              data-country-code="kp"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__kp"></div>
                              </div>
                              <span class="iti__country-name">
                                North Korea (조선 민주주의 인민 공화국)
                              </span>
                              <span class="iti__dial-code">+850</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-mp"
                              role="option"
                              data-dial-code="1"
                              data-country-code="mp"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__mp"></div>
                              </div>
                              <span class="iti__country-name">
                                Northern Mariana Islands
                              </span>
                              <span class="iti__dial-code">+1</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-no"
                              role="option"
                              data-dial-code="47"
                              data-country-code="no"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__no"></div>
                              </div>
                              <span class="iti__country-name">
                                Norway (Norge)
                              </span>
                              <span class="iti__dial-code">+47</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-om"
                              role="option"
                              data-dial-code="968"
                              data-country-code="om"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__om"></div>
                              </div>
                              <span class="iti__country-name">
                                Oman (‫عُمان‬‎)
                              </span>
                              <span class="iti__dial-code">+968</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-pk"
                              role="option"
                              data-dial-code="92"
                              data-country-code="pk"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__pk"></div>
                              </div>
                              <span class="iti__country-name">
                                Pakistan (‫پاکستان‬‎)
                              </span>
                              <span class="iti__dial-code">+92</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-pw"
                              role="option"
                              data-dial-code="680"
                              data-country-code="pw"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__pw"></div>
                              </div>
                              <span class="iti__country-name">Palau</span>
                              <span class="iti__dial-code">+680</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-ps"
                              role="option"
                              data-dial-code="970"
                              data-country-code="ps"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__ps"></div>
                              </div>
                              <span class="iti__country-name">
                                Palestine (‫فلسطين‬‎)
                              </span>
                              <span class="iti__dial-code">+970</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-pa"
                              role="option"
                              data-dial-code="507"
                              data-country-code="pa"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__pa"></div>
                              </div>
                              <span class="iti__country-name">
                                Panama (Panamá)
                              </span>
                              <span class="iti__dial-code">+507</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-pg"
                              role="option"
                              data-dial-code="675"
                              data-country-code="pg"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__pg"></div>
                              </div>
                              <span class="iti__country-name">
                                Papua New Guinea
                              </span>
                              <span class="iti__dial-code">+675</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-py"
                              role="option"
                              data-dial-code="595"
                              data-country-code="py"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__py"></div>
                              </div>
                              <span class="iti__country-name">Paraguay</span>
                              <span class="iti__dial-code">+595</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-pe"
                              role="option"
                              data-dial-code="51"
                              data-country-code="pe"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__pe"></div>
                              </div>
                              <span class="iti__country-name">Peru (Perú)</span>
                              <span class="iti__dial-code">+51</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-ph"
                              role="option"
                              data-dial-code="63"
                              data-country-code="ph"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__ph"></div>
                              </div>
                              <span class="iti__country-name">Philippines</span>
                              <span class="iti__dial-code">+63</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-pl"
                              role="option"
                              data-dial-code="48"
                              data-country-code="pl"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__pl"></div>
                              </div>
                              <span class="iti__country-name">
                                Poland (Polska)
                              </span>
                              <span class="iti__dial-code">+48</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-pt"
                              role="option"
                              data-dial-code="351"
                              data-country-code="pt"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__pt"></div>
                              </div>
                              <span class="iti__country-name">Portugal</span>
                              <span class="iti__dial-code">+351</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-pr"
                              role="option"
                              data-dial-code="1"
                              data-country-code="pr"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__pr"></div>
                              </div>
                              <span class="iti__country-name">Puerto Rico</span>
                              <span class="iti__dial-code">+1</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-qa"
                              role="option"
                              data-dial-code="974"
                              data-country-code="qa"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__qa"></div>
                              </div>
                              <span class="iti__country-name">
                                Qatar (‫قطر‬‎)
                              </span>
                              <span class="iti__dial-code">+974</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-re"
                              role="option"
                              data-dial-code="262"
                              data-country-code="re"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__re"></div>
                              </div>
                              <span class="iti__country-name">
                                Réunion (La Réunion)
                              </span>
                              <span class="iti__dial-code">+262</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-ro"
                              role="option"
                              data-dial-code="40"
                              data-country-code="ro"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__ro"></div>
                              </div>
                              <span class="iti__country-name">
                                Romania (România)
                              </span>
                              <span class="iti__dial-code">+40</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-ru"
                              role="option"
                              data-dial-code="7"
                              data-country-code="ru"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__ru"></div>
                              </div>
                              <span class="iti__country-name">
                                Russia (Россия)
                              </span>
                              <span class="iti__dial-code">+7</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-rw"
                              role="option"
                              data-dial-code="250"
                              data-country-code="rw"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__rw"></div>
                              </div>
                              <span class="iti__country-name">Rwanda</span>
                              <span class="iti__dial-code">+250</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-bl"
                              role="option"
                              data-dial-code="590"
                              data-country-code="bl"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__bl"></div>
                              </div>
                              <span class="iti__country-name">
                                Saint Barthélemy
                              </span>
                              <span class="iti__dial-code">+590</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-sh"
                              role="option"
                              data-dial-code="290"
                              data-country-code="sh"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__sh"></div>
                              </div>
                              <span class="iti__country-name">
                                Saint Helena
                              </span>
                              <span class="iti__dial-code">+290</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-kn"
                              role="option"
                              data-dial-code="1"
                              data-country-code="kn"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__kn"></div>
                              </div>
                              <span class="iti__country-name">
                                Saint Kitts and Nevis
                              </span>
                              <span class="iti__dial-code">+1</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-lc"
                              role="option"
                              data-dial-code="1"
                              data-country-code="lc"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__lc"></div>
                              </div>
                              <span class="iti__country-name">Saint Lucia</span>
                              <span class="iti__dial-code">+1</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-mf"
                              role="option"
                              data-dial-code="590"
                              data-country-code="mf"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__mf"></div>
                              </div>
                              <span class="iti__country-name">
                                Saint Martin (Saint-Martin (partie française))
                              </span>
                              <span class="iti__dial-code">+590</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-pm"
                              role="option"
                              data-dial-code="508"
                              data-country-code="pm"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__pm"></div>
                              </div>
                              <span class="iti__country-name">
                                Saint Pierre and Miquelon
                                (Saint-Pierre-et-Miquelon)
                              </span>
                              <span class="iti__dial-code">+508</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-vc"
                              role="option"
                              data-dial-code="1"
                              data-country-code="vc"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__vc"></div>
                              </div>
                              <span class="iti__country-name">
                                Saint Vincent and the Grenadines
                              </span>
                              <span class="iti__dial-code">+1</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-ws"
                              role="option"
                              data-dial-code="685"
                              data-country-code="ws"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__ws"></div>
                              </div>
                              <span class="iti__country-name">Samoa</span>
                              <span class="iti__dial-code">+685</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-sm"
                              role="option"
                              data-dial-code="378"
                              data-country-code="sm"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__sm"></div>
                              </div>
                              <span class="iti__country-name">San Marino</span>
                              <span class="iti__dial-code">+378</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-st"
                              role="option"
                              data-dial-code="239"
                              data-country-code="st"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__st"></div>
                              </div>
                              <span class="iti__country-name">
                                São Tomé and Príncipe (São Tomé e Príncipe)
                              </span>
                              <span class="iti__dial-code">+239</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-sa"
                              role="option"
                              data-dial-code="966"
                              data-country-code="sa"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__sa"></div>
                              </div>
                              <span class="iti__country-name">
                                Saudi Arabia (‫المملكة العربية السعودية‬‎)
                              </span>
                              <span class="iti__dial-code">+966</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-sn"
                              role="option"
                              data-dial-code="221"
                              data-country-code="sn"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__sn"></div>
                              </div>
                              <span class="iti__country-name">
                                Senegal (Sénégal)
                              </span>
                              <span class="iti__dial-code">+221</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-rs"
                              role="option"
                              data-dial-code="381"
                              data-country-code="rs"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__rs"></div>
                              </div>
                              <span class="iti__country-name">
                                Serbia (Србија)
                              </span>
                              <span class="iti__dial-code">+381</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-sc"
                              role="option"
                              data-dial-code="248"
                              data-country-code="sc"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__sc"></div>
                              </div>
                              <span class="iti__country-name">Seychelles</span>
                              <span class="iti__dial-code">+248</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-sl"
                              role="option"
                              data-dial-code="232"
                              data-country-code="sl"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__sl"></div>
                              </div>
                              <span class="iti__country-name">
                                Sierra Leone
                              </span>
                              <span class="iti__dial-code">+232</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-sg"
                              role="option"
                              data-dial-code="65"
                              data-country-code="sg"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__sg"></div>
                              </div>
                              <span class="iti__country-name">Singapore</span>
                              <span class="iti__dial-code">+65</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-sx"
                              role="option"
                              data-dial-code="1"
                              data-country-code="sx"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__sx"></div>
                              </div>
                              <span class="iti__country-name">
                                Sint Maarten
                              </span>
                              <span class="iti__dial-code">+1</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-sk"
                              role="option"
                              data-dial-code="421"
                              data-country-code="sk"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__sk"></div>
                              </div>
                              <span class="iti__country-name">
                                Slovakia (Slovensko)
                              </span>
                              <span class="iti__dial-code">+421</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-si"
                              role="option"
                              data-dial-code="386"
                              data-country-code="si"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__si"></div>
                              </div>
                              <span class="iti__country-name">
                                Slovenia (Slovenija)
                              </span>
                              <span class="iti__dial-code">+386</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-sb"
                              role="option"
                              data-dial-code="677"
                              data-country-code="sb"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__sb"></div>
                              </div>
                              <span class="iti__country-name">
                                Solomon Islands
                              </span>
                              <span class="iti__dial-code">+677</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-so"
                              role="option"
                              data-dial-code="252"
                              data-country-code="so"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__so"></div>
                              </div>
                              <span class="iti__country-name">
                                Somalia (Soomaaliya)
                              </span>
                              <span class="iti__dial-code">+252</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-za"
                              role="option"
                              data-dial-code="27"
                              data-country-code="za"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__za"></div>
                              </div>
                              <span class="iti__country-name">
                                South Africa
                              </span>
                              <span class="iti__dial-code">+27</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-kr"
                              role="option"
                              data-dial-code="82"
                              data-country-code="kr"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__kr"></div>
                              </div>
                              <span class="iti__country-name">
                                South Korea (대한민국)
                              </span>
                              <span class="iti__dial-code">+82</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-ss"
                              role="option"
                              data-dial-code="211"
                              data-country-code="ss"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__ss"></div>
                              </div>
                              <span class="iti__country-name">
                                South Sudan (‫جنوب السودان‬‎)
                              </span>
                              <span class="iti__dial-code">+211</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-es"
                              role="option"
                              data-dial-code="34"
                              data-country-code="es"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__es"></div>
                              </div>
                              <span class="iti__country-name">
                                Spain (España)
                              </span>
                              <span class="iti__dial-code">+34</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-lk"
                              role="option"
                              data-dial-code="94"
                              data-country-code="lk"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__lk"></div>
                              </div>
                              <span class="iti__country-name">
                                Sri Lanka (ශ්‍රී ලංකාව)
                              </span>
                              <span class="iti__dial-code">+94</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-sd"
                              role="option"
                              data-dial-code="249"
                              data-country-code="sd"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__sd"></div>
                              </div>
                              <span class="iti__country-name">
                                Sudan (‫السودان‬‎)
                              </span>
                              <span class="iti__dial-code">+249</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-sr"
                              role="option"
                              data-dial-code="597"
                              data-country-code="sr"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__sr"></div>
                              </div>
                              <span class="iti__country-name">Suriname</span>
                              <span class="iti__dial-code">+597</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-sj"
                              role="option"
                              data-dial-code="47"
                              data-country-code="sj"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__sj"></div>
                              </div>
                              <span class="iti__country-name">
                                Svalbard and Jan Mayen
                              </span>
                              <span class="iti__dial-code">+47</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-se"
                              role="option"
                              data-dial-code="46"
                              data-country-code="se"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__se"></div>
                              </div>
                              <span class="iti__country-name">
                                Sweden (Sverige)
                              </span>
                              <span class="iti__dial-code">+46</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-ch"
                              role="option"
                              data-dial-code="41"
                              data-country-code="ch"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__ch"></div>
                              </div>
                              <span class="iti__country-name">
                                Switzerland (Schweiz)
                              </span>
                              <span class="iti__dial-code">+41</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-sy"
                              role="option"
                              data-dial-code="963"
                              data-country-code="sy"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__sy"></div>
                              </div>
                              <span class="iti__country-name">
                                Syria (‫سوريا‬‎)
                              </span>
                              <span class="iti__dial-code">+963</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-tw"
                              role="option"
                              data-dial-code="886"
                              data-country-code="tw"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__tw"></div>
                              </div>
                              <span class="iti__country-name">
                                Taiwan (台灣)
                              </span>
                              <span class="iti__dial-code">+886</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-tj"
                              role="option"
                              data-dial-code="992"
                              data-country-code="tj"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__tj"></div>
                              </div>
                              <span class="iti__country-name">Tajikistan</span>
                              <span class="iti__dial-code">+992</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-tz"
                              role="option"
                              data-dial-code="255"
                              data-country-code="tz"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__tz"></div>
                              </div>
                              <span class="iti__country-name">Tanzania</span>
                              <span class="iti__dial-code">+255</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-th"
                              role="option"
                              data-dial-code="66"
                              data-country-code="th"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__th"></div>
                              </div>
                              <span class="iti__country-name">
                                Thailand (ไทย)
                              </span>
                              <span class="iti__dial-code">+66</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-tl"
                              role="option"
                              data-dial-code="670"
                              data-country-code="tl"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__tl"></div>
                              </div>
                              <span class="iti__country-name">Timor-Leste</span>
                              <span class="iti__dial-code">+670</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-tg"
                              role="option"
                              data-dial-code="228"
                              data-country-code="tg"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__tg"></div>
                              </div>
                              <span class="iti__country-name">Togo</span>
                              <span class="iti__dial-code">+228</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-tk"
                              role="option"
                              data-dial-code="690"
                              data-country-code="tk"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__tk"></div>
                              </div>
                              <span class="iti__country-name">Tokelau</span>
                              <span class="iti__dial-code">+690</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-to"
                              role="option"
                              data-dial-code="676"
                              data-country-code="to"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__to"></div>
                              </div>
                              <span class="iti__country-name">Tonga</span>
                              <span class="iti__dial-code">+676</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-tt"
                              role="option"
                              data-dial-code="1"
                              data-country-code="tt"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__tt"></div>
                              </div>
                              <span class="iti__country-name">
                                Trinidad and Tobago
                              </span>
                              <span class="iti__dial-code">+1</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-tn"
                              role="option"
                              data-dial-code="216"
                              data-country-code="tn"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__tn"></div>
                              </div>
                              <span class="iti__country-name">
                                Tunisia (‫تونس‬‎)
                              </span>
                              <span class="iti__dial-code">+216</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-tr"
                              role="option"
                              data-dial-code="90"
                              data-country-code="tr"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__tr"></div>
                              </div>
                              <span class="iti__country-name">
                                Turkey (Türkiye)
                              </span>
                              <span class="iti__dial-code">+90</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-tm"
                              role="option"
                              data-dial-code="993"
                              data-country-code="tm"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__tm"></div>
                              </div>
                              <span class="iti__country-name">
                                Turkmenistan
                              </span>
                              <span class="iti__dial-code">+993</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-tc"
                              role="option"
                              data-dial-code="1"
                              data-country-code="tc"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__tc"></div>
                              </div>
                              <span class="iti__country-name">
                                Turks and Caicos Islands
                              </span>
                              <span class="iti__dial-code">+1</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-tv"
                              role="option"
                              data-dial-code="688"
                              data-country-code="tv"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__tv"></div>
                              </div>
                              <span class="iti__country-name">Tuvalu</span>
                              <span class="iti__dial-code">+688</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-vi"
                              role="option"
                              data-dial-code="1"
                              data-country-code="vi"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__vi"></div>
                              </div>
                              <span class="iti__country-name">
                                U.S. Virgin Islands
                              </span>
                              <span class="iti__dial-code">+1</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-ug"
                              role="option"
                              data-dial-code="256"
                              data-country-code="ug"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__ug"></div>
                              </div>
                              <span class="iti__country-name">Uganda</span>
                              <span class="iti__dial-code">+256</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-ua"
                              role="option"
                              data-dial-code="380"
                              data-country-code="ua"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__ua"></div>
                              </div>
                              <span class="iti__country-name">
                                Ukraine (Україна)
                              </span>
                              <span class="iti__dial-code">+380</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-ae"
                              role="option"
                              data-dial-code="971"
                              data-country-code="ae"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__ae"></div>
                              </div>
                              <span class="iti__country-name">
                                United Arab Emirates (‫الإمارات العربية
                                المتحدة‬‎)
                              </span>
                              <span class="iti__dial-code">+971</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-gb"
                              role="option"
                              data-dial-code="44"
                              data-country-code="gb"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__gb"></div>
                              </div>
                              <span class="iti__country-name">
                                United Kingdom
                              </span>
                              <span class="iti__dial-code">+44</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-us"
                              role="option"
                              data-dial-code="1"
                              data-country-code="us"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__us"></div>
                              </div>
                              <span class="iti__country-name">
                                United States
                              </span>
                              <span class="iti__dial-code">+1</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-uy"
                              role="option"
                              data-dial-code="598"
                              data-country-code="uy"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__uy"></div>
                              </div>
                              <span class="iti__country-name">Uruguay</span>
                              <span class="iti__dial-code">+598</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-uz"
                              role="option"
                              data-dial-code="998"
                              data-country-code="uz"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__uz"></div>
                              </div>
                              <span class="iti__country-name">
                                Uzbekistan (Oʻzbekiston)
                              </span>
                              <span class="iti__dial-code">+998</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-vu"
                              role="option"
                              data-dial-code="678"
                              data-country-code="vu"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__vu"></div>
                              </div>
                              <span class="iti__country-name">Vanuatu</span>
                              <span class="iti__dial-code">+678</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-va"
                              role="option"
                              data-dial-code="39"
                              data-country-code="va"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__va"></div>
                              </div>
                              <span class="iti__country-name">
                                Vatican City (Città del Vaticano)
                              </span>
                              <span class="iti__dial-code">+39</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-ve"
                              role="option"
                              data-dial-code="58"
                              data-country-code="ve"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__ve"></div>
                              </div>
                              <span class="iti__country-name">Venezuela</span>
                              <span class="iti__dial-code">+58</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-vn"
                              role="option"
                              data-dial-code="84"
                              data-country-code="vn"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__vn"></div>
                              </div>
                              <span class="iti__country-name">
                                Vietnam (Việt Nam)
                              </span>
                              <span class="iti__dial-code">+84</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-wf"
                              role="option"
                              data-dial-code="681"
                              data-country-code="wf"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__wf"></div>
                              </div>
                              <span class="iti__country-name">
                                Wallis and Futuna (Wallis-et-Futuna)
                              </span>
                              <span class="iti__dial-code">+681</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-eh"
                              role="option"
                              data-dial-code="212"
                              data-country-code="eh"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__eh"></div>
                              </div>
                              <span class="iti__country-name">
                                Western Sahara (‫الصحراء الغربية‬‎)
                              </span>
                              <span class="iti__dial-code">+212</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-ye"
                              role="option"
                              data-dial-code="967"
                              data-country-code="ye"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__ye"></div>
                              </div>
                              <span class="iti__country-name">
                                Yemen (‫اليمن‬‎)
                              </span>
                              <span class="iti__dial-code">+967</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-zm"
                              role="option"
                              data-dial-code="260"
                              data-country-code="zm"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__zm"></div>
                              </div>
                              <span class="iti__country-name">Zambia</span>
                              <span class="iti__dial-code">+260</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-zw"
                              role="option"
                              data-dial-code="263"
                              data-country-code="zw"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__zw"></div>
                              </div>
                              <span class="iti__country-name">Zimbabwe</span>
                              <span class="iti__dial-code">+263</span>
                            </li>
                            <li
                              class="iti__country iti__standard"
                              tabindex="-1"
                              id="iti-0__item-ax"
                              role="option"
                              data-dial-code="358"
                              data-country-code="ax"
                              aria-selected="false"
                            >
                              <div class="iti__flag-box">
                                <div class="iti__flag iti__ax"></div>
                              </div>
                              <span class="iti__country-name">
                                Åland Islands
                              </span>
                              <span class="iti__dial-code">+358</span>
                            </li>
                          </ul>
                        </div>
                        <input
                          id="inf_field_Phone1"
                          name="inf_field_Phone1[short]"
                          placeholder="Phone 1 *"
                          type="tel"
                          required=""
                          autocomplete="off"
                          data-intl-tel-input-id="0"
                          style={{ paddingLeft: "123px" }}
                        />
                        <input type="hidden" name="inf_field_Phone1" />
                      </div>
                    </div>

                    <div class="infusion-field">
                      <label class="d-none" for="inf_custom_Country1">
                        Country *
                      </label>
                      <select>
                        <option value="Australia">🇦🇺 Australia</option>
                        <option value="Canada">🇨🇦 Canada</option>
                        <option value="New Zealand">🇳🇿 New Zealand</option>
                        <option value="United States of America">
                          🇺🇸 United States of America
                        </option>
                        <option value="United Kingdom">
                          🇬🇧 United Kingdom
                        </option>
                        <option disabled="disabled">------</option>
                        <option disabled="disabled">Choose a country</option>
                        <option value="Andorra">🇦🇩 Andorra</option>
                        <option value="Afghanistan">🇦🇫 Afghanistan</option>
                        <option value="Antigua and Barbuda">
                          🇦🇬 Antigua and Barbuda
                        </option>
                        <option value="Anguilla">🇦🇮 Anguilla</option>
                        <option value="Albania">🇦🇱 Albania</option>
                        <option value="Armenia">🇦🇲 Armenia</option>
                        <option value="Angola">🇦🇴 Angola</option>
                        <option value="Antarctica">🇦🇶 Antarctica</option>
                        <option value="Argentina">🇦🇷 Argentina</option>
                        <option value="American Samoa">
                          🇦🇸 American Samoa
                        </option>
                        <option value="Austria">🇦🇹 Austria</option>
                        <option value="Aruba">🇦🇼 Aruba</option>
                        <option value="Åland Islands">🇦🇽 Åland Islands</option>
                        <option value="Azerbaijan">🇦🇿 Azerbaijan</option>
                        <option value="Bosnia and Herzegovina">
                          🇧🇦 Bosnia and Herzegovina
                        </option>
                        <option value="Barbados">🇧🇧 Barbados</option>
                        <option value="Bangladesh">🇧🇩 Bangladesh</option>
                        <option value="Belgium">🇧🇪 Belgium</option>
                        <option value="Burkina Faso">🇧🇫 Burkina Faso</option>
                        <option value="Bulgaria">🇧🇬 Bulgaria</option>
                        <option value="Bahrain">🇧🇭 Bahrain</option>
                        <option value="Burundi">🇧🇮 Burundi</option>
                        <option value="Benin">🇧🇯 Benin</option>
                        <option value="Saint Barthélemy">
                          🇧🇱 Saint Barthélemy
                        </option>
                        <option value="Bermuda">🇧🇲 Bermuda</option>
                        <option value="Brunei Darussalam">
                          🇧🇳 Brunei Darussalam
                        </option>
                        <option value="Bolivia (Plurinational State of)">
                          🇧🇴 Bolivia (Plurinational State of)
                        </option>
                        <option value="Bonaire, Sint Eustatius and Saba">
                          🇧🇶 Bonaire, Sint Eustatius and Saba
                        </option>
                        <option value="Brazil">🇧🇷 Brazil</option>
                        <option value="Bhutan">🇧🇹 Bhutan</option>
                        <option value="Bouvet Island">🇧🇻 Bouvet Island</option>
                        <option value="Botswana">🇧🇼 Botswana</option>
                        <option value="Belarus">🇧🇾 Belarus</option>
                        <option value="Belize">🇧🇿 Belize</option>
                        <option value="Switzerland">🇨🇭 Switzerland</option>
                        <option value="Côte d'Ivoire">🇨🇮 Côte d'Ivoire</option>
                        <option value="Chile">🇨🇱 Chile</option>
                        <option value="Cameroon">🇨🇲 Cameroon</option>
                        <option value="China">🇨🇳 China</option>
                        <option value="Colombia">🇨🇴 Colombia</option>
                        <option value="Costa Rica">🇨🇷 Costa Rica</option>
                        <option value="Cuba">🇨🇺 Cuba</option>
                        <option value="Cabo Verde">🇨🇻 Cabo Verde</option>
                        <option value="Curaçao">🇨🇼 Curaçao</option>
                        <option value="Christmas Island">
                          🇨🇽 Christmas Island
                        </option>
                        <option value="Cyprus">🇨🇾 Cyprus</option>
                        <option value="Germany">🇩🇪 Germany</option>
                        <option value="Djibouti">🇩🇯 Djibouti</option>
                        <option value="Denmark">🇩🇰 Denmark</option>
                        <option value="Dominica">🇩🇲 Dominica</option>
                        <option value="Algeria">🇩🇿 Algeria</option>
                        <option value="Ecuador">🇪🇨 Ecuador</option>
                        <option value="Estonia">🇪🇪 Estonia</option>
                        <option value="Egypt">🇪🇬 Egypt</option>
                        <option value="Western Sahara">
                          🇪🇭 Western Sahara
                        </option>
                        <option value="Eritrea">🇪🇷 Eritrea</option>
                        <option value="Spain">🇪🇸 Spain</option>
                        <option value="Ethiopia">🇪🇹 Ethiopia</option>
                        <option value="Finland">🇫🇮 Finland</option>
                        <option value="Fiji">🇫🇯 Fiji</option>
                        <option value="Micronesia (Federated States of)">
                          🇫🇲 Micronesia (Federated States of)
                        </option>
                        <option value="France">🇫🇷 France</option>
                        <option value="Gabon">🇬🇦 Gabon</option>
                        <option value="Grenada">🇬🇩 Grenada</option>
                        <option value="Georgia">🇬🇪 Georgia</option>
                        <option value="French Guiana">🇬🇫 French Guiana</option>
                        <option value="Guernsey">🇬🇬 Guernsey</option>
                        <option value="Ghana">🇬🇭 Ghana</option>
                        <option value="Gibraltar">🇬🇮 Gibraltar</option>
                        <option value="Greenland">🇬🇱 Greenland</option>
                        <option value="Guinea">🇬🇳 Guinea</option>
                        <option value="Guadeloupe">🇬🇵 Guadeloupe</option>
                        <option value="Equatorial Guinea">
                          🇬🇶 Equatorial Guinea
                        </option>
                        <option value="Greece">🇬🇷 Greece</option>
                        <option value="South Georgia and the South Sandwich Islands">
                          🇬🇸 South Georgia and the South Sandwich Islands
                        </option>
                        <option value="Guatemala">🇬🇹 Guatemala</option>
                        <option value="Guam">🇬🇺 Guam</option>
                        <option value="Guinea-Bissau">🇬🇼 Guinea-Bissau</option>
                        <option value="Guyana">🇬🇾 Guyana</option>
                        <option value="Hong Kong">🇭🇰 Hong Kong</option>
                        <option value="Honduras">🇭🇳 Honduras</option>
                        <option value="Croatia">🇭🇷 Croatia</option>
                        <option value="Haiti">🇭🇹 Haiti</option>
                        <option value="Hungary">🇭🇺 Hungary</option>
                        <option value="Indonesia">🇮🇩 Indonesia</option>
                        <option value="Ireland">🇮🇪 Ireland</option>
                        <option value="Israel">🇮🇱 Israel</option>
                        <option value="Isle of Man">🇮🇲 Isle of Man</option>
                        <option value="India">🇮🇳 India</option>
                        <option value="Iraq">🇮🇶 Iraq</option>
                        <option value="Iran (Islamic Republic of)">
                          🇮🇷 Iran (Islamic Republic of)
                        </option>
                        <option value="Iceland">🇮🇸 Iceland</option>
                        <option value="Italy">🇮🇹 Italy</option>
                        <option value="Jersey">🇯🇪 Jersey</option>
                        <option value="Jamaica">🇯🇲 Jamaica</option>
                        <option value="Jordan">🇯🇴 Jordan</option>
                        <option value="Japan">🇯🇵 Japan</option>
                        <option value="Kenya">🇰🇪 Kenya</option>
                        <option value="Kyrgyzstan">🇰🇬 Kyrgyzstan</option>
                        <option value="Cambodia">🇰🇭 Cambodia</option>
                        <option value="North Korea">🇰🇵 North Korea</option>
                        <option value="South Korea">🇰🇷 South Korea</option>
                        <option value="Kiribati">🇰🇮 Kiribati</option>
                        <option value="Saint Kitts and Nevis">
                          🇰🇳 Saint Kitts and Nevis
                        </option>
                        <option value="Kuwait">🇰🇼 Kuwait</option>
                        <option value="Kazakhstan">🇰🇿 Kazakhstan</option>
                        <option value="Lebanon">🇱🇧 Lebanon</option>
                        <option value="Saint Lucia">🇱🇨 Saint Lucia</option>
                        <option value="Liechtenstein">🇱🇮 Liechtenstein</option>
                        <option value="Sri Lanka">🇱🇰 Sri Lanka</option>
                        <option value="Liberia">🇱🇷 Liberia</option>
                        <option value="Lesotho">🇱🇸 Lesotho</option>
                        <option value="Lithuania">🇱🇹 Lithuania</option>
                        <option value="Luxembourg">🇱🇺 Luxembourg</option>
                        <option value="Latvia">🇱🇻 Latvia</option>
                        <option value="Libya">🇱🇾 Libya</option>
                        <option value="Morocco">🇲🇦 Morocco</option>
                        <option value="Monaco">🇲🇨 Monaco</option>
                        <option value="Montenegro">🇲🇪 Montenegro</option>
                        <option value="Saint Martin (French part)">
                          🇲🇫 Saint Martin (French part)
                        </option>
                        <option value="Madagascar">🇲🇬 Madagascar</option>
                        <option value="Mali">🇲🇱 Mali</option>
                        <option value="Myanmar">🇲🇲 Myanmar</option>
                        <option value="Mongolia">🇲🇳 Mongolia</option>
                        <option value="Macao">🇲🇴 Macao</option>
                        <option value="Martinique">🇲🇶 Martinique</option>
                        <option value="Mauritania">🇲🇷 Mauritania</option>
                        <option value="Montserrat">🇲🇸 Montserrat</option>
                        <option value="Malta">🇲🇹 Malta</option>
                        <option value="Mauritius">🇲🇺 Mauritius</option>
                        <option value="Maldives">🇲🇻 Maldives</option>
                        <option value="Malawi">🇲🇼 Malawi</option>
                        <option value="Mexico">🇲🇽 Mexico</option>
                        <option value="Malaysia">🇲🇾 Malaysia</option>
                        <option value="Mozambique">🇲🇿 Mozambique</option>
                        <option value="Namibia">🇳🇦 Namibia</option>
                        <option value="New Caledonia">🇳🇨 New Caledonia</option>
                        <option value="Norfolk Island">
                          🇳🇫 Norfolk Island
                        </option>
                        <option value="Nigeria" selected="true">
                          🇳🇬 Nigeria
                        </option>
                        <option value="Nicaragua">🇳🇮 Nicaragua</option>
                        <option value="Norway">🇳🇴 Norway</option>
                        <option value="Nepal">🇳🇵 Nepal</option>
                        <option value="Nauru">🇳🇷 Nauru</option>
                        <option value="Niue">🇳🇺 Niue</option>
                        <option value="Oman">🇴🇲 Oman</option>
                        <option value="Panama">🇵🇦 Panama</option>
                        <option value="Peru">🇵🇪 Peru</option>
                        <option value="French Polynesia">
                          🇵🇫 French Polynesia
                        </option>
                        <option value="Papua New Guinea">
                          🇵🇬 Papua New Guinea
                        </option>
                        <option value="Pakistan">🇵🇰 Pakistan</option>
                        <option value="Poland">🇵🇱 Poland</option>
                        <option value="Saint Pierre and Miquelon">
                          🇵🇲 Saint Pierre and Miquelon
                        </option>
                        <option value="Pitcairn">🇵🇳 Pitcairn</option>
                        <option value="Puerto Rico">🇵🇷 Puerto Rico</option>
                        <option value="Palestine, State of">
                          🇵🇸 Palestine, State of
                        </option>
                        <option value="Portugal">🇵🇹 Portugal</option>
                        <option value="Palau">🇵🇼 Palau</option>
                        <option value="Paraguay">🇵🇾 Paraguay</option>
                        <option value="Qatar">🇶🇦 Qatar</option>
                        <option value="Réunion">🇷🇪 Réunion</option>
                        <option value="Romania">🇷🇴 Romania</option>
                        <option value="Serbia">🇷🇸 Serbia</option>
                        <option value="Russia">🇷🇺 Russia</option>
                        <option value="Rwanda">🇷🇼 Rwanda</option>
                        <option value="Saudi Arabia">🇸🇦 Saudi Arabia</option>
                        <option value="Solomon Islands">
                          🇸🇧 Solomon Islands
                        </option>
                        <option value="Seychelles">🇸🇨 Seychelles</option>
                        <option value="Sweden">🇸🇪 Sweden</option>
                        <option value="Singapore">🇸🇬 Singapore</option>
                        <option value="Saint Helena, Ascension and Tristan da Cunha">
                          🇸🇭 Saint Helena, Ascension and Tristan da Cunha
                        </option>
                        <option value="Slovenia">🇸🇮 Slovenia</option>
                        <option value="Svalbard and Jan Mayen">
                          🇸🇯 Svalbard and Jan Mayen
                        </option>
                        <option value="Slovakia">🇸🇰 Slovakia</option>
                        <option value="Sierra Leone">🇸🇱 Sierra Leone</option>
                        <option value="San Marino">🇸🇲 San Marino</option>
                        <option value="Senegal">🇸🇳 Senegal</option>
                        <option value="Somalia">🇸🇴 Somalia</option>
                        <option value="Suriname">🇸🇷 Suriname</option>
                        <option value="South Sudan">🇸🇸 South Sudan</option>
                        <option value="Sao Tome and Principe">
                          🇸🇹 Sao Tome and Principe
                        </option>
                        <option value="El Salvador">🇸🇻 El Salvador</option>
                        <option value="Sint Maarten (Dutch part)">
                          🇸🇽 Sint Maarten (Dutch part)
                        </option>
                        <option value="Syrian Arab Republic">
                          🇸🇾 Syrian Arab Republic
                        </option>
                        <option value="Chad">🇹🇩 Chad</option>
                        <option value="Togo">🇹🇬 Togo</option>
                        <option value="Thailand">🇹🇭 Thailand</option>
                        <option value="Tajikistan">🇹🇯 Tajikistan</option>
                        <option value="Tokelau">🇹🇰 Tokelau</option>
                        <option value="Timor-Leste">🇹🇱 Timor-Leste</option>
                        <option value="Turkmenistan">🇹🇲 Turkmenistan</option>
                        <option value="Tunisia">🇹🇳 Tunisia</option>
                        <option value="Tonga">🇹🇴 Tonga</option>
                        <option value="Turkey">🇹🇷 Turkey</option>
                        <option value="Trinidad and Tobago">
                          🇹🇹 Trinidad and Tobago
                        </option>
                        <option value="Tuvalu">🇹🇻 Tuvalu</option>
                        <option value="United Republic of Tanzania">
                          🇹🇿 United Republic of Tanzania
                        </option>
                        <option value="Ukraine">🇺🇦 Ukraine</option>
                        <option value="Uganda">🇺🇬 Uganda</option>
                        <option value="Uruguay">🇺🇾 Uruguay</option>
                        <option value="Uzbekistan">🇺🇿 Uzbekistan</option>
                        <option value="Saint Vincent and the Grenadines">
                          🇻🇨 Saint Vincent and the Grenadines
                        </option>
                        <option value="Venezuela (Bolivarian Republic of)">
                          🇻🇪 Venezuela (Bolivarian Republic of)
                        </option>
                        <option value="Virgin Islands (British)">
                          🇻🇬 Virgin Islands (British)
                        </option>
                        <option value="Virgin Islands (U.S.)">
                          🇻🇮 Virgin Islands (U.S.)
                        </option>
                        <option value="Vietnam">🇻🇳 Vietnam</option>
                        <option value="Vanuatu">🇻🇺 Vanuatu</option>
                        <option value="Wallis and Futuna">
                          🇼🇫 Wallis and Futuna
                        </option>
                        <option value="Samoa">🇼🇸 Samoa</option>
                        <option value="Yemen">🇾🇪 Yemen</option>
                        <option value="Mayotte">🇾🇹 Mayotte</option>
                        <option value="South Africa">🇿🇦 South Africa</option>
                        <option value="Zambia">🇿🇲 Zambia</option>
                        <option value="Zimbabwe">🇿🇼 Zimbabwe</option>
                        <option value="Eswatini">🇸🇿 Eswatini</option>
                        <option value="North Macedonia">
                          🇲🇰 North Macedonia
                        </option>
                        <option value="Philippines">🇵🇭 Philippines</option>
                        <option value="Netherlands">🇳🇱 Netherlands</option>
                        <option value="United Arab Emirates">
                          🇦🇪 United Arab Emirates
                        </option>
                        <option value="Republic of Moldova">
                          🇲🇩 Republic of Moldova
                        </option>
                        <option value="Gambia">🇬🇲 Gambia</option>
                        <option value="Dominican Republic">
                          🇩🇴 Dominican Republic
                        </option>
                        <option value="Sudan">🇸🇩 Sudan</option>
                        <option value="Lao People's Democratic Republic">
                          🇱🇦 Lao People's Democratic Republic
                        </option>
                        <option value="Taiwan, Province of China">
                          🇹🇼 Taiwan, Province of China
                        </option>
                        <option value="Republic of the Congo">
                          🇨🇬 Republic of the Congo
                        </option>
                        <option value="Czechia">🇨🇿 Czechia</option>
                        <option value="Niger">🇳🇪 Niger</option>
                        <option value="Democratic Republic of the Congo">
                          🇨🇩 Democratic Republic of the Congo
                        </option>
                        <option value="Commonwealth of The Bahamas">
                          🇧🇸 Commonwealth of The Bahamas
                        </option>
                        <option value="Cocos (Keeling) Islands">
                          🇨🇨 Cocos (Keeling) Islands
                        </option>
                        <option value="Central African Republic">
                          🇨🇫 Central African Republic
                        </option>
                        <option value="Cook Islands">🇨🇰 Cook Islands</option>
                        <option value="Falkland Islands">
                          🇫🇰 Falkland Islands
                        </option>
                        <option value="Faroe Islands">🇫🇴 Faroe Islands</option>
                        <option value="Territory of Heard Island and McDonald Islands">
                          🇭🇲 Territory of Heard Island and McDonald Islands
                        </option>
                        <option value="British Indian Ocean Territory">
                          🇮🇴 British Indian Ocean Territory
                        </option>
                        <option value="Comoros">🇰🇲 Comoros</option>
                        <option value="Cayman Islands">
                          🇰🇾 Cayman Islands
                        </option>
                        <option value="Republic of the Marshall Islands">
                          🇲🇭 Republic of the Marshall Islands
                        </option>
                        <option value="Commonwealth of the Northern Mariana Islands">
                          🇲🇵 Commonwealth of the Northern Mariana Islands
                        </option>
                        <option value="Turks and Caicos Islands">
                          🇹🇨 Turks and Caicos Islands
                        </option>
                        <option value="French Southern and Antarctic Lands">
                          🇹🇫 French Southern and Antarctic Lands
                        </option>
                        <option value="United States Minor Outlying Islands">
                          🇺🇲 United States Minor Outlying Islands
                        </option>
                        <option value="Holy See">🇻🇦 Holy See</option>
                      </select>
                      <input
                        id="inf_custom_Country1"
                        name="inf_custom_Country1"
                        placeholder="Country *"
                        data-country-select=""
                        data-country-preferred="AU,CA,NZ,GB,US"
                        type="hidden"
                        required=""
                        value="Nigeria"
                      />
                    </div>

                    <div class="infusion-field">
                      <label
                        class="d-none"
                        for="inf_custom_Tellusaboutyourproject"
                      >
                        Any Message for us
                      </label>
                      <textarea
                        id="inf_custom_Tellusaboutyourproject"
                        name="inf_custom_Tellusaboutyourproject"
                        placeholder="Any message for us *"
                        required=""
                      ></textarea>
                    </div>
                    <input name="inf_custom_GaContent" type="hidden" value="" />
                    <input name="inf_custom_GaSource" type="hidden" value="" />
                    <input name="inf_custom_GaMedium" type="hidden" value="" />
                    <input name="inf_custom_GaTerm" type="hidden" value="" />
                    <input
                      name="inf_custom_GaCampaign"
                      type="hidden"
                      value=""
                    />
                    <input
                      name="inf_custom_GaReferurl"
                      type="hidden"
                      value="null"
                    />
                    <input
                      name="inf_custom_IPAddress"
                      type="hidden"
                      value="102.89.47.204"
                    />
                    <div>
                      <div>&nbsp;</div>
                    </div>

                    <input
                      type="hidden"
                      id="timeZone"
                      name="timeZone"
                      value="Africa/Lagos"
                    />
                    <input
                      type="text"
                      name="planet"
                      style={{
                        position: "absolute",
                        pointerEvents: "none",
                        visibility: "hidden",
                      }}
                    />
                    <div class="form-messages"></div>
                  </form>
                </div>
              </div>
            </div>
          </section>

          {/*}   <section class="text-dark content-text content-wysiwyg">
            <div
              class="background-image-container"
              style={{ backgroundColor: "#f3f3f3" }}
            ></div>
            <div class="container">
              <div class="row justify-content-center">
                <div class="pill--flashing text-center col-12">
                  <span class="pill__flashing">
                    <span class="eyebrow__flashing-dot"></span>
                    Warning: We reserve the right to revoke this offer at any
                    time
                  </span>
                </div>
                <div class="col-md-10 col-xxl-9 mt-3 mb-2 mt-lg-3 mb-lg-5 text-center">
                  <span class="h2">
                    Features designed to help you win your dream job
                  </span>
                </div>
                <div class="col-xxl-7 col-xl-8 col-md-9">
                  <p>
                    There are
                    <span style={{ textDecoration: "underline" }}>
                      three important factors
                    </span>
                    which must exist before you should even think about applying
                    for this opportunity:
                  </p>
                  <ol>
                    <li>
                      <strong>
                        Make a resume that wins interviews(sign up)
                      </strong>
                      <br></br>
                      Use our resume maker with its advanced creation tools to
                      tell a professional story that engages recruiters, hiring
                      managers and even CEOs.
                    </li>
                    <li>
                      <strong>Resume writing made easy(create)</strong>
                      <br></br>
                      Resume writing has never been this effortless.
                      Pre-generated text, visual designs and more - all already
                      integrated into the resume maker. Just fill in your
                      details.
                    </li>
                    <li>
                      <strong>
                        A recruiter-tested CV maker tool(download)
                      </strong>
                      <br></br>
                      Our resume builder and its pre-generated content are
                      tested by recruiters and IT experts. We help your resume
                      become truly competitive in the hiring proces
                    </li>
                  </ol>
                </div>
                <div class="col-lg-8 null ">
                  <span class="null">
                    <a class="btn btn-doublelayer btn-green" href="/">
                      <span class="btn-doublelayer__maintext">
                        Create your Resume
                      </span>
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </section>
                      */}
          <section className="text-dark content-text content-accordion">
            <div className="background-image-container"></div>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 text-center">
                  <span className="h2">
                    YOU'VE GOT QUESTIONS,
                    <br />
                    WE'VE GOT ANSWERS.
                  </span>
                </div>
                <div className="col-lg-10">
                  <dl className="accordions">
                    {accordionData.map((item, index) => (
                      <div className="accordion" key={index}>
                        <dt
                          className={`accordion__intro ${
                            activeAccordion === index ? "active" : ""
                          }`}
                          onClick={() => toggleAccordion(index)}
                        >
                          {item.question}
                        </dt>
                        <dd
                          className={`accordion__content ${
                            activeAccordion === index ? "active" : ""
                          }`}
                        >
                          <div className="accordion__text">
                            <p>{item.answer}</p>
                          </div>
                        </dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>
            </div>
          </section>
          <section class="text-light content-global hit-the-damn-button">
            <div
              class="background-image-container"
              style={{ backgroundColor: "#000000" }}
            ></div>
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-sm-10 col-md-9 col-lg-8 col-xl-7 text-center ">
                  <span class="h2">
                    Try our resume builder today, it is for free, be among
                    people that will get that job
                  </span>
                </div>
                <div class="col-sm-9 col-md-7 col-lg-6 col-xl-6 null ">
                  <span class="null">
                    <a class="btn btn-doublelayer btn-green" href="/">
                      <span class="btn-doublelayer__maintext">
                        Create your resume
                      </span>
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </section>
        </main>
        <footer class="site-footer" id="footer-container">
          <div class="container">
            <div class="footer-columns">
              <div class="row justify-content-center">
                <div class="col footer-column-1">
                  <div
                    id="custom_html-4"
                    class="widget_text widget widget_custom_html footer-widgets-1"
                  >
                    <div class="textwidget custom-html-widget">
                      <img
                        src={w1}
                        style={{ width: "180px", height: "50px" }}
                      />
                    </div>
                  </div>
                  <div id="text-2" class="widget widget_text footer-widgets-1">
                    <div class="textwidget">
                      <p>
                        Securing a job with visa sponsorship is tough. We make
                        it a whole lot easier, more attainable, less stressful,
                        and more rewarding.
                      </p>
                    </div>
                  </div>
                  <div
                    id="kk_address_widget-2"
                    class="widget widget_kk_address_widget footer-widgets-1"
                  >
                    Submit your CV and get a professional makeover from industry
                    experts - Free for the first 60 people. Limited Offer!
                    Phone: <a href="tel:+18883038580">+447867266523</a>
                  </div>
                  <div
                    id="custom_html-2"
                    class="widget_text widget widget_custom_html footer-widgets-1"
                  >
                    <div class="textwidget custom-html-widget">
                      <a
                        href="https://facebook.com/kingkongco/"
                        rel="nofollow"
                        class="social-icon"
                        aria-label="Our Facebook"
                      >
                        <svg
                          height="512"
                          viewBox="0 0 24 24"
                          width="512"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M15.997 3.985h2.191V.169C17.81.117 16.51 0 14.996 0c-3.159 0-5.323 1.987-5.323 5.639V9H6.187v4.266h3.486V24h4.274V13.267h3.345l.531-4.266h-3.877V6.062c.001-1.233.333-2.077 2.051-2.077z"></path>
                        </svg>
                      </a>
                      <a
                        href="https://www.instagram.com/kingkong.com.au/"
                        rel="nofollow"
                        class="social-icon"
                        aria-label="Our Instagram"
                      >
                        <svg
                          height="511pt"
                          viewBox="0 0 511 511.9"
                          width="511pt"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M510.95 150.5c-1.2-27.2-5.598-45.898-11.9-62.102-6.5-17.199-16.5-32.597-29.6-45.398-12.802-13-28.302-23.102-45.302-29.5-16.296-6.3-34.898-10.7-62.097-11.898C334.648.3 325.949 0 256.449 0s-78.199.3-105.5 1.5c-27.199 1.2-45.898 5.602-62.097 11.898-17.204 6.5-32.602 16.5-45.403 29.602-13 12.8-23.097 28.3-29.5 45.3-6.3 16.302-10.699 34.9-11.898 62.098C.75 177.801.449 186.5.449 256s.301 78.2 1.5 105.5c1.2 27.2 5.602 45.898 11.903 62.102 6.5 17.199 16.597 32.597 29.597 45.398 12.801 13 28.301 23.102 45.301 29.5 16.3 6.3 34.898 10.7 62.102 11.898 27.296 1.204 36 1.5 105.5 1.5s78.199-.296 105.5-1.5c27.199-1.199 45.898-5.597 62.097-11.898a130.934 130.934 0 0 0 74.903-74.898c6.296-16.301 10.699-34.903 11.898-62.102 1.2-27.3 1.5-36 1.5-105.5s-.102-78.2-1.3-105.5zm-46.098 209c-1.102 25-5.301 38.5-8.801 47.5-8.602 22.3-26.301 40-48.602 48.602-9 3.5-22.597 7.699-47.5 8.796-27 1.204-35.097 1.5-103.398 1.5s-76.5-.296-103.403-1.5c-25-1.097-38.5-5.296-47.5-8.796C94.551 451.5 84.45 445 76.25 436.5c-8.5-8.3-15-18.3-19.102-29.398-3.5-9-7.699-22.602-8.796-47.5-1.204-27-1.5-35.102-1.5-103.403s.296-76.5 1.5-103.398c1.097-25 5.296-38.5 8.796-47.5C61.25 94.199 67.75 84.1 76.352 75.898c8.296-8.5 18.296-15 29.398-19.097 9-3.5 22.602-7.7 47.5-8.801 27-1.2 35.102-1.5 103.398-1.5 68.403 0 76.5.3 103.403 1.5 25 1.102 38.5 5.3 47.5 8.8 11.097 4.098 21.199 10.598 29.398 19.098 8.5 8.301 15 18.301 19.102 29.403 3.5 9 7.699 22.597 8.8 47.5 1.2 27 1.5 35.097 1.5 103.398s-.3 76.301-1.5 103.301zm0 0"></path>
                          <path d="M256.45 124.5c-72.598 0-131.5 58.898-131.5 131.5s58.902 131.5 131.5 131.5c72.6 0 131.5-58.898 131.5-131.5s-58.9-131.5-131.5-131.5zm0 216.8c-47.098 0-85.302-38.198-85.302-85.3s38.204-85.3 85.301-85.3c47.102 0 85.301 38.198 85.301 85.3s-38.2 85.3-85.3 85.3zm167.402-222c0 16.954-13.747 30.7-30.704 30.7-16.953 0-30.699-13.746-30.699-30.7 0-16.956 13.746-30.698 30.7-30.698 16.956 0 30.703 13.742 30.703 30.699zm0 0"></path>
                        </svg>
                      </a>
                      <a
                        href="https://www.linkedin.com/company/kingkong/"
                        rel="nofollow"
                        class="social-icon"
                        aria-label="Our LinkedIn"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 504.4 504.4"
                        >
                          <path d="M377.6.2H126.4C56.8.2 0 57 0 126.6v251.6c0 69.2 56.8 126 126.4 126H378c69.6 0 126.4-56.8 126.4-126.4V126.6C504 57 447.2.2 377.6.2zM168 408.2H96v-208h72v208zm-36.4-240c-20.4 0-36.8-16.4-36.8-36.8s16.4-36.8 36.8-36.8 36.8 16.4 36.8 36.8c-.4 20.4-16.8 36.8-36.8 36.8zm276.8 240H348V307.4c0-24.4-3.2-55.6-36.4-55.6-34 0-39.6 26.4-39.6 54v102.4h-60v-208h56v28h1.6c8.8-16 29.2-28.4 61.2-28.4 66 0 77.6 38 77.6 94.4v114z"></path>
                        </svg>
                      </a>
                      <a
                        href="https://www.youtube.com/channel/UCwDi1NwScPpDYyvwPvHBj9g"
                        rel="nofollow"
                        class="social-icon"
                        aria-label="Our Youtube"
                      >
                        <svg
                          height="682pt"
                          viewBox="-21 -117 682.667 682"
                          width="682pt"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M626.813 64.035c-7.375-27.418-28.993-49.031-56.407-56.414C520.324-6.082 319.992-6.082 319.992-6.082s-200.324 0-250.406 13.184c-26.887 7.375-49.031 29.52-56.406 56.933C0 114.113 0 217.97 0 217.97s0 104.379 13.18 153.933c7.382 27.414 28.992 49.028 56.41 56.41C120.195 442.02 320 442.02 320 442.02s200.324 0 250.406-13.184c27.418-7.379 49.032-28.992 56.414-56.406 13.176-50.082 13.176-153.934 13.176-153.934s.527-104.383-13.183-154.46zM256.21 313.915V122.022l166.586 95.946zm0 0"></path>
                        </svg>
                      </a>
                      <a
                        href="https://open.spotify.com/user/12147390775/playlist/6p7sfcY8NgLASvan5kY5KX"
                        rel="nofollow"
                        class="social-icon"
                        aria-label="Our Spotify Playlist"
                      >
                        <svg
                          height="512"
                          viewBox="0 0 24 24"
                          width="512"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M12 24c6.624 0 12-5.376 12-12S18.624 0 12 0 0 5.376 0 12s5.376 12 12 12zm4.872-6.344v.001c-.807 0-3.356-2.828-10.52-1.36-.189.049-.436.126-.576.126-.915 0-1.09-1.369-.106-1.578 3.963-.875 8.013-.798 11.467 1.268.824.526.474 1.543-.265 1.543zm1.303-3.173c-.113-.03-.08.069-.597-.203-3.025-1.79-7.533-2.512-11.545-1.423-.232.063-.358.126-.576.126-1.071 0-1.355-1.611-.188-1.94 4.716-1.325 9.775-.552 13.297 1.543.392.232.547.533.547.953a.938.938 0 0 1-.938.944zM4.548 6.998c4.523-1.324 11.368-.906 15.624 1.578 1.091.629.662 2.22-.498 2.22l-.001-.001c-.252 0-.407-.063-.625-.189C15.605 8.55 9.444 8.057 5.458 9.17c-.175.048-.393.125-.625.125-.639 0-1.127-.499-1.127-1.142 0-.657.407-1.029.842-1.155z"></path>
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col footer-column-2">
                  <div
                    id="nav_menu-2"
                    class="widget widget_nav_menu footer-widgets-2"
                  >
                    <p class="widget-title">Company</p>
                    <div class="menu-company-container">
                      <ul id="menu-company" class="menu">
                        <li
                          id="menu-item-12074"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-12074"
                        >
                          <a href="about">About Us</a>
                        </li>
                        <li
                          id="menu-item-12070"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-12070"
                        >
                          <a href="contact">Contact Us</a>
                        </li>
                        <li
                          id="menu-item-12071"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-12071"
                        >
                          <a href="blog">Blog</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col footer-column-3">
                  <div
                    id="nav_menu-3"
                    class="widget widget_nav_menu footer-widgets-3"
                  >
                    <p class="widget-title">Services</p>
                    <div class="menu-services-container">
                      <ul id="menu-services" class="menu">
                        <li
                          id="menu-item-12075"
                          class="footer-cro-trigger menu-item menu-item-type-post_type menu-item-object-page menu-item-12075"
                        >
                          <a>We help you prepare your resume</a>
                        </li>
                        <li
                          id="menu-item-12078"
                          class="footer-ppc-trigger menu-item menu-item-type-post_type menu-item-object-page menu-item-12078"
                        >
                          <a>We help you get the Job</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col footer-column-4">
                  <div
                    id="nav_menu-4"
                    class="widget widget_nav_menu footer-widgets-4"
                  >
                    <p class="widget-title">More</p>
                    <div class="menu-solutions-container"></div>
                  </div>
                </div>
                <div class="col footer-column-5">
                  <div id="text-3" class="widget widget_text footer-widgets-5">
                    <p class="widget-title">Want to get started?</p>
                    <div class="textwidget">
                      <p>Fill in this form and lets help you get started</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="copyright-line text-center">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  Copyright
                  <br class="d-none d-md-block" />
                </div>
              </div>
            </div>
          </div>
        </footer>
      </body>
    </>
  );
};

export default Contact;
