import React, { Fragment, useEffect, useState, useRef } from "react";

const Nav = () => {
  return (
    <div>
      <header class="header-area p-relative">
        <div class="navbar-area navbar-area-two">
          <div class="mobile-nav">
            <div class="container">
              <a href="index-2.html" class="logo">
                <img
                  src="assets/img/black-logo.png"
                  alt="Logo"
                  class="logo-light"
                />
                <img src="assets/img/logo.png" alt="Logo" class="logo-dark" />
              </a>
            </div>
          </div>

          <div class="main-nav">
            <div class="container">
              <div class="navbar navbar-expand-md">
                <a class="navbar-brand" href="index-2.html">
                  <img
                    src="assets/img/black-logo.png"
                    alt="Logo"
                    class="logo-light"
                  />
                  <img src="assets/img/logo.png" alt="Logo" class="logo-dark" />
                </a>

                <div class="collapse navbar-collapse mean-menu">
                  <ul class="navbar-nav m-auto">
                    <li class="nav-item">
                      <a href="#" class="nav-link active">
                        Home
                        <i class="bx bx-chevron-down"></i>
                      </a>

                      <ul class="dropdown-menu">
                        <li class="nav-item">
                          <a href="index-2.html" class="nav-link active">
                            Home One
                          </a>
                        </li>
                        <li class="nav-item">
                          <a href="index-3.html" class="nav-link">
                            Home Two
                          </a>
                        </li>
                        <li class="nav-item">
                          <a href="index-4.html" class="nav-link">
                            Home Three
                          </a>
                        </li>
                        <li class="nav-item">
                          <a href="index-5.html" class="nav-link">
                            Home Four
                          </a>
                        </li>
                        <li class="nav-item">
                          <a href="index-6.html" class="nav-link">
                            Home Five
                          </a>
                        </li>
                        <li class="nav-item">
                          <a href="index-7.html" class="nav-link">
                            Home Six
                          </a>
                        </li>
                      </ul>
                    </li>

                    <li class="nav-item">
                      <a href="#" class="nav-link">
                        Pages
                        <i class="bx bx-chevron-down"></i>
                      </a>

                      <ul class="dropdown-menu">
                        <li class="nav-item">
                          <a href="about.html" class="nav-link">
                            About Us
                          </a>
                        </li>

                        <li class="nav-item">
                          <a href="#" class="nav-link">
                            Services
                            <i class="bx bx-chevron-right"></i>
                          </a>

                          <ul class="dropdown-menu">
                            <li class="nav-item">
                              <a
                                href="services-style-one.html"
                                class="nav-link"
                              >
                                Services Style One
                              </a>
                            </li>
                            <li class="nav-item">
                              <a
                                href="services-style-two.html"
                                class="nav-link"
                              >
                                Services Style Two
                              </a>
                            </li>
                            <li class="nav-item">
                              <a
                                href="services-style-three.html"
                                class="nav-link"
                              >
                                Services Style Three
                              </a>
                            </li>
                            <li class="nav-item">
                              <a href="services-details.html" class="nav-link">
                                Service Details
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li class="nav-item">
                          <a href="team.html" class="nav-link">
                            Team
                          </a>
                        </li>

                        <li class="nav-item">
                          <a href="testimonials.html" class="nav-link">
                            Testimonials
                          </a>
                        </li>

                        <li class="nav-item">
                          <a href="pricing.html" class="nav-link">
                            Pricing
                          </a>
                        </li>

                        <li class="nav-item">
                          <a href="#" class="nav-link">
                            User
                            <i class="bx bx-chevron-right"></i>
                          </a>

                          <ul class="dropdown-menu">
                            <li class="nav-item">
                              <a href="my-account.html" class="nav-link">
                                My Account
                              </a>
                            </li>
                            <li class="nav-item">
                              <a href="log-in.html" class="nav-link">
                                Log In
                              </a>
                            </li>
                            <li class="nav-item">
                              <a href="registration.html" class="nav-link">
                                Registration
                              </a>
                            </li>
                            <li class="nav-item">
                              <a href="recover-password.html" class="nav-link">
                                Recover Password
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li class="nav-item">
                          <a href="faq.html" class="nav-link">
                            FAQ
                          </a>
                        </li>
                        <li class="nav-item">
                          <a href="privacy-policy.html" class="nav-link">
                            Privacy Policy
                          </a>
                        </li>
                        <li class="nav-item">
                          <a href="terms-conditions.html" class="nav-link">
                            Terms & Conditions
                          </a>
                        </li>
                        <li class="nav-item">
                          <a href="coming-soon.html" class="nav-link">
                            Coming Soon
                          </a>
                        </li>
                        <li class="nav-item">
                          <a href="404.html" class="nav-link">
                            404 Error Page
                          </a>
                        </li>
                      </ul>
                    </li>

                    <li class="nav-item">
                      <a href="#" class="nav-link">
                        Solutions
                        <i class="bx bx-chevron-down"></i>
                      </a>

                      <ul class="dropdown-menu">
                        <li class="nav-item">
                          <a href="solutions.html" class="nav-link">
                            Solutions
                          </a>
                        </li>
                        <li class="nav-item">
                          <a href="solution-details.html" class="nav-link">
                            Solution Details
                          </a>
                        </li>
                      </ul>
                    </li>

                    <li class="nav-item">
                      <a href="#" class="nav-link">
                        Shop
                        <i class="bx bx-chevron-down"></i>
                      </a>

                      <ul class="dropdown-menu">
                        <li class="nav-item">
                          <a href="products.html" class="nav-link">
                            Products
                          </a>
                        </li>
                        <li class="nav-item">
                          <a href="shopping-cart.html" class="nav-link">
                            Shopping Cart
                          </a>
                        </li>
                        <li class="nav-item">
                          <a href="checkout.html" class="nav-link">
                            Checkout
                          </a>
                        </li>
                        <li class="nav-item">
                          <a href="single-product.html" class="nav-link">
                            Single Product
                          </a>
                        </li>
                        <li class="nav-item">
                          <a href="wishlist.html" class="nav-link">
                            Wishlist
                          </a>
                        </li>
                      </ul>
                    </li>

                    <li class="nav-item">
                      <a href="#" class="nav-link">
                        Blog
                        <i class="bx bx-chevron-down"></i>
                      </a>

                      <ul class="dropdown-menu">
                        <li class="nav-item">
                          <a href="blog-column-one.html" class="nav-link">
                            Blog Column One
                          </a>
                        </li>
                        <li class="nav-item">
                          <a href="blog-column-two.html" class="nav-link">
                            Blog Column Two
                          </a>
                        </li>
                        <li class="nav-item">
                          <a href="blog-column-three.html" class="nav-link">
                            Blog Column Three
                          </a>
                        </li>
                        <li class="nav-item">
                          <a href="blog-left-sidebar.html" class="nav-link">
                            Blog Left Sidebar
                          </a>
                        </li>
                        <li class="nav-item">
                          <a href="blog-details.html" class="nav-link">
                            Blog Details
                          </a>
                        </li>
                      </ul>
                    </li>

                    <li class="nav-item">
                      <a href="contact.html" class="nav-link">
                        Contact
                      </a>
                    </li>
                  </ul>

                  <div class="others-option">
                    <div class="cart-icon">
                      <a href="shopping-cart.html">
                        <i class="bx bx-cart"></i>
                        <span>0</span>
                      </a>
                    </div>

                    <div class="get-quote">
                      <a href="#" class="default-btn">
                        <span>Get a Quote</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="others-option-for-responsive">
            <div class="container">
              <div class="dot-menu">
                <div class="inner">
                  <div class="circle circle-one"></div>
                  <div class="circle circle-two"></div>
                  <div class="circle circle-three"></div>
                </div>
              </div>

              <div class="container">
                <div class="option-inner">
                  <div class="others-option justify-content-center d-flex align-items-center">
                    <div class="get-quote">
                      <a href="#" class="default-btn">
                        <span>Get a Quote</span>
                      </a>
                    </div>

                    <div class="cart-icon">
                      <a href="shopping-cart.html">
                        <i class="bx bx-cart"></i>
                        <span>0</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Nav;
